import { useState, useEffect, useRef } from "react";

import Form from "react-bootstrap/Form";
import {
  faPlus,
  faCheck,
  faFloppyDisk,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import Table from "../../../../components/Table";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal";
import MultiSelect from "../../../../components/MultiSelect";
import IconButton from "../../../../components/IconButton";

import COLORS from "../../../../data/colors";
import toastConfiguration from "../../../../data/toastConfiguration";
import permissions from "../../../../data/permissions";

import ADD_ROLE from "../../../../graphql/mutations/account/addRole";
import REMOVE_ROLE from "../../../../graphql/mutations/account/removeRole";
import UPDATE_ROLE from "../../../../graphql/mutations/account/updateRole";

import { useTranslation } from "react-i18next";

const Roles = ({ accounts, roles, refetchRoles }) => {
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showRemovePermissionMessage, setShowRemovePermissionMessage] =
    useState(false);

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [buttonAction, setButtonAction] = useState("");

  const formRef = useRef(null);
  const showedRemovePermissionMessage = useRef(false);
  
  const { t } = useTranslation();

  useEffect(() => {
    setValidated(false);
  }, [roleName, selectedPermissions]);

  const [addRole, { loading: loadingAddRole }] = useMutation(ADD_ROLE);

  const [removeRole, { loading: loadingRemoveRole }] = useMutation(REMOVE_ROLE);

  const [updateRole, { loading: loadingUpdateRole }] = useMutation(UPDATE_ROLE);

  const formIsValidated = (e) => {
    setValidated(true);
    e.preventDefault();

    if (!formRef.current.checkValidity() || selectedPermissions.length === 0) {
      e.stopPropagation();
      return false;
    }

    return true;
  };

  const handleAdd = async (e) => {
    if (!formIsValidated(e)) return;

    const toastId = toast.loading(t('accountsAndAccess.roleFeedback.create.loading'), toastConfiguration);
    try {
      const result = await addRole({
        variables: {
          roleName,
          permissions: selectedPermissions.map((permission) => ({
            Name: permission.label,
            Value: permission.value,
          })),
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.roleFeedback.create.success') + `"${roleName}"`,
        type: "success",
        isLoading: false,
      });

      refetchRoles();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.roleFeedback.create.failure') + `"${roleName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleDelete = async (e) => {
    const numOfAccountsThatHaveTheChosenRole = accounts.filter(
      (account) => account.role.id === selectedRoleId
    ).length;

    if (numOfAccountsThatHaveTheChosenRole !== 0) {
      toast.warning(
        t('accountsAndAccess.roleFeedback.delete.warning.part1') + 
        `"${roleName}"` +
        t('accountsAndAccess.roleFeedback.delete.warning.part2') +
        `${numOfAccountsThatHaveTheChosenRole}` + 
        t('accountsAndAccess.roleFeedback.delete.warning.part3') +
        `${
          numOfAccountsThatHaveTheChosenRole > 1 ? 
          t('accountsAndAccess.roleFeedback.delete.warning.part4.a') : 
          t('accountsAndAccess.roleFeedback.delete.warning.part4.b')
        }` +
        t('accountsAndAccess.roleFeedback.delete.warning.part5'),
        toastConfiguration
      );
      setSelectedRoleId("");
      setRoleName("");
      setSelectedPermissions([]);
      setShowModal(false);
      return;
    }

    const toastId = toast.loading(t('accountsAndAccess.roleFeedback.delete.loading'), toastConfiguration);
    try {
      const result = await removeRole({
        variables: {
          id: selectedRoleId,
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.roleFeedback.delete.success') + `"${roleName}"`,
        type: "success",
        isLoading: false,
      });

      refetchRoles();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.roleFeedback.delete.failure') + `"${roleName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleEdit = async (e) => {
    if (!formIsValidated(e)) return;

    const toastId = toast.loading(t('accountsAndAccess.roleFeedback.update.loading'), toastConfiguration);
    try {
      const result = await updateRole({
        variables: {
          id: selectedRoleId,
          roleName: roleName,
          permissions: selectedPermissions.map((permission) => ({
            Name: permission.label,
            Value: permission.value,
          })),
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.roleFeedback.update.success') + `"${result.data.updateRole.name}"`,
        type: "success",
        isLoading: false,
      });

      refetchRoles();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.roleFeedback.update.failure') + `"${roleName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  return (
    <>
      <Table
        data={roles.map((role) => ({
          name: role.name,
          permissions: role.permissions
            .map((permission) => permission.name)
            .join(", "),
        }))}
        page={0}
        setPage={() => {}}
        type={``}
        expandFilters={false}
        setExpandFilters={() => {}}
        filters={{}}
        setFilters={() => {}}
        sortBy={""}
        setSortBy={() => {}}
        columnsPerFieldMap={{
          roleName: "col-3",
          permissions: "col-9",
        }}
        rowClassName="accounts-and-access-table-row"
        headerClassName="accounts-and-access-table-header"
      >
        {roles.map((role) => (
          <div
            key={role.id}
            className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
          >
            <IconButton
              variant="edit"
              disabled={false}
              title={t('accountsAndAccess.editRole.buttonText')}
              onClick={(e) => {
                setButtonAction("edit");
                setSelectedRoleId(role.id);
                setRoleName(role.name);
                setSelectedPermissions(
                  role.permissions.map((permission) => ({
                    value: permission.value,
                    label: permission.name,
                  }))
                );
                setShowModal(true);
              }}
            />
            <IconButton
              variant="delete"
              disabled={loadingRemoveRole}
              title={t('accountsAndAccess.deleteRole.buttonText')}
              onClick={(e) => {
                setButtonAction("delete");
                setSelectedRoleId(role.id);
                setRoleName(role.name);
                setSelectedPermissions([]);
                setShowModal(true);
              }}
            />
          </div>
        ))}
      </Table>
      <div className="d-flex justify-content-end mx-1 mx-xxl-5">
        <CustomButton
          buttonType={"primary"}
          disabled={false}
          text={t('accountsAndAccess.addNewRole.buttonText')}
          icon={faPlus}
          hasIcon={true}
          iconColor={COLORS.white}
          handleClick={(e) => {
            setSelectedRoleId("");
            setRoleName("");
            setSelectedPermissions([]);
            setShowModal(true);
          }}
        />
      </div>
      <CustomModal
        show={showModal}
        handleClose={() => {
          setSelectedRoleId("");
          setRoleName("");
          setSelectedPermissions([]);
          setShowModal(false);
        }}
        disabledCancelButton={
          loadingAddRole || loadingRemoveRole || loadingUpdateRole
        }
        body={
          (!selectedRoleId || buttonAction === "edit") && (
            <div>
              <Form validated={validated} noValidate ref={formRef}>
                <Form.Group className="mb-3" controlId="formRoleName">
                  <Form.Label>{t('accountsAndAccess.roleForm.roleName.label')}</Form.Label>
                  <Form.Control
                    placeholder={t('accountsAndAccess.roleForm.roleName.label')}
                    value={roleName}
                    required
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                  {t('accountsAndAccess.roleForm.roleName.error')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formRoleName">
                  <Form.Label>{t('accountsAndAccess.roleForm.rolePermissions.label')}</Form.Label>
                  <MultiSelect
                    options={permissions}
                    placeholder={t('accountsAndAccess.roleForm.rolePermissions.placeholder')}
                    onChange={(selectedValues) => {
                      if (selectedValues.length === 0) {
                        // if (showedRemovePermissionMessage.current) {
                        //   return;
                        // }

                        showedRemovePermissionMessage.current = true;
                        setShowRemovePermissionMessage(true);
                        setTimeout(() => {
                          setShowRemovePermissionMessage(false);
                        }, 3000);
                        return;
                      }
                      setSelectedPermissions(selectedValues);
                    }}
                    value={selectedPermissions}
                    validated={validated}
                    invalid={selectedPermissions.length === 0}
                    invalidText={t('accountsAndAccess.roleForm.rolePermissions.error.a')}
                  />
                  {showRemovePermissionMessage && (
                    <div className="invalid-feedback d-block">
                      {t('accountsAndAccess.roleForm.rolePermissions.error.b')}
                    </div>
                  )}
                </Form.Group>
              </Form>
            </div>
          )
        }
        button={
          !selectedRoleId ? ( // new
            <CustomButton
              buttonType={"primary"}
              disabled={loadingAddRole}
              text={t('accountsAndAccess.addNewRole.buttonText')}
              icon={faCheck}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleAdd}
            />
          ) : buttonAction === "edit" ? ( // edit
            <CustomButton
              buttonType={"accept"}
              disabled={loadingUpdateRole}
              text={t('accountsAndAccess.saveChangesButton')}
              icon={faFloppyDisk}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleEdit}
            />
          ) :  buttonAction === "delete" && (
            // delete
            <CustomButton
              buttonType={"delete"}
              disabled={loadingRemoveRole}
              text={t('accountsAndAccess.deleteRole.buttonText')}
              icon={faTrash}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleDelete}
            />
          )
        }
        title={
          !selectedRoleId ? (
            t('accountsAndAccess.addNewRole.title')
          ) : buttonAction === "edit" ? (
            t('accountsAndAccess.editRole.title')
          ) : buttonAction === "delete" && (
            <>
              {t('accountsAndAccess.deleteRole.title.part1')}
              <span className="accounts-and-access-delete-text-on-modal">
                {t('accountsAndAccess.deleteRole.title.part2')}
              </span>{" "}
              {t('accountsAndAccess.deleteRole.title.part3')}
              {`"${roles.find((role) => role.id === selectedRoleId).name}"`}
            </>
          )
        }
        subtitle={
          !selectedRoleId ? (
            t('accountsAndAccess.addNewRole.subTitle')
          ) : buttonAction === "edit" ? (
            <>
              {t('accountsAndAccess.editRole.subTitle')}
              <span className="fw-bold">{t('accountsAndAccess.saveChangesButton')}</span>
            </>
          ) : buttonAction === "delete" && (
            t('accountsAndAccess.deleteRole.subTitle')
          )
        }
        hasIcon
        fullscreen
      />
    </>
  );
};

export default Roles;
