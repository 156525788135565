import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import GET_IIA_LISTING from "../../../graphql/queries/IIAs/getIIAsListing";

import CustomTable from "../../../components/Table";
import TableSkeleton from "../../../components/Table/components/TableSkeleton";
import Filters from "../../../components/Filters";

import { useEffect, useState } from "react";
import { useIiaFilters } from "./hooks/useIiaFilters";
import { StatusMapping } from "../../../data/statusMapping";
import IconButton from "../../../components/IconButton";
import CustomModal from "../../../components/CustomModal";
import CustomButton from "../../../components/CustomButton";
import { toast, ToastContainer } from "react-toastify";
import toastConfiguration from "../../../data/toastConfiguration";
import DELETE_IIA from "../../../graphql/mutations/IIAs/deleteIIA";
import COLORS from "../../../data/colors";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useUser from "../../../hooks/useUser";
import TERMINATE_IIA from "../../../graphql/mutations/IIAs/terminateIIA";
import { Form } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const InterInstitutionalAgreementList = ({ userHeiID }) => {
  const { t } = useTranslation();

  const { user } = useUser();
  const initialState = {
    partnerID: "",
    status: "",
    partnerHeiId: "",
    approvingPersonEmail: "",
    organizationalUnit: "",
    sortBy: localStorage.getItem('sortByIIAs') !== null ? JSON.parse(localStorage.getItem('sortByIIAs')) : "changed_descending",
    selectedFiltersList: localStorage.getItem('selectedFiltersIIAs') !== null ? JSON.parse(localStorage.getItem('selectedFiltersIIAs')) : {
      partnerID: "",
      status: "",
      partnerHeiId: "",
      approvingPersonEmail: "",
      organizationalUnit: ""
    },
  };
  const filters = useIiaFilters(initialState);
  const {
    state,
    setPartnerId,
    setApprovingPersonEmail,
    setOrganizationalUnit,
    setPartnerHeiId,
    setStatus,
    setExpandFilters,
    setSelectedFiltersList,
    setSortBy,
    updateMultipleValues
  } = filters;
  const {
    partnerID,
    status,
    partnerHeiId,
    approvingPersonEmail,
    organizationalUnit,
    selectedFiltersList,
    sortBy,
    expandFilters,
  } = state;
  const [page, setPage] = useState(localStorage.getItem('pageIIAs') !== null ? parseInt(JSON.parse(localStorage.getItem('pageIIAs'))) : 0);
  const navigate = useNavigate();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalTerminate, setShowModalTerminate] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [uuidToTerminate, setUuidToTerminate] = useState("");
  const [checkedTerminate, setCheckedTerminate] = useState(false);

  const {
    loading: iiaListingLoading,
    error: iiaListingError,
    data: iiaListingData,
    refetch: iiaListingRefetch,
  } = useQuery(GET_IIA_LISTING, {
    variables: {
      page: page,
      sortBy: sortBy,
      optionalFilters:{
        signingContactEmail: approvingPersonEmail,
        partnerIiaID:partnerID,
        partnerHeiID:partnerHeiId,
        organizationalUnit: organizationalUnit,
        status: StatusMapping[status]?.toLowerCase().split(" ").join("_")
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    deleteIIA, { loading: loadingDeleteIIA }
  ] = useMutation(DELETE_IIA);

  const [
    terminateIIA, { loading: loadingTerminateIIA }
  ] = useMutation(TERMINATE_IIA);

  const handleExpand = (value) => {
    setExpandFilters(value);
  };

  useEffect(() => {
    const data = localStorage.getItem('selectedFiltersIIAs');
    
    if (data) {
      if (!Object.values(JSON.parse(data) || {})?.every(x => x === '')) {
        setSelectedFiltersList(JSON.parse(data));
        iiaListingRefetch();
      }
      
    }
  }, []);

  useEffect(() => {
    page !== null && localStorage.setItem('pageIIAs', JSON.stringify(page));
  }, [page]);

  useEffect(() => {
    localStorage.setItem('selectedFiltersIIAs', JSON.stringify(selectedFiltersList));
  }, [selectedFiltersList]);

  useEffect(() => {
    sortBy !== null && localStorage.setItem('sortByIIAs', JSON.stringify(sortBy));
  }, [sortBy]);

  useEffect(() => {
    updateMultipleValues(selectedFiltersList);
    iiaListingRefetch();
  }, [
    selectedFiltersList,
    updateMultipleValues,
    iiaListingRefetch,
  ]);

  const handleRemoveFilter = (filter, value) => {
    if (filter === "partnerHeiId") {
      let temp = Object.assign({}, selectedFiltersList);
      temp[filter] = "";
      temp["erasmusCode"] = "";
      setSelectedFiltersList(temp);
    } else {
      let temp = Object.assign({}, selectedFiltersList);
      temp[filter] = "";
      setSelectedFiltersList(temp);
    }
  };

  const formatDate = (string) => {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(string).toLocaleDateString("en-UK",options);
  }

  const handleDelete = async () => {
    const toastId = toast.loading(t('iiaList.iiaFeedback.delete.loading'), toastConfiguration);

    try {
        const result = await deleteIIA({
            variables: {
              id: idToDelete,
            },
          });
          setShowModalDelete(false);
            if (!result?.errors) {
            iiaListingRefetch();
            toast.update(toastId, {
                ...toastConfiguration,
                render: t('iiaList.iiaFeedback.delete.success'),
                type: "success",
                isLoading: false,
                });
        }
    } catch (error) {
        if (error) {
            toast.update(toastId, {
                ...toastConfiguration,
                render: t('iiaList.iiaFeedback.delete.failure'),
                type: "error",
                isLoading: false,
              });
        }
    }
    
}

const handleTerminate = async () => {
  const toastId = toast.loading(t('iiaList.iiaFeedback.terminate.loading'), toastConfiguration);

  try {
      const result = await terminateIIA({
          variables: {
            uuid: uuidToTerminate,
          },
        });
        setShowModalTerminate(false);
          if (!result?.errors) {
          iiaListingRefetch();
          toast.update(toastId, {
              ...toastConfiguration,
              render: t('iiaList.iiaFeedback.terminate.success'),
              type: "success",
              isLoading: false,
              });
      }
  } catch (error) {
      if (error) {
          toast.update(toastId, {
              ...toastConfiguration,
              render: t('iiaList.iiaFeedback.terminate.failure'),
              type: "error",
              isLoading: false,
            });
      }
  }
  
}

  if (iiaListingLoading) {
    return (
      <TableSkeleton
        columnsPerFieldMap={{
          id_: "col-2",
          partner: "col-6",
          last_edited: "col-2",
          _: "col-2"

        }}
      />
    );
  }


  if (iiaListingError)
    return (
      <div className="text-center text-danger mx-auto">{t('errorMessage')}</div>
    );

  return (
    <main className="h-auto text-center mt-4">
      <CustomTable
        page={page}
        setPage={(p) => setPage(p)}
        type={"iia-"}
        expandFilters={expandFilters}
        setExpandFilters={(e) => setExpandFilters(e)}
        filters={selectedFiltersList}
        setFilters={(filter, value) => {handleRemoveFilter(filter, value); setPage(0);}}
        sortBy={sortBy}
        setSortBy={() => setSortBy("")}
        columnsPerFieldMap={{
          your_id: "col-3",
          partner_id: "col-3",
          partner: "col-3",
          last_edited: "col-1",
          status: "col-2"
        }}
        pageLimit={30}
        totalCount={iiaListingData?.iias?.count}
        hasPagination={true}
        hasFilters={true}
        rowClassName="iias-listing-table-row"
        headerClassName="iias-listing-table-header"
        onClickHandlers={iiaListingData?.iias?.iiaList.map(({ id }) => (e) => {
          navigate("/iias/single-view/" + id);
        })}
        onMouseDownHandlers={iiaListingData?.iias?.iiaList.map(({ id }) => (e) => {
          e.button === 1 && window.open(window.location.origin + "/iias/single-view/" + id);
        })}
        data={iiaListingData?.iias?.iiaList?.map(
          ({
            partners,
            iia_status,
            changed_time,
            last_author,
            type_of_communication,
            terminated
          }) => {
            const currentUserIiaId = partners?.find(p => p?.institution?.heiID === userHeiID);
            const partner = partners?.find(p => p?.institution?.heiID !== userHeiID);
            
            return ({
              your_id: type_of_communication==="dashboard"?(currentUserIiaId?.iia_id?currentUserIiaId?.iia_id:partner?.iia_id):currentUserIiaId?.iia_id,
              partner_id:  type_of_communication==="dashboard"?(partner?.iia_id?partner?.iia_id:currentUserIiaId?.iia_id):partner?.iia_id,
              partner: <>{partner?.institution?.heiID}<div className="iia-institution-name">{partner?.institution?.name}</div></>,
              last_edited: formatDate(changed_time),
              status: (iia_status==="approved-by-all"||iia_status==="draft"||iia_status==="deleted") ? terminated ? "terminated-approved-by-all" : iia_status :
               terminated ? "terminated-" + iia_status + "-" + (last_author === userHeiID ? "p1" : "p2")  : iia_status + "-" + (last_author === userHeiID ? "p1" : "p2"),
            })
          }
        )
        }
      >
        {iiaListingData?.iias?.iiaList?.map((iia) => (
          <div
            key={iia?.id}
            className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
          >
            <IconButton
              variant="edit"
              disabled={(iia?.terminated && iia?.iia_status !== "approved-by-all")}
              title={(iia?.iia_status === "approved-by-all") ? t('iiaList.modifyIIA') : t('iiaList.editIIA')}
              onClick={(e) => {
                navigate("/iias/edit/" + iia?.id);
              }}
            />
            <IconButton
              variant="delete"
              title={t('iiaList.deleteIIA')}
              disabled={(iia?.approved_copy) || iia?.iia_status === "approved-by-all"}
              onClick={() => {
                setShowModalDelete(true); setIdToDelete(iia?.id);}}   
            />
          </div>
        ))}
      </CustomTable>
      <Filters
        expand={expandFilters}
        handleExpand={handleExpand}
        selectedFiltersList={selectedFiltersList}
        setSelectedFiltersList={(e) => {setSelectedFiltersList(e); setPage(0);}}
        sortBy={sortBy}
        setSortBy={(e) => setSortBy(e)}
        countries={[]}
        type={"iia"}
      />
      <CustomModal show={showModalDelete}  handleClose={() => setShowModalDelete(false)} 
            body={t('iiaList.modalDelete.body')}
            button={<CustomButton buttonType={"decline"} text={t('iiaList.modalDelete.deleteButton')} disabled={loadingDeleteIIA} icon={faTrash} hasIcon={true} iconColor={COLORS.white}  handleClick={handleDelete}/>} 
            title={<div>{t('iiaList.modalDelete.title.part1')}<span style={{color: "red"}}>{t('iiaList.modalDelete.title.part2')}</span>{t('iiaList.modalDelete.title.part3')}</div>} hasIcon={false} disabledCancelButton={loadingDeleteIIA}/>
      <CustomModal show={showModalTerminate}  handleClose={() => setShowModalTerminate(false)} 
            body={<><Form.Check type="checkbox">
            <Form.Check.Input
                type={"checkbox"}
                className="register"
                // isInvalid={validated && !checked}
                // isValid={validated && checked}
                value={checkedTerminate}
                onClick={(e) => setCheckedTerminate(!checkedTerminate)}
            />
            <Form.Check.Label>
            <div className="h4-style">{t('iiaList.modalTerminate.body.part1')}<span style={{color: "red"}}>{t('iiaList.modalTerminate.body.part2')}</span>{t('iiaList.modalTerminate.body.part3')}</div>
            </Form.Check.Label>
        </Form.Check></>}
            button={<CustomButton buttonType={"decline"} text={t('iiaList.modalTerminate.terminateButton')} disabled={loadingTerminateIIA || !checkedTerminate} icon={faTrash} hasIcon={true} iconColor={COLORS.white}  handleClick={handleTerminate}/>} 
            title={<div>{t('iiaList.modalTerminate.title.part1')}<span style={{color: "red"}}>{t('iiaList.modalTerminate.title.part2')}</span>{t('iiaList.modalTerminate.title.part3')}</div>} hasIcon={false} disabledCancelButton={loadingTerminateIIA}/>
      <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
    </main>
  );
};

export default InterInstitutionalAgreementList;
