import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client/react';
import GET_ISCED_CODES from '../../../graphql/queries/IIAs/getISCEDCodes';
import useUser from '../../../hooks/useUser';
import { useNavigate } from "react-router-dom";

import { Form, Row, Col, ProgressBar } from "react-bootstrap";
import {
    faUpload
} from "@fortawesome/free-solid-svg-icons";
import Papa from 'papaparse';
import CustomButton from '../../../components/CustomButton';

import COLORS from '../../../data/colors';
import CustomModal from '../../../components/CustomModal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from '../../../data/toastConfiguration';
import { validateEmailFormat, validateErasmusCode, validatePhoneFaxNumber, validateRegularNamesOfRequiredFields } from './Validator';
import GET_OUNITS from '../../../graphql/queries/IIAs/getOUnits';
import ADD_IIA from '../../../graphql/mutations/IIAs/addIIA';
import { languagesvalues } from '../../Nominations/Requirements/Taxonomy';
import GET_SETTINGS from '../../../graphql/queries/IIAs/getSettings';
import GET_ERASMUS_CODES from '../../../graphql/queries/registration/getErasmusCodes';

import { useTranslation } from 'react-i18next';

const ImportIIA = () => {
    const { user } = useUser();
    const navigate = useNavigate();

    const [checkedConsent, setCheckedConsent] = useState(false);
    const [file, setFile] = useState(null);
    const [selectedFileData, setSelectedFileData] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(['Please select a CSV file']);
    const [pendingUpload, setPendingUpload] = useState(false);
    const [uploadData, setUploadData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [finishedUploading, setFinishedUploading] = useState(false);
    const [uploadResults, setUploadResults] = useState([]);
    const [uploadHasError, setUploadHasError] = useState(false);
    const [allIIAs, setAllIIAs] = useState(null);
    const [firstMount, setFirstMount] = useState(true);
    const [localOunits, setLocalOunits] = useState([]);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const maxIIA2Import = 51;        // always add 1 more to the actual limit, because the first row is the CSV header

    const { t } = useTranslation('translation', {keyPrefix: 'iiaImport'});

    const {
        data: iscedCodes,
        loading: loadingIsced
    } = useQuery(GET_ISCED_CODES);

    const {
        data: ecodes,
    } = useQuery(GET_ERASMUS_CODES, {
        variables: { countryCodes: [] }
    });

    const {
        refetch: refetchQueryOunitsPartner
    } = useQuery(GET_OUNITS, {
        variables: {
            heiID: user?.heiID
        },
        onCompleted: (data) => {
            if (firstMount) {
                setLocalOunits(data?.ounits);
                setFirstMount(false);
            }
        }

    });



    const [
        addIIA
    ] = useMutation(ADD_IIA);

    const {
        refetch: fetchEWPSettings
    }
        = useQuery(GET_SETTINGS);


    useEffect(() => {
        if (uploadData) {
            upload();
        }
    }, [uploadData]);

    useEffect(() => {
        if (file?.length > 0) {
            convertData();
        }
    }, [file]);

    useEffect(() => {
        if (selectedFileData) {
            parsingData();
        }
    }, [selectedFileData])

    const checkConsent = () => {
        setCheckedConsent(!checkedConsent);
    }

    const upload = (e) => {
        setFile(e.target.files[0]);
        let tempFile = e.target.files[0]

        if (tempFile != null) {
            if (tempFile.name.substring(tempFile.name.lastIndexOf('.') + 1) !== "csv") {
                alert(t('alerts.incorrectFileType'));

                setSelectedFileData(null);

            }
            else {
                var reader = new FileReader();
                reader.readAsText(tempFile);

                reader.onload = (event) => {
                    let data = convertData(event.target.result);
                    if (isTemplateValid(data)) {
                        setSelectedFileData(removeEmptyLines(data));
                    }
                    else {
                        alert(t('alerts.invalidTemplate'));
                        setSelectedFileData(null);
                    }
                }
            }
            sessionStorage.removeItem("iiaSubmited");
        }
        else {
            setSelectedFileData(null);
        }
    }

    const convertData = (data) => {
        let dataParsed = Papa.parse(data);

        return dataParsed;
    }

    const isTemplateValid = (data) => {
        let csvHeader = data.data[0];

        if (csvHeader[0] !== "Creator OUnit" || csvHeader[1] !== "Creator Contact Name" || csvHeader[2] !== "Creator Contact Email" || csvHeader[3] !== "Creator Contact Phone" ||
            csvHeader[4] !== "Partner EC" || csvHeader[5] !== "Partner OUnit" || csvHeader[6] !== "Partner Contact Name" || csvHeader[7] !== "Partner Contact Email" ||
            csvHeader[8] !== "Partner Contact Phone") {

            return false;
        }

        for (let i = 9; i < csvHeader.length; i += 15) {
            if (csvHeader[i] !== "Sender EC" || csvHeader[i + 1] !== "Receiver EC" || csvHeader[i + 2] !== "Sending OU" || csvHeader[i + 3] !== "Receiving OU" || csvHeader[i + 4] !== "Start Academic Year" || csvHeader[i + 5] !== "End Academic Year" || csvHeader[i + 6] !== "Mobilities Per Year" ||
                csvHeader[i + 7] !== "Subject Area" || csvHeader[i + 8] !== "Mobility Type" || csvHeader[i + 9] !== "Total Months Per Year Per Mobility" || csvHeader[i + 10] !== "Study Cycle" ||
                csvHeader[i + 11] !== "Blended" || csvHeader[i + 12] !== "Total Days Per Year Per Mobility" || csvHeader[i + 13] !== "Recommended Language Skill" || csvHeader[i + 14] !== "Other Information") {
                return false;
            }
        }

        return true;
    }

    const removeEmptyLines = (data) => {
        let newData = [];
        let countValidIIA = 1;          // the initial value is 1 because of the header of the CSV
        let countEmptyRows = 0;
        let countWrongEC = 0;
        let currentEC = user.erasmusCode;

        data.data.map((line, index) => {
            if (index == 0) { newData.push(line); }
            else {
                if (line.join("").length === 0) { countEmptyRows++; }
                else if (currentEC === line[7] || line[7] === "") { countWrongEC++; }
                else if (countValidIIA < maxIIA2Import) {           // it only imports the first 'maxIIA2Import' agreements and discards the rest
                    newData.push(line);
                    countValidIIA++;
                }

                if ((data.data.length === index + 1) && (countValidIIA >= maxIIA2Import)) {
                    let message1 = "";
                    let message2 = "";
                    if (countEmptyRows > 0 || countWrongEC > 0) {
                        message1 = t('alerts.errorMessage.message1');
                        message2 = t('alerts.errorMessage.message2');
                    }
                    let emptyRowsMessage = "";
                    if (countEmptyRows > 0) { emptyRowsMessage = countEmptyRows + t('alerts.errorMessage.emptyRows'); }
                    let invalidIIAMessage = "";
                    if (countWrongEC > 0) { invalidIIAMessage = countWrongEC + t('alerts.errorMessage.wrongPartnerEC'); }
                    alert(t('alerts.errorMessage.part1') + (maxIIA2Import - 1) + t('alerts.errorMessage.part2')
                        + t('alerts.errorMessage.part3') + (data.data.length - 1) + t('alerts.errorMessage.part4') + message1 + emptyRowsMessage + invalidIIAMessage + t('alerts.errorMessage.part5') + message2 + t('alerts.errorMessage.part6')
                        + ((data.data.length - 1) - (maxIIA2Import - 1) - countWrongEC - countEmptyRows) + t('alerts.errorMessage.part7'));
                }
            }
            return newData;
        });
        if (newData.length > 1) { return newData; }
        else {
            alert(t('alerts.errorMessage2.message'));
            return null;
        }
    }


    const importIIAs = () => {
        setShowModal(false);
        setPendingUpload(true);
    }

    useEffect(() => {
        if (pendingUpload) {
            submitIIA();
        }
    }, [pendingUpload]);

    const parsingData = () => {
        if (!loadingIsced) {
            let allMissingFields = [];
            let missingFields = [];
            let iiaList = [];

            selectedFileData.forEach(async (iia, index) => {
                if (index > 0) {
                    let creatorHei = user.heiID;
                    let partnerHei = ecodes?.heis?.find(ec => iia[4] === ec?.erasmusCode)?.heiID;
                    let partnerOu, ounits, dataSettings;
                    if (partnerHei) {
                        dataSettings = await fetchEWPSettings({ heiID: partnerHei })
                        ounits = await refetchQueryOunitsPartner({ heiID: partnerHei })
                        if (ounits.hasOwnProperty("errors")) {
                            allMissingFields.push("Ounits API for " + partnerHei + " returned error.");
                        }
                        ounits = ounits?.data;
                        partnerOu = ounits?.ounits?.find(ounit => ounit?.ounit_name?.name === iia[5]);
                    }
                    else {
                        allMissingFields.push("Partner HEI Erasmus Code " + iia[4] + " cannot be found in HEI API.");
                        return;
                    }
                    let localOu = localOunits?.find(ounit => ounit?.ounit_name?.name === iia[0]);

                    let partnerDetails = [{
                        conditions_hash: "",
                        hei_id: creatorHei,
                        iia_code: "",
                        ounit_id: localOu?.ounit_id,
                        ounit_name: localOu ? [
                            {
                                language: "en",
                                value: localOu?.ounit_name?.name
                            }] : null,
                        contact: [
                            {
                                contact_names: [
                                    {
                                        language: "en",
                                        value: iia[1]
                                    }
                                ],
                                emails: [
                                    iia[2]
                                ],
                                phone_numbers: [
                                    {
                                        e164: iia[3]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        conditions_hash: "",
                        hei_id: partnerHei,
                        iia_code: "",
                        ounit_id: partnerOu?.ounit_id,
                        ounit_name: partnerOu ? [
                            {
                                "language": "en",
                                "value": partnerOu?.ounit_name?.name
                            }] : null,
                        contact: [
                            {
                                contact_names: [
                                    {
                                        language: "en",
                                        value: iia[6]
                                    }
                                ],
                                emails: [
                                    iia[7]
                                ],
                                phone_numbers: [
                                    {
                                        e164: iia[8]
                                    }
                                ]
                            }
                        ],
                    }];

                    let emptyConditionCount = JSON.stringify(iia).split(",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\",\"\"").length - 1;  // ignores empty conditions (13 empty fields)
                    let actualLength = iia.length - (emptyConditionCount * 15);
                    let staff_teachers = [];
                    let staff_trainings = [];
                    let student_studies = [];
                    let student_traineeships = [];
                    for (let i = 9; i < actualLength; i += 15) {

                        let iscedFCodeValues = [];
                        if (selectedFileData[index][i + 7] !== "") {
                            let iscedFCodeArray = selectedFileData[index][i + 7].includes(";") ? selectedFileData[index][i + 7].split(";") : [selectedFileData[index][i + 7]];
                            iscedFCodeValues = new Array();

                            iscedFCodeArray.map((value, index) => {
                                if (index % 2 === 0) {
                                    iscedFCodeValues.push({ isced_f_code: value, isced_clarification: (iscedFCodeArray.length >= index + 1) ? iscedFCodeArray[index + 1] : "" });

                                }
                            });
                        }
                        let eqfValues = [];
                        if (selectedFileData[index][i + 10] !== "") {
                            let eqfArray = selectedFileData[index][i + 10].includes(";") ? selectedFileData[index][i + 10].split(";") : [selectedFileData[index][i + 10]];
                            eqfValues = new Array();
                            eqfArray.forEach((item) => {
                                eqfValues.push(item);
                            });
                        }
                        let languagesValues = [];
                        if (selectedFileData[index][i + 13] !== "") {
                            let languagesArray = selectedFileData[index][i + 13].includes(";") ? selectedFileData[index][i + 13].split(";") : [selectedFileData[index][i + 13]];
                            languagesValues = new Array();

                            let language = "";
                            let cefr_level = "";
                            let isced = "";
                            let clarification = "";
                            languagesArray.map((value, index) => {
                                let pos = index % 4;

                                if (pos === 0) {
                                    language = "";
                                    cefr_level = "";
                                    languagesvalues.map((data) => {
                                        if (data.code === value) {
                                            language = data.title + " - " + data.code;
                                            cefr_level = languagesArray[index + 1];
                                            return;
                                        }
                                    });
                                }
                                else if (pos === 2) {
                                    isced = "";
                                    clarification = "";
                                    isced = value;
                                    clarification = languagesArray[index + 1];

                                }
                                else if (pos === 3) {
                                    languagesValues.push({ language: language, cefr_level: cefr_level, isced_f_code: isced, isced_clarification: clarification });
                                }
                            });
                        }
                        let senderEC = iia[i] === iia[4] ? partnerHei : (iia[i] === user?.erasmusCode ? creatorHei : "");
                        let receiverEC = iia[i + 1] === iia[4] ? partnerHei : (iia[i + 1] === user?.erasmusCode ? creatorHei : "");
                        if (iia[i + 8] === "" || (iia[i + 8] !== "Student studies" && iia[i + 8] !== "Student traineeship" && iia[i + 8] !== "Staff teacher" && iia[i + 8] !== "Staff training")) {
                            allMissingFields.push("Mobility Type (agreement " + index + ", condition " + parseInt(i + 6) + ", column " + parseInt(i + 6) + ") - This field must be filled with Student studies/Student traineeship/Staff teacher/Staff training.");
                        }
                        let senderOu = (iia[i] === iia[4] ? ounits?.ounits : localOunits)?.find(ounit => ounit?.ounit_name?.name === iia[i + 2]);
                        let receiverOu = (iia[i + 1] === iia[4] ? ounits?.ounits : localOunits)?.find(ounit => ounit?.ounit_name?.name === iia[i + 3]);

                        if (iia[i + 8] === "Student studies") {
                            student_studies.push({
                                sending_hei_id: senderEC,
                                receiving_hei_id: receiverEC,
                                sending_ounit_id: senderOu?.ounit_id,
                                sending_ounit_name: senderOu ? [{ value: senderOu?.ounit_name?.name, language: "en" }] : null,
                                receiving_ounit_id: receiverOu?.ounit_id,
                                receiving_ounit_name: receiverOu ? [{ value: receiverOu?.ounit_name?.name, language: "en" }] : null,
                                sending_contact: partnerDetails[0]?.contact,
                                receiving_contact: partnerDetails[1]?.contact,
                                receiving_acad_year: [iia[i + 4], iia[i + 5]],
                                mobilities_per_year: parseInt(iia[i + 6]),
                                subject_area: iscedFCodeValues,
                                total_months_per_year: parseInt(iia[i + 9]),
                                eqf_level: eqfValues,
                                blended: iia[i + 11] != "" ? iia[i + 11] : 0,
                                language_skill: languagesValues,
                                other_info_terms: iia[i + 14]
                            });
                        } else if (iia[i + 8] === "Student traineeship") {
                            student_traineeships.push({
                                sending_hei_id: senderEC,
                                receiving_hei_id: receiverEC,
                                sending_ounit_id: senderOu?.ounit_id,
                                sending_ounit_name: senderOu ? [{ value: senderOu?.ounit_name?.name, language: "en" }] : null,
                                receiving_ounit_id: receiverOu?.ounit_id,
                                receiving_ounit_name: receiverOu ? [{ value: receiverOu?.ounit_name?.name, language: "en" }] : null,
                                sending_contact: partnerDetails[0]?.contact,
                                receiving_contact: partnerDetails[1]?.contact,
                                receiving_acad_year: [iia[i + 4], iia[i + 5]],
                                mobilities_per_year: parseInt(iia[i + 6]),
                                subject_area: iscedFCodeValues,
                                total_months_per_year: parseInt(iia[i + 9]),
                                eqf_level: eqfValues,
                                blended: iia[i + 11] != "" ? iia[i + 11] : 0,
                                language_skill: languagesValues,
                                other_info_terms: iia[i + 14]
                            });
                        } else if (iia[i + 8] === "Staff teacher") {
                            staff_teachers.push({
                                sending_hei_id: senderEC,
                                receiving_hei_id: receiverEC,
                                sending_ounit_id: senderOu?.ounit_id,
                                sending_ounit_name: senderOu ? [{ value: senderOu?.ounit_name?.name, language: "en" }] : null,
                                receiving_ounit_id: receiverOu?.ounit_id,
                                receiving_ounit_name: receiverOu ? [{ value: receiverOu?.ounit_name?.name, language: "en" }] : null,
                                sending_contact: partnerDetails[0]?.contact,
                                receiving_contact: partnerDetails[1]?.contact,
                                receiving_acad_year: [iia[i + 4], iia[i + 5]],
                                mobilities_per_year: parseInt(iia[i + 6]),
                                subject_area: iscedFCodeValues,
                                total_days_per_year: parseInt(iia[i + 12]),
                                language_skill: languagesValues,
                                other_info_terms: iia[i + 14]
                            });
                        }
                        else {
                            staff_trainings.push({
                                sending_hei_id: senderEC,
                                receiving_hei_id: receiverEC,
                                sending_ounit_id: senderOu?.ounit_id,
                                sending_ounit_name: senderOu ? [{ value: senderOu?.ounit_name?.name, language: "en" }] : null,
                                receiving_ounit_id: receiverOu?.ounit_id,
                                receiving_ounit_name: receiverOu ? [{ value: receiverOu?.ounit_name?.name, language: "en" }] : null,
                                sending_contact: partnerDetails[0]?.contact,
                                receiving_contact: partnerDetails[1]?.contact,
                                receiving_acad_year: [iia[i + 4], iia[i + 5]],
                                mobilities_per_year: parseInt(iia[i + 6]),
                                subject_area: iscedFCodeValues,
                                total_days_per_year: parseInt(iia[i + 12]),
                                language_skill: languagesValues,
                                other_info_terms: iia[i + 14]
                            });
                        }
                    }

                    let currentIIA = {
                        status: "draft",
                        type_of_communication: dataSettings?.data?.ewpConnections?.length > 0 && dataSettings?.data?.ewpConnections[0]?.api_iia ? "dashboard" : "ewp",
                        in_effect: false,
                        partner: partnerDetails,
                        cooperation_conditions: {
                            staff_teachers: staff_teachers,
                            staff_trainings: staff_trainings,
                            student_studies: student_studies,
                            student_traineeships: student_traineeships
                        }
                    }
                    missingFields = validateFields(currentIIA, index)
                    allMissingFields = allMissingFields.concat(missingFields);
                    if (allMissingFields.length === 0) {
                        iiaList.push(currentIIA)

                    }

                    if (index === selectedFileData.length - 1) {
                        setAllIIAs(iiaList)
                        console.log(iiaList)

                    }
                }
                setSelectedFileError(allMissingFields)
            })


        }
    }



    const validateFields = (currentIia, iiaNum) => {
        let errorMessage = "";
        let missingFields = [];

        if (currentIia?.partner) {
            currentIia?.partner?.map(par => {
                par?.contact?.map((c) => {
                    if (par?.hei_id === "") { missingFields.push("Creator EC (agreement " + iiaNum + ") - This field may not be empty/invalid."); }
                    else {
                        errorMessage = validateErasmusCode(par?.hei_id, false);
                        if (errorMessage !== "") { missingFields.push("Creator EC (agreement " + iiaNum + ") - " + errorMessage); }
                    }
                    if (c?.contact_names[0]?.value !== "") {
                        errorMessage = validateRegularNamesOfRequiredFields(c?.contact_names[0]?.value);
                        if (errorMessage !== "") { missingFields.push("Contact Name " + c?.contact_names[0]?.value + " (agreement " + iiaNum + ") - " + errorMessage); }
                    }
                    if (c?.emails[0] !== "") {
                        errorMessage = validateEmailFormat(c?.emails[0], false);
                        if (errorMessage !== "") { missingFields.push("Contact Email " + c?.emails[0] + " (agreement " + iiaNum + ") - " + errorMessage); }
                    }
                    if (c?.phone_numbers[0]?.e164 !== "") {
                        errorMessage = validatePhoneFaxNumber(c?.phone_numbers[0]?.e164, true);
                        if (errorMessage !== "") { missingFields.push("Contact Phone " + c?.phone_numbers[0]?.e164 + " (agreement " + iiaNum + ") - " + errorMessage); }
                    }

                })
            })
        }

        let coop_types = ["staff_teachers", "staff_trainings", "student_studies", "student_traineeships"]

        if (currentIia?.cooperation_conditions) {
            coop_types?.map(type => {
                currentIia?.cooperation_conditions[type]?.map((cc, index) => {
                    let condNum = index + 1;   // Number of the condition
                    // let columnIndex = index * 13 + 18;
                    if (cc?.receiving_acad_year?.length === 0) { missingFields.push("Academic Year (agreement " + iiaNum + ") - This field may not be empty."); }
                    let academicYearStartLeftYear = parseInt(cc?.receiving_acad_year[0]?.substring(0, cc?.receiving_acad_year[0].indexOf('/')));
                    let academicYearStartRightYear = parseInt(cc?.receiving_acad_year[0]?.substring(cc?.receiving_acad_year[0].indexOf('/') + 1));
                    let academicYearEndLeftYear = parseInt(cc?.receiving_acad_year[1]?.substring(0, cc?.receiving_acad_year[1].indexOf('/')));
                    let academicYearEndRightYear = parseInt(cc?.receiving_acad_year[1]?.substring(cc?.receiving_acad_year[1].indexOf('/') + 1));
                    if (academicYearStartLeftYear + 1 !== academicYearStartRightYear) {
                        missingFields.push("Start Academic Year (agreement " + iiaNum + ") - The year on the left must be less one year than the year on the right.");
                    }
                    if (academicYearEndLeftYear + 1 !== academicYearEndRightYear) {
                        missingFields.push("End Academic Year (agreement " + iiaNum + ") - The year on the left must be less one year than the year on the right.");
                    }
                    if (academicYearStartLeftYear > academicYearEndLeftYear) { missingFields.push("Academic Year (agreement " + iiaNum + ") - The Start Academic Year may not be greater than End Academic Year."); }

                    if (cc?.sending_hei_id === "") { missingFields.push("Sender EC (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - Erasmus Code not found or invalid."); }
                    if (cc?.receiving_hei_id === "") { missingFields.push("Receiver EC (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - Erasmus Code not found or invalid."); }
                    if (cc?.mobilities_per_year === "") { missingFields.push("Mobilities Per Year (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - This field may not be empty."); }

                    cc?.subject_area.map((value, idx) => {
                        let subject_area = iscedCodes?.iscedCodes?.find(i => i.code === value?.isced_f_code)
                        if (!subject_area || value?.isced_f_code?.length !== 4) {
                            missingFields.push("Subject Area (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - Code " + value?.isced_f_code + " is not valid.");
                        }
                        if (value?.isced_f_code == "" && value?.isced_clarification !== "") {
                            missingFields.push("Subject Area (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - The pair " + parseInt((idx / 2) + 1) + " cannot have a clarification without a code.");
                        }
                    });
                    if (type === "student_studies" || type === "student_traineeships") {
                        if (cc?.total_months_per_year == 0) { missingFields.push("Total Months (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - This field may not be empty."); }
                        if (cc?.blended != 1 && cc?.blended != 0) { missingFields.push("Blended (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - This field must be filled with 0 (not blended) or 1 (blended).") }
                    }
                    else if ((type === "staff_teachers" || type === "staff_trainings") && cc?.total_days_per_year == 0) { missingFields.push("Total Days (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - This field may not be empty."); }

                    cc?.language_skill?.map((lang, idx) => {
                        if (lang?.cefr_level !== "A1" && lang?.cefr_level !== "A2" && lang?.cefr_level !== "B1" && lang?.cefr_level !== "B2" && lang?.cefr_level !== "C1" && lang?.cefr_level !== "C2" && lang?.cefr_level !== "") {
                            missingFields.push("Recommended Language Skill (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - The group " + parseInt((idx / 4) + 1) + " has a invalid CEFR level " + lang?.cefr_level + ". The only possible values are A1, A2, B1, B2, C1 and C2.");
                        }
                        let isced_lang = iscedCodes?.iscedCodes?.find(i => i.code === lang?.isced_f_code)
                        if (!isced_lang || lang?.isced_f_code?.length !== 4) {
                            missingFields.push("Subject Area of languages skills (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - Code " + lang?.isced_f_code + " is not valid.");
                        }
                        if (lang?.isced_f_code === "" && lang?.isced_clarification !== "") {
                            missingFields.push("Subject Area of languages skills (agreement " + iiaNum + ", condition " + condNum + ", type " + type.replace("_", " ") + ") - The group " + parseInt((idx / 4) + 1) + " cannot have a clarification without a code.");
                        }
                    });

                })
            })
        }
        return missingFields;
    }


    const submitIIA = async () => {
        const toastId = toast.loading("Uploading IIAs...", toastConfiguration);

        for (let i = 0; i < allIIAs?.length; i++) {
            try {
                const dataCreateIia = await addIIA({
                    variables: { iiaData: allIIAs[i] }
                });
                if (dataCreateIia?.data?.addIIA) {
                    let tempUploadedResults = uploadResults;
                    tempUploadedResults.push(allIIAs[i])
                    setUploadResults(tempUploadedResults);
                }
                const checkForUploadErrors = dataCreateIia?.data?.errors?.find((item) => item.includes("was not created"));
                setUploadHasError(checkForUploadErrors ? true : false)
                if (allIIAs?.length - 1 === i)
                    setFinishedUploading(true);
                if (checkForUploadErrors) {
                    let temp = selectedFileError;
                    temp.push('IIA in row ' + (i + 1) + "was not created")
                    setSelectedFileError(temp);
                }
            } catch (error) {
                if (error) {
                    let temp = selectedFileError;
                    temp = temp.concat(['IIA in row ' + (i + 1) + " was not created"])
                    setSelectedFileError(temp);
                    toast.update(toastId, {
                        ...toastConfiguration,
                        render: `There were some errors while uploading IIAs.`,
                        type: "error",
                        isLoading: false,
                    });
                }
            }
        }
        if (uploadHasError) {
            toast.update(toastId, {
                ...toastConfiguration,
                render: `There were some errors while uploading IIAs.`,
                type: "error",
                isLoading: false,
            });
        }
        else {
            toast.update(toastId, {
                ...toastConfiguration,
                render: `Successfully uploaded IIAs`,
                type: "success",
                isLoading: false,
            });
        }

    }

    const returnToUploadPageAndReset = () => {
        setFile(null);
        setFinishedUploading(false);
        setUploadData(false);
        setCheckedConsent(false);
        setPendingUpload(false);
        setSelectedFileData(null);
        setSelectedFileError(['Please select a CSV file']);
        setUploadHasError(false);
        setUploadResults([]);
        setSelectedFileData([]);
        setAllIIAs(null);
    }


    if (pendingUpload) {
        return (
            <div className="mx-5 pt-4 pt-xxl-5">
                <h3 className='h3-upload'>{t('header')}</h3>
                <p className='upload-subtitle'>{t('loadingMessage')}</p>
                <ProgressBar variant="success" now={(uploadResults?.length / allIIAs?.length) * 100} />
                {(selectedFileError && selectedFileError?.length > 0) && <div className='p-2 upload-results text-danger'>
                    {selectedFileError?.map(i => <>{i}</>)}
                </div>}

                {finishedUploading &&
                    <Col style={{ marginTop: 76, marginBottom: 10, display: "flex", justifyContent: "flex-end" }}>
                        <CustomButton buttonType={"primary"} styles={{ marginRight: "10px", marginTop: "20px" }} small={true} text={t('returnToIIAListing')} handleClick={() => navigate("/iias/iia-list")} />
                        <CustomButton buttonType={"primary"} styles={{ marginTop: "20px" }} small={true} text={t('returnToUploadPage')} handleClick={returnToUploadPageAndReset} />
                    </Col>
                }
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }

    return (
        <div className="d-flex justify-content-between mx-5 pt-4 pt-xxl-5">
            <Form noValidate validated={true} id={"upload-ola-form"}>
                <h3 className='h3-upload'>
                    {t('header')}
                </h3>
                <p className='body-upload'>
                    {t('description')}
                    <br />
                    {t('verifyFormat')}<a rel="noopener noreferrer"
                        target="_blank"
                        href="https://esci-sd.atlassian.net/wiki/spaces/DASH/pages/52789249/Upload+IIAs">
                        {t('here')}
                    </a>.
                </p>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control type="file" accept='.csv' onChange={(e) => upload(e)} required isInvalid={selectedFileError?.length > 0} />
                    <Form.Control.Feedback type="invalid">
                        {selectedFileError?.map(i => <>{i}<br /></>)}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formAuthorizations">
                    <Form.Check type="checkbox" id="check-authorizations">
                        <Form.Check.Input type="checkbox" required onChange={checkConsent} />
                        <Form.Check.Label>{t('confirmation')}</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            {t('consentError')}
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
                <Row>
                    <Col style={{ marginTop: 36, display: "flex", justifyContent: "flex-end" }}>
                        <CustomButton disabled={!(checkedConsent && file?.name && selectedFileError?.length === 0)} buttonType={"primary"} small={true} text={t('uploadButton')} hasIcon={true} iconColor={COLORS.white} icon={faUpload} handleClick={handleShowModal} />
                    </Col>
                </Row>
                <CustomModal show={showModal} button={<CustomButton disabled={!(checkedConsent && file?.name && selectedFileError?.length === 0)} buttonType={"primary"} small={true} text={t('uploadButton')} hasIcon={true} iconColor={COLORS.white} icon={faUpload} handleClick={importIIAs} />} handleClose={handleCloseModal} title={t('importConfirmation')} />
            </Form>
        </div>)
};

export default ImportIIA;