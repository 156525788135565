import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/Table";
import TableSkeleton from "../../../components/Table/components/TableSkeleton";
import Filters from "../../../components/Filters";

import { useEffect, useState } from "react";
import { useCourses } from "./hooks/useCourses";
import GET_COURSES_LISTING from "../../../graphql/queries/courses-evaluations/getCoursesListing";


const CoursesEvaluationsList = () => {
  const initialState = {
    courseCode: "",
    courseTitle: "",
    selectedFiltersList: {
        courseCode: "",
        courseTitle: "",
    },
  };
  const filters = useCourses(initialState);
  const {
    state,
    setExpandFilters,
    setSelectedFiltersList,
    updateMultipleValues
  } = filters;
  const {
    courseTitle,
    courseCode,
    selectedFiltersList,
    expandFilters,
  } = state;
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const {
    loading: coursesListingLoading,
    error: coursesListingError,
    data: coursesListingData,
    refetch: coursesListingRefetch,
  } = useQuery(GET_COURSES_LISTING, {
    variables: {
      page: page,
      course_title: courseTitle || "",
      course_code: courseCode || "",
      limit: 30
    },
    fetchPolicy: "cache-and-network",
  });

  const handleExpand = (value) => {
    setExpandFilters(value);
  };

  useEffect(() => {
    updateMultipleValues(selectedFiltersList);
    coursesListingRefetch();
  }, [
    selectedFiltersList,
    updateMultipleValues,
    coursesListingRefetch,
  ]);

  const handleRemoveFilter = (filter, value) => {
    if (filter === "partnerHeiId") {
      let temp = Object.assign({}, selectedFiltersList);
      temp[filter] = "";
      temp["erasmusCode"] = "";
      setSelectedFiltersList(temp);
    } else {
      let temp = Object.assign({}, selectedFiltersList);
      temp[filter] = "";
      setSelectedFiltersList(temp);
    }
  };

  if (coursesListingLoading) {
    return (
      <TableSkeleton
        columnsPerFieldMap={{
          id_: "col-2",
          partner: "col-6",
          last_edited: "col-2",
          _: "col-2"

        }}
      />
    );
  }


  if (coursesListingError)
    return (
      <div className="text-center text-danger mx-auto">An error occured</div>
    );

  return (
    <main className="h-auto text-center mt-4">
      <CustomTable
        page={page}
        setPage={(p) => setPage(p)}
        type={"courses-"}
        expandFilters={expandFilters}
        setExpandFilters={(e) => setExpandFilters(e)}
        filters={selectedFiltersList}
        setFilters={(filter, value) => {handleRemoveFilter(filter, value); setPage(0);}}
        columnsPerFieldMap={{
          code: "col-1",
          ects: "col-1",
          institution: "col-3",
          term: "col-3",
          title: "col-3"
        }}
        pageLimit={30}
        totalCount={coursesListingData?.courses?.pagination?.totalCount}
        hasPagination={true}
        customTitle={"Total Courses"}
        hasFilters={true}
        rowClassName="iias-listing-table-row"
        headerClassName="iias-listing-table-header"
        onClickHandlers={coursesListingData?.courses?.courses.map(({ id }) => (e) => {
          navigate("/courses/single-view/" + id);
        })}
        onMouseDownHandlers={coursesListingData?.courses?.courses.map(({ id }) => (e) => {
          e.button === 1 && window.open(window.location.origin + "/courses/single-view/" + id);
        })}
        data={coursesListingData?.courses?.courses.map(({code, ects, institution,term,title}) => 
            ({code: code, ects: ects, institution: institution, term: term, title: title}))}
      >
      </CustomTable>
      <Filters
        expand={expandFilters}
        handleExpand={handleExpand}
        selectedFiltersList={selectedFiltersList}
        setSelectedFiltersList={(e) => {setSelectedFiltersList(e); setPage(0);}}
        countries={[]}
        type={"courses"}
      />
    </main>
  );
};

export default CoursesEvaluationsList;
