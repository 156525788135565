import { useRef, forwardRef } from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Input from "react-phone-number-input/input";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { useTranslation } from "react-i18next";

import withRow from "../withRow";

const ChildRow = (props) => <Row className="mb-3 mx-0" {...props} />;

const PhoneNumberInputComponent = forwardRef((props, ref) => (
  <Form.Control
    required
    {...props}
    ref={ref}
    value={(() => {
      return props.value.includes(" ") ? props.value : "";
    })()}
  />
));

const Child = ({
  size,
  phoneCode,
  showTooltip,
  phone,
  updateMultipleValues,
  phoneLabel,
  phonePropertyName,
  children,
}) => {
  const tooltipTarget = useRef(null);
  const { t } = useTranslation();

  return (
    <>
      <Overlay
        target={tooltipTarget.current}
        show={showTooltip && size !== "xs" && size !== "sm" && size !== "md"}
        placement="left"
      >
        {(props) => (
          <Tooltip
            id="overlay-example"
            {...props}
            className="position-absolute"
          >
            We filled the phone code based on the country you chose. In case it
            is not correct, please select the correct one for your institutional
            phone.
          </Tooltip>
        )}
      </Overlay>
      <Form.Group
        as={size !== "xs" && size !== "sm" ? Col : ChildRow}
        controlId="formCountryCode"
        ref={size !== "xs" && size !== "sm" ? tooltipTarget : null}
      >
        <Form.Label className="ps-0">{t('selectPhoneInput.label')}</Form.Label>
        <Form.Select
          required
          value={phoneCode}
          onChange={(e) =>
            updateMultipleValues({
              phoneCode: e.target.value,
              [phonePropertyName]: "",
            })
          }
          className="position-relative"
        >
          <option value="" disabled>
            {t('selectPhoneInput.placeholder')}
          </option>
          {getCountries().map((country) => (
            <option key={country} value={country}>
              {`${country} (+${getCountryCallingCode(country)})`}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {t('selectPhoneInput.error.a')}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        as={size !== "xs" && size !== "sm" ? Col : ChildRow}
        controlId="formPhone"
      >
        <Form.Label className="ps-0">{phoneLabel}</Form.Label>
        <Input
          placeholder="2310996727"
          withCountryCallingCode
          international
          value={phone}
          onChange={(newValue) => {
            updateMultipleValues({ [phonePropertyName]: newValue || "" });
          }}
          country={phoneCode}
          disabled={!phoneCode}
          inputComponent={PhoneNumberInputComponent}
        />
        <Form.Control.Feedback type="invalid">
        {t('selectPhoneInput.error.b')}
        </Form.Control.Feedback>
      </Form.Group>
      {children}
    </>
  );
};

const SelectPhoneInput = (props) => {
  return withRow(Child, props.size !== "xs" && props.size !== "sm")(props);
};

export default SelectPhoneInput;
