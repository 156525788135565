import { useLazyQuery, useQuery } from "@apollo/client";
import { faCheck, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useState } from "react";
import { Accordion, Col, Form, Row, Table } from "react-bootstrap";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal";
import IconButton from "../../../../components/IconButton";
import MultiSelect from "../../../../components/MultiSelect";
import Select from "../../../../components/Select";
import COLORS from "../../../../data/colors";
import GET_ISCED_CODES from "../../../../graphql/queries/IIAs/getISCEDCodes";
import GET_OUNITS from "../../../../graphql/queries/IIAs/getOUnits";
import useUser from "../../../../hooks/useUser";
import { CEFRvalues, languagesvalues } from "../../../Nominations/Requirements/Taxonomy";
import "./index.scss";

import { useTranslation } from "react-i18next";

 const academicYearsValues = [
    {value: "2022/2023", disabled: false},
    {value: "2023/2024", disabled: false},
    {value: "2024/2025", disabled: false},
    {value: "2025/2026", disabled: false},
    {value: "2026/2027", disabled: false},
    {value: "2027/2028", disabled: false},
    {value: "2028/2029", disabled: false}
  ];

const academicYears = [
    "2022/2023",
    "2023/2024",
    "2024/2025",
    "2025/2026",
    "2026/2027",
    "2027/2028",
    "2028/2029"
  ];

const ThirdStep = ({updateMultipleValues, staffTeachers, staffTrainings, studentStudies, studentTraineeships, partnerSCHAC, actionType}) => {
  const {user} = useUser();
  const [showModal, setShowModal] = useState(false);
  const [coopCondition, setCoopCondition] = useState({});
  const [typeOfCoopCondition, setTypeOfCoopCondition] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [type, setType] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [SCHACCodes, setSCHACCodes] = useState([]);
  const [sendingOUnits, setSendingOUnits] = useState([]);
  const [receivingOUnits, setReceivingOUnits] = useState([]);
  const [indexReceivingSchac, setIndexReceivingSchac] = useState(0);
  const [indexSendingSchac, setIndexSendingSchac] = useState(0);
  const [academicYears, setAcademicYears] = useState(academicYearsValues);
  const [validatedSending, setValidatedSending] = useState(false);
  const [validatedReceiving, setValidatedReceiving] = useState(false);
  const [validatedEmailSending, setValidatedEmailSending] = useState(false);
  const [validatedEmailReceiving, setValidatedEmailReceiving] = useState(false);
 
  const { t } = useTranslation('translation', {keyPrefix: 'iiasEdit.thirdStep'})

  const mobilityTypes = [t('mobilityTypes.studentStudies'), t('mobilityTypes.studentTraineeships'), t('mobilityTypes.staffTeachers'), t('mobilityTypes.staffTrainings')];
    const blendedMobility = [t('blendedMobility.yes'), t('blendedMobility.no')];
    const eqfLevels = [{label: t('eqfLevels.lvl5'), value: "5"}, {label: t('eqfLevels.lvl6'), value: "6"},
    {label: t('eqfLevels.lvl7'), value: "7"}, {label: t('eqfLevels.lvl8'), value: "8"}];


  const {
    loading: loadingISCEDCodes,
    error: errorISCEDCodes,
    data: dataISCEDCodes,
  } = useQuery(GET_ISCED_CODES);

  const {
    loading: loadingOUnits,
    error: errorOUnits,
    data: dataOUnits,
  } = useQuery(GET_OUNITS, {variables: {heiID: user?.heiID}});

  const {
    loading: loadingOUnitsPartner,
    error: errorOUnitsPartner,
    data: dataOUnitsPartner,
  } = useQuery(GET_OUNITS, {variables: {heiID: partnerSCHAC}});

  useEffect(() => {
    let SCHACTemp = [];
    SCHACTemp.push(partnerSCHAC);
    SCHACTemp.push(user.heiID);
    setSCHACCodes(SCHACTemp);
  }, []);

  useEffect( () => {
    let oUnitsTemp = [];
    if (coopCondition?.receiving_hei_id === user?.heiID) {
        oUnitsTemp.push({
            "abbreviation": "",
            "ounit_id": "",
            "ounit_code": "",
            "ounit_name": {
                "name": "- - null - -",
                "language": null
            }
        });
        dataOUnits?.ounits && oUnitsTemp.push(...dataOUnits?.ounits);
    }
    else {
        oUnitsTemp.push({
            "abbreviation": "",
            "ounit_id": "",
            "ounit_code": "",
            "ounit_name": {
                "name": "- - null - -",
                "language": null
            }
        });
        dataOUnitsPartner?.ounits && oUnitsTemp.push(...dataOUnitsPartner?.ounits);
    }
    setReceivingOUnits(oUnitsTemp);

  }, [coopCondition?.receiving_hei_id]);

  useEffect( () => {
    let oUnitsTemp = [];
    if (coopCondition?.sending_hei_id === user?.heiID) {
        oUnitsTemp.push({
            "abbreviation": "",
            "ounit_id": "",
            "ounit_code": "",
            "ounit_name": {
                "name": "- - null - -",
                "language": null
            }
        }); 
        dataOUnits?.ounits && oUnitsTemp.push(...dataOUnits?.ounits);
    }
    else {
        oUnitsTemp.push({
            "abbreviation": "",
            "ounit_id": "",
            "ounit_code": "",
            "ounit_name": {
                "name": "- - null - -",
                "language": null
            }
        });
        dataOUnitsPartner?.ounits && oUnitsTemp.push(...dataOUnitsPartner?.ounits);
    }
    setSendingOUnits(oUnitsTemp);

  }, [coopCondition?.sending_hei_id]);

  const getValueFromSelectEqfs = (el) => {
    if (el === "" || el === null || el === undefined) {
        return null;
      } else {
        return eqfLevels.map((option) => {
            return el.includes(option.value) && ({ "label": option.label });
          });
      }
    
  };

  const handleMultiSelectEqf = (item) => {
    const tempEqfs = item.filter((i) => i.hasOwnProperty("value")).map((item) => item.value);
    let value = {...coopCondition};

    if(!value.eqf_level) {
        value.eqf_level = [];
        value.eqf_level = value.eqf_level.concat(tempEqfs);
    } else {
        value.eqf_level = value.eqf_level.concat(tempEqfs);
    }
    if (tempEqfs.length === 0) {
        value.eqf_level = [];
    }
    value.eqf_level = value.eqf_level.filter((v, i, a) => a.indexOf(v) === i);

    setCoopCondition(value);

  }

  const handleInputChange = (event) => {
    let value = {...coopCondition};

      if (event.target.name === "mobilityPerYear") {
        value.mobilities_per_year = event.target.value ? parseInt(event.target.value) : event.target.value;
      }
      else if(event.target.name === "ISCEDClar") {
        value.isced_clarification = event.target.value;
      }
      else if(event.target.name === "info") {
        value.other_info_terms = event.target.value;
      }
      else if(event.target.name === "duration") {
        if (showFields) {
            value.total_months_per_year = (event.target.value !== "") ? parseInt(event.target.value) : null;
        }
        else {
            value.total_days_per_year = (event.target.value !== "") ? parseInt(event.target.value) : null;
        }
        
      }
        
        setCoopCondition(value);
};

const handleChangeSendingSchacCode = (el) => {
    let value = {...coopCondition};

    value.sending_hei_id = el?.value;
    value.receiving_hei_id = value.sending_hei_id === user?.heiID ? partnerSCHAC : user?.heiID;
    value.sending_ounit_name = [];
    value.sending_ounit_id = null;
    value.receiving_ounit_name = [];
    value.receiving_ounit_id = null;
    value.sending_contact = [];
    value.receiving_contact = [];

    setCoopCondition(value);

}

const handleChangeSendingOUnit = (el) => {
    let value = {...coopCondition};
    value.sending_ounit_name = [];
    el?.value && value.sending_ounit_name.push({value: el?.label, language: "en"});
    value.sending_ounit_id = el?.value ? el?.value : null;

    setCoopCondition(value);

}

const handleChangeReceivingOUnit = (el) => {
    let value = {...coopCondition};
    value.receiving_ounit_name = [];
    el?.value && value.receiving_ounit_name.push({value: el?.label, language: "en"});
    value.receiving_ounit_id = el?.value ? el?.value : null;

    setCoopCondition(value);

}

const handleChangeReceivingSchacCode = (el) => {
    let value = {...coopCondition};

    value.receiving_hei_id = el?.value;
    value.sending_hei_id = value.receiving_hei_id === user?.heiID ? partnerSCHAC : user?.heiID;
    value.sending_ounit_name = [];
    value.sending_ounit_id = null;
    value.receiving_ounit_name = [];
    value.receiving_ounit_id = null;
    value.sending_contact = [];
    value.receiving_contact = [];
    
    setCoopCondition(value);

}

const handleChangeLanguage = (el, index) => {
    let value = {...coopCondition};
    let values = value.language_skill.map((item) => ({
        ...item, 
      }));
      values[index].language = el?.value.toLowerCase();
      value.language_skill = values;

    setCoopCondition(value);

}

const handleChangeLanguageLevel = (el, index) => {
    let value = {...coopCondition};
    let values = value.language_skill.map((item) => ({
        ...item, 
      }));
      values[index].cefr_level = el?.value;
      value.language_skill = values;

    setCoopCondition(value);

}

const handleChangeLanguageIsced = (el, index) => {
    let value = {...coopCondition};
    let values = value.language_skill.map((item) => ({
        ...item, 
      }));
      values[index].isced_f_code = el?.value;
      value.language_skill = values;

    setCoopCondition(value);

}

const handleChangeStartAcademicYear = (el) => {
    let value = {...coopCondition};
    let tempAcademic = [...academicYears];

    if(!value.receiving_acad_year) {
        value.receiving_acad_year = [];
        value.receiving_acad_year[0] = el.target.value;
    } else {
        value.receiving_acad_year = [...value?.receiving_acad_year];
        value.receiving_acad_year[0] = el.target.value;
    }
    
    const elementFind = tempAcademic.slice().reverse().find((elem) => parseInt(elem.value.split("/")[0]) <= parseInt(el.target.value.split("/")[0]));
    const lastIndex = tempAcademic.lastIndexOf(elementFind);

    tempAcademic = tempAcademic.map((elem, index) => index <= lastIndex ? {value: elem.value, disabled: true} : {value: elem.value, disabled: false});

    setAcademicYears(tempAcademic);
    setCoopCondition(value);

}

const handleChangeEndAcademicYear = (el) => {
    let value = {...coopCondition};

    if(!value.receiving_acad_year) {
        value.receiving_acad_year = [];
        value.receiving_acad_year[1] = el.target.value;
    } else {
        value.receiving_acad_year = [...value?.receiving_acad_year];
        value.receiving_acad_year[1] = el.target.value;
    }

    setCoopCondition(value);

}

const handleChangeSubjectAreaIsced = (el, index) => {
    let value = {...coopCondition};
    let values = value.subject_area.map((item) => ({
        ...item, 
      }))

    values[index].isced_f_code = el?.value;

    value.subject_area = values;
    setCoopCondition(value);

}

const handleInputArrayChange = (event, index) => {
    let value = {...coopCondition};
    
      if(event.target.name === "ISCEDClar") {
        let values = value?.subject_area?.map((item) => ({
            ...item, 
          }));
        values[index].isced_clarification = event.target.value;
        value.subject_area = values;
      } else if(event.target.name === "clar") {
        let values = value?.language_skill?.map((item) => ({
            ...item, 
          }))
          values[index].isced_clarification = event.target.value;
          value.language_skill = values;

      }
        
    setCoopCondition(value);
};

const getFilterValueForSelectField = (el) => {
    if (el === "" || el === null || el === undefined) {
      return null;
    } else {
      return [{ label: el }];
    }
  };

  const getFilterValueForSelectFieldSendingOUnit = () => {
    let value = {...coopCondition};
    
    if (value?.sending_ounit_name?.length === 0 && value?.sending_ounit_id) {
      value.sending_ounit_id = null;
      setCoopCondition(value);
      return null;
    } else if ((value?.sending_ounit_name?.length > 0 && value?.sending_ounit_name[0]?.value === "" )|| 
    (value?.sending_ounit_name?.length > 0 && value?.sending_ounit_name[0]?.value === undefined )|| (
    value?.sending_ounit_name?.length > 0 && value?.sending_ounit_name[0]?.value === null)){
        return null;
    } else {
      return [{ label: value?.sending_ounit_name?.length > 0 && value?.sending_ounit_name[0]?.value}];
    }
  };

  const getFilterValueForSelectFieldReceivingOUnit = () => {
    let value = {...coopCondition};
    
    if (value?.receiving_ounit_name?.length === 0 && value?.receiving_ounit_id) {
      value.receiving_ounit_id = null;
      setCoopCondition(value);
      return null;
    } else if ((value?.receiving_ounit_name?.length > 0 && value?.receiving_ounit_name[0]?.value === "" )|| 
    (value?.receiving_ounit_name?.length > 0 && value?.receiving_ounit_name[0]?.value === undefined )|| (
    value?.receiving_ounit_name?.length > 0 && value?.receiving_ounit_name[0]?.value === null)){
        return null;
    } else {
      return [{ label: value?.receiving_ounit_name?.length > 0 && value?.receiving_ounit_name[0]?.value}];
    }
  };

  const getFilterValueForSelectFieldLanguage = (el) => {
    if (el === "" || el === null || el === undefined) {
      return null;
    } else {
        let language_item = languagesvalues.find(elem => el.language.toLowerCase() === elem.code.toLowerCase());
      return [{ label: language_item?.title }];
    }
  };

  const getFilterValueForSelectBlendedField = () => {
    if (coopCondition?.blended === "0"){
      return [{ label: t('blendedMobility.no') }];
    } else if (coopCondition?.blended === "1"){
        return [{ label: t('blendedMobility.yes') }];
      }
  };

  const handleChangeTypeOfCoopCondition = (el) => {
    setTypeOfCoopCondition(el?.value);
    if(el?.value === t('mobilityTypes.studentStudies') || el?.value === t('mobilityTypes.studentTraineeships')) {
        setShowFields(true);
    }
    else {
        setShowFields(false);
    }
    if(el?.value === t('mobilityTypes.studentStudies') || el?.value === t('mobilityTypes.staffTeachers')){
        let value = {...coopCondition};
        value.language_skill = [{
          isced_f_code: "",
          cefr_level: "",
          language: ""
        }];
    
        setCoopCondition(value);
    }
    else{
      let value = {...coopCondition};
      value.language_skill = [];
  
      setCoopCondition(value);
  }
  }

  const handleRemoveCoopCondition = (index, type) => {
    if(type === "Student Studies") {
        const values = [...studentStudies];
        values.splice(index, 1);
        updateMultipleValues({studentStudies: values});
    }
    else if(type === "Student Traineeships") {
        const values = [...studentTraineeships];
        values.splice(index, 1);
        updateMultipleValues({studentTraineeships: values});
    }
    else if(type === "Staff Teachers") {
        const values = [...staffTeachers];
        values.splice(index, 1);
        updateMultipleValues({staffTeachers: values});
    }
    else {
        const values = [...staffTrainings];
        values.splice(index, 1);
        updateMultipleValues({staffTrainings: values});

    }
  }

  const handleChangeBlended = (el) => {
    let value = {...coopCondition};
    if(el?.value === t('blendedMobility.yes')) {
        value.blended = "1";
    }
    else {
        value.blended = "0";
    }
    setCoopCondition(value);
  }

  const handleAdd = () => {

    const hasInvalidSendingEmails = validateAllEmails(coopCondition.sending_contact);
    const hasInvalidReceivingEmails = validateAllEmails(coopCondition.receiving_contact);

    if (hasInvalidSendingEmails) {
      alert(t('typeOfCoopCondition.validEmailSending'));
      return;
    }

    if (hasInvalidReceivingEmails) {
      alert(t('typeOfCoopCondition.validEmailReceiving'));
      return;
    }

    if (typeOfCoopCondition === "") {
        alert(t('typeOfCoopCondition.fillAllFields'));
        return;
    }
    
    if(typeOfCoopCondition === t('mobilityTypes.studentStudies')) {
        let temp = [...studentStudies];
        if (!coopCondition?.blended || !coopCondition?.receiving_acad_year || !(coopCondition?.receiving_acad_year.length > 0 && coopCondition?.receiving_acad_year[1]) || !coopCondition?.receiving_hei_id || 
            !coopCondition?.sending_hei_id || !coopCondition?.mobilities_per_year || (!(coopCondition?.language_skill?.length > 0))) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        if (coopCondition?.subject_area?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.subject_area.length; i++) {
                if (!coopCondition.subject_area[i]?.isced_f_code) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.language_skill?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.language_skill.length; i++) {
                if (!coopCondition.language_skill[i]?.language) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.sending_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.sending_contact.length; i++) {
                if (!coopCondition.sending_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.receiving_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.receiving_contact.length; i++) {
                if (!coopCondition.receiving_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        temp.push(coopCondition);
        updateMultipleValues({studentStudies: temp});
    }
    else if(typeOfCoopCondition === t('mobilityTypes.studentTraineeships')) {
        let temp = [...studentTraineeships];
        if (!coopCondition?.blended || !coopCondition?.receiving_acad_year || !(coopCondition?.receiving_acad_year.length > 0 && coopCondition?.receiving_acad_year[1]) || !coopCondition?.receiving_hei_id || 
            !coopCondition?.sending_hei_id || !coopCondition?.mobilities_per_year) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        if (coopCondition?.subject_area?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.subject_area.length; i++) {
                if (!coopCondition.subject_area[i]?.isced_f_code) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.language_skill?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.language_skill.length; i++) {
                if (!coopCondition.language_skill[i]?.language) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.sending_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.sending_contact.length; i++) {
                if (!coopCondition.sending_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.receiving_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.receiving_contact.length; i++) {
                if (!coopCondition.receiving_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        temp.push(coopCondition);
        updateMultipleValues({studentTraineeships: temp});
    }
    else if(typeOfCoopCondition === t('mobilityTypes.staffTeachers')) {
        let temp = [...staffTeachers];

        if (!coopCondition?.receiving_acad_year || !(coopCondition?.receiving_acad_year.length > 0 && coopCondition?.receiving_acad_year[1]) || !coopCondition?.receiving_hei_id || 
            !coopCondition?.sending_hei_id || !coopCondition?.mobilities_per_year || (!(coopCondition?.language_skill?.length > 0))) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        if (coopCondition?.subject_area?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.subject_area.length; i++) {
                if (!coopCondition.subject_area[i]?.isced_f_code) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.language_skill?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.language_skill.length; i++) {
                if (!coopCondition.language_skill[i]?.language) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.sending_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.sending_contact.length; i++) {
                if (!coopCondition.sending_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.receiving_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.receiving_contact.length; i++) {
                if (!coopCondition.receiving_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        temp.push(coopCondition);
        updateMultipleValues({staffTeachers: temp});
    }
    else {
        let temp = [...staffTrainings];
        if (!coopCondition?.receiving_acad_year || !(coopCondition?.receiving_acad_year.length > 0 && coopCondition?.receiving_acad_year[1]) || !coopCondition?.receiving_hei_id || 
            !coopCondition?.sending_hei_id || !coopCondition?.mobilities_per_year) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        if (coopCondition?.subject_area?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.subject_area.length; i++) {
                if (!coopCondition.subject_area[i]?.isced_f_code) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.language_skill?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.language_skill.length; i++) {
                if (!coopCondition.language_skill[i]?.language) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.sending_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.sending_contact.length; i++) {
                if (!coopCondition.sending_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.receiving_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.receiving_contact.length; i++) {
                if (!coopCondition.receiving_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        temp.push(coopCondition);
        updateMultipleValues({staffTrainings: temp});
    }
    setShowModal(false);

  };

  const handleEdit = () => {

    const hasInvalidSendingEmails = validateAllEmails(coopCondition.sending_contact);
    const hasInvalidReceivingEmails = validateAllEmails(coopCondition.receiving_contact);
  
    if (hasInvalidSendingEmails) {
      alert(t('typeOfCoopCondition.validEmailSending'));
      return;
    }
  
    if (hasInvalidReceivingEmails) {
      alert(t('typeOfCoopCondition.validEmailReceiving'));
      return;
    }

    if(typeOfCoopCondition === t('mobilityTypes.studentStudies')) {
        let temp = [...studentStudies];

        if (!coopCondition?.blended || !coopCondition?.receiving_acad_year || !(coopCondition?.receiving_acad_year.length > 0 && coopCondition?.receiving_acad_year[1]) || !coopCondition?.receiving_hei_id || 
            !coopCondition?.sending_hei_id || !coopCondition?.mobilities_per_year || (!(coopCondition?.language_skill?.length > 0))) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        if (coopCondition?.subject_area?.length > 0) {
            let missingRequiredFields = false;
            let missingRequiredFieldsIsced = false;
            for(let i = 0; i < coopCondition.subject_area.length; i++) {
                if (!coopCondition.subject_area[i]?.isced_f_code) {
                    missingRequiredFields = true;
                    break;
                }
                if (coopCondition.subject_area[i]?.isced_f_code?.length !== 4) {
                    missingRequiredFieldsIsced = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
            if (missingRequiredFieldsIsced) {
                alert(t('typeOfCoopCondition.invalidISCED'));
                return;
            }
        }
        if (coopCondition?.language_skill?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.language_skill.length; i++) {
                if (!coopCondition.language_skill[i]?.language) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.sending_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.sending_contact.length; i++) {
                if (!coopCondition.sending_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.receiving_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.receiving_contact.length; i++) {
                if (!coopCondition.receiving_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        temp[currentIndex] = coopCondition;
        updateMultipleValues({studentStudies: temp});
    }
    else if(typeOfCoopCondition === t('mobilityTypes.studentTraineeships')) {
        let temp = [...studentTraineeships];
        if (!coopCondition?.blended || !coopCondition?.receiving_acad_year || !(coopCondition?.receiving_acad_year.length > 0 && coopCondition?.receiving_acad_year[1]) || !coopCondition?.receiving_hei_id || 
            !coopCondition?.sending_hei_id || !coopCondition?.mobilities_per_year) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        if (coopCondition?.subject_area?.length > 0) {
            let missingRequiredFields = false;
            let missingRequiredFieldsIsced = false;
            for(let i = 0; i < coopCondition.subject_area.length; i++) {
                if (!coopCondition.subject_area[i]?.isced_f_code) {
                    missingRequiredFields = true;
                    break;
                }
                if (coopCondition.subject_area[i]?.isced_f_code?.length !== 4) {
                    missingRequiredFieldsIsced = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
            if (missingRequiredFieldsIsced) {
                alert(t('typeOfCoopCondition.invalidISCED'));
                return;
            }
        }
        if (coopCondition?.language_skill?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.language_skill.length; i++) {
                if (!coopCondition.language_skill[i]?.language) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.sending_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.sending_contact.length; i++) {
                if (!coopCondition.sending_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.receiving_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.receiving_contact.length; i++) {
                if (!coopCondition.receiving_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        temp[currentIndex] = coopCondition;
        updateMultipleValues({studentTraineeships: temp});
    }
    else if(typeOfCoopCondition === t('mobilityTypes.staffTeachers')) {
        let temp = [...staffTeachers];
        if (!coopCondition?.receiving_acad_year || !(coopCondition?.receiving_acad_year.length > 0 && coopCondition?.receiving_acad_year[1]) || !coopCondition?.receiving_hei_id || 
            !coopCondition?.sending_hei_id || !coopCondition?.mobilities_per_year || (!(coopCondition?.language_skill?.length > 0))) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        if (coopCondition?.subject_area?.length > 0) {
            let missingRequiredFields = false;
            let missingRequiredFieldsIsced = false;
            for(let i = 0; i < coopCondition.subject_area.length; i++) {
                if (!coopCondition.subject_area[i]?.isced_f_code) {
                    missingRequiredFields = true;
                    break;
                }
                if (coopCondition.subject_area[i]?.isced_f_code?.length !== 4) {
                    missingRequiredFieldsIsced = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
            if (missingRequiredFieldsIsced) {
                alert(t('typeOfCoopCondition.invalidISCED'));
                return;
            }
        }
        if (coopCondition?.language_skill?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.language_skill.length; i++) {
                if (!coopCondition.language_skill[i]?.language) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.sending_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.sending_contact.length; i++) {
                if (!coopCondition.sending_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.receiving_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.receiving_contact.length; i++) {
                if (!coopCondition.receiving_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        temp[currentIndex] = coopCondition;
        updateMultipleValues({staffTeachers: temp});
    }
    else {
        let temp = [...staffTrainings];
        if (!coopCondition?.receiving_acad_year || !(coopCondition?.receiving_acad_year.length > 0 && coopCondition?.receiving_acad_year[1]) || !coopCondition?.receiving_hei_id || 
            !coopCondition?.sending_hei_id || !coopCondition?.mobilities_per_year) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        if (coopCondition?.subject_area?.length > 0) {
            let missingRequiredFields = false;
            let missingRequiredFieldsIsced = false;
            for(let i = 0; i < coopCondition.subject_area.length; i++) {
                if (!coopCondition.subject_area[i]?.isced_f_code) {
                    missingRequiredFields = true;
                    break;
                }
                if (coopCondition.subject_area[i]?.isced_f_code?.length !== 4) {
                    missingRequiredFieldsIsced = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
            if (missingRequiredFieldsIsced) {
                alert(t('typeOfCoopCondition.invalidISCED'));
                return;
            }
        }
        if (coopCondition?.language_skill?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.language_skill.length; i++) {
                if (!coopCondition.language_skill[i]?.language) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.sending_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.sending_contact.length; i++) {
                if (!coopCondition.sending_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        if (coopCondition?.receiving_contact?.length > 0) {
            let missingRequiredFields = false;
            for(let i = 0; i < coopCondition.receiving_contact.length; i++) {
                if (!coopCondition.receiving_contact[i]?.contact_names[0]?.value) {
                    missingRequiredFields = true;
                    break;
                }
            }
            if (missingRequiredFields) {
                alert(t('typeOfCoopCondition.fillAllFields'));
                return;
            }
        }
        temp[currentIndex] = coopCondition;
        updateMultipleValues({staffTrainings: temp});

    }
    setShowModal(false);

  };

  const handleAddLanguage = () => {
    const values = {...coopCondition};
    if(!values.language_skill) {
        values.language_skill = [];
        values.language_skill.push({language: "", cefr_level: "", isced_f_code: "", isced_clarification: ""});
    } else {
        values.language_skill = [...values.language_skill];
        values.language_skill.push({language: "", cefr_level: "", isced_f_code: "", isced_clarification: ""});
    }
    setCoopCondition(values);
}

const handleRemoveLanguage = (index) => {
    const values = {...coopCondition};
    let value = values.language_skill.map((item) => ({
        ...item, 
        
      }));
      
      value.splice(index, 1);
      values.language_skill = value;
    
      setCoopCondition(values);
    };

  const handleAddSubjectArea = () => {
    const values = {...coopCondition};
    if(!values.subject_area) {
        values.subject_area = [];
        values.subject_area.push({isced_f_code: "",isced_clarification: ""});
    }
    else {
        values.subject_area = [...values.subject_area];
        values.subject_area.push({isced_f_code: "",isced_clarification: ""});
    }
    setCoopCondition(values);
}

const handleRemoveSubjectArea = (index) => {
    const values = {...coopCondition};
    let value = values.subject_area.map((item) => ({
        ...item, 
        
      }));
      value.splice(index, 1);
      values.subject_area = value;
      setCoopCondition(values);
    };

    const handleAddContactSending = () => {
        const values = {...coopCondition};
        if(!values.sending_contact) {
            values.sending_contact = [];
            values.sending_contact.push({contact_names: [], emails: [], phone_numbers: []});
        } else {
            values.sending_contact = [...values.sending_contact];
            values.sending_contact.push({ contact_names: [], emails: [], phone_numbers: []});
        }
        setCoopCondition(values);
    }
    
    const handleRemoveContactSending = (index) => {
          const values = {...coopCondition};
          let value = values.sending_contact.map((item) => ({
            ...item, 
            
          }));

          value.splice(index, 1);
          values.sending_contact = value;
          setCoopCondition(values);
          if (validatedEmailSending && !validateAllEmails(values.sending_contact)) {
            setValidatedEmailSending(false);
          }
    }

    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(email);
    };

    const validateAllEmails = (contacts) => {
      if (!contacts) return false;
      return contacts.some(contact => 
        contact.emails.length > 0 && 
        contact.emails[0] && 
        !validateEmail(contact.emails[0])
      );
    };
    
    const handleInputContactSendingNameChange = (index, event) => {
        const values = {...coopCondition};
          if (event.target.name === "name") {
            let value = values?.sending_contact[index]?.contact_names?.map((item) => ({
                ...item, 
              }));
            value.pop();
            value.push({value: event.target.value, language: "en"});
            values.sending_contact[index].contact_names = value;
            } else if (event.target.name === "email") {
                let value = values?.sending_contact?.map((item) => ({
                    ...item, 
                  }));
                value[index].emails.pop();
                value[index].emails.push(event.target.value);
                values.sending_contact = value;
            }
            else {
                values.sending_contact[index].phone_numbers = [...values.sending_contact[index].phone_numbers];
                values.sending_contact[index].phone_numbers.pop();
                values.sending_contact[index].phone_numbers.push({e164: event.target.value});
            }
    
            setCoopCondition(values);
    }

    const handleAddContactReceiving = () => {
        const values = {...coopCondition};
        if(!values.receiving_contact) {
            values.receiving_contact = [];
            values.receiving_contact.push({contact_names: [], emails: [], phone_numbers: []});
        } else {
            values.receiving_contact = [...values.receiving_contact];
            values.receiving_contact.push({ contact_names: [], emails: [], phone_numbers: []});
        }
        setCoopCondition(values);
    }
    
    const handleRemoveContactReceiving = (index) => {
        const values = {...coopCondition};
        let value = values.receiving_contact.map((item) => ({
            ...item, 
            
          }))
          value.splice(index, 1);
          values.receiving_contact = value;
          setCoopCondition(values);
          if (validatedEmailReceiving && !validateAllEmails(values.receiving_contact)) {
            setValidatedEmailReceiving(false);
          }
    }
    
    const handleInputContactReceivingNameChange = (index, event) => {
        const values = {...coopCondition};
          if (event.target.name === "name") {
            values.receiving_contact[index].contact_names = [...values.receiving_contact[index].contact_names];
            values.receiving_contact[index].contact_names.pop();
            values.receiving_contact[index].contact_names.push({value: event.target.value, language: "en"});
            } else if (event.target.name === "email") {
                values.receiving_contact[index].emails = [...values.receiving_contact[index].emails];
                values.receiving_contact[index].emails.pop();
                values.receiving_contact[index].emails.push(event.target.value);
    
            }
            else {
                values.receiving_contact[index].phone_numbers = [...values.receiving_contact[index].phone_numbers];
                values.receiving_contact[index].phone_numbers.pop();
                values.receiving_contact[index].phone_numbers.push({e164: event.target.value});
            }
    
            setCoopCondition(values);
    }
  return (
    <>
    <Row style={{marginLeft: 25, marginRight: 25, background: "#EAECF3"}}>
                <Col style={{marginBottom: 12}} md={12} lg={12}>
                    
    <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                <div className="header">{t('cooperationConditions.header')}</div>
                {staffTeachers.length > 0 && staffTeachers.map((elem, index) => (
                    <><Row><Col lg={11}>
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>{t('cooperationConditions.staffTeacher')}:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / {t('cooperationConditions.studyFields')}: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('heis.sender')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('heis.receiver')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name?.length > 0 && elem?.sending_ounit_name[0]?.value}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name?.length > 0 && elem?.receiving_ounit_name[0]?.value}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>{t('conditionDetails.header')} </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('conditionDetails.mobilityType')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.duration')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>{t('conditionDetails.academicYears')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.spots')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{t('mobilityTypes.staffTeachers')}</td>
                                        <td className='border-hidden body'>{elem?.total_days_per_year ? elem?.total_days_per_year + t('daysPerYear') : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year?.length > 0 && elem?.receiving_acad_year[0].split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1].split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>{t('otherInfo')}</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('languageRequirements.header')} </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('languageRequirements.language')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('languageRequirements.studyField')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>{t('languageRequirements.clarification')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('studyFields.header')} </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('studyFields.studyField')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('studyFields.clarification')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('contacts.header')} </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('contacts.sendingContacts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('contacts.receivingContacts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row style={{marginLeft: 35, marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"orange"} text={t('editConditionButton')} handleClick={() => {setCurrentIndex(index); setShowFields(false); setType("edit"); setTypeOfCoopCondition(t('mobilityTypes.staffTeachers')); setCoopCondition(elem); setAcademicYears(academicYearsValues); setShowModal(true)}} icon={faPen} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion></Col>
                    <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                        <IconButton
                            variant="delete"
                            onClick={() => handleRemoveCoopCondition(index, "Staff Teachers")}
                            title={t('deleteCoopConditionButton')}
                            // onClick={() => handleRemoveOtherDocumentsFields(index)}
                        />
                    </Col></Row>
                    </>
                    ))}
                    {staffTrainings.length > 0 && staffTrainings.map((elem, index) => (
                        <><Row><Col lg={11}><Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>{t('cooperationConditions.staffTraining')}:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / {t('cooperationConditions.studyFields')}: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('heis.sender')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('heis.receiver')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name?.length > 0 && elem?.sending_ounit_name[0]?.value}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name?.length > 0 && elem?.receiving_ounit_name[0]?.value}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>{t('conditionDetails.header')} </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('conditionDetails.mobilityType')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.duration')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>{t('conditionDetails.academicYears')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.spots')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{t('mobilityTypes.staffTrainings')}</td>
                                        <td className='border-hidden body'>{elem?.total_days_per_year ? elem?.total_days_per_year + t('daysPerYear') : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year?.length > 0 && elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>{t('otherInfo')}</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('languageRequirements.header')} </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('languageRequirements.language')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('languageRequirements.studyField')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>{t('languageRequirements.clarification')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('studyFields.header')} </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('studyFields.studyField')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('studyFields.clarification')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('contacts.header')} </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('contacts.sendingContacts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('contacts.receivingContacts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row style={{marginLeft: 35, marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"orange"} text={t('editConditionButton')} handleClick={() => { setCurrentIndex(index); setShowFields(false); setType("edit"); setTypeOfCoopCondition(t('mobilityTypes.staffTrainings')); setCoopCondition(elem); setAcademicYears(academicYearsValues); setShowModal(true)}} icon={faPen} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion></Col>
                    <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                    <IconButton
                        variant="delete"
                        onClick={() => handleRemoveCoopCondition(index, "Staff Trainings")}
                        title={t('deleteCoopConditionButton')}
                        // onClick={() => handleRemoveOtherDocumentsFields(index)}
                    />
                </Col></Row>
                </>
                    ))}
                    {studentStudies.length > 0 && studentStudies.map((elem, index) => (
                        <><Row><Col lg={11}><Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>{t('cooperationConditions.studentStudy')}:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / {t('cooperationConditions.studyFields')}: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('heis.sender')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('heis.receiver')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name?.length > 0 && elem?.sending_ounit_name[0]?.value}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name?.length > 0 && elem?.receiving_ounit_name[0]?.value}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>{t('conditionDetails.header')} </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('conditionDetails.mobilityType')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.duration')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>{t('conditionDetails.academicYears')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.eqfLevel')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.spots')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{t('mobilityTypes.studentStudies')}{elem?.blended === "1" && ", blended mobility"}</td>
                                        <td className='border-hidden body'>{elem?.total_months_per_year ? elem?.total_months_per_year + t('monthsPerYear') : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year?.length > 0 && elem?.receiving_acad_year[0].split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1].split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.eqf_level?.length > 0 && elem?.eqf_level?.join(", ")}</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>{t('otherInfo')}</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('languageRequirements.header')} </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('languageRequirements.language')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('languageRequirements.studyField')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>{t('languageRequirements.clarification')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('studyFields.header')} </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('studyFields.studyField')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('studyFields.clarification')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('contacts.header')} </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('contacts.sendingContacts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('contacts.receivingContacts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row style={{marginLeft: 35, marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"orange"} text={t('editConditionButton')} handleClick={() => { setCurrentIndex(index); setType("edit"); setShowFields(true); setTypeOfCoopCondition(t('mobilityTypes.studentStudies')); setCoopCondition(elem); setAcademicYears(academicYearsValues); setShowModal(true)}} icon={faPen} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion></Col>
                    <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                    <IconButton
                        variant="delete"
                        onClick={() => handleRemoveCoopCondition(index, "Student Studies")}
                        title={t('deleteCoopConditionButton')}
                        // onClick={() => handleRemoveOtherDocumentsFields(index)}
                    />
                    </Col></Row></>
                    ))}
                    {studentTraineeships.length > 0 && studentTraineeships.map((elem, index) => (
                        <><Row><Col lg={11}><Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>{t('cooperationConditions.studentTraineeship')}:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / {t('cooperationConditions.studyFields')}: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('heis.sender')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('heis.receiver')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name?.length > 0 && elem?.sending_ounit_name[0]?.value}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name?.length > 0 && elem?.receiving_ounit_name[0]?.value}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>{t('conditionDetails.header')} </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('conditionDetails.mobilityType')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.duration')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>{t('conditionDetails.academicYears')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.eqfLevel')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('conditionDetails.spots')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{"Student trainships"}{elem?.blended === "1" && ", blended mobility"}</td>
                                        <td className='border-hidden body'>{elem?.total_months_per_year ? elem?.total_months_per_year + t('monthsPerYear') : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year?.length > 0 && elem?.receiving_acad_year[0].split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1].split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.eqf_level?.length > 0 && elem?.eqf_level?.join(", ")}</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>{t('otherInfo')}</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('languageRequirements.header')} </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('languageRequirements.language')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('languageRequirements.studyField')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>{t('languageRequirements.clarification')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('studyFields.header')} </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('studyFields.studyField')}</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('studyFields.clarification')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>{t('contacts.header')} </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>{t('contacts.sendingContacts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>{t('contacts.receivingContacts')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.contact_names?.length > 0 ? element?.contact_names[0]?.value + ", " : ""}
                                            {element?.emails?.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers?.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col>
                                </Row>
                                <Row style={{marginLeft: 35, marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"orange"} text={t('editConditionButton')}  handleClick={() => {setCurrentIndex(index); setType("edit"); setTypeOfCoopCondition(t('mobilityTypes.studentTraineeships')); setShowFields(true); setCoopCondition(elem); setAcademicYears(academicYearsValues); setShowModal(true)}} icon={faPen} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion></Col>
                    <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                    <IconButton
                        variant="delete"
                        onClick={() => handleRemoveCoopCondition(index, "Student Traineeships")}
                        title={t('deleteCoopConditionButton')}
                        // onClick={() => handleRemoveOtherDocumentsFields(index)}
                    />
                    </Col></Row></>
                    ))}
                    
                <Row style={{marginLeft: 35, marginRight: 35}}>
                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                            <CustomButton buttonType={"primary"} text={t('addNewConditionButton')} handleClick={() => {setTypeOfCoopCondition("");     
                            setCoopCondition({}); setType("add"); setShowFields(false); setIndexReceivingSchac(0); setIndexSendingSchac(0); setSendingOUnits([]); setReceivingOUnits([]); setAcademicYears(academicYearsValues); setShowModal(true)}} icon={faPlus} hasIcon styles={{marginLeft: 20}} small/>
                        </Col>
                </Row>
                </Col>
            </Row>
        </Col>
    </Row>
    <CustomModal
        show={showModal}
        size={"xl"}
        
        handleClose={() => {
          setShowModal(false);
        }}
        // disabledCancelButton={
        // //   loadingAddAccount || loadingRemoveAccount || loadingUpdateAccount
        // }
        body={<>
            <Row style={{background: "#F2F3F6", paddingBottom: 50}}>
                <div className='h6-style' style={{marginTop: 5, paddingLeft: 12, color: "#3D619C"}}>{t('modal.title')} </div>
                <div className='body' style={{marginTop: 5, paddingLeft: 12}}>{t('modal.subTitle')} </div>
                    <Col lg={12} style={{display: "flex", marginTop: 10}}>
                    <Col lg={6}>
                    <Form
                        onSubmit={(e) => console.log("handle submit")}
                        noValidate
                    >
                        <Row>
                        <Col lg={10}>
                        <Form.Group  controlId="formBasicName">
                        <Form.Label>{t('modal.schacCode.sender.title')} *</Form.Label>
                        <Select
                            placeholder={t('modal.schacCode.sender.placeholder')}
                            // invalidText="Please provide a role for the account."
                            // isDisabled={type === "edit" || indexSendingSchac === 1}
                            value={getFilterValueForSelectField(coopCondition?.sending_hei_id)}
                            onChange={(el) => {setIndexSendingSchac(indexSendingSchac + 1); handleChangeSendingSchacCode(el)}}
                            options={SCHACCodes.map((el) => ({
                            value: el,
                            label: el,
                            }))}
                        />
                        </Form.Group></Col>
                        <Col lg={2} className="flex-center"><span className="arrow-cond"/></Col></Row>
                    </Form>
                    </Col>
                    <Col lg={6}>
                    <Form
                        onSubmit={(e) => console.log("handle submit")}
                        noValidate
                    >
                        <Form.Group  className="col-11" controlId="formBasicName">
                        <Form.Label>{t('modal.schacCode.receiver.title')} *</Form.Label>
                        <Select
                            placeholder={t('modal.schacCode.receiver.placeholder')}
                            // invalidText="Please provide a role for the account."
                            // isDisabled={type === "edit" || indexReceivingSchac === 1}
                            value={getFilterValueForSelectField(coopCondition?.receiving_hei_id)}
                            onChange={(el) => {setIndexReceivingSchac(indexReceivingSchac + 1);  handleChangeReceivingSchacCode(el)}}
                            options={SCHACCodes?.map((el) => ({
                            value: el,
                            label: el,
                            }))}
                        />
                        </Form.Group>
                    </Form>
                    </Col>
                    </Col>
                    <Col lg={12} style={{display: "flex", marginTop: 10}}>
                    <Col lg={6}>
                    <Form
                        onSubmit={(e) => console.log("handle submit")}
                        noValidate
                    >
                        <Row>
                        <Col lg={10}>
                        <Form.Group  controlId="formBasicName">
                        <Form.Label>{t('modal.orgUnit.sender.title')}</Form.Label>
                        <Select
                            placeholder={t('modal.orgUnit.sender.placeholder')}
                            isDisabled={!coopCondition?.sending_hei_id}
                            value={getFilterValueForSelectFieldSendingOUnit()}
                            onChange={(el) => handleChangeSendingOUnit(el)}
                            options={sendingOUnits?.map((el) => ({
                            value: el.ounit_id,
                            label: el?.ounit_name?.name,
                            }))}
                        />
                        </Form.Group></Col>
                        <Col lg={2} className="flex-center"><span className="arrow-cond"/></Col></Row>
                    </Form>
                    </Col>
                    <Col lg={6}>
                    <Form
                        onSubmit={(e) => console.log("handle submit")}
                        noValidate
                    >
                        <Form.Group  className="col-11" controlId="formBasicName">
                        <Form.Label>{t('modal.orgUnit.receiver.title')}</Form.Label>
                        <Select
                            placeholder={t('modal.orgUnit.receiver.placeholder')}
                            isDisabled={!coopCondition?.receiving_hei_id}
                            value={getFilterValueForSelectFieldReceivingOUnit()}
                            onChange={(el) => handleChangeReceivingOUnit(el)}
                            options={receivingOUnits?.map((el) => ({
                            value: el.ounit_id,
                            label: el?.ounit_name?.name,
                            }))}
                        />
                        </Form.Group>
                    </Form>
                    </Col>
                    </Col>
                    <Row>
                        <Col lg={12} style={{marginTop: 10}}>
                            <Form
                                onSubmit={(e) => console.log("handle submit")}
                                noValidate
                            >
                                <Form.Group className="col-12" controlId="formBasicName">
                                <Form.Label>{t('modal.mobilitiesPerYear.title')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="10"
                                    onChange={(event) => handleInputChange(event)}
                                    required
                                    name="mobilityPerYear"
                                    value={coopCondition?.mobilities_per_year}
                                />
                                </Form.Group>
                            </Form>
                            <div className='body' style={{marginTop: 5, color: "#6C757D"}}>{t('modal.mobilitiesPerYear.description')}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} style={{marginTop: 10}}>
                            {/* <Form
                                onSubmit={(e) => console.log("handle submit")}
                                noValidate
                            >
                                <Form.Group className="col-12" controlId="formBasicName">
                                <Form.Label>Start Academic Year *</Form.Label>
                                <Select
                                    placeholder="Select.."
                                    onChange={(el) => handleChangeStartAcademicYear(el)}
                                    
                                    value={getFilterValueForSelectField(coopCondition?.receiving_acad_year?.length > 0 && coopCondition?.receiving_acad_year[0])}
                                    options={academicYears?.map((el) => ({
                                    value: el,
                                    label: el}))}
                                />
                                </Form.Group>
                            </Form> */}
                            
                                <Form.Group
                                    controlId="formInstitutionalWebsite"
                                    className={"px-0"}
                                >
                                    <Form.Label className="ps-0">{t('modal.academicYear.start.title')} *</Form.Label>
                                    <Form.Select
                                    required
                                    value={coopCondition?.receiving_acad_year?.length > 0 ? coopCondition?.receiving_acad_year[0] : ""}
                                    onChange={
                                        
                                        (el) => handleChangeStartAcademicYear(el)
                                    }
                                    >
                                    <option value="" disabled>
                                        
                                        {t('modal.academicYear.start.placeholder')}
                                    </option>
                                    {
                                        academicYears
                                        ?.map((academicYear) => (
                                            <option
                                            key={academicYear.value}
                                            value={academicYear.value}
                                            >
                                            {academicYear.value}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                    {t('modal.academicYear.start.error')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                        </Col>
                        <Col lg={6} style={{marginTop: 10}}>
                            {/* <Form
                                onSubmit={(e) => console.log("handle submit")}
                                noValidate
                            >
                                <Form.Group className="col-12" controlId="formBasicName">
                                <Form.Label>End Academic Year *</Form.Label>
                                <Select
                                    placeholder="Select.."
                                    onChange={(el) => handleChangeEndAcademicYear(el)}
                                    
                                    value={getFilterValueForSelectField(coopCondition?.receiving_acad_year?.length > 0 && coopCondition?.receiving_acad_year[1])}
                                    options={academicYears?.map((el) => ({
                                    value: el,
                                    label: el}))}
                                    
                                />
                                </Form.Group>
                            </Form> */}
                            <Form.Group
                                    controlId="formInstitutionalWebsite"
                                    className={"px-0"}
                                >
                                    <Form.Label className="ps-0">{t('modal.academicYear.end.title')} *</Form.Label>
                                    <Form.Select
                                    required
                                    value={coopCondition?.receiving_acad_year?.length > 0 ? coopCondition?.receiving_acad_year[1] : ""}
                                    onChange={
                                        
                                        (el) => handleChangeEndAcademicYear(el)
                                    }
                                    >
                                    <option value="" disabled>
                                        
                                        {t('modal.academicYear.end.placeholder')}
                                    </option>
                                    {
                                        academicYears
                                        ?.map((academicYear) => (
                                            <option
                                            key={academicYear.value}
                                            value={academicYear.value}
                                            disabled={academicYear.disabled}
                                            >
                                            {academicYear.value}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                    {t('modal.academicYear.end.error')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                        </Col>
                    </Row>
                    <div className='h6-style' style={{marginTop: 20, paddingLeft: 12, color: "#3D619C"}}>{t('modal.subjectArea.header')} </div>
                    {coopCondition?.subject_area?.map((elem, index) => 
                        <Row style={{background: "white", borderRadius: 10, marginLeft: 1, marginRight: 5}}>
                        
                        <Col lg={11} style={{paddingLeft: 30}}>
                                            <div style={{marginBottom: 30, marginTop: 20}} className='label-style'>
                                            <Col lg={12} style={{display: "flex"}}>
                                            <Col lg={6}>
                                            <Form
                                                onSubmit={(e) => console.log("handle submit")}
                                                noValidate
                                            >
                                                <Form.Group className="col-11" controlId="formBasicName">
                                                <Form.Label>{t('modal.subjectArea.fCode.title')} *</Form.Label>
                                                <Select
                                                    placeholder={t('modal.subjectArea.fCode.placeholder')}
                                                    onChange={(el) => handleChangeSubjectAreaIsced(el, index)}
                                                    
                                                    value={getFilterValueForSelectField(elem?.isced_f_code)}
                                                    options={dataISCEDCodes?.iscedCodes?.map((el) => ({
                                                    value: el.code,
                                                    label: `${el.code} - ${el.name}`}))}
                                                />
                                                </Form.Group>
                                            </Form></Col>
                                            <Col lg={6}>
                                            <Form
                                                onSubmit={(e) => console.log("handle submit")}
                                                noValidate
                                            >
                                                <Form.Group className="col-11" controlId="formBasicName">
                                                <Form.Label>{t('modal.subjectArea.clarification.title')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t('modal.subjectArea.clarification.placeholder')}
                                                    onChange={(event) => handleInputArrayChange(event, index)}
                                                    required
                                                    name="ISCEDClar"
                                                    value={elem?.isced_clarification}
                                                    // value={element.url}
                                                />
                                                </Form.Group>
                                            </Form></Col>
                                            </Col>
                                            </div>
                                            </Col>
                                            <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                                                <IconButton
                                                    variant="delete"
                                                    onClick={() => handleRemoveSubjectArea(index)}
                                                    title={t('modal.subjectArea.deleteButton')}
                                                    // onClick={() => handleRemoveOtherDocumentsFields(index)}
                                                />
                                            </Col>
                                    </Row>
                    )}
                                <Row style={{marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={t('modal.subjectArea.addSubjectArea')} handleClick={handleAddSubjectArea} icon={faPlus} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 20, paddingLeft: 12, color: "#3D619C"}}>{t('modal.mobilityType.header')} </div>
                                <div className='body' style={{marginTop: 5, paddingLeft: 12}}>{t('modal.mobilityType.description')} </div>
                                <Col lg={12} style={{display: "flex"}}>
                                        <Col lg={3}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.mobilityType.header')} *</Form.Label>
                                            <Select
                                                isDisabled={type === "edit"}
                                                value={getFilterValueForSelectField(
                                                typeOfCoopCondition
                                                )}
                                                onChange={(el) => handleChangeTypeOfCoopCondition(el)}
                                                options={mobilityTypes.map((el) => ({
                                                value: el,
                                                label: el,
                                                }))}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </Col>
                                        <Col lg={3}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.mobilityType.duration.title')}{showFields ? t('modal.mobilityType.duration.totalMonths') : t('modal.mobilityType.duration.totalDays')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="10"
                                                onChange={(event) => handleInputChange(event)}
                                                name="duration"
                                                value={showFields ? coopCondition?.total_months_per_year : coopCondition?.total_days_per_year}
                                            />
                                            <div className='body' style={{marginTop: 5, color: "#6C757D"}}>
                                               {showFields ? t('modal.mobilityType.duration.fill.allStudents') : t('modal.mobilityType.duration.fill.staff')}
                                            </div>
                                            </Form.Group>
                                        </Form></Col>
                                        {showFields && (<><Col lg={3}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.mobilityType.studyCycle.header')}</Form.Label>
                                            <MultiSelect
                                                value={getValueFromSelectEqfs(
                                                coopCondition?.eqf_level
                                                )}
                                                onChange={(item) => 
                                                    handleMultiSelectEqf(item)
                                                }
                                                options={eqfLevels?.map((eqf) => ({
                                                value: eqf.value,
                                                label: eqf.label,
                                                }))}
                                                placeholder={t('modal.mobilityType.studyCycle.placeholder')}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={3}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.mobilityType.blendedMobility.header')} * </Form.Label>
                                            <Select
                                                onChange={(el) => handleChangeBlended(el)}
                                                value={getFilterValueForSelectBlendedField(
                                                    )}
                                                options={blendedMobility.map((el) => ({
                                                value: el,
                                                label: el,
                                                }))}
                                            />
                                            </Form.Group>
                                        </Form></Col></>)}
                                        </Col>
                                        {showFields && <div className='body' style={{marginTop: 5, paddingLeft: 12}}>{t('modal.mobilityType.blendedMobility.confirmation')}</div>}
                                        <div className='h6-style' style={{marginTop: 20, paddingLeft: 12, color: "#3D619C"}}>{t('modal.languageSkills.header')} {(typeOfCoopCondition === t('modalTypes.studentStudies') || typeOfCoopCondition === t('modalTypes.staffTeachers')) && <span>*</span>}</div>
                                        <div className='body' style={{marginTop: 5, paddingLeft: 12}}>{t('modal.languageSkills.description')} </div>
                                        {coopCondition?.language_skill?.map((elem, index) => <Row style={{background: "white", borderRadius: 10, marginLeft: 1, marginRight: 5}}>
                                        <Col lg={11} style={{paddingLeft: 30}}>
                                        <div style={{marginBottom: 30, marginTop: 20}} className='label-style'>
                                        <Col lg={12} style={{display: "flex"}}>
                                        <Col lg={3}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-11" controlId="formBasicName">
                                            <Form.Label>{t('modal.languageSkills.languageOfInstruction.title')} *</Form.Label>
                                            <Select
                                                options={languagesvalues?.map((el) => ({
                                                    value: el.code.toLowerCase(),
                                                    label: el.title}))}
                                                placeholder={t('modal.languageSkills.languageOfInstruction.placeholder')}
                                                onChange={(el) => handleChangeLanguage(el, index)}
                                                required={typeOfCoopCondition === t('mobilityTypes.studentStudies') || typeOfCoopCondition === t('mobilityTypes.staffTeachers')}
                                                value={getFilterValueForSelectFieldLanguage(elem)}
                                                name={'code'}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={3}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-11" controlId="formBasicName">
                                            <Form.Label>{t('modal.languageSkills.level.title')}</Form.Label>
                                            <Select
                                                placeholder={t('modal.languageSkills.level.placeholder')}
                                                onChange={(el) => handleChangeLanguageLevel(el, index)}
                                                
                                                value={getFilterValueForSelectField(elem?.cefr_level)}
                                                options={CEFRvalues?.map((el) => ({
                                                    value: el.code,
                                                    label: `${el.code}: ${el.title}`}))}
                                                
                                                // value={getValueFromSelectLanguageLevel(element)}
                                                // onChange={(event) => handleInputLanguageLevelChange(index, event)}
                                                
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={3}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-11" controlId="formBasicName">
                                            <Form.Label>{t('modal.languageSkills.fCode.title')}</Form.Label>
                                            <Select
                                                placeholder={t('modal.languageSkills.fCode.placeholder')}
                                                onChange={(el) => handleChangeLanguageIsced(el, index)}
                                                
                                                value={getFilterValueForSelectField(elem?.isced_f_code)}
                                                options={dataISCEDCodes?.iscedCodes?.map((el) => ({
                                                value: el.code,
                                                label: `${el.code} - ${el.name}`}))}
                                                // value={getFilterValueForMultiSelectField(
                                                //     selectedValues["country"]
                                                //   )}
                                                // onChange={(item) => {
                                                // handleMultiSelectISCED
                                                // }}
                                            // value={getValueFromSelectISCED}
                                            // onChange={(event) => handleInputLanguageLevelChange(index, event)}
                                                
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={3}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-11" controlId="formBasicName">
                                            <Form.Label>{t('modal.languageSkills.clarification.title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('modal.languageSkills.clarification.placeholder')}
                                                onChange={(event) => handleInputArrayChange(event, index)}
                                                required
                                                name="clar"
                                                disabled={!elem?.isced_f_code}
                                                value={elem?.isced_clarification}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        </Col>
                                        </div>
                                        </Col>
                                        <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                                            <IconButton
                                                variant="delete"
                                                onClick={() => handleRemoveLanguage(index)}
                                                title={t('modal.languageSkills.deleteButton')}
                                                // onClick={() => handleRemoveOtherDocumentsFields(index)}
                                            />
                                        </Col>
                                        
                                </Row>)}
                                <Row style={{marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={t('modal.languageSkills.addLanguage')} handleClick={handleAddLanguage} icon={faPlus} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 20, paddingLeft: 12, color: "#3D619C"}}>{t('modal.contact.sending.header')} </div>
                                        <div className='body' style={{marginTop: 5, paddingLeft: 12}}>{t('modal.contact.sending.description')}</div>
                                        {coopCondition?.sending_contact?.map((elem, index) => <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        <Col lg={11}>
                                        <div style={{marginBottom: 30, marginTop: 20}} className='label-style'>
                                        <Col lg={12} style={{display: "flex"}}>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.contact.name.title')} *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('modal.contact.name.placeholder')}
                                                onChange={(event) => handleInputContactSendingNameChange(index, event)}
                                                required
                                                name="name"
                                                value={elem?.contact_names[0]?.value}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate 
                                            //validated={validatedSending}
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.contact.email.title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('modal.contact.email.placeholder')}
                                                onChange={(event) => handleInputContactSendingNameChange(index, event)}
                                                required
                                                name="email"
                                                value={elem?.emails[0]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t('modal.contact.email.error')}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.contact.phone.title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('modal.contact.phone.placeholder')}
                                                onChange={(event) => handleInputContactSendingNameChange(index, event)}
                                                required
                                                name="phone"
                                                value={elem?.phone_numbers[0]?.e164}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        </Col>
                                        </div>
                                        </Col>
                                        <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                                            <IconButton
                                                variant="delete"
                                            
                                                title={t('modal.contact.deleteContact')}
                                                onClick={() => handleRemoveContactSending(index)}
                                            />
                                        </Col>
                                </Row>)}
                                <Row style={{marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={t('modal.contact.sending.addContact')} handleClick={handleAddContactSending} icon={faPlus} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 20, paddingLeft: 12, color: "#3D619C"}}>{t('modal.contact.receiving.header')} </div>
                                        <div className='body' style={{marginTop: 5, paddingLeft: 12}}>{t('modal.contact.receiving.description')} </div>
                                        {coopCondition?.receiving_contact?.map((elem, index) => <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        <Col lg={11}>
                                        <div style={{marginBottom: 30, marginTop: 20}} className='label-style'>
                                        <Col lg={12} style={{display: "flex"}}>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.contact.name.title')} *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('modal.contact.name.placeholder')}
                                                onChange={(event) => handleInputContactReceivingNameChange(index, event)}
                                                required
                                                name="name"
                                                value={elem?.contact_names[0]?.value}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate 
                                            //validated={validatedReceiving}
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.contact.email.title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('modal.contact.email.placeholder')}
                                                onChange={(event) => handleInputContactReceivingNameChange(index, event)}
                                                required
                                                name="email"
                                                value={elem?.emails[0]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            {t('modal.contact.email.error')}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>{t('modal.contact.phone.title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('modal.contact.name.placeholder')}
                                                onChange={(event) => handleInputContactReceivingNameChange(index, event)}
                                                required
                                                name="phone"
                                                value={elem?.phone_numbers[0]?.e164}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        </Col>
                                        </div>
                                        </Col>
                                        <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                                            <IconButton
                                                variant="delete"
                                            
                                                title={t('modal.contact.deleteContact')}
                                                onClick={() => handleRemoveContactReceiving(index)}
                                            />
                                        </Col>
                                </Row>)}
                                <Row style={{marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={t('modal.contact.receiving.addContact')} handleClick={handleAddContactReceiving} icon={faPlus} hasIcon styles={{marginLeft: 20}} small/>
                                        </Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 20, paddingLeft: 12, color: "#3D619C"}}>{t('modal.otherInfo.header')} </div>
                                <div className='body' style={{marginTop: 5, paddingLeft: 12}}>{t('modal.otherInfo.description')}</div>
                                <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-11" controlId="formBasicName">
                                            <Form.Label>{t('modal.otherInfo.header')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('modal.otherInfo.placeholder')}
                                                onChange={(event) => handleInputChange(event)}
                                                required
                                                name="info"
                                                value={coopCondition?.other_info_terms}
                                                as={"textarea"}
                                            />
                                            </Form.Group>
                                        </Form>
                </Row>
        </>
        
        }
        button={
           // new
            <CustomButton
              buttonType={"accept"}
            //   disabled={loadingAddAccount}
              text={type === "add" ? t('modal.addCondition') : t('modal.saveChanges')}
              icon={faCheck}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={type === "add" ? handleAdd : handleEdit}
            />
        }
        title={
          
            type === "add" ? t('modal.addNewCondition') : t('modal.editCondition')
          
        }
        subtitle={
           type === "add" ? t('modal.fillNew') : t('modal.fillEdit')
        }
        // hasIcon
        fullscreen
      />
    </>
  );
};

export default ThirdStep;