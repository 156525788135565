import { reducer } from '../reducers/useIiaFiltersReducer';
import { useReducer, useCallback } from 'react';
import {
    SET_PARTNER_ID_ACTION,
    SET_PARTNER_HEI_ID_ACTION,
    SET_APPROVING_PERSON_ACTION,
    SET_OUNIT_ACTION,
    SET_STATUS_ACTION,
    SET_EXPAND_FILTERS_ACTION,
    SET_SELECTED_FILTERS_LIST_ACTION,
    SET_SORT_BY_ACTION,
    UPDATE_MULTIPLE_VALUES_ACTION
} from '../reducers/useIiaFiltersReducer';

// fallback state
export const defaultState = {
    partnerID: "",
    status: "",
    partnerHeiId: "",
    approvingPersonEmail: "",
    organizationalUnit: "",
    selectedFiltersList: [],
    sortBy: "",
    expandFilters: false
}

/**
 * Used to handle filters for IIA queries
 * 
 * @param {Object} initialState the initial state of the filters, fallbacks to a default state with empty string and lists if no initial state is provided
 * @returns Filters state as well as handlers for changing the state
 */
export const useIiaFilters = (initialState = defaultState) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setPartnerId = useCallback((data) => {
        dispatch({ type: SET_PARTNER_ID_ACTION, payload: { partnerID: data } });
    }, []);

    const setPartnerHeiId = useCallback((data) => {
        dispatch({ type: SET_PARTNER_HEI_ID_ACTION, payload: { partnerHeiId: data } });
    }, []);

    const setApprovingPersonEmail = useCallback((data) => {
        dispatch({ type: SET_APPROVING_PERSON_ACTION, payload: { approvingPersonEmail: data } });
    }, []);

    const setOrganizationalUnit = useCallback((data) => {
      dispatch({ type: SET_OUNIT_ACTION, payload: { organizationalUnit: data } });
  }, []);

    const setStatus = useCallback((data) => {
        dispatch({ type: SET_STATUS_ACTION, payload: { status: data } });
    }, []);

    const setExpandFilters = useCallback((data) => {
        dispatch({ type: SET_EXPAND_FILTERS_ACTION, payload: { expandFilters: data } });
    }, []);

    const setSelectedFiltersList = useCallback((data) => {
        dispatch({ type: SET_SELECTED_FILTERS_LIST_ACTION, payload: { selectedFiltersList: data } });
    }, []);

    const setSortBy = useCallback((data) => {
        dispatch({ type: SET_SORT_BY_ACTION, payload: { sortBy: data } });
    }, []);

    const updateMultipleValues = useCallback((data) => {
        dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data })
    }, []);

    return {
        state,
        setPartnerId,
        setApprovingPersonEmail,
        setOrganizationalUnit,
        setPartnerHeiId,
        setStatus,
        setExpandFilters,
        setSelectedFiltersList,
        setSortBy,
        updateMultipleValues
    };
}