import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import COLORS from "../../data/colors";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";
import "./index.scss";
function CustomModal({
  show,
  handleClose,
  body,
  button,
  title,
  subtitle,
  hasIcon,
  fullscreen,
  size,
  disabledCancelButton = false,
  bodyClassName
}) {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        fullscreen={fullscreen && "lg-down"}
        size={size}
      >
        <Modal.Header closeButton style={{ borderStyle: "none" }}>
          <Modal.Title>
            <div className="h2-style">{title}</div>
            <div className="h6-style">{subtitle}</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={bodyClassName && bodyClassName}>
          <div className="body">{body}</div>
        </Modal.Body>

        <Modal.Footer style={{ borderStyle: "none" }}>
          <CustomButton
            buttonType={"secondary"}
            disabled={disabledCancelButton}
            text={t('modal.cancelButton')}
            icon={hasIcon && faXmark}
            hasIcon={hasIcon}
            iconColor={hasIcon && COLORS.black}
            handleClick={handleClose}
          />
          {button}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomModal;
