import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            translation: {
                loadingMessage: "Loading...",
                errorMessage: "An error occurred",
                sidebar: {
                  institutions: "Institutions",
                  requests: "Requests",
                  myUniversity: "My University",
                  accountsAndAccess: "Accounts & Access",
                  organizationalUnits: "Organizational Units",
                  ukraineAssistance: "Ukraine Assistance",
                  ola: "OLA",
                  outgoingStudents: "Outgoing Students",
                  incomingStudents: "Incoming Students",
                  upload: "Upload",
                  shortTerm: "Short Term",
                  applications: "Applications",
                  requirements: "Requirements",
                  iia: "IIA",
                  iiaList: "IIA List",
                  newDigitalIIA: "New Digital IIA",
                  importIIA: "Import IIA",
                  exportIIAs: "Export IIAs",
                  defaultIIAData: "Default IIA Data",
                  erasmusApp: "Erasmus+ App",
                  usefulInfo: "Useful Info",
                  deals: "Deals",
                  events: "Events",
                  coursesEvaluations: "Courses Evaluations",
                  ewpSettings: "EWP Settings",
                },
                home: {
                    header: "Welcome to EWP Dashboard!",
                    title: "Home Page",
                    tab: "Home",
                    description: {
                      part1: "Welcome to EWP Dashboard, the tool for managing your students' mobilities through the Erasmus+ program. As an IRO, you play a critical role in helping your students make the most of their experiences abroad, and EWP Dashboard is here to support you in that effort.", 
                      part2: "We are excited to announce that we have made some significant improvements to the user interface and user experience of the application. We have worked hard to make EWP Dashboard more intuitive and user-friendly, and we believe that these changes will make it easier for you to manage your students' mobilities and make the most of the opportunities offered by the Erasmus+ program."
                    }
                },
                ewpSettings: {
                  header: "EXCHANGE OF INFORMATION THROUGH EWP DASHBOARD",
                  title: "EWP SETTINGS",
                  subTitle: {
                    part1: "Please ",
                    part2: " check / uncheck  ",
                    part3: "according to what applies to your HEI."
                  },
                  ewpSettingType: {
                    updatingSettings: "Updating EWP Settings...",
                    updatedSuccessfully: `Successfully updated EWP Settings`,
                    unableToUpdate: `Could not update EWP Settings`,
                    exchangeOfIIAs: {
                      title: "Exchange of IIAs through EWP Dashboard",
                      checkbox: "I agree that the EWP Dashboard represents my higher education institution in the EWP Network to exchange inter-institutional agreements with your partners."
                    },
                    exchangeOfOUnits: {
                      title: "Exchange of OUnits through EWP Dashboard",
                      checkbox: "I agree that the EWP Dashboard represents my higher education institution in the EWP Network for the purpose of sharing the Organizational Units information with your partners."
                    },
                    exchangeOfStaticInfo: {
                      title: "Exchange of static information for IIAs through EWP Dashboard",
                      checkbox: "I agree that the EWP Dashboard represents my higher education institution in the EWP Network to exchange the static information for inter-institutional agreements with your partners."
                    },
                    exchangeOfOLAs: {
                      title: "Exchange of OLAs through EWP Dashboard",
                      checkbox: "I agree that the EWP Dashboard represents my higher education institution in the EWP Network for the purpose of enabling the electronic exchange of the Online Learning Agreement information."
                    }
                  },
                  cancelButton: "Cancel",
                  saveSettingsButton: "Save EWP Settings"
                },
                articleSlider: {
                  loadingMessage: "Loading...",
                  errorMessage: "An error occurred",
                  created: "Created: "
                },
                articleSingleView: {
                  title: "Blog Post",
                  subTitle: "Single View",
                  created: "Created: ",
                  lastUpdated: "Last Updated: ",
                  tags: "Tags: "
                },
                accountsAndAccess: {
                  title: "Accounts and Access",
                  accountsTab: "Accounts",
                  rolesTab: "Roles",
                  saveChangesButton: "Save Changes",
                  accountForm: {
                    accountName: {
                      label: "Account Name",
                      error: "Please provide an account name."
                    },
                    accountEmail: {
                      label: "Account Email",
                      error: "Please provide a valid account email."
                    },
                    accountPhone: {
                      label: "Phone Number",
                      error: "This account's registered phone number is not a valid phone number. Please provide a valid phone."
                    },
                    accountRole: {
                      label: "Role",
                      placeholder: "Select role...",
                      error: "Please provide a role for the account."
                    },
                  },
                  roleForm: {
                    roleName: {
                      label: "Role Name",
                      error: "Please provide an role name."
                    },
                    rolePermissions: {
                      label: "Permissions",
                      placeholder: "Select permissions...",
                      error: {
                        a: "Please select at least one permission for this role.",
                        b: "You must have chosen at least one permission for a role."
                      }
                    }
                  },
                  accountFeedback: {
                    create: {
                      loading: "Creating account...",
                      success: "Successfully created the account ",
                      failure: "Could not create the account "
                    },
                    update: {
                      loading: "Updating account...",
                      success: "Successfully updated the account ",
                      failure: "Could not update the account "
                    },
                    delete: {
                      loading: "Deleting account...",
                      success: "Successfully deleted the account ",
                      failure: "Could not delete the account "
                    }
                  },
                  addNewAccount: {
                    title: "Add a new Account",
                    subTitle: "Add the necessary information to add a new Account for someone of your staff members",
                    buttonText: "Add new Account"
                  },
                  editAccount: {
                    title: "Editing an existing Account",
                    subTitle: "Edit the information of an existing Account and ",
                    buttonText: "Edit account"
                  },
                  deleteAccount: {
                    title: {
                      part1: "You are about to ",
                      part2: "delete",
                      part3: " the account "
                    },
                    subTitle: "After deleting the account, your staff member won't be able to sign in.",
                    buttonText: "Delete account"
                  },
                  form: {
                    accountName: {
                      label: "Account Name",
                      error: "Please provide an account name."
                    },
                    accountEmail: {
                      label: "Account Email",
                      error: "Please provide a valid account email."
                    },
                    accountPhone: {
                      label: "Phone Number",
                      error: "This account's registered phone number is not a valid phone number. Please provide a valid phone."
                    },
                    accountRole: {
                      label: "Role",
                      placeholder: "Select role...",
                      error: "Please provide a role for the account."
                    }
                  },
                  roleFeedback: {
                    create: {
                      loading: "Creating role...",
                      success: "Successfully created the role ",
                      failure: "Could not create the role "
                    },
                    update: {
                      loading: "Updating role...",
                      success: "Successfully updated the role ",
                      failure: "Could not update the role "
                    },
                    delete: {
                      loading: "Deleting role...",
                      success: "Successfully deleted the role ",
                      failure: "Could not delete the role ",
                      warning: {
                        part1: "The role ",
                        part2: " is used by ",
                        part3: " account",
                        part4: {
                          a: "s",
                          b: ""
                        },
                        part5: ". Thus you cannot delete it."
                      }
                    }
                  },
                  addNewRole: {
                    title: "Add a new Role",
                    subTitle: "Add the necessary information to add a new Role that you can later assign to your staff members",
                    buttonText: "Add new Role"
                  },
                  editRole: {
                    title: "Editing an existing Role",
                    subTitle: "Edit the information of an existing Role and ",
                    buttonText: "Edit role"
                  },
                  deleteRole: {
                    title: {
                      part1: "You are about to ",
                      part2: "delete",
                      part3: " the role "
                    },
                    subTitle: "After deleting the role, you won’t be able to see it again.",
                    buttonText: "Delete role"
                  }
                },
                organizationalUnits: {
                  title: "Organizational Units",
                  saveChangesButton: "Save Changes",
                  unitForm: {
                    unitLanguage: {
                      label: "Unit Name Language",
                      placeholder: "Select language...",
                    },
                    unitName: {
                      label: "Unit Name",
                      placeholder: "Ounit Name",
                      error: "Please provide a valid Organizational Unit name."
                    },
                    unitAbbreviation: {
                      label: "Unit Abbreviation",
                      placeholder: "Ounit Abbreviation"
                    },
                    unitContactName: {
                      label: "Contact Name Outgoing Learning Agreements",
                    },
                    unitContactEmail: {
                      label: "Contact Email Outgoing Learning Agreements",
                      error: "Please provide a valid contact email."
                    },
                    incomingDifferent: {
                      label: "Incoming Learning Agreements contact person is different"
                    },
                    incomingContactName:{
                      label: "Contact Name Incoming Learning Agreements",
                    },
                    incomingContactEmail:{
                      label: "Contact Email Incoming Learning Agreements",
                      error: "Please provide a valid contact email."
                    }
                  },
                  unitFeedback: {
                    create: {
                      loading: "Creating Organizational Unit...",
                      success: "Successfully created the Organizational Unit with name ",
                      failure: "Could not create the Organizational Unit"
                    },
                    update: {
                      loading: "Updating Organizational Unit...",
                      success: "Successfully updated the Organizational Unit ",
                      failure: "Could not update the Organizational Unit"
                    },
                    delete: {
                      loading: "Deleting Organizational Unit...",
                      success: "Successfully deleted the Organizational Unit",
                      failure: "Could not delete the Organizational Unit"
                    }
                  },
                  addNewUnit: {
                    title: "Add a new Organizational Unit",
                    subTitle: "Add the necessary information to add a new Organizational Unit for your HEI",
                    buttonText: "Add a new Organizational Unit"
                  },
                  editUnit: {
                    title: "Edit an existing Organizational Unit",
                    subTitle: "Add the necessary information to edit an Organizational Unit for your HEI",
                    buttonText: "Edit Organizational Unit"
                  },
                  deleteUnit: {
                    title: {
                      part1: "You are about to ",
                      part2: "delete",
                      part3: " an Organizational Unit"
                    },
                    subTitle: "After deleting an Organizational Unit, you won't be able to see it again.",
                    buttonText: "Delete Organizational Unit",
                    buttonLabel: "Delete",
                  },
                },
                ukraineAssistance: {
                  title: "Ukraine Assistance",
                  infoFeedback: {
                    loading: "Updating Ukraine Assistance Information...",
                    success: "Successfully updated Ukraine Assistance Information",
                    failure: "Could not update Ukraine Assistance Information"
                  },
                  validateError: "Please select at least one 'Yes' option or provide a valid link",
                  invalidUrl: "Invalid URL",
                  header: "War in Ukraine - Student Support Centre",
                  description: "In response to the dramatic events unfolding in Ukraine, we have developed a portal to provide information about assistance offered by Higher Education Institutions to students fleeing the war. If you would like to help us broadcast how your University is stepping up and making a difference, please indicate:",
                  form: {
                    shelter: "Does your Institution provide emergency shelter?",
                    health: "Is health and psychological support made available at your Institution?",
                    fastTrack: "Does your Institution provide a fast track application procedure for studies?",
                    scholarship: "Does your Institution offer special scholarships for studies?",
                    options: {
                      yes: "Yes",
                      no: "No",
                      unkown: "Unknown"
                    }
                  },
                  moreInfo: {
                    header: "Please provide a link to your Institution's dedicated webpage with more information on this topic, or to your Institution's relevant contacts page.",
                    error: "Please provide a valid URL.",
                    buttonText: "Save Ukraine Assistance Information"
                  }
                },
                incomingStudents: {
                  title: "Online Learning Agreement",
                  subTitle: "Incoming Students",
                  tab: "Incoming Online Learning Agreements",
                  tabSingleView: "View single Incoming Online Learning Agreement",
                },
                outgoingStudents: {
                  title: "Online Learning Agreement",
                  subTitle: "Outgoing Students",
                  tab: "Outgoing Online Learning Agreements",
                  tabSingleView: "View single Outgoing Online Learning Agreement",
                },
                applications: {
                  title: "Applications for Erasmus+",
                  tab: {
                    out: "Outgoing Nominations",
                    in: "Incoming Nominations"
                  }
                },
                learningAgreements: {
                  loadingMessage: "Loading...",
                  signFeedback: {
                    loading: "Signing OLA...",
                    success: "Successfully signed learning agreement",
                    failure: "Error on signing."
                  },
                  declineOlaFeedback: {
                    loading: "Declining OLA...",
                    success: "Successfully declined learning agreement",
                    failure: "Error on declining."
                  }, 
                  editTableFailed: "Edit OLA course table failed.",
                  LearningAgreementInfo: {
                    title: "LEARNING AGREEMENT INFO"
                  },
                  generalInfo: {
                    title: "General Info",
                    studentPersonalData: {
                      title: "STUDENT PERSONAL DATA"
                    },
                    sendingInstInfo: {
                      title: "SENDING INSTITUTION INFO"
                    },
                    receivingInstInfo: {
                      title: "RECEIVING INSTITUTION INFO"
                    }
                  },
                  courses: {
                    title: "Courses",
                    tableA: "TABLE A",
                    tableB: "TABLE B",
                    tableC: "TABLE C",
                  },
                  changes: {
                    title: "Changes",
                    tableA: "CHANGES TO TABLE A",
                    tableB: "CHANGES TO TABLE B",
                    tableC: "CHANGES TO TABLE C",
                  },
                  modalReject: {
                    declineButton: "Decline",
                    body: {
                      part1: "After declining the OLA, the student will be able to edit it and introduce your proposed changes. Please fill the comments you want to be emailed to the student in field below: ",
                      part2: "Reason For Declining",
                      part3: " Note",
                    },
                    title: {
                      part1: "You are about to ",
                      part2: "decline",
                      part3: " a student's OLA"
                    }
                  },
                  modalSign: {
                    signButton: "Sign OLA",
                    body: "By digitally signing this document, the student, the Sending Institution and the Receiving Institution confirm that they approve the Learning Agreement and that they will comply with all the arrangements agreed by all parties. Sending and Receiving Institutions undertake to apply all the principles of the Erasmus Charter for Higher Education relating to mobility for studies (or the principles agreed in the Inter-Institutional Agreement for institutions located in Partner Countries). The Beneficiary Institution and the student should also commit to what is set out in the Erasmus+ grant agreement. The Receiving Institution confirms that the educational components listed are in line with its course catalogue and should be available to the student. The Sending Institution commits to recognise all the credits or equivalent units gained at the Receiving Institution for the successfully completed educational components and to count them towards the student's degree. The student and the Receiving Institution will communicate to the Sending Institution any problems or changes regarding the study programme, responsible persons and/or study period.",
                    title: {
                      part1: "You are about to ",
                      part2: "sign",
                      part3: " a student's OLA"
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Confirm",
                    body: {
                      part1: "As per official guidelines from the European Commission, if the automatic recognition will not take place, please provide a clear justification and an indication on what other type of formal recognition will be applied. ",
                      part2: "Fill in reason of rejecting"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "As per official guidelines from the European Commission, if the automatic recognition will not take place, please provide a clear justification and an indication on what other type of formal recognition will be applied. ",
                      part2: "Fill in reason of rejecting",
                    }
                  },
                  upload: {
                    titleMain: "Online Learning Agreement",
                    subTitle: "Upload CSV",
                    tab: "Upload CSV for Online Learning Agreements",
                    validateData: {
                      invalidCode: {
                        part1: "Invalid erasmus code ",
                        part2: " in line "
                      },
                      emailError: {
                        part1: "Email error in line ",
                        part2: ": Check the email format of your data."
                      },
                      dataError: {
                        part1: "Date error in line ",
                        part2: ": Make your the dates are in dd/mm/yyyy format."
                      },
                      fileError: "File error: You can only upload 25 LA's at once."
                    },
                    uploadOla: {
                      loading: "Uploading OLAs...",
                      success: "Successfully uploaded learning agreements",
                      failure: "There were some errors while uploading OLAs."
                    },
                    header: "Uploading Learning Agreements",
                    cancel: "Cancel",
                    upload: "Upload",
                    modalSign: {
                      part1: "Please make sure that the data is compliant with the ",
                      part2: "template"
                    },
                    title: {
                      part1: "You are about to ",
                      part2: "upload",
                      part3: " some OLAs"
                    },
                    dontClosePage: "This can take a while, please do not close this page!",
                    commonErrors: {
                      part1: "If you see some errors, please check the Common Errors page available ",
                      part2: "here"
                    },
                    returnToUploadPage: "Return to Upload Page",
                    header2: "Upload CSV file to pre-fill the Online Learning Agreements",
                    bodyUpload: {
                      part1: "The Online Learning Agreement pre-filling functionality now supports the CSV upload. Upon entering the data you can overview the students list to make sure all the information is correct and submit the data. This action will trigger a creation of pre-filled Online Learning Agreements and a notification for the students inviting them to finalise the document.",
                      part2: "The format of the CSV file can be verified through the use of a template spreadsheet made available on the EWP CC ",
                      part3: "here"
                    },
                    checkBox: "I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard Terms & Conditions and Privacy Policy.",
                    consent: "Please give consent on the treatment of data"
                  }
                },
                nominationList: {
                  totalApplications: "Total Applications",
                  downloadFiles: "Download All Files",
                  outSignleView: {
                    loadingMessage: "Loading...",
                    errorMessage: "An error occurred",
                    applicationFeedback: {
                      nominate: {
                        loading: "Nominating application...",
                        success: "Succesfully nominated Application",
                        failure: "Nomination of application failed"
                      },
                      reject: {
                        loading: "Rejecting application...",
                        success: "Succesfully rejected Application",
                        failure: "Rejection of application failed"
                      },
                      wait: {
                        loading: "Application putting in the waiting list...",
                        success: "Succesfully updated Application",
                        failure: "Update of Application failed"
                      },
                      delete: {
                        loading: "Deleting application...",
                        success: "Successfully deleted Application",
                        failure: "Delete of application failed"
                      },
                    },
                    applicationInfo: {
                      title: "APPLICATION INFO",
                      status: "STATUS",
                      academicYear: "ACADEMIC YEAR",
                      academicTerm: "ACADEMIC TERM",
                      submissionTime: "SUBMISSION TIME",
                      downloadFiles: "Download All Files",
                    },
                    generalInfo: {
                      title: "General Info",
                      studentInfo: {
                        title: "STUDENT INFO",
                        name: "NAME",
                        email: "EMAIL",
                        studyLevel: "STUDY LEVEL",
                        studyField: "STUDY FIELD"
                      },
                      destinationheis: {
                        title: "DESTINATION HEIs",
                        institutionFaculty: "INSTITUTION / FACULTY",
                        nominatedStudents: "NOMINATED STUDENTS"
                      }
                    },
                    documents: {
                      title: "Documents",
                      viewPdf: "View Pdf",
                      files: { 
                        portfolio: "PORTFOLIO",
                        externalCertification: "EXTERNAL CERTIFICATION",
                        referenceContact: "REFERENCE CONTACT",
                        motivationLetter: "MOTIVATION LETTER",
                        languageCert: "LANGUAGE CERTIFICATES",
                        other: "OTHER DOCUMENTS"
                      }
                    },
                    waitingList: "WAITING LIST",
                    reject: "REJECT",
                    delete: "DELETE",
                    modalReject: {
                      body: "After rejecting the application, the student will not be able to apply for any other mobility program in the current academic year.",
                      reject: "Reject",
                      title: {
                        part1: "You are about to ",
                        part2: "reject",
                        part3: " a student’s application"
                      }
                    },
                    modalWaiting: {
                      body: "After putting the student’s application in the Waiting List, you will still be able to nominate the student later, if you wish to.",
                      waitingList: "Waiting List",
                      title: {
                        part1: "You are about to put a student’s application in the ",
                        part2: "Waiting List"
                      }
                    },
                    modalDelete: {
                      body: "After deleting the student’s application, you won’t be able to see it again.",
                      delete: "Delete",
                      title: {
                        part1: "You are about to ",
                        part2: "delete",
                        part3: " a student’s application"
                      }
                    },
                    modalNominate: {
                      body: "After nominating the student for a mobility to Host HEI, the Receiving Institution will be able to accept or reject the nomination. In case of rejection by the Host HEI, you will be able to nominate the student for a mobility in another HEI, if available.",
                      nominate: "Nominate",
                      title: {
                        part1: "You are about to ",
                        part2: "nominate",
                        part3: " a student’s application"
                      }
                    }
                  },
                  inSingleView: {
                    loadingMessage: "Loading...",
                    errorMessage: "An error occurred",
                    applicationFeedback: {
                      nominate: {
                        loading: "Nominating application...",
                        success: "Succesfully nominated Application",
                        failure: "Nomination of application failed"
                      },
                      reject: {
                        loading: "Rejecting application...",
                        success: "Succesfully rejected Application",
                        failure: "Rejection of application failed"
                      }
                    },
                    applicationInfo: {
                      title: "APPLICATION INFO",
                      status: "STATUS",
                      academicYear: "ACADEMIC YEAR",
                      academicTerm: "ACADEMIC TERM",
                      submissionTime: "SUBMISSION TIME",
                      downloadFiles: "Download All Files",
                    },
                    generalInfo: {
                      title: "General Info",
                      studentInfo: {
                        title: "STUDENT INFO",
                        name: "NAME",
                        email: "EMAIL",
                        studyLevel: "STUDY LEVEL",
                        studyField: "STUDY FIELD"
                      },
                      homeHei: {
                        title: "HOME HEI",
                        name: "NAME",
                        orgUnit: "ORGANIZATION UNIT"
                      },
                      destinationheis: {
                        title: "DESTINATION HEIs",
                        institutionFaculty: "INSTITUTION / FACULTY",
                        nominatedStudents: "NOMINATED STUDENTS"
                      }
                    },
                    documents: {
                      title: "Documents",
                      viewPdf: "View Pdf",
                      files: { 
                        portfolio: "PORTFOLIO",
                        externalCertification: "EXTERNAL CERTIFICATION",
                        referenceContact: "REFERENCE CONTACT",
                        motivationLetter: "MOTIVATION LETTER",
                        languageCert: "LANGUAGE CERTIFICATES",
                        other: "OTHER DOCUMENTS"
                      }
                    },
                    reject: "Reject",
                    nominate: "Nominate",
                    modalReject: {
                      body: "After rejecting the application, the student will not be able to apply for any other mobility program at your HEI, in the current academic year. However, the Sending IRO may be able to nominate this Student for a mobility in another HEI, if available.",
                      title: {
                        part1: "You are about to ",
                        part2: "reject",
                        part3: " a student’s application"
                      }
                    },
                    modalNominate: {
                      body: "By nominating the student, you are making the student will be eligible to start their mobility in the HEI you represent.",
                      title: {
                        part1: "You are about to ",
                        part2: "nominate",
                        part3: " a student’s application"
                      }
                    }
                  }
                },
                shortTerm: {
                  title: "Short Term Mobilities",
                  tabOutgoing: "Outgoing Short Term Mobilities",
                  tabIncoming: "Incoming Short Term Mobilities",
                },
                shortTermMobilities: {
                  loadingMessage: "Loading...",
                  errorMessage: "An error occurred",
                  title: "Short Term Mobilities",
                  tabOutgoing: "View single Outgoing Short Term Mobility",
                  tabIncoming: "View single Incoming Short Term Mobility",
                  studentType: {
                    out: "Responsible person at the Sending Institution",
                    in: "Responsible person at the Receiving Institution"
                  },
                  editTableFail: "Edit OLA course table failed.",
                  signFeedback: {
                    loading: "Signing OLA...",
                    success: "Successfully signed learning agreement",
                    failure: "Error on signing."
                  },
                  declineOlaFeedback: {
                    loading: "Declining OLA...",
                    success: "Successfully declined learning agreement",
                    failure: "Error on declining."
                  }, 
                  LearningAgreementInfo: {
                    title: "LEARNING AGREEMENT INFO"
                  },
                  generalInfo: {
                    title: "General Info",
                    studentPersonalData: {
                      title: "STUDENT PERSONAL DATA"
                    },
                    sendingInstInfo: {
                      title: "SENDING INSTITUTION INFO"
                    },
                    receivingInstInfo: {
                      title: "RECEIVING INSTITUTION INFO"
                    }
                  },
                  courses: {
                    title: "Courses",
                    table: "SHORT-TERM MOBILITY TABLE"
                  },
                  modalReject: {
                    declineButton: "Decline",
                    body: {
                      part1: "After declining the OLA, the student will be able to edit it and introduce your proposed changes. Please fill the comments you want to be emailed to the student in field below: ",
                      part2: "Reason For Declining",
                      part3: " Note",
                    },
                    title: {
                      part1: "You are about to ",
                      part2: "decline",
                      part3: " a student's OLA"
                    }
                  },
                  modalSign: {
                    signButton: "Sign OLA",
                    body: "By digitally signing this document, the student, the Sending Institution and the Receiving Institution confirm that they approve the Learning Agreement and that they will comply with all the arrangements agreed by all parties. Sending and Receiving Institutions undertake to apply all the principles of the Erasmus Charter for Higher Education relating to mobility for studies (or the principles agreed in the Inter-Institutional Agreement for institutions located in Partner Countries). The Beneficiary Institution and the student should also commit to what is set out in the Erasmus+ grant agreement. The Receiving Institution confirms that the educational components listed are in line with its course catalogue and should be available to the student. The Sending Institution commits to recognise all the credits or equivalent units gained at the Receiving Institution for the successfully completed educational components and to count them towards the student's degree. The student and the Receiving Institution will communicate to the Sending Institution any problems or changes regarding the study programme, responsible persons and/or study period.",
                    title: {
                      part1: "You are about to ",
                      part2: "sign",
                      part3: " a student's OLA"
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Confirm",
                    body: {
                      part1: "As per official guidelines from the European Commission, if the automatic recognition will not take place, please provide a clear justification and an indication on what other type of formal recognition will be applied. ",
                      part2: "Fill in reason of rejecting"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "As per official guidelines from the European Commission, if the automatic recognition will not take place, please provide a clear justification and an indication on what other type of formal recognition will be applied. ",
                      part2: "Fill in reason of rejecting",
                    }
                  },
                },
                requirements: {
                  applicationSettings: {
                    feedback: {
                      success: "Application settings updated successfully.",
                      error1: "There was an error with updating application requirements.",
                      error2: "There was an error with updating application settings: "
                    },
                    title: "Application Settings",
                    description: "Please choose the maximum number of Host HEIs for your Outgoing Students and set the deadlines for the Applications",
                    numOfHeis: {
                      title: "Number Of HEIs ",
                      placeholder: "Select number of HEIs",
                      error: "Please select a country for your institution",
                      message: "Max number of priorities of host HEI per student application"
                    },
                    semesterApplications: {
                      titleAw: "Autumn/Winter Semester Application Period",
                      titleSs: "Spring/Summer Semester Application Period",
                      startDate: "Start Date ",
                      endDate: "End Date ",
                      error: "Please provide a valid value prompt."
                    },
                    cancel: "Cancel",
                    saveSettings: "Save Application settings"
                  },
                  applicationRequirements: {
                    feedback: {
                      success: "Application requirements updated successfully.",
                      error1: "There was an error with updating application requirements.",
                      error2: "There was an error with updating application requirements: "
                    },
                    title: "Requirements (Outgoing)",
                    header: "Required Documents",
                    descriptionMain: "Please activate the switches next to the files that are required for the Outgoing Students of your HEI and provide the relevant URLs and descriptions",
                    description: "Description",
                    motivationLetter: "Motivation Letter",
                    transcriptOfRecords: "Transcript of Records",
                    externalCertification: "External Certification",
                    recommendationLetter: "Recommendation Letter",
                    languageLevel: {
                      title: "Language Level",
                      languageRequired: {
                        title: "Language Required",
                        placeholder: "Select a Language"
                      },
                      languageL: {
                        title: "Language Level",
                        placeholder: "Select a Language Level"
                      },
                      languageMoreInfo: "Language More Info",
                      delete: "Delete Language",
                      languageCertRequired: "Language Certification Required",
                      addLanguage: "Add Language"
                    },
                    otherDocument: "Other Document",
                    referenceContact: "Reference Contact",
                    portfolio: "Portfolio",
                    other: "Other Requirement",
                    addOther: "Add Other Requirement",
                    addDocument: "Add Other Document",
                    deleteRequirement: "Delete Requirement",
                    cancel: "Cancel",
                    saveRequirements: "save requirements"
                  }
                },
                iiaList: {
                  title: "Inter-Institutional Agreements",
                  subTitle: "Listing of Agreements",
                  iiaFeedback: {
                    delete: {
                      loading: "Deleting IIA...",
                      success: "Succesfully deleted IIA",
                      failure: "Deletion of IIA failed"
                    },
                    terminate: {
                      loading: "Terminating IIA...",
                      success: "Succesfully terminated IIA",
                      failure: "Termination of IIA failed"
                    }
                  },
                  modifyIIA: "Modify IIA",
                  editIIA: "Edit IIA",
                  deleteIIA: "Delete IIA",
                  modalDelete: {
                    body: "After deleting an IIA, you will not be able to see it again.",
                    title: {
                      part1: "You are about to ",
                      part2: "delete",
                      part3: " an Inter-institutional Agreement"
                    },
                    deleteButton: "Delete"
                  },
                  modalTerminate: {
                    body: {
                      part1: "The action you are about to take, results in ",
                      part2: "terminating",
                      part3: " the in effect IIA and its irreversible. Do you still wish to continue?"
                    },
                    title: {
                      part1: "You are about to ",
                      part2: "terminate",
                      part3: " an Inter-institutional Agreement"
                    },
                    terminateButton: "Terminate"
                  }
                },
                iiaSingleView: {
                  loadingMessage: "Loading...",
                  errorMessage: "An error occurred",
                  iiaFeedback: {
                    approve: {
                      loading: "Saving Changes...",
                      success: "Successfully approved IIA",
                      failure: "There was an error with approving IIA: ",
                      error: "Error on saving changes."
                    },
                    revert: {
                      loading: "Saving Changes...",
                      success: "Successfully reverted IIA",
                      failure: "There was an error with reverting IIA: ",
                      error: "Error on saving changes."
                    },
                    terminate: {
                      loading: "Terminating IIA...",
                      success: "Succesfully terminated IIA",
                      failure: "Termination of IIA failed"
                    },
                    notifyPartner: {
                      loading: "Notifying partner...",
                      success: "Succesfully notified Partner",
                      failure: "Notification of Partner failed"
                    },
                  },
                  iiaInformation: {
                    title: "IIA INFORMATION",
                    yourId: "YOUR ID",
                    partnerId: "PARTNER ID",
                    partner: "PARTNER",
                    lastEdited: "LAST EDITED",
                    status: "STATUS",
                    terminatedByYou: "Terminated by You",
                    terminatedByPartner: "Terminated by Partner",
                    terminationApprovedByYou: "Termination Approved by You",
                    terminationApprovedByPartner: "Termination Approved by Partner",
                    submittedByYou: "Submitted by You",
                    submittedByPartner: "Submitted by Partner",
                    approvedByYou: "Approved by You",
                    approvedByPartner: "Approved by Partner",
                    terminated: "Terminated",
                    draft: "Draft",
                    terminatedByAll: "Termination Approved by All",
                    approvedByAll: "Approved by All",
                    invalid: {
                      invalid1: "This IIA contains invalid ISCED F-Codes or is missing Language Requirements. The approvals are still valid. If you wish to terminate this IIA, you need to modify and mutually approve it.",
                      invalid2: "This IIA contains invalid ISCED F-Codes or is missing Language Requirements. Please edit/modify the IIA."
                    },
                    thirdParty: "Please note that the Partner HEI data is managed by a third-party provider. That means that this particular IIA is represented by a different ID in their system.",
                    exportPdf: "Export Pdf"
                  },
                  generalInfo: {
                    title: "General Info",
                    institutionData: "YOUR INSTITUTION DATA",
                    name: "Name:",
                    country: "Country:",
                    schacCode: "SCHAC Code:",
                    erasmusCode: "Erasmus Code:",
                    orgUnit: "Org. Unit:",
                    approvingPerson: "Approving Person:",
                    contactPersons: "Contact Persons:",
                    signDate: "Sign Date:",
                    partnerInstitutionData: "PARTNER INSTITUTION DATA",
                  },
                  yourData: {
                    title: "Your Data",
                    calendar: {
                      title: "CALENDAR",
                      studentNominations: "Student Nominations must reach the Receiving Institution by: ",
                      autumnTerm: "Autumn Term: ",
                      springTerm: "Spring Term: ",
                      applicationsForNominated: "Applications for Nominated Students must reach the Receiving Institution by: ",
                      applicationProcedure: "Application procedure for Nominated Students: ",
                      email: "Contact Email: ",
                      phone: "Contact Phone: ",
                      websiteForInfo: "Website for information: "
                    },
                    additionalRequirements: {
                      title: "ADDITIONAL REQUIREMENTS",
                      requirements: "REQUIREMENT",
                      details: "DETAILS",
                      ectsNumber: "ECTS NUMBER",
                      subjectFieldISCED: "SUBJECT FIELD (ISCED)",
                      studyLevelEQF: "STUDY LEVEL (EQF)",
                      website: "WEBSITE",
                      message: "The institution will send its decision within 4 weeks, and no later than 5 weeks."
                    },
                    inclusionAndAccessibility: {
                      title: "INCLUSION AND ACCESSIBILITY",
                      descriptionMain: {
                        part1: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      infrastructure: "Infrastructure",
                      name: "NAME",
                      description: "DESCRIPTION",
                      contact: "CONTACT",
                      services: "Services"
                    },
                    housing: {
                      title: "HOUSING",
                      description: {
                        part1: "The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      contact: "Contact"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      contact: "Contact"
                    },
                    insurance: {
                      title: "INSURANCE",
                      description: {
                        part1: "The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.",
                        part3: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      contact: "Contact"
                    },
                    additionalInfo: {
                      title: "ADDITIONAL INFORMATION",
                      description: {
                        part1: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      contact: "CONTACT"
                    },
                    message: {
                      part1: "A Transcript of Records will be issued by the institution no later than 6 weeks after the assessment period has finished.",
                      part2: "[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]",
                      part3: "*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners."
                    }
                  },
                  partnerData: {
                    title: "Partner Data",
                    calendar: {
                      title: "CALENDAR",
                      studentNominations: "Student Nominations must reach the Receiving Institution by: ",
                      autumnTerm: "Autumn Term: ",
                      springTerm: "Spring Term: ",
                      applicationsForNominated: "Applications for Nominated Students must reach the Receiving Institution by: ",
                      applicationProcedure: "Application procedure for Nominated Students: ",
                      email: "Contact Email: ",
                      phone: "Contact Phone: ",
                      websiteForInfo: "Website for information: "
                    },
                    additionalRequirements: {
                      title: "ADDITIONAL REQUIREMENTS",
                      requirements: "REQUIREMENT",
                      details: "DETAILS",
                      ectsNumber: "ECTS NUMBER",
                      subjectFieldISCED: "SUBJECT FIELD (ISCED)",
                      studyLevelEQF: "STUDY LEVEL (EQF)",
                      website: "WEBSITE",
                      message: "The institution will send its decision within 4 weeks, and no later than 5 weeks."
                    },
                    inclusionAndAccessibility: {
                      title: "INCLUSION AND ACCESSIBILITY",
                      descriptionMain: {
                        part1: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      infrastructure: "Infrastructure",
                      name: "NAME",
                      description: "DESCRIPTION",
                      contact: "CONTACT",
                      services: "Services"
                    },
                    housing: {
                      title: "HOUSING",
                      description: {
                        part1: "The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      contact: "Contact"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      contact: "Contact"
                    },
                    insurance: {
                      title: "INSURANCE",
                      description: {
                        part1: "The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.",
                        part3: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      contact: "Contact"
                    },
                    additionalInfo: {
                      title: "ADDITIONAL INFORMATION",
                      description: {
                        part1: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.",
                        part2: " Information and assistance can be provided by the following contact points and information sources:"
                      },
                      contact: "CONTACT"
                    },
                    message: {
                      part1: "A Transcript of Records will be issued by the institution no later than 6 weeks after the assessment period has finished.",
                      part2: "[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]",
                      part3: "*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners."
                    }
                  },
                  conditions: {
                    title: "Conditions",
                    header: {
                      part1: "Staff Teacher:",
                      part2: " / Study Fields: ",
                      part3: "Staff Training:",
                      part4: "Student Study:",
                      part5: "Student Traineeship:"
                    },
                    heis: {
                      sender: "SENDER",
                      receiver: "RECEIVER"
                    },
                    conditionDetails: {
                      title: "Condition Details ",
                      mobilityType: "MOBILITY TYPE",
                      duration: "DURATION",
                      academicYears: "ACADEMIC YEARS",
                      spots: "SPOTS",
                      eqfLevel: "EQF Level",
                      staffTeachers: "Staff Teachers",
                      staffTrainings: "Staff Trainings",
                      otherInfo: "OTHER INFO",
                    },
                    languageRequirements: {
                      title: "Language Requirements ",
                      language: "LANGUAGE",
                      studyField: "STUDY FIELD",
                      clarification: "CLARIFICATION"
                    },
                    studyFields: {
                      title: "Study Fields ",
                      studyField: "STUDY FIELD",
                      clarification: "CLARIFICATION"
                    },
                    contacts: {
                      title: "Contacts ",
                      sendingContacts: "SENDING CONTACTS",
                      receivingContacts: "RECEIVING CONTACTS"
                    }
                  },
                  notifyPartner: {
                    text: "Notify partner",
                    part1: "You will be able to click the button and notify the partner again soon.",
                    part2: "Notify partner is disable because language requirements are missing or there are ISCED codes with less that 4 digits in the cooperation conditions of the IIA.",
                    part3: "Notify partner about your current state of the IIA."
                  },
                  terminate: {
                    invalidISCEDormissingLang: "This IIA cannot be terminated because it contains invalid ISCED F-Codes or is missing Language Requirements. Please modify and mutually approve the IIA in order to terminate it.",
                    invalidISCEDormissingLang2: "This IIA cannot be approved because it contains invalid ISCED F-Codes or is missing Language Requirements. Please edit/modify the IIA.",
                    approvedByAll: "You can terminate an IIA only if it’s Approved by All.",
                    iiaTerminationApproved: "IIA’s termination is already Approved by All.",
                    terminateButton: "terminate iia"
                  },
                  revert: {
                    noApproval: "You cannot revert the IIA because there is no approval yet",
                    approvedByAll: "You cannot revert the IIA because it’s already approved by all",
                    revertButton: "revert iia"
                  },
                  edit: {
                    cannotEditWhileTerminating: "You cannot edit the IIA during termination process.",
                    editButton: "edit iia",
                    modifyButton: "modify iia"
                  },
                  approve: {
                    approveButton: "Approve",
                    confirm: {
                      part1: "You are about to ",
                      part2: "approve",
                      part3Terminate: " the termination of an Inter-institutional Agreement.",
                      part3Approve: " an Inter-institutional Agreement"
                    }
                  },
                  modalTerminate: {
                    body: "After terminating the IIA, your partner will be notified about your termination. The last approval remains valid and you can revert it back to its previously state at any time until both of you approve the IIA termination.",
                    title: {
                      part1: "You are about to ",
                      part2: "terminate",
                      part3: " an Inter-institutional Agreement"
                    },
                    terminateButton: "Terminate"
                  },
                  modalRevert: {
                    title: "You are about to revert the Inter-institutional Agreement to its previously approved by all state.",
                    revertButton: "Revert"
                  },
                  modalModify: {
                    body: "After modifying the IIA, your partner will be notified about your changes. The last approval remains valid and you can revert it back to its previously state at any time until both of you re-approve the IIA.",
                    title: "You are about to modify and make changes to the Inter-institutional Agreement.",
                    modifyButton: "Modify"
                  },
                  iiaApprovedByAll: "IIA is already Approved by All or Partner has not shared their local IIA ID.",
                  approveTermination: "approve termination",
                  approveIIA: "approve iia"
                },
                iiasEdit: {
                  title: "Inter-Institutional Agreements",
                  subTitle: "Edit a IIA",
                  createNewSubTitle: "Create a new IIA",
                  createTab: "Create IIA",
                  tab: "Edit IIA",
                  steps: {
                    yourDetails: "YOUR DETAILS",
                    partnerDetails: "PARTNER DETAILS",
                    conditions: "CONDITIONS",
                    overviewAndSubmit: "OVERVIEW & SUBMIT"
                  },
                  alerts: {
                    part1: "Invalid ISCED F-Codes are detected in the cooperation conditions. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.",
                    part2: "You must fill at least one Language of Instruction in the Student Studies cooperation conditions to proceed",
                    part3: "You must fill at least one Language of Instruction in the Staff Teachers cooperation conditions to proceed",
                    part4: "The Higher Education Institution you selected is not ready to exchange Inter-Institutional Agreements via the Erasmus Without Paper Network as of yet.",
                    missingName: "You must fill the contact name to proceed",
                    invalidEmail: "You must fill a valid email address to proceed",
                  },
                  iiaFeedback: {
                    saving: {
                      loading: "Saving Changes...",
                      success: {
                        savedDraft: "Successfully saved as draft IIA",
                        submit: "Successfully submitted IIA",
                        updateDraft: "Successfully updated draft IIA",
                        update: "Successfully updated IIA"
                      },
                      failure: "Error on saving changes."
                    },
                  },
                  errors: {
                    part1: "You must select one Partner SCHAC to proceed",
                    part2: "You must add at least one Cooperation Condition to proceed"
                  },
                  next: "Next",
                  back: "Back",
                  saveAsDraft: "SAVE AS DRAFT",
                  submit: "Submit",
                  firstStep: {
                    header: "YOUR DETAILS",
                    institutionData: {
                      title: "Your Institution Data",
                      name: "Name: ",
                      country: "Country: ",
                      schacCode: "SCHAC Code: ",
                      erasmusCode: "Erasmus Code: ",
                      orgUnit: "Organizational Unit",
                      placeholder: "Select"
                    },
                    contactPerson: {
                      title: "Contact Persons",
                      name: {
                        title: "Name ",
                        placeholder: "Enter name.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Enter email..",
                        error: "Please provide a valid email address."
                      },
                      phone: {
                        title: "Phone",
                        placeholder: "Enter Phone..",
                      },
                      deleteButton: "Delete Contact",
                      addContactPerson: "add contact person"
                    }
                  },
                  secondStep: {
                    header: "PARTNER DETAILS",
                    institutionData: {
                      title: "Partner Institution Data",
                      institution: {
                        title: "Institution ",
                        loading: "Loading...",
                        choose: "Choose institution...",
                        error: "Please select your institution."
                      },
                      schac: {
                        title: "SCHAC Code",
                        loading: "Loading...",
                        choose: "Choose SCHAC Code...",
                        error: "Please select your SCHAC Code."
                      },
                      country: "Country: ",
                      schacCode: "SCHAC Code: ",
                      erasmusCode: "Erasmus Code: ",
                      orgUnit: "Organizational Unit",
                      placeholder: "Select"
                    },
                    erasmusCode: {
                      title: "Erasmus Code ",
                      loading: "Loading...",
                      choose: "Choose Erasmus Code...",
                      error: "Please select your Erasmus Code."
                    },
                    orgUnit: {
                      title: "Organizational Unit",
                      loading: "Loading...",
                      choose: "Choose OUnit...",
                      error: "Please select your OUnit."
                    },
                    contactPersons: {
                      title: "Contact Persons",
                      name: {
                        title: "Name ",
                        placeholder: "Enter name.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Enter email..",
                        error: "Please provide a valid email address."
                      },
                      phone: {
                        title: "Phone",
                        placeholder: "Enter Phone..",
                      },
                      deleteButton: "Delete Contact",
                      addContactPerson: "add contact person"
                    }
                  },
                  thirdStep: {
                    mobilityTypes: {
                      studentStudies: "Student Studies",
                      studentTraineeships: "Student Traineeships",
                      staffTeachers: "Staff Teachers",
                      staffTrainings: "Staff Trainings"
                    },
                    blendedMobility: {
                      yes: "Yes",
                      no: "No"
                    },
                    eqfLevels: {
                      lvl5: "EQF Level 5 - Associate Degree",
                      lvl6: "EQF Level 6 - Bachelor's Degree",
                      lvl7: "EQF Level 7 - Master's Degree",
                      lvl8: "EQF Level 8 - Doctorate Degree",
                    },
                    typeOfCoopCondition: {
                      fillAllFields: "Please fill all the required fields",
                      validEmailSending: "Please fill a valid email address at sending contacts",
                      validEmailReceiving: "Please fill a valid email address at receiving contacts",
                      invalidISCED: "Invalid ISCED F-Codes are detected in the cooperation condition. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.",
                    },
                    cooperationConditions: {
                      header: "COOPERATION CONDITIONS",
                      staffTeacher: "Staff Teacher",
                      studyFields: "Study Fields",
                      staffTraining: "Staff Training",
                      studentStudy: "Student Study",
                      studentTraineeship: "Student Traineeship"
                    },
                    daysPerYear: " days per year",
                    monthsPerYear: " months per year",
                    heis: {
                      sender: "SENDER",
                      receiver: "RECEIVER"
                    },
                    conditionDetails: {
                      header: "Condition Details",
                      mobilityType: "MOBILITY TYPE",
                      duration: "DURATION",
                      academicYears: "ACADEMIC YEARS",
                      eqfLevel: "EQF Level",
                      spots: "SPOTS"
                    },
                    otherInfo: "OTHER INFO",
                    languageRequirements: {
                      header: "Language Requirements",
                      language: "LANGUAGE",
                      studyField: "STUDY FIELD",
                      clarification: "CLARIFICATION"
                    },
                    studyFields: {
                      header: "Study Fields",
                      studyField: "STUDY FIELD",
                      clarification: "CLARIFICATION"
                    },
                    contacts: {
                      header: "Contacts",
                      sendingContacts: "SENDING CONTACTS",
                      receivingContacts: "RECEIVING CONTACTS"
                    },
                    editConditionButton: "edit condition",
                    deleteCoopConditionButton: "Delete Coop Condition",
                    addNewConditionButton: "add a new condition",
                    modal: {
                      title: "Mobility numbers per academic year",
                      subTitle: "The partners commit to amend the table below in case of changes in the mobility data by no later than the end of September in the preceding academic year. ",
                      schacCode: {
                        sender: {
                          title: "Sender SCHAC Code",
                          placeholder: "Select SCHAC..."
                        },
                        receiver: {
                          title: "Receiver SCHAC Code",
                          placeholder: "Select SCHAC..."
                        }
                      },
                      orgUnit: {
                        sender: {
                          title: "Sender Organizational Unit",
                          placeholder: "Select Organizational Unit..."
                        },
                        receiver: {
                          title: "Receiver Organizational Unit",
                          placeholder: "Select Organizational Unit..."
                        }
                      },
                      mobilitiesPerYear: {
                        title: "Mobilities per Year",
                        description: "Maximum number of people to be sent each academic year"
                      },
                      academicYear: {
                        start: {
                          title: "Start Academic Year",
                          placeholder: "Choose Start Academic Year...",
                          error: "Please select your SCHAC Code."
                        },
                        end: {
                          title: "End Academic Year",
                          placeholder: "Choose End Academic Year...",
                          error: "Please select your SCHAC Code."
                        }
                      },
                      subjectArea: {
                        header: "Subject Area",
                        fCode: {
                          title: "ISCED F-Code",
                          placeholder: "Select ISCED Code"
                        },
                        clarification: {
                          title: "ISCED Clarification",
                          placeholder: "Enter text.."
                        },
                        deleteButton: "Delete Subject Area",
                        addSubjectArea: "add subject area"
                      },
                      mobilityType: {
                        header: "Mobility Type",
                        description: "The partners commit to amend the table below in case of changes in the mobility data by no later than the end of September in the preceding academic year.",
                        duration: {
                          title: "Duration",
                          totalMonths: " (total months per year)",
                          totalDays: " (total days per year)",
                          fill: {
                            allStudents: "Fill in the sum of months for all students (e.g., 10 students on a 6-month mobility gives a duration of 60 months)",
                            staff: "Fill in the sum of months for staff (e.g., 4 staff members on a 20 days mobility gives a duration of 80 days)"
                          }
                        },
                        studyCycle: {
                          header: "Study Cycle",
                          placeholder: "Select one or more..."
                        },
                        blendedMobility: {
                          header: "Blended Mobility Option",
                          confirmation: "*By choosing ‘Yes’ in the Blended Mobility Option, the partners confirm that they are willing to exchange students who wish to carry out their mobility in a blended format, a combination of a short-term physical mobility with a virtual component. "
                        }
                      },
                      languageSkills: {
                        header: "Recommended Language Skills",
                        description: "The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates so that they can have the recommended language skills at the start of the study or teaching period (Student Mobility for Studies - Minimum recommended level: B1):",
                        languageOfInstruction: {
                          title: "Language of Instruction",
                          placeholder: "Select a Language"
                        },
                        level: {
                          title: "Language of Instruction Level",
                          placeholder: "Select a Language Level"
                        },
                        fCode: {
                          title: "ISCED F-Code",
                          placeholder: "Select ISCED Code"
                        },
                        clarification: {
                          title: "ISCED Clarification",
                          placeholder: "Enter text.."
                        },
                        deleteButton: "Delete Language",
                        addLanguage: "add LANGUAGE"
                      },
                      contact: {
                        sending: {
                          header: "Sending Contact Persons",
                          description: "Please fill the information of the Sending Contact Persons",
                          addContact: "add sending contact person"
                        },
                        receiving: {
                          header: "Receiving Contact Persons",
                          description: "Please fill the information of the Receiving Contact Persons",
                          addContact: "add receiving contact person"
                        },
                        name: {
                          title: "Name",
                          placeholder: "Enter name.."
                        },
                        email: {
                          title: "Email",
                          placeholder: "Enter email..",
                          error: "Please provide a valid email address."
                        },
                        phone: {
                          title: "Phone",
                          placeholder: "Enter Phone.."
                        },
                        deleteContact: "Delete Contact"
                      },
                      otherInfo: {
                        header: "Other Information",
                        description: "Please fill any other information regarding the terms of the Agreement (5000 characters maximum)",
                        placeholder: "Enter Other Info.."
                      },
                      addCondition: "add this condition",
                      addNewCondition: "Add a new Cooperation Condition",
                      fillNew: "Fill in the following fields to add a new Cooperation Condition ",
                      saveChanges: "save condition changes",
                      editCondition: "Edit Cooperation Condition",
                      fillEdit: "Fill in the following fields to edit the Cooperation Condition"
                    }
                  },
                  forthStep: {
                    overview: {
                      header: "OVERVIEW & SUBMIT",
                      staticInfo: {
                        header: "Static information applicable to all Inter-Institutional Agreements",
                        body: "The institutions agree to cooperate for the exchange of students and/or staff in the context of the Erasmus programme. They commit to respect the quality requirements of the Erasmus Charter for Higher Education in all aspects related to the organisation and management of the mobility, in particular the automatic recognition of the credits awarded to students by the partner institution as agreed in the Learning Agreement and confirmed in the Transcript of Records, or according to the learning outcomes of the modules completed abroad, as described in the Course Catalogue, in line with the European Credit and Accumulation System. The institutions agree on exchanging their mobility related data in line with the technical standards of the European Student Card Initiative.",
                      },
                      gradingSystem: {
                        header: "Grading systems of the institutions",
                        body: "It is recommended that receiving institutions provide a link to the statistical distribution of grades or makes the information available through EGRACONS according to the descriptions in the ECTS users’ guide. The information will facilitate the interpretation of each grade awarded to students and will facilitate the credit transfer by the sending institution."
                      }
                    },
                    generalInfo: {
                      header: "General Info",
                      institutionData: {
                        header: "YOUR INSTITUTION DATA",
                        headerPartner: "PARTNER INSTITUTION DATA",
                        name: "Name",
                        country: "Country",
                        schacCode: "SCHAC Code",
                        erasmusCode: "Erasmus Code",
                        orgUnit: "Org. Unit",
                        approvingPerson: "Approving Person",
                        contactPerson: "Contact Persons"
                      }
                    },
                    factSheet: {
                      yourFactSheet: {
                        header: "Your Factsheet Data",
                        calendar: {
                          title: "CALENDAR",
                          studentNominations: "Student Nominations must reach the Receiving Institution by: ",
                          autumnTerm: "Autumn Term: ",
                          springTerm: "Spring Term: ",
                          applicationsForNominated: "Applications for Nominated Students must reach the Receiving Institution by: ",
                          applicationProcedure: "Application procedure for Nominated Students: ",
                          email: "Contact Email: ",
                          phone: "Contact Phone: ",
                          websiteForInfo: "Website for information: "
                        },
                        additionalRequirements: {
                          title: "ADDITIONAL REQUIREMENTS",
                          requirements: "REQUIREMENT",
                          details: "DETAILS",
                          ectsNumber: "ECTS NUMBER",
                          subjectFieldISCED: "SUBJECT FIELD (ISCED)",
                          studyLevelEQF: "STUDY LEVEL (EQF)",
                          website: "WEBSITE",
                          message: "The institution will send its decision within 4 weeks, and no later than 5 weeks."
                        },
                        inclusionAndAccessibility: {
                          title: "INCLUSION AND ACCESSIBILITY",
                          descriptionMain: {
                            part1: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          infrastructure: "Infrastructure",
                          name: "NAME",
                          description: "DESCRIPTION",
                          contact: "CONTACT",
                          services: "Services"
                        },
                        housing: {
                          title: "HOUSING",
                          description: {
                            part1: "The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          contact: "Contact"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          contact: "Contact"
                        },
                        insurance: {
                          title: "INSURANCE",
                          description: {
                            part1: "The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.",
                            part3: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          contact: "Contact"
                        },
                        additionalInfo: {
                          title: "ADDITIONAL INFORMATION",
                          description: {
                            part1: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          contact: "CONTACT"
                        }
                      },
                      partnerFactSheet: {
                        header: "Partner Factsheet Data",
                        calendar: {
                          title: "CALENDAR",
                          studentNominations: "Student Nominations must reach the Receiving Institution by: ",
                          autumnTerm: "Autumn Term: ",
                          springTerm: "Spring Term: ",
                          applicationsForNominated: "Applications for Nominated Students must reach the Receiving Institution by: ",
                          applicationProcedure: "Application procedure for Nominated Students: ",
                          email: "Contact Email: ",
                          phone: "Contact Phone: ",
                          websiteForInfo: "Website for information: "
                        },
                        additionalRequirements: {
                          title: "ADDITIONAL REQUIREMENTS",
                          requirements: "REQUIREMENT",
                          details: "DETAILS",
                          ectsNumber: "ECTS NUMBER",
                          subjectFieldISCED: "SUBJECT FIELD (ISCED)",
                          studyLevelEQF: "STUDY LEVEL (EQF)",
                          website: "WEBSITE",
                          message: "The institution will send its decision within 4 weeks, and no later than 5 weeks."
                        },
                        inclusionAndAccessibility: {
                          title: "INCLUSION AND ACCESSIBILITY",
                          descriptionMain: {
                            part1: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          infrastructure: "Infrastructure",
                          name: "NAME",
                          description: "DESCRIPTION",
                          contact: "CONTACT",
                          services: "Services"
                        },
                        housing: {
                          title: "HOUSING",
                          description: {
                            part1: "The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          contact: "Contact"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          contact: "Contact"
                        },
                        insurance: {
                          title: "INSURANCE",
                          description: {
                            part1: "The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.",
                            part3: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          contact: "Contact"
                        },
                        additionalInfo: {
                          title: "ADDITIONAL INFORMATION",
                          description: {
                            part1: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.",
                            part2: " Information and assistance can be provided by the following contact points and information sources:"
                          },
                          contact: "CONTACT"
                        }
                      }
                    },
                    cooperationConditions: {
                      header: "Cooperation Conditions",
                      blendedMobility: {
                        yes: "Yes",
                        no: "No"
                      },
                      eqfLevels: {
                        lvl5: "EQF Level 5 - Associate Degree",
                        lvl6: "EQF Level 6 - Bachelor's Degree",
                        lvl7: "EQF Level 7 - Master's Degree",
                        lvl8: "EQF Level 8 - Doctorate Degree",
                      },
                      typeOfCoopCondition: {
                        fillAllFields: "Please fill all the required fields",
                        validEmailSending: "Please fill a valid email address at sending contacts",
                        validEmailReceiving: "Please fill a valid email address at receiving contacts",
                        invalidISCED: "Invalid ISCED F-Codes are detected in the cooperation condition. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.",
                      },
                      cooperationConditions: {
                        header: "COOPERATION CONDITIONS",
                        staffTeacher: "Staff Teacher",
                        staffTeachers: "Staff Teachers",
                        studyFields: "Study Fields",
                        staffTraining: "Staff Training",
                        staffTrainings: "Staff Trainings",
                        studentStudy: "Student Study",
                        studentTraineeship: "Student Traineeship"
                      },
                      daysPerYear: " days per year",
                      monthsPerYear: " months per year",
                      heis: {
                        sender: "SENDER",
                        receiver: "RECEIVER"
                      },
                      conditionDetails: {
                        header: "Condition Details",
                        mobilityType: "MOBILITY TYPE",
                        duration: "DURATION",
                        academicYears: "ACADEMIC YEARS",
                        eqfLevel: "EQF Level",
                        spots: "SPOTS"
                      },
                      otherInfo: "OTHER INFO",
                      languageRequirements: {
                        header: "Language Requirements",
                        language: "LANGUAGE",
                        studyField: "STUDY FIELD",
                        clarification: "CLARIFICATION"
                      },
                      studyFields: {
                        header: "Study Fields",
                        studyField: "STUDY FIELD",
                        clarification: "CLARIFICATION"
                      },
                      contacts: {
                        header: "Contacts",
                        sendingContacts: "SENDING CONTACTS",
                        receivingContacts: "RECEIVING CONTACTS"
                      }
                    },
                    confirmation: "I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard Terms & Conditions and Privacy Policy.",
                    confirmationCheck: "Please agree that you have read the Privacy Policy and Terms and Conditions."
                  }
                }, 
                iiaImport: {
                  subTitle: "Import IIA",
                  alerts: {
                    incorrectFileType: "Sorry, but the selected file does not have the correct extension. Please, select a .csv file.",
                    invalidTemplate: "Sorry, but the template used it's not valid. You can get the official template in the Import IIA page.",
                    errorMessage: {
                      part1: "Currently, importing inter-institutional agreements only accepts up to ",
                      part2: " agreements, and the file you just selected ",
                      part3: "have a total of ",
                      part4: " agreements, ",
                      part5: "so ",
                      part6: "the last ",
                      part7: " valid agreements will be discarded.",
                      message1: "with ",
                      message2: "these invalid rows and ",
                      emptyRows: " empty rows, ",
                      wrongPartnerEC: " agreements with wrong PartnerEC (partners cannot have the same Erasmus Code as you or an invalid Erasmus Code), "
                    },
                    errorMessage2: {
                      message:  "This error might be caused by one of the following:\n" +
                                " - All rows in the file uploaded are empty or invalid;\n" +
                                " - There is no Partner EC;" +
                                " - You used your own EC as the Partner EC.\n" +
                                "Please check your file. Without these errors fixed, it is not possible to check the remaining fields."
                    },
                  },
                  header: "Upload CSV file to import Inter-Institutional Agreements",
                  loadingMessage: "This can take a while, please do not close this page!",
                  returnToIIAListing: "Return to IIA Listing",
                  returnToUploadPage: "Return to Upload Page",
                  description: "The Inter-Institutional Agreement import funcionality now supports the CSV upload. Upon entering the data you can overview the agreements one-by-one to make sure all the information is correct and submit the data. This action will trigger a creation of pre-filled Inter-Institutional Agreement and a notification for the partner inviting it to edit or sign the document. It is also possible to submit all agreements at the same time by clicking on the 'Import all' button.",
                  verifyFormat: "The format of the CSV file can be verified through the use of a template spreadsheet made available on the EWP CC  ",
                  here: "here",
                  confirmation: "I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard Terms & Conditions and Privacy Policy.                        ",
                  consentError: "Please give consent on the treatment of data",
                  uploadButton: "Upload",
                  importConfirmation: "Are you sure you want to import all agreements?"
                },
                iiaExport: {
                  subTitle: "Export IIAs",
                  header: "Download CSV file to export Inter-Institutional Agreements",
                  description: "The Inter-Institutional Agreement export functionality now supports the CSV download. The CSV export file for your institution is created asynchronously and periodically. The latest CSV export file is available for download below.",
                  message: {
                    part1: "CSV export file successfully created on",
                    part2: " and is available for download"
                  },
                  downloadButton: "Download"
                },
                editFactsheet: {
                  subTitle: "Edit factsheet",
                  loadingMessage: "Loading...",
                  errorMessage: "An error occurred",
                  factsheetFeedBack: {
                    update: {
                      success: "Factsheet data updated successfully.",
                      failure: "There was an error with updating factsheet data.",
                      alert: "There was an error with updating factsheet data: "
                    }
                  },
                  calendar: {
                    header: "CALENDAR",
                    studentNominations: "Student Nominations must reach the Receiving Institution by:",
                    autumnTerm: "Autumn Term Date ",
                    springTerm: "Spring Term Date ",
                    invalidDateFormat: "Invalid date format",
                    applicationsForNominated: "Applications for Nominated Students must reach the Receiving Institution by:",
                    applicationProcedure: "Application Procedure for Nominated Students:",
                    email: {
                      title: "Contact Email ",
                      error: "Not valid"
                    },
                    phone: {
                      title: "Contact Phone ",
                      error: " Please enter a phone number that start with your country code (ex: +000)."
                    },
                    websiteForInfo: {
                      title: "Website for information ",
                      error: "Please enter an URL that start with http or https."
                    },
                    defaultWeekValues: "Default weeks Values:",
                    weeksForDecision: {
                      title: "Weeks for additional requirements decision ",
                      error: "Not valid"
                    },
                    weeksForTranscript: {
                      title: "Weeks for transcript of records issue  ",
                      error: "Not valid"
                    }
                  },
                  additionalRequirements: {
                    title: "ADDITIONAL REQUIREMENTS",
                    requirement: {
                      title: "Requirement ",
                      placeholder: "Select Requirement"
                    },
                    details: {
                      title: "Details",
                    },
                    ectsNumber: {
                      title: "Number of ECTS Completed",
                    },
                    studyFieldISCED: {
                      title: "Study Field (ISCED)",
                      placeholder: "Select ISCED"
                    },
                    studyLevelEQF: {
                      title: "Study Level (EQF)",
                      placeholder: "Select EQF"
                    },
                    website: {
                      title: "Website for information",
                      error: "Please enter an URL that start with http or https."
                    },
                    deleteButton: "Delete additional requirement",
                    addRequirement: "Add Additional Requirement"
                  },
                  inclusionAndAccessibility: {
                    title: "INCLUSION AND ACCESSIBILITY",
                    descriptionMain: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:",
                    infrastructure: "Infrastructure",
                    name: "Name ",
                    description: "Description",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Not valid"
                    },
                    contactPhone: {
                      title: "Contact Phone ",
                      error: "Please enter a phone number that start with your country code (ex: +000)."
                    },
                    website: {
                      title: "Website for information ",
                      error: "Please enter an URL that start with http or https."
                    },
                    addInfrastucture: "Add Infrastructure",
                    services: "Services",
                    addService: "Add service"
                  },
                  deleteInfrastructure: "Delete infrastructure",
                  deleteService: "Delete service",
                  housing: {
                    title: "HOUSING",
                    description: "The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:",
                    contact: "Contact",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Not valid"
                    },
                    contactPhone: {
                      title: "Contact Phone ",
                      error: "Please enter a phone number that start with your country code (ex: +000)."
                    },
                    website: {
                      title: "Website for information ",
                      error: "Please enter an URL that start with http or https."
                    }
                  },
                  visa: {
                    title: "VISA",
                    description: "The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:",
                    contact: "Contact",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Not valid"
                    },
                    contactPhone: {
                      title: "Contact Phone ",
                      error: "Please enter a phone number that start with your country code (ex: +000)."
                    },
                    website: {
                      title: "Website for information ",
                      error: "Please enter an URL that start with http or https."
                    }
                  },
                  insurance: {
                    title: "INSURANCE",
                    description: "The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education. The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided. Information and assistance can be provided by the following contact points and information sources:",
                    contact: "Contact",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Not valid"
                    },
                    contactPhone: {
                      title: "Contact Phone ",
                      error: "Please enter a phone number that start with your country code (ex: +000)."
                    },
                    website: {
                      title: "Website for information ",
                      error: "Please enter an URL that start with http or https."
                    }
                  },
                  additionalInfo: {
                    title: "ADDITIONAL INFORMATION",
                    description: "The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and information sources:",
                    recognitionProcess: "Recognition Process",
                    contact: "CONTACT",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Not valid"
                    },
                    contactPhone: {
                      title: "Contact Phone ",
                      error: "Please enter a phone number that start with your country code (ex: +000)."
                    },
                    website: {
                      title: "Website for information ",
                      error: "Please enter an URL that start with http or https."
                    },
                    deleteRecognitionProcess: "Delete recognition process",
                    addRecognitionProcess: "Add Recognition Process Info",
                    otherInfo: "Other useful information",
                    deleteInfo: "Delete other info",
                    addInfo: "Add Other useful Info"
                  },
                  saveFactsheet: "save factsheet data",
                  containsErrors: "The form contains errors",
                  confirmation: "I confirm, also on behalf of my HEI, that I have all the authorisations, including the consent of the relevant natural persons, necessary to upload the personal data and information I am providing, pursuant to the Dashboard Terms & Conditions and Privacy Policy.",
                  confirmationError: "Please give consent on the treatment of data",
                  saveConfirmation: "You are about to edit your factsheet data"
                },
                filter: {
                  title: "Filters & Sorting",
                  clearButton: "Clear All",
                  applyButton: "Apply Filters",
                  academicTerms: {
                    firstSemester: "First semester (Winter/Autumn)",
                    secondSemester: "Second semester (Summer/Spring)",
                    firstTrimester: "First trimester",
                    secondTrimester: "Second trimester",
                    fullYear: "Full academic year",
                  },
                  sortingList: {
                    status: "Sorting by Status",
                    lastChangedAscending: "Sorting by Last Changed Ascending",
                    lastChangedDescending: "Sorting by Last Changed Descending",
                    name: "Sorting by Name"
                  },
                  pendingRequests: {
                    pending: "Pending",
                    accepted: "Accepted",
                    declined: "Declined"
                  },
                  sortBy: {
                    title: "Sort By",
                    placeholder: "Select sort by..."
                  },
                  status: {
                    title: "Status",
                    placeholder: "Select status..."
                  },
                  iia: {
                    title: "IIA ID",
                    placeholder: "IIA ID",
                    label: "Search by your ID or partner ID:"
                  },
                  academicYear: {
                    title: "Academic Year",
                    placeholder: "Select academic year..."
                  },
                  academicTerm: {
                    title: "Academic Term",
                    placeholder: "Select academic year..."
                  },
                  country: {
                    title: "Country",
                    placeholder: "Select one or more countries..."
                  },
                  institution: {
                    title: "Institution",
                    erasmusCode: "Erasmus Code:",
                    esasmusCodePlaceholder: "Select erasmus code...",
                    institutionName: "Institution Name:",
                    institutionNamePlaceholder: "Select institution name...",
                  },
                  approvingEmail: {
                    title: "Approving Person Email",
                    placeholder: "Approving Person Email"
                  },
                  createdBy: {
                    title: "Created By",
                    placeholder: "Select created by...",
                    student: "Created by Student",
                    institution: "Created by Institution"
                  },
                  studentName: {
                    title: "Student Name",
                    firstNameLabel: "First Name:",
                    firstNamePlaceholder: "First Name",
                    lastNameLabel: "Last Name:",
                    lastNamePlaceholder: "Last Name"
                  },
                  organizationalUnit: {
                    title: "Organizational Unit",
                    placeholder: "Select organizational unit..."
                  },
                  name: {
                    title: "Name",
                  },
                  email: {
                    title: "Email",
                    error: "Please provide a valid value prompt."
                  }
                },
                modal: {
                  cancelButton: "Cancel"
                },
                selectPhoneInput: {
                  label: "Phone Code",
                  placeholder: "Choose phone code...",
                  error: {
                    a: "Please provide a phone code",
                    b: "Please provide a valid phone."
                  }
                },
                table: {
                  filterLabel: "Filters",
                  totalAgreements: "Total Agreements",
                  activeFilters: "Active Filters:",
                  noResults: "Sorry, we couldn't find any results"
                },
                coursesTable: {
                  olaReasons: {
                    part1: "Previously selected educational component is not available at the Receiving Institution",
                    part2: "Component is in a different language than previously specified in the course catalogue",
                    part3: "Timetable conflict",
                    part4: "Other (please specify)",
                    part5: "Substituting a deleted component",
                    part6: "Extending the mobility period",
                    part7: "Other (please specify)",
                    other: "Other "
                  },
                  noCourses: "No courses to show",
                  totalECTs: "Total ECTS credits: ",
                  labels: {
                    code: "CODE",
                    subject: "SUBJECT",
                    ects: "ECTS",
                    semester: "SEMESTER",
                    reason: "REASON",
                    autoRecognition: "AUTOMATIC RECOGNITION",
                    description: "DESCRIPTION"
                  }
                },
                olaPdf: {
                  download: {
                    part1: "Download the PDF by clicking ",
                    part2: "here"
                  }
                },
                institutionInfoTable: {
                  name: "Name:",
                  country: "Country:",
                  erasmusCode: "Erasmus Code:",
                  address: "Address:",
                  faculty: "Faculty:",
                  contactPerson: "Contact Person: ",
                  resPerson: "Res. Person:"
                },
                learningAgreementInfoTable: {
                  status: "STATUS",
                  academicYear: "ACADEMIC YEAR",
                  plannedPeriod: "PLANNED PERIOD",
                  created: "CREATED",
                  pdfExportText: "Export to PDF"
                },
                studentInfo: {
                  firstName: "First name:",
                  lastName: "Last name:",
                  email: "Email:",
                  birthDate: "Birth date:",
                  gender: "Gender:",
                  nationality: "Nationality:",
                  foeISCED: "Field of Education (ISCED):",
                  foeClarification: "Field of Education (Clarification):"
                },
                tor: {
                  totalECTs: "Total ECTS: ",
                  form: {
                    componentCode: {
                      title: {
                        part1: "Component Code",
                        part2: "*"
                      },
                      error: "Please provide a code.",
                    },
                    componentTitle: {
                      title: {
                        part1: "Component Title",
                        part2: "*"
                      },
                      error: "Please provide a title."
                    },
                    grade: {
                      title: {
                        part1: "Grade",
                        part2: "*"
                      },
                      error: "Please provide a grade."
                    },
                    ectNum: {
                      title: {
                        part1: "Number of ECTS Creadits",
                        part2: "*"
                      },
                      error: "Please provide ects credits."
                    },
                    success: {
                      part1: "Successfully Completed",
                      part2: "*"
                    }
                  },
                  torFeedback: {
                    create: {
                      loading: "Creating a new ToR...",
                      success: "Successfully created ToR",
                      failure: "Error while creating ToR."
                    },
                    update: {
                      loading: "Updating a new ToR...",
                      success: "Successfully updated ToR",
                      failure: "Error while updating ToR."
                    }
                  },
                  componentFeedback: {
                    create: {
                      loading: "Creating a new Component...",
                      success: "Successfully created component",
                      failure: "Error while creating component."
                    },
                    edit: {
                      loading: "Editing a new component...",
                      success: "Successfully edited component",
                      failure: "Error while editing component."
                    },
                    delete: {
                      loading: "Deleting a new component...",
                      success: "Successfully deleted component",
                      failure: "Error while deleting component."
                    }
                  },
                  createTor: "Create ToR",
                  mobilityStart: "Mobility Start Date:",
                  mStart: "Mobility Start Date",
                  mEnd: "Mobility End Date",
                  mobilityEnd: "Mobility End Date:",
                  invalidInput: "Please provide a valid value prompt.",
                  transcript: "TRANSCRIPT OF RECORDS AT THE RECEIVING INSTITUTION",
                  recognition: "RECOGNITION AT THE SENDING INSTITUTION",
                  addComponentTitle: { 
                    part1: "Add a new ",
                    part2: "Component"
                  },
                  editComponentTitle: {
                    part1: "Edit ",
                    part2: "Component"
                  },
                  deleteComponentTitle: {
                    confirmMessage: "Are you sure?",
                    part1: "Delete ",
                    part2: "Component"
                  },
                  updateTor: {
                    part1: "Update ",
                    part2: "ToR"
                  },
                  addComponent: "add a new component",
                  editComponent: "Edit Component",
                  deleteComponent: "Delete Component",
                  submitButton: "Submit",
                  updateButton: "Update"
                },
                destinationHeader: {
                  nominated: "NOMINATED",
                  rejected: "REJECTED",
                  nominate: "Nominate"
                },
                destinationInfo: {
                  studyLevel: "STUDY LEVEL",
                  studyField: "STUDY FIELD",
                  reqDocuments: "REQUIRED DOCUMENTS",
                  otherReq: "OTHER REQUIREMENTS"
                },
            }
          },
          de: {
            translation: {
                loadingMessage: "Laden...",
                errorMessage: "Es ist ein Fehler aufgetreten",
                home: {
                  header: "Willkommen beim EWP-Dashboard!",
                  title: "Startseite",
                  tab: "Startseite",
                  description: {
                    part1: "Willkommen beim EWP-Dashboard, dem Tool zur Verwaltung der Mobilitäten Ihrer Studierenden im Rahmen des Programms Erasmus+. Als Koordinator*in für internationale Beziehungen spielen Sie eine entscheidende Rolle, wenn es darum geht, Ihren Studierenden zu helfen, das Beste aus ihren Auslandserfahrungen zu machen, und EWP-Dashboard unterstützt Sie dabei.", 
                    part2: "Wir freuen uns, Ihnen mitteilen zu können, dass wir die Benutzeroberfläche und die Benutzerfreundlichkeit der Anwendung erheblich verbessert haben. Wir haben hart daran gearbeitet, EWP-Dashboard intuitiver und benutzerfreundlicher zu gestalten, und wir glauben, dass diese Änderungen es Ihnen erleichtern werden, die Mobilitäten Ihrer Studierenden zu verwalten und das Beste aus den Möglichkeiten zu machen, die das Programm Erasmus+ bietet."
                  }
                },
                sidebar: {
                  institutions: "Einrichtungen",
                  requests: "Anfragen an",
                  myUniversity: "Meine Universität",
                  accountsAndAccess: "Konten & Zugang",
                  organizationalUnits: "Organisatorische Einheiten",
                  ukraineAssistance: "Hilfe für die Ukraine",
                  ola: "OLA",
                  outgoingStudents: "Outgoing Mobilität",
                  incomingStudents: "Incoming Mobilität",
                  upload: "Hochladen",
                  shortTerm: "Kurze Mobilität",
                  applications: "Bewerbung",
                  requirements: "Voraussetzungen",
                  iia: "IIV",
                  iiaList: "IIV-Liste",
                  newDigitalIIA: "Neue digitale IIV",
                  importIIA: "IIV hochladen",
                  exportIIAs: "IIV exportieren",
                  defaultIIAData: "Standard-IIV-Daten",
                  erasmusApp: "Erasmus+ App",
                  usefulInfo: "Nützliche Informationen",
                  deals: "Angebote",
                  events: "Veranstaltungen",
                  coursesEvaluations: "Bewertungen von Kursen",
                  ewpSettings: "EWP-Einstellungen",
                },
                articleSlider: {
                  loadingMessage: "Laden...",
                  errorMessage: "Es ist ein Fehler aufgetreten",
                  created: "Erstellt: "
                },
                articleSingleView: {
                  title: "Blog Beitrag",
                  subTitle: "Einzelansicht",
                  created: "Erstellt: ",
                  lastUpdated: "Zuletzt aktualisiert: ",
                  tags: "Tags: "
                },
                ewpSettings: {
                  header: "INFORMATIONSAUSTAUSCH ÜBER DAS EWP-DASHBOARD",
                  title: "EWP-EINSTELLUNGEN",
                  subTitle: {
                    part1: "Bitte ",
                    part2: " ankreuzen / abwählen  ",
                    part3: "je nachdem, was für Ihre HEI gilt."
                  },
                  ewpSettingType: {
                    updatingSettings: "EWP-Einstellungen aktualisieren...",
                    updatedSuccessfully: `Erfolgreich aktualisierte EWP-Einstellungen`,
                    unableToUpdate: `EWP-Einstellungen konnten nicht aktualisiert werden`,
                    exchangeOfIIAs: {
                      title: "Austausch von IIV über EWP-Dashboard",
                      checkbox: "Ich bin damit einverstanden, dass das EWP-Dashboard meine Hochschuleinrichtung im EWP-Netzwerk vertritt, um interinstitutionelle Vereinbarungen mit Ihren Partnern auszutauschen."
                    },
                    exchangeOfOUnits: {
                      title: "Austausch von OUnits über EWP-Dashboard",
                      checkbox: "Ich erkläre mich damit einverstanden, dass das EWP-Dashboard meine Hochschuleinrichtung im EWP-Netzwerk repräsentiert, um die Informationen der Organisationseinheiten mit Ihren Partnern zu teilen."
                    },
                    exchangeOfStaticInfo: {
                      title: "Austausch statischer Informationen für IIV über EWP-Dashboard",
                      checkbox: "Ich bin damit einverstanden, dass das EWP-Dashboard meine Hochschule im EWP-Netzwerk vertritt, um die statischen Informationen für interinstitutionelle Vereinbarungen mit Ihren Partnern auszutauschen."
                    },
                    exchangeOfOLAs: {
                      title: "Austausch von OLAs über EWP-Dashboard",
                      checkbox: "Ich erkläre mich damit einverstanden, dass das EWP-Dashboard meine Hochschuleinrichtung im EWP-Netzwerk vertritt, um den elektronischen Austausch von Informationen aus der Online-Lernvereinbarung zu ermöglichen."
                    }
                  },
                  cancelButton: "Abbrechen",
                  saveSettingsButton: "EWP-Einstellungen speichern"
                },
                accountsAndAccess: {
                  title: "Konten und Zugang",
                  accountsTab: "Konten",
                  rolesTab: "Rollen",
                  saveChangesButton: "Änderungen speichern",
                  accountForm: {
                    accountName: {
                      label: "Name des Kontos",
                      error: "Bitte geben Sie einen Kontonamen an."
                    },
                    accountEmail: {
                      label: "Konto E-Mail",
                      error: "Bitte geben Sie eine gültige E-Mail-Adresse an."
                    },
                    accountPhone: {
                      label: "Rufnummer",
                      error: "Die für dieses Konto registrierte Telefonnummer ist keine gültige Telefonnummer. Bitte geben Sie eine gültige Telefonnummer an."
                    },
                    accountRole: {
                      label: "Rolle",
                      placeholder: "Rolle auswählen...",
                      error: "Bitte geben Sie eine Rolle für das Konto an."
                    },
                  },
                  roleForm: {
                    roleName: {
                      label: "Rolle Name",
                      error: "Bitte geben Sie einen Rollennamen an."
                    },
                    rolePermissions: {
                      label: "Berechtigungen",
                      placeholder: "Berechtigungen auswählen...",
                      error: {
                        a: "Bitte wählen Sie mindestens eine Erlaubnis für diese Rolle aus.",
                        b: "Sie müssen mindestens eine Erlaubnis für eine Rolle ausgewählt haben."
                      }
                    }
                  },
                  accountFeedback: {
                    create: {
                      loading: "Konto erstellen...",
                      success: "Erfolgreich das Konto erstellt ",
                      failure: "Das Konto konnte nicht erstellt werden "
                    },
                    update: {
                      loading: "Updating account...",
                      success: "Erfolgreiche Aktualisierung des Kontos ",
                      failure: "Das Konto konnte nicht aktualisiert werden "
                    },
                    delete: {
                      loading: "Löschung des Kontos...",
                      success: "Erfolgreich das Konto gelöscht ",
                      failure: "Das Konto konnte nicht gelöscht werden  "
                    }
                  },
                  addNewAccount: {
                    title: "Ein neues Konto hinzufügen",
                    subTitle: "Fügen Sie die erforderlichen Informationen hinzu, um ein neues Konto für einen Ihrer Mitarbeiter anzulegen",
                    buttonText: "Neues Konto hinzufügen"
                  },
                  editAccount: {
                    title: "Bearbeiten eines bestehenden Kontos",
                    subTitle: "Bearbeiten Sie die Informationen eines bestehenden Kontos und ",
                    buttonText: "Konto bearbeiten"
                  },
                  deleteAccount: {
                    title: {
                      part1: "Sie sind dabei, das Konto zu ",
                      part2: "löschen",
                      part3: ""
                    },
                    subTitle: "Nach der Löschung des Kontos kann sich Ihr Mitarbeiter nicht mehr anmelden.",
                    buttonText: "Konto löschen"
                  },
                  form: {
                    accountName: {
                      label: "Name des Kontos",
                      error: "Bitte geben Sie einen Kontonamen an."
                    },
                    accountEmail: {
                      label: "Konto E-Mail",
                      error: "Bitte geben Sie eine gültige E-Mail-Adresse an."
                    },
                    accountPhone: {
                      label: "Rufnummer",
                      error: "Die für dieses Konto registrierte Telefonnummer ist keine gültige Telefonnummer. Bitte geben Sie eine gültige Telefonnummer an."
                    },
                    accountRole: {
                      label: "Rolle",
                      placeholder: "Rolle auswählen...",
                      error: "Bitte geben Sie eine Rolle für das Konto an."
                    }
                  },
                  roleFeedback: {
                    create: {
                      loading: "Rolle schaffen...",
                      success: "Erfolgreich die Rolle erstellt ",
                      failure: "Die Rolle konnte nicht erstellt werden "
                    },
                    update: {
                      loading: "Aktualisierung der Rolle...",
                      success: "Erfolgreich die Rolle aktualisiert ",
                      failure: "Die Rolle konnte nicht aktualisiert werden "
                    },
                    delete: {
                      loading: "Löschen der Rolle...",
                      success: "Erfolgreich die Rolle gelöscht ",
                      failure: "Die Rolle konnte nicht gelöscht werden ",
                      warning: {
                        part1: "Die Rolle ",
                        part2: " wird von ",
                        part3: "",
                        part4: {
                          a: "Konten verwendet",
                          b: " Konto verwendet"
                        },
                        part5: ". Sie können sie also nicht löschen."
                      }
                    }
                  },
                  addNewRole: {
                    title: "Eine neue Rolle hinzufügen",
                    subTitle: "Fügen Sie die erforderlichen Informationen hinzu, um eine neue Rolle hinzuzufügen, die Sie später Ihren Mitarbeitern zuweisen können",
                    buttonText: "Neue Rolle hinzufügen"
                  },
                  editRole: {
                    title: "Bearbeiten einer bestehenden Rolle",
                    subTitle: "Bearbeiten Sie die Informationen einer bestehenden Rolle und ",
                    buttonText: "Rolle bearbeiten"
                  },
                  deleteRole: {
                    title: { // fix sentence structure
                      part1: "Sie sind im Begriff ",
                      part2: "löschen",
                      part3: " die Rolle "
                    },
                    subTitle: "Nachdem Sie die Rolle gelöscht haben, können Sie sie nicht mehr sehen.",
                    buttonText: "Rolle löschen"
                  }
                },
                organizationalUnits: {
                  title: "Organisatorische Einheiten",
                  saveChangesButton: "Änderungen speichern",
                  unitForm: {
                    unitLanguage: {
                      label: "Name der Einheit Sprache",
                      placeholder: "Sprache auswählen...",
                    },
                    unitName: {
                      label: "Name der Einheit",
                      placeholder: "Ounit Name",
                      error: "Bitte geben Sie einen gültigen Namen für die Organisationseinheit an."
                    },
                    unitAbbreviation: {
                      label: "Einheit Abkürzung",
                      placeholder: "Ounit Abkürzung"
                    },
                    unitContactName: {
                      label: "Kontakt Name Ausgehende Lernvereinbarungen",
                    },
                    unitContactEmail: {
                      label: "Kontakt E-Mail Ausgehende Lernvereinbarungen",
                      error: "Bitte geben Sie eine gültige Kontakt-E-Mail an."
                    },
                    incomingDifferent: {
                      label: "Die Kontaktperson für eingehende Lernvereinbarungen ist eine andere"
                    },
                    incomingContactName:{
                      label: "Kontakt Name Eingehende Lernvereinbarungen",
                    },
                    incomingContactEmail:{
                      label: "Kontakt E-Mail Eingehende Lernvereinbarungen",
                      error: "Bitte geben Sie eine gültige Kontakt-E-Mail an."
                    }
                  },
                  unitFeedback: {
                    create: {
                      loading: "Organisationseinheit erstellen...",
                      success: "Erfolgreich die Organisationseinheit mit dem Namen ",
                      failure: "Die Organisationseinheit konnte nicht erstellt werden"
                    },
                    update: {
                      loading: "Aktualisierung der Organisationseinheit...",
                      success: "Erfolgreiche Aktualisierung der Organisationseinheit ",
                      failure: "Die Organisationseinheit konnte nicht aktualisiert werden"
                    },
                    delete: {
                      loading: "Organisatorische Einheit löschen...",
                      success: "Erfolgreich die Organisationseinheit gelöscht",
                      failure: "Die Organisationseinheit konnte nicht gelöscht werden"
                    }
                  },
                  addNewUnit: {
                    title: "Eine neue Organisationseinheit hinzufügen",
                    subTitle: "Fügen Sie die erforderlichen Informationen hinzu, um eine neue Organisationseinheit für Ihre HEI hinzuzufügen",
                    buttonText: "Eine neue Organisationseinheit hinzufügen"
                  },
                  editUnit: {
                    title: "Eine bestehende Organisationseinheit bearbeiten",
                    subTitle: "Fügen Sie die notwendigen Informationen hinzu, um eine Organisationseinheit für Ihr HEI zu bearbeiten",
                    buttonText: "Organisatorische Einheit bearbeiten"
                  },
                  deleteUnit: {
                    title: {
                      part1: "Sie sind gerade dabei, eine Organisationseinheit zu ",
                      part2: "löschen",
                      part3: ""
                    },
                    subTitle: "Nachdem Sie eine Organisationseinheit gelöscht haben, können Sie sie nicht mehr sehen.",
                    buttonText: "Organisatorische Einheit löschen",
                    buttonLabel: "Löschen",
                  }
                },
                ukraineAssistance: {
                  title: "Hilfe für die Ukraine",
                  infoFeedback: {
                    loading: "Aktualisierung der Ukraine-Hilfsinformationen...",
                    success: "Erfolgreich aktualisierte Informationen zur Ukraine-Unterstützung",
                    failure: "Ukraine Assistance Information konnte nicht aktualisiert werden"
                  },
                  validateError: "Bitte wählen Sie mindestens eine „Ja“-Option oder geben Sie einen gültigen Link an",
                  invalidUrl: "Ungültige URL",
                  header: "Krieg in der Ukraine - Student Support Centre",
                  description: "Als Reaktion auf die dramatischen Ereignisse in der Ukraine haben wir ein Portal entwickelt, das Informationen über die von den Hochschulen angebotene Hilfe für Studierende, die vor dem Krieg fliehen, enthält. Wenn Sie uns dabei helfen möchten, zu verbreiten, wie Ihre Universität sich engagiert und einen Beitrag leistet, geben Sie dies bitte an:",
                  form: {
                    shelter: "Bietet Ihre Institution Notunterkünfte an?",
                    health: "Wird an Ihrer Institution gesundheitliche und psychologische Unterstützung angeboten?",
                    fastTrack: "Bietet Ihre Institution ein beschleunigtes Bewerbungsverfahren für ein Studium an?",
                    scholarship: "Bietet Ihre Institution spezielle Stipendien für Studien an?",
                    options: {
                      yes: "Ja",
                      no: "Nein",
                      unkown: "Unbekannt"
                    }
                  },
                  moreInfo: {
                    header: "Bitte geben Sie einen Link zu der speziellen Webseite Ihrer Institution mit weiteren Informationen zu diesem Thema oder zu der entsprechenden Kontaktseite Ihrer Institution an.",
                    error: "Bitte geben Sie eine gültige URL an.",
                    buttonText: "Save Ukraine Hilfe Informationen"
                  }
                },
                incomingStudents: {
                  title: "Vereinbarung zum Online-Lernen",
                  subTitle: "Incoming Mobilität",
                  tab: "Vereinbarungen über eingehendes Online-Lernen",
                  tabSingleView: "Einzelnes Incoming Online Learning Agreement anzeigen",
                },
                outgoingStudents: {
                  title: "Vereinbarung zum Online-Lernen",
                  subTitle: "Outgoing Mobilität",
                  tab: "Vereinbarungen über ausgehendes Online-Lernen",
                  tabSingleView: "Einzelnes Outgoing Online Learning Agreement anzeigen",
                },
                applications: {
                  title: "Bewerbungen für Erasmus+",
                  tab: {
                    out: "Ausgehende Nominierungen",
                    in: "Eingehende Nominierungen"
                  }
                },
                learningAgreements: {
                  loadingMessage: "Laden...",
                  signFeedback: {
                    loading: "Unterzeichnung der OLA...",
                    success: "Erfolgreich unterzeichnete Lernvereinbarung",
                    failure: "Fehler beim Signieren."
                  },
                  declineOlaFeedback: {
                    loading: "Rückläufige OLA...",
                    success: "Erfolgreich abgelehnte Lernvereinbarung",
                    failure: "Fehler beim Ablehnen."
                  }, 
                  editTableFailed: "OLA-Kurstabelle bearbeiten ist fehlgeschlagen.",
                  LearningAgreementInfo: {
                    title: "INFORMATIONEN ZUR LERNVEREINBARUNG"
                  },
                  generalInfo: {
                    title: "Allgemeine Informationen",
                    studentPersonalData: {
                      title: "PERSÖNLICHE DATEN DER STUDENTEN"
                    },
                    sendingInstInfo: {
                      title: "ÜBERMITTLUNG VON INFORMATIONEN ÜBER DIE EINRICHTUNG"
                    },
                    receivingInstInfo: {
                      title: "ANGABEN ZUR EMPFANGSEINRICHTUNG"
                    }
                  },
                  courses: {
                    title: "Kurse",
                    tableA: "TABELLE A",
                    tableB: "TABELLE B",
                    tableC: "TABELLE C",
                  },
                  changes: {
                    title: "Änderungen",
                    tableA: "ÄNDERUNGEN IN TABELLE A",
                    tableB: "ÄNDERUNGEN IN TABELLE B",
                    tableC: "ÄNDERUNGEN IN TABELLE C",
                  },
                  modalReject: {
                    declineButton: "Rückgang",
                    body: {
                      part1: "Nachdem Sie die OLA abgelehnt haben, können Sie sie bearbeiten und Ihre Änderungsvorschläge einbringen. Bitte geben Sie die Kommentare, die Sie dem/der Studierenden per E-Mail schicken möchten, in das unten stehende Feld ein: ",
                      part2: "Grund für die Ablehnung",
                      part3: " Hinweis",
                    },
                    title: {
                      part1: "Sie sind dabei, die OLA eines Schülers  ",
                      part2: "abzulehnen",
                      part3: ""
                    }
                  },
                  modalSign: {
                    signButton: "OLA unterschreiben",
                    body: "Mit der digitalen Unterzeichnung dieses Dokuments bestätigen der/die Studierende, die entsendende und die aufnehmende Einrichtung, dass sie der Lernvereinbarung zustimmen und sich an die von allen Parteien vereinbarten Regelungen halten werden. Die entsendende und die aufnehmende Einrichtung verpflichten sich, alle Grundsätze der Erasmus-Charta für die Hochschulbildung in Bezug auf die Mobilität zu Studienzwecken anzuwenden (bzw. die in der interinstitutionellen Vereinbarung für Einrichtungen in Partnerländern vereinbarten Grundsätze). Die begünstigte Einrichtung und der/die Studierende sollten sich auch zur Einhaltung der in der Erasmus+ Finanzhilfevereinbarung festgelegten Bestimmungen verpflichten. Die aufnehmende Einrichtung bestätigt, dass die aufgelisteten Bildungskomponenten mit ihrem Kurskatalog übereinstimmen und dem Studierenden zur Verfügung stehen sollten. Die entsendende Einrichtung verpflichtet sich, alle an der aufnehmenden Einrichtung erworbenen Credits oder gleichwertigen Einheiten für die erfolgreich abgeschlossenen Lerneinheiten anzuerkennen und sie auf den Abschluss des Studierenden anzurechnen. Der Studierende und die aufnehmende Einrichtung teilen der entsendenden Einrichtung alle Probleme oder Änderungen in Bezug auf das Studienprogramm, die verantwortlichen Personen und/oder den Studienzeitraum mit.",
                    title: {
                      part1: "Sie sind dabei, die OLA eines Schülers zu ",
                      part2: "unterschreiben",
                      part3: ""
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Bestätigen Sie",
                    body: {
                      part1: "Gemäß den offiziellen Leitlinien der Europäischen Kommission ist für den Fall, dass die automatische Anerkennung nicht erfolgt, eine klare Begründung und ein Hinweis auf eine andere Art der förmlichen Anerkennung beizufügen. ",
                      part2: "Grund der Ablehnung angeben"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "Gemäß den offiziellen Leitlinien der Europäischen Kommission ist für den Fall, dass die automatische Anerkennung nicht erfolgt, eine klare Begründung und ein Hinweis auf eine andere Art der förmlichen Anerkennung beizufügen. ",
                      part2: "Grund der Ablehnung angeben",
                    }
                  },
                  upload: {
                    titleMain: "Vereinbarung zum Online-Lernen",
                    subTitle: "CSV hochladen",
                    tab: "CSV für Online-Lernvereinbarungen hochladen",
                    validateData: {
                      invalidCode: {
                        part1: "Ungültiger Erasmus-Code ",
                        part2: " in Zeile "
                      },
                      emailError: {
                        part1: "E-Mail-Fehler in Zeile ",
                        part2: ": Überprüfen Sie das E-Mail-Format Ihrer Daten."
                      },
                      dataError: {
                        part1: "Datumsfehler in Zeile ",
                        part2: ": Achten Sie darauf, dass die Daten im Format tt/mm/jjjj angegeben werden."
                      },
                      fileError: "Datei-Fehler: Sie können nur 25 LA's auf einmal hochladen."
                    },
                    uploadOla: {
                      loading: "Hochladen von OLAs...",
                      success: "Erfolgreich hochgeladene Lernvereinbarungen",
                      failure: "Beim Hochladen der OLAs traten einige Fehler auf."
                    },
                    header: "Hochladen von Lernvereinbarungen",
                    cancel: "Abbrechen",
                    upload: "Hochladen",
                    modalSign: { // fix sentence structure
                      part1: "Bitte stellen Sie sicher, dass die Daten mit der ",
                      part2: "Vorlage übereinstimmen."
                    },
                    title: {
                      part1: "Sie sind dabei, einige OLAs ",
                      part2: "hochzuladen",
                      part3: ""
                    },
                    dontClosePage: "Dies kann eine Weile dauern, bitte schließen Sie diese Seite nicht!",
                    commonErrors: { // fix sentence structure
                      part1: "Wenn Sie Fehler sehen, überprüfen Sie bitte die Seite „Common Errors“, die Sie ",
                      part2: "hier finden."
                    },
                    returnToUploadPage: "Zurück zur Upload-Seite",
                    header2: "CSV-Datei zum Vorausfüllen der Online-Lernvereinbarungen hochladen",
                    bodyUpload: { // fix sentece stucture
                      part1: "Die Funktion zum Vorausfüllen der Online-Lernvereinbarung unterstützt jetzt den CSV-Upload. Nach der Eingabe der Daten können Sie die Liste der Studierenden überprüfen, um sicherzustellen, dass alle Informationen korrekt sind, und die Daten übermitteln. Daraufhin werden vorausgefüllte Online-Lernvereinbarungen erstellt und die Studierenden erhalten eine Benachrichtigung, in der sie aufgefordert werden, das Dokument zu vervollständigen.",
                      part2: "Das Format der CSV-Datei kann mit Hilfe eines Muster-Tabellenblatts überprüft werden, das auf dem EWP CC ",
                      part3: "hier zur Verfügung steht."
                    },
                    checkBox: "Ich bestätige, auch im Namen meiner HEI, dass ich über alle Genehmigungen, einschließlich der Zustimmung der betreffenden natürlichen Personen, verfüge, die für das Hochladen der von mir bereitgestellten personenbezogenen Daten und Informationen gemäß den Allgemeinen Geschäftsbedingungen für das Dashboard und der Datenschutzrichtlinie erforderlich sind.",
                    consent: "Bitte geben Sie Ihr Einverständnis zur Verarbeitung der Daten"
                  }
                },
                nominationList: {
                  totalApplications: "Anträge insgesamt",
                  downloadFiles: "Alle Dateien herunterladen",
                  outSignleView: {
                    loadingMessage: "Laden...",
                    errorMessage: "Es ist ein Fehler aufgetreten",
                    applicationFeedback: {
                      nominate: {
                        loading: "Antrag auf Nominierung...",
                        success: "Erfolgreich nominierte Bewerbung",
                        failure: "Nominierung der Bewerbung gescheitert"
                      },
                      reject: {
                        loading: "Ablehnung der Bewerbung...",
                        success: "Erfolgreich abgelehnter Antrag",
                        failure: "Ablehnung der Bewerbung gescheitert"
                      },
                      wait: {
                        loading: "Bewerbung auf die Warteliste setzen...",
                        success: "Erfolgreich aktualisierte Anwendung",
                        failure: "Aktualisierung der Anwendung fehlgeschlagen"
                      },
                      delete: {
                        loading: "Löschen der Anwendung...",
                        success: "Erfolgreich gelöschte Anwendung",
                        failure: "Löschen der Anwendung fehlgeschlagen"
                      },
                    },
                    applicationInfo: {
                      title: "ANWENDUNGSINFORMATION",
                      status: "STATUS",
                      academicYear: "AKADEMISCHES JAHR",
                      academicTerm: "AKADEMISCHER TERMIN",
                      submissionTime: "EINREICHUNGSZEIT",
                      downloadFiles: "Alle Dateien herunterladen",
                    },
                    generalInfo: {
                      title: "Allgemeine Informationen",
                      studentInfo: {
                        title: "STUDENTEN-INFO",
                        name: "NAME",
                        email: "EMAIL",
                        studyLevel: "STUDIENSTUFE",
                        studyField: "STUDIENBEREICH"
                      },
                      destinationheis: {
                        title: "DESTINATION HEIs",
                        institutionFaculty: "EINRICHTUNG / FAKULTÄT",
                        nominatedStudents: "NOMINIERTE STUDENTEN"
                      }
                    },
                    documents: {
                      title: "Dokumente",
                      viewPdf: "Ansicht Pdf",
                      files: { 
                        portfolio: "PORTFOLIO",
                        externalCertification: "EXTERNE ZERTIFIZIERUNG",
                        referenceContact: "REFERENZKONTAKT",
                        motivationLetter: "MOTIVATIONSSCHREIBEN",
                        languageCert: "SPRACHZERTIFIKATE",
                        other: "ANDERE DOKUMENTE"
                      }
                    },
                    waitingList: "WARTELISTE",
                    reject: "REJECT",
                    delete: "DELETE",
                    modalReject: {
                      body: "Nach Ablehnung des Antrags kann sich der Studierende im laufenden Studienjahr für kein anderes Mobilitätsprogramm bewerben.",
                      reject: "Ablehnen",
                      title: {
                        part1: "Sie sind dabei, die Bewerbung eines Studenten ",
                        part2: "abzulehnen",
                        part3: ""
                      }
                    },
                    modalWaiting: {
                      body: "Nachdem Sie die Bewerbung des Schülers auf die Warteliste gesetzt haben, können Sie den Schüler später immer noch nominieren, wenn Sie dies wünschen.",
                      waitingList: "Warteliste",
                      title: { // fix sentence structure
                        part1: "Sie sind dabei, die Bewerbung eines Schülers auf die ",
                        part2: "Warteliste zu setzen"
                      }
                    },
                    modalDelete: {
                      body: "Nachdem Sie die Bewerbung des Schülers gelöscht haben, können Sie sie nicht mehr sehen.",
                      delete: "Löschen",
                      title: {
                        part1: "Sie sind dabei, die Bewerbung eines Schülers zu ",
                        part2: "löschen",
                        part3: ""
                      }
                    },
                    modalNominate: {
                      body: "Nach der Nominierung des Studierenden für eine Mobilitätsmaßnahme an der aufnehmenden HEI kann die aufnehmende Einrichtung die Nominierung annehmen oder ablehnen. Im Falle einer Ablehnung durch die aufnehmende HEI können Sie den Studierenden für eine Mobilitätsmaßnahme an einer anderen HEI nominieren, sofern diese verfügbar ist.",
                      nominate: "Nominieren Sie",
                      title: {
                        part1: "Sie sind dabei, die Bewerbung eines Studenten zu ",
                        part2: "nominieren",
                        part3: ""
                      }
                    }
                  },
                  inSingleView: {
                    loadingMessage: "Laden...",
                    errorMessage: "Es ist ein Fehler aufgetreten",
                    applicationFeedback: {
                      nominate: {
                        loading: "Antrag auf Nominierung...",
                        success: "Erfolgreich nominierte Bewerbung",
                        failure: "Nominierung der Bewerbung gescheitert"
                      },
                      reject: {
                        loading: "Ablehnung der Bewerbung...",
                        success: "Erfolgreich abgelehnter Antrag",
                        failure: "Ablehnung der Bewerbung gescheitert"
                      }
                    },
                    applicationInfo: {
                      title: "ANWENDUNGSINFORMATION",
                      status: "STATUS",
                      academicYear: "AKADEMISCHES JAHR",
                      academicTerm: "AKADEMISCHER TERMIN",
                      submissionTime: "EINREICHUNGSZEIT",
                      downloadFiles: "Alle Dateien herunterladen",
                    },
                    generalInfo: {
                      title: "Allgemeine Informationen",
                      studentInfo: {
                        title: "STUDENTEN-INFO",
                        name: "NAME",
                        email: "EMAIL",
                        studyLevel: "STUDIENSTUFE",
                        studyField: "STUDIENBEREICH"
                      },
                      homeHei: {
                        title: "HOME HEI",
                        name: "NAME",
                        orgUnit: "ORGANISATIONSEINHEIT"
                      },
                      destinationheis: {
                        title: "DESTINATION HEIs",
                        institutionFaculty: "EINRICHTUNG / FAKULTÄT",
                        nominatedStudents: "NOMINIERTE STUDENTEN"
                      }
                    },
                    documents: {
                      title: "Dokumente",
                      viewPdf: "Ansicht Pdf",
                      files: { 
                        portfolio: "PORTFOLIO",
                        externalCertification: "EXTERNE ZERTIFIZIERUNG",
                        referenceContact: "REFERENZKONTAKT",
                        motivationLetter: "MOTIVATIONSSCHREIBEN",
                        languageCert: "SPRACHZERTIFIKATE",
                        other: "ANDERE DOKUMENTE"
                      }
                    },
                    reject: "Ablehnen",
                    nominate: "Nominieren Sie",
                    modalReject: {
                      body: "Nach der Ablehnung der Bewerbung kann sich der/die Studierende im laufenden akademischen Jahr nicht für ein anderes Mobilitätsprogramm an Ihrer HEI bewerben. Die entsendende IRO kann den Studierenden jedoch möglicherweise für ein Mobilitätsprogramm an einer anderen HEI nominieren, falls verfügbar.",
                      title: {
                        part1: "Sie sind dabei, die Bewerbung eines Studenten ",
                        part2: "abzulehnen",
                        part3: ""
                      }
                    },
                    modalNominate: {
                      body: "Mit der Nominierung des/der Studierenden stellen Sie sicher, dass der/die Studierende für den Beginn seiner/ihrer Mobilität an der von Ihnen vertretenen HEI zugelassen wird.",
                      title: {
                        part1: "Sie sind dabei, die Bewerbung eines Studenten zu ",
                        part2: "nominieren",
                        part3: ""
                      }
                    }
                  }
                },
                shortTerm: {
                  title: "Kurzfristige Mobilitäten",
                  tabOutgoing: "Ausgehende kurzfristige Mobilitäten",
                  tabIncoming: "Eingehende kurzfristige Mobilitäten",
                },
                shortTermMobilities: {
                  loadingMessage: "Laden...",
                  errorMessage: "Es ist ein Fehler aufgetreten",
                  title: "Kurzfristige Mobilitäten",
                  tabOutgoing: "Einzelne Outgoing Short Term Mobility anzeigen",
                  tabIncoming: "Einzelne Incoming Short Term Mobility anzeigen",
                  studentType: {
                    out: "Verantwortliche Person an der Entsendeeinrichtung",
                    in: "Verantwortliche Person in der Empfangseinrichtung"
                  },
                  editTableFail: "OLA-Kurstabelle bearbeiten ist fehlgeschlagen.",
                  signFeedback: {
                    loading: "Unterzeichnung der OLA...",
                    success: "Erfolgreich unterzeichnete Lernvereinbarung",
                    failure: "Fehler beim Signieren."
                  },
                  declineOlaFeedback: {
                    loading: "Rückläufige OLA...",
                    success: "Erfolgreich abgelehnte Lernvereinbarung",
                    failure: "Fehler beim Ablehnen."
                  }, 
                  LearningAgreementInfo: {
                    title: "INFORMATIONEN ZUR LERNVEREINBARUNG"
                  },
                  generalInfo: {
                    title: "Allgemeine Informationen",
                    studentPersonalData: {
                      title: "PERSÖNLICHE DATEN DER STUDENTEN"
                    },
                    sendingInstInfo: {
                      title: "ÜBERMITTLUNG VON INFORMATIONEN ÜBER DIE EINRICHTUNG"
                    },
                    receivingInstInfo: {
                      title: "ANGABEN ZUR EMPFANGSEINRICHTUNG"
                    }
                  },
                  courses: {
                    title: "Kurse",
                    table: "TABELLE ZUR KURZFRISTIGEN MOBILITÄT"
                  },
                  modalReject: {
                    declineButton: "Rückgang",
                    body: {
                      part1: "Nachdem Sie die OLA abgelehnt haben, können Sie sie bearbeiten und Ihre Änderungsvorschläge einbringen. Bitte geben Sie die Kommentare, die Sie dem/der Studierenden per E-Mail schicken möchten, in das unten stehende Feld ein: ",
                      part2: "Grund für die Ablehnung",
                      part3: " Hinweis",
                    },
                    title: {
                      part1: "Sie sind dabei, die OLA eines Schülers ",
                      part2: "abzulehnen",
                      part3: ""
                    }
                  },
                  modalSign: {
                    signButton: "OLA unterschreiben",
                    body: "Mit der digitalen Unterzeichnung dieses Dokuments bestätigen der/die Studierende, die entsendende und die aufnehmende Einrichtung, dass sie der Lernvereinbarung zustimmen und sich an die von allen Parteien vereinbarten Regelungen halten werden. Die entsendende und die aufnehmende Einrichtung verpflichten sich, alle Grundsätze der Erasmus-Charta für die Hochschulbildung in Bezug auf die Mobilität zu Studienzwecken anzuwenden (bzw. die in der interinstitutionellen Vereinbarung für Einrichtungen in Partnerländern vereinbarten Grundsätze). Die begünstigte Einrichtung und der/die Studierende sollten sich auch zur Einhaltung der in der Erasmus+ Finanzhilfevereinbarung festgelegten Bestimmungen verpflichten. Die aufnehmende Einrichtung bestätigt, dass die aufgelisteten Bildungskomponenten mit ihrem Kurskatalog übereinstimmen und dem Studierenden zur Verfügung stehen sollten. Die entsendende Einrichtung verpflichtet sich, alle an der aufnehmenden Einrichtung erworbenen Credits oder gleichwertigen Einheiten für die erfolgreich abgeschlossenen Lerneinheiten anzuerkennen und sie auf den Abschluss des Studierenden anzurechnen. Der Studierende und die aufnehmende Einrichtung teilen der entsendenden Einrichtung alle Probleme oder Änderungen in Bezug auf das Studienprogramm, die verantwortlichen Personen und/oder den Studienzeitraum mit.",
                    title: {
                      part1: "Sie sind dabei, die OLA eines Schülers zu ",
                      part2: "unterschreiben",
                      part3: ""
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Bestätigen Sie",
                    body: {
                      part1: "Gemäß den offiziellen Leitlinien der Europäischen Kommission ist für den Fall, dass die automatische Anerkennung nicht erfolgt, eine klare Begründung und ein Hinweis auf eine andere Art der förmlichen Anerkennung beizufügen. ",
                      part2: "Grund der Ablehnung angeben"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "Gemäß den offiziellen Leitlinien der Europäischen Kommission ist für den Fall, dass die automatische Anerkennung nicht erfolgt, eine klare Begründung und ein Hinweis auf eine andere Art der förmlichen Anerkennung beizufügen. ",
                      part2: "Grund der Ablehnung angeben",
                    }
                  },
                },
                requirements: {
                  applicationSettings: {
                    feedback: {
                      success: "Die Anwendungseinstellungen wurden erfolgreich aktualisiert.",
                      error1: "Bei der Aktualisierung der Bewerbungsanforderungen ist ein Fehler aufgetreten.",
                      error2: "Beim Aktualisieren der Anwendungseinstellungen ist ein Fehler aufgetreten: "
                    },
                    title: "Anwendungseinstellungen",
                    description: "Bitte wählen Sie die maximale Anzahl der HEI für Ihre ausgehenden Studierenden und legen Sie die Bewerbungsfristen fest",
                    numOfHeis: {
                      title: "Anzahl der HEI ",
                      placeholder: "Anzahl der HEI auswählen",
                      error: "Bitte wählen Sie ein Land für Ihre Einrichtung",
                      message: "Maximale Anzahl von Prioritäten der aufnehmenden HEI pro Bewerbung eines Studenten"
                    },
                    semesterApplications: {
                      titleAw: "Herbst/Wintersemester Bewerbungszeitraum",
                      titleSs: "Frühjahr/Sommersemester Bewerbungszeitraum",
                      startDate: "Datum des Beginns ",
                      endDate: "Datum der Beendigung",
                      error: "Bitte geben Sie einen gültigen Wert an."
                    },
                    cancel: "Abbrechen",
                    saveSettings: "Anwendungseinstellungen speichern"
                  },
                  applicationRequirements: {
                    feedback: {
                      success: "Bewerbungsvoraussetzungen erfolgreich aktualisiert.",
                      error1: "Bei der Aktualisierung der Bewerbungsanforderungen ist ein Fehler aufgetreten.",
                      error2: "Bei der Aktualisierung der Bewerbungsanforderungen ist ein Fehler aufgetreten: "
                    },
                    title: "Voraussetzungen (ausgehend)",
                    header: "Erforderliche Dokumente",
                    descriptionMain: "Bitte aktivieren Sie die Schalter neben den Dateien, die für die Outgoing Students Ihrer HEI benötigt werden und geben Sie die entsprechenden URLs und Beschreibungen an",
                    description: "Beschreibung",
                    motivationLetter: "Motivationsschreiben",
                    transcriptOfRecords: "Abschrift der Aufzeichnungen",
                    externalCertification: "Externe Zertifizierung",
                    recommendationLetter: "Empfehlungsschreiben",
                    languageLevel: {
                      title: "Sprachniveau",
                      languageRequired: {
                        title: "Erforderliche Sprache",
                        placeholder: "Wählen Sie eine Sprache"
                      },
                      languageL: {
                        title: "Sprachniveau",
                        placeholder: "Wählen Sie eine Sprachstufe"
                      },
                      languageMoreInfo: "Sprache Mehr Infos",
                      delete: "Sprache löschen",
                      languageCertRequired: "Sprachzertifizierung erforderlich",
                      addLanguage: "Sprache hinzufügen"
                    },
                    otherDocument: "Anderes Dokument",
                    referenceContact: "Referenz Kontakt",
                    portfolio: "Portfolio",
                    other: "Andere Anforderung",
                    addOther: "Andere Anforderung hinzufügen",
                    addDocument: "Anderes Dokument hinzufügen",
                    deleteRequirement: "Bedingung löschen",
                    cancel: "Abbrechen",
                    saveRequirements: "Voraussetzungen speichern"
                  }
                },
                iiaList: {
                  title: "Interinstitutionelle Vereinbarungen",
                  subTitle: "Auflistung der Abkommen",
                  iiaFeedback: {
                    delete: {
                      loading: "Löschen der IIV...",
                      success: "Erfolgreich gelöschte IIV",
                      failure: "Löschung der IIV gescheitert"
                    },
                    terminate: {
                      loading: "Beendigung der IIV...",
                      success: "Erfolgreich beendete IIV",
                      failure: "Beendigung der IIV gescheitert"
                    }
                  },
                  modifyIIA: "IIV ändern",
                  editIIA: "IIV bearbeiten",
                  deleteIIA: "IIV streichen",
                  modalDelete: {
                    body: "Nachdem Sie eine IIV gelöscht haben, können Sie sie nicht mehr sehen.",
                    title: {
                      part1: "Sie sind dabei, eine Interinstitutionelle Vereinbarung zu ",
                      part2: "löschen",
                      part3: ""
                    },
                    deleteButton: "Löschen"
                  },
                  modalTerminate: {
                    body: { // fix sentence structure
                      part1: "Die Maßnahme, die Sie jetzt ergreifen werden, führt zur ",
                      part2: "Beendigung",
                      part3: " der geltenden IIV und ist nicht rückgängig zu machen. Möchten Sie trotzdem fortfahren?"
                    },
                    title: {
                      part1: "Sie sind dabei, eine Interinstitutionelle Vereinbarung zu ",
                      part2: "kündigen",
                      part3: ""
                    },
                    terminateButton: "Beenden Sie"
                  }
                },
                iiaSingleView: {
                  loadingMessage: "Laden...",
                  errorMessage: "Es ist ein Fehler aufgetreten",
                  iiaFeedback: {
                    approve: {
                      loading: "Speichern von Änderungen...",
                      success: "Erfolgreich genehmigte IIV",
                      failure: "Bei der Genehmigung der IIV ist ein Fehler aufgetreten: ",
                      error: "Fehler beim Speichern von Änderungen."
                    },
                    revert: {
                      loading: "Speichern von Änderungen...",
                      success: "IIV erfolgreich rückgängig gemacht",
                      failure: "Bei der Rückgängigmachung der IIV ist ein Fehler aufgetreten: ",
                      error: "Fehler beim Speichern von Änderungen."
                    },
                    terminate: {
                      loading: "Beendigung der IIV...",
                      success: "Erfolgreich beendete IIV",
                      failure: "Beendigung der IIV gescheitert"
                    },
                    notifyPartner: {
                      loading: "Partner benachrichtigen...",
                      success: "Erfolgreich angemeldeter Partner",
                      failure: "Benachrichtigung über gescheiterten Partner"
                    },
                  },
                  iiaInformation: {
                    title: "IIV-INFORMATIONEN",
                    yourId: "IHRE ID",
                    partnerId: "PARTNER-ID",
                    partner: "PARTNER",
                    lastEdited: "LETZTE BEARBEITUNG",
                    status: "STATUS",
                    terminatedByYou: "Von Ihnen gekündigt",
                    terminatedByPartner: "Vom Partner gekündigt",
                    terminationApprovedByYou: "Von Ihnen genehmigte Beendigung",
                    terminationApprovedByPartner: "Beendigung vom Partner genehmigt",
                    submittedByYou: "Eingereicht von Ihnen",
                    submittedByPartner: "Eingereicht von Partner",
                    approvedByYou: "Von Ihnen genehmigt",
                    approvedByPartner: "Genehmigt durch Partner",
                    terminated: "Beendet",
                    draft: "Entwurf",
                    terminatedByAll: "Beendigung von allen gebilligt",
                    approvedByAll: "Von allen gebilligt",
                    invalid: {
                      invalid1: "Diese IIV enthält ungültige ISCED F-Codes oder es fehlen sprachliche Voraussetzungen. Die Genehmigungen sind weiterhin gültig. Wenn Sie diese IIV kündigen möchten, müssen Sie sie ändern und gegenseitig genehmigen.",
                      invalid2: "Diese IIV enthält ungültige ISCED F-Codes oder es fehlen sprachliche Voraussetzungen. Bitte bearbeiten/ändern Sie die IIV."
                    },
                    thirdParty: "Bitte beachten Sie, dass die Daten der Partner HEI von einem Drittanbieter verwaltet werden. Das bedeutet, dass diese spezielle IIV durch eine andere ID in deren System repräsentiert wird.",
                    exportPdf: "Pdf exportieren"
                  },
                  generalInfo: {
                    title: "Allgemeine Informationen",
                    institutionData: "DATEN IHRER EINRICHTUNG",
                    name: "Name:",
                    country: "Land:",
                    schacCode: "SCHAC-Code:",
                    erasmusCode: "Erasmus-Kodex:",
                    orgUnit: "Org. Unit:",
                    approvingPerson: "Genehmigende Person:",
                    contactPersons: "Kontaktpersonen:",
                    signDate: "Unterschrift Datum:",
                    partnerInstitutionData: "DATEN DER PARTNEREINRICHTUNG",
                  },
                  yourData: {
                    title: "Ihre Daten",
                    calendar: {
                      title: "KALENDER",
                      studentNominations: "Die Nominierungen der Studenten müssen bei der empfangenden Einrichtung bis zu diesem Zeitpunkt eingehen: ",
                      autumnTerm: "Herbstsemester: ",
                      springTerm: "Frühjahrssemester: ",
                      applicationsForNominated: "Bewerbungen für nominierte Studierende müssen bei der aufnehmenden Einrichtung bis zu diesem Zeitpunkt eingehen: ",
                      applicationProcedure: "Bewerbungsverfahren für nominierte Studenten: ",
                      email: "Kontakt E-Mail: ",
                      phone: "Kontakt Telefon: ",
                      websiteForInfo: "Website für Informationen: "
                    },
                    additionalRequirements: {
                      title: "ZUSÄTZLICHE VORAUSSETZUNGEN",
                      requirements: "ANFORDERUNG",
                      details: "DETAILS",
                      ectsNumber: "ECTS ANZAHL",
                      subjectFieldISCED: "FACHGEBIET (ISCED)",
                      studyLevelEQF: "STUDIENNIVEAU (EQF)",
                      website: "WEBSITE",
                      message: "Das Organ übermittelt seine Entscheidung innerhalb von 4 Wochen, spätestens jedoch innerhalb von 5 Wochen."
                    },
                    inclusionAndAccessibility: {
                      title: "EINGLIEDERUNG UND ZUGÄNGLICHKEIT",
                      descriptionMain: {
                        part1: "Die Einrichtung wird ankommende mobile Teilnehmer mit besonderen Bedürfnissen gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung unterstützen.",
                        part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      infrastructure: "Infrastruktur",
                      name: "NAME",
                      description: "BESCHREIBUNG",
                      contact: "KONTAKT",
                      services: "Dienstleistungen"
                    },
                    housing: {
                      title: "UNTERBRINGUNG",
                      description: {
                        part1: "Die Einrichtung unterstützt die ankommenden mobilen Teilnehmer bei der Suche nach einer Unterkunft, entsprechend den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                        part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      contact: "Kontakt"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "Die Einrichtung leistet bei Bedarf Unterstützung bei der Beschaffung von Visa für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                        part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      contact: "Kontakt"
                    },
                    insurance: {
                      title: "VERSICHERUNG",
                      description: {
                        part1: "Die Einrichtung bietet Unterstützung beim Abschluss einer Versicherung für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                        part2: " Die aufnehmende Einrichtung informiert die mobilen Teilnehmer über die Fälle, in denen der Versicherungsschutz nicht automatisch gewährt wird.",
                        part3: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      contact: "Kontakt"
                    },
                    additionalInfo: {
                      title: "ZUSÄTZLICHE INFORMATIONEN",
                      description: {
                        part1: "Die Einrichtung wird ankommende mobile Teilnehmer mit besonderen Bedürfnissen gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung unterstützen.",
                        part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      contact: "KONTAKT"
                    },
                    message: {
                      part1: "Ein Transcript of Records wird von der Einrichtung spätestens 6 Wochen nach Ende des Prüfungszeitraums ausgestellt.",
                      part2: "[Gemäß den Leitlinien der Erasmus-Charta für die Hochschulbildung sollte sie normalerweise fünf Wochen nicht überschreiten.]",
                      part3: "*Nach der Erstellung des Profils der Hochschuleinrichtung und dem Hinzufügen der relevanten allgemeinen Informationen kann die Hochschuleinrichtung damit fortfahren, interinstitutionelle Vereinbarungen mit ihren ausgewählten Partnern zu erstellen."
                    }
                  },
                  partnerData: {
                    title: "Partner-Daten",
                    calendar: {
                      title: "KALENDER",
                      studentNominations: "Die Nominierungen der Studenten müssen bei der empfangenden Einrichtung bis zu diesem Zeitpunkt eingehen: ",
                      autumnTerm: "Herbstsemester: ",
                      springTerm: "Frühjahrssemester: ",
                      applicationsForNominated: "Bewerbungen für nominierte Studierende müssen bei der aufnehmenden Einrichtung bis zu diesem Zeitpunkt eingehen: ",
                      applicationProcedure: "Bewerbungsverfahren für nominierte Studenten: ",
                      email: "Kontakt E-Mail: ",
                      phone: "Kontakt Telefon: ",
                      websiteForInfo: "Website für Informationen: "
                    },
                    additionalRequirements: {
                      title: "ZUSÄTZLICHE VORAUSSETZUGEN",
                      requirements: "ANFORDERUNG",
                      details: "DETAILS",
                      ectsNumber: "ECTS ANZAHL",
                      subjectFieldISCED: "FACHGEBIET (ISCED)",
                      studyLevelEQF: "STUDIENNIVEAU (EQF)",
                      website: "WEBSITE",
                      message: "Das Organ übermittelt seine Entscheidung innerhalb von 4 Wochen, spätestens jedoch innerhalb von 5 Wochen."
                    },
                    inclusionAndAccessibility: {
                      title: "EINGLIEDERUNG UND ZUGÄNGLICHKEIT",
                      descriptionMain: {
                        part1: "Die Einrichtung wird ankommende mobile Teilnehmer mit besonderen Bedürfnissen gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung unterstützen.",
                        part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      infrastructure: "Infrastruktur",
                      name: "NAME",
                      description: "BESCHREIBUNG",
                      contact: "KONTAKT",
                      services: "Dienstleistungen"
                    },
                    housing: {
                      title: "UNTERBRINGUNG",
                      description: {
                        part1: "Die Einrichtung unterstützt die ankommenden mobilen Teilnehmer bei der Suche nach einer Unterkunft, entsprechend den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                        part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      contact: "Kontakt"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "Die Einrichtung leistet bei Bedarf Unterstützung bei der Beschaffung von Visa für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                        part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      contact: "Kontakt"
                    },
                    insurance: {
                      title: "VERSICHERUNG",
                      description: {
                        part1: "Die Einrichtung bietet Unterstützung beim Abschluss einer Versicherung für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                        part2: " Die aufnehmende Einrichtung informiert die mobilen Teilnehmer über die Fälle, in denen der Versicherungsschutz nicht automatisch gewährt wird.",
                        part3: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      contact: "Kontakt"
                    },
                    additionalInfo: {
                      title: "ZUSÄTZLICHE INFORMATIONEN",
                      description: {
                        part1: "Die Einrichtung wird ankommende mobile Teilnehmer mit besonderen Bedürfnissen gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung unterstützen.",
                        part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                      },
                      contact: "KONTAKT"
                    },
                    message: {
                      part1: "Ein Transcript of Records wird von der Einrichtung spätestens 6 Wochen nach Ende des Prüfungszeitraums ausgestellt.",
                      part2: "[Gemäß den Leitlinien der Erasmus-Charta für die Hochschulbildung sollte sie normalerweise fünf Wochen nicht überschreiten.]",
                      part3: "*Nach der Erstellung des Profils der Hochschuleinrichtung und dem Hinzufügen der relevanten allgemeinen Informationen kann die Hochschuleinrichtung damit fortfahren, interinstitutionelle Vereinbarungen mit ihren ausgewählten Partnern zu erstellen."
                    }
                  },
                  conditions: {
                    title: "Bedingungen",
                    header: {
                      part1: "Lehrkraft:",
                      part2: " / Studienbereiche: ",
                      part3: "Ausbildung des Personals:",
                      part4: "Studentische Studie:",
                      part5: "Studentenpraktikum:"
                    },
                    heis: {
                      sender: "SENDER",
                      receiver: "EMPFÄNGER"
                    },
                    conditionDetails: {
                      title: "Zustand Details ",
                      mobilityType: "MOBILITÄTSTYP",
                      duration: "DAUER",
                      academicYears: "ACADEMIC YEARS",
                      spots: "SPOTS",
                      eqfLevel: "EQF-Niveau",
                      staffTeachers: "Personal Lehrkräfte",
                      staffTrainings: "Personalschulungen",
                      otherInfo: "ANDERE INFO",
                    },
                    languageRequirements: {
                      title: "Sprachliche Voraussetzungen ",
                      language: "SPRACHE",
                      studyField: "STUDIENBEREICH",
                      clarification: "CLARIFICATION"
                    },
                    studyFields: {
                      title: "Studienbereiche ",
                      studyField: "STUDIENBEREICH",
                      clarification: "CLARIFICATION"
                    },
                    contacts: {
                      title: "Kontakte ",
                      sendingContacts: "KONTAKTE SENDEN",
                      receivingContacts: "EMPFANGSKONTAKTE"
                    }
                  },
                  notifyPartner: {
                    text: "Partner benachrichtigen",
                    part1: "In Kürze können Sie die Schaltfläche anklicken und den Partner erneut benachrichtigen.",
                    part2: "Partner benachrichtigen ist nicht möglich, weil sprachliche Voraussetzungen fehlen oder ISCED-Codes mit weniger als 4 Ziffern in den Kooperationsbedingungen der IIV enthalten sind.",
                    part3: "Informieren Sie Ihren Partner über den aktuellen Stand der IIV."
                  },
                  terminate: {
                    invalidISCEDormissingLang: "Diese IIV kann nicht gekündigt werden, da sie ungültige ISCED F-Codes enthält oder die sprachlichen Voraussetzungen fehlen. Bitte ändern Sie die IIV und stimmen Sie ihr zu, um sie zu beenden.",
                    invalidISCEDormissingLang2: "Diese IIV kann nicht genehmigt werden, da sie ungültige ISCED F-Codes enthält oder sprachliche Voraussetzungen fehlen. Bitte bearbeiten/ändern Sie die IIV.",
                    approvedByAll: "Sie können eine IIV nur beenden, wenn sie von allen gebilligt wurde.",
                    iiaTerminationApproved: "Die Kündigung der IIV ist bereits von allen gebilligt worden.",
                    terminateButton: "iiv beenden"
                  },
                  revert: {
                    noApproval: "Sie können die IIV nicht zurücknehmen, da sie noch nicht genehmigt ist.",
                    approvedByAll: "Sie können die IIV nicht mehr rückgängig machen, da sie bereits von allen genehmigt wurde.",
                    revertButton: "IIV rückgängig machen"
                  },
                  edit: {
                    cannotEditWhileTerminating: "Sie können die IIV während des Beendigungsprozesses nicht bearbeiten.",
                    editButton: "iiv bearbeiten",
                    modifyButton: "iiv ändern"
                  },
                  approve: {
                    approveButton: "Genehmigen Sie",
                    confirm: { // fix sentence structure
                      part1: "Sie sind im Begriff ",
                      part2: "",
                      part3Terminate: " die Kündigung einer interinstitutionellen Vereinbarung zu genehmigen.",
                      part3Approve: " eine Interinstitutionelle Vereinbarung zu genehmigen."
                    }
                  },
                  modalTerminate: {
                    body: "Nach Beendigung der IIV wird Ihr Partner über Ihre Kündigung informiert. Die letzte Genehmigung bleibt gültig, und Sie können sie jederzeit in den vorherigen Zustand zurückversetzen, bis Sie beide der Beendigung der IIV zustimmen.",
                    title: {
                      part1: "Sie sind dabei, eine Interinstitutionelle Vereinbarung zu ",
                      part2: "kündigen",
                      part3: ""
                    },
                    terminateButton: "Beenden Sie"
                  },
                  modalRevert: {
                    title: "Sie sind im Begriff, die Interinstitutionelle Vereinbarung in den Zustand zurückzuversetzen, in dem sie zuvor von allen Staaten genehmigt wurde.",
                    revertButton: "Zurückkehren"
                  },
                  modalModify: {
                    body: "Nachdem Sie die IIV geändert haben, wird Ihr Partner über Ihre Änderungen informiert. Die letzte Genehmigung bleibt gültig und Sie können sie jederzeit in den vorherigen Zustand zurückversetzen, bis Sie beide die IIV erneut genehmigen.",
                    title: "Sie sind im Begriff, die Interinstitutionelle Vereinbarung zu ändern und zu ergänzen.",
                    modifyButton: "Ändern Sie"
                  },
                  iiaApprovedByAll: "Die IIV wurde bereits von allen genehmigt oder der Partner hat seine lokale IIV-ID nicht mitgeteilt.",
                  approveTermination: "Kündigung genehmigen",
                  approveIIA: "iiv genehmigen"
                },
                iiasEdit: {
                  title: "Interinstitutionelle Vereinbarungen",
                  subTitle: "Eine IIV bearbeiten",
                  createNewSubTitle: "Eine neue IIV erstellen",
                  createTab: "IIV erstellen",
                  tab: "IIV bearbeiten",
                  steps: {
                    yourDetails: "IHRE DETAILS",
                    partnerDetails: "PARTNER-DETAILS",
                    conditions: "BEDINGUNGEN",
                    overviewAndSubmit: "ÜBERSICHT & EINREICHEN"
                  },
                  alerts: {
                    part1: "In den Kooperationsbedingungen werden ungültige ISCED F-Codes erkannt. Bitte korrigieren Sie die ISCED F-Codes, die weniger als 4 Ziffern enthalten, indem Sie den entsprechenden Code aus der ISCED F-Code Dropdown-Liste auswählen.",
                    part2: "Sie müssen mindestens eine Unterrichtssprache in den Bedingungen für die Zusammenarbeit im Rahmen des Studiums angeben, um fortzufahren",
                    part3: "Sie müssen mindestens eine Unterrichtssprache in den Bedingungen für die Zusammenarbeit mit den Lehrkräften angeben, um fortzufahren",
                    part4: "Die von Ihnen ausgewählte Hochschuleinrichtung ist noch nicht bereit, interinstitutionelle Vereinbarungen über das Netzwerk Erasmus ohne Papier auszutauschen.",
                    missingName: "Sie müssen den Namen der Kontaktperson eingeben, um fortzufahren",
                    invalidEmail: "Sie müssen eine gültige E-Mail-Adresse angeben, um fortzufahren",
                  },
                  iiaFeedback: {
                    saving: {
                      loading: "Speichern von Änderungen...",
                      success: {
                        savedDraft: "Erfolgreich als Entwurf einer IIV gespeichert",
                        submit: "Erfolgreich eingereichte IIV",
                        updateDraft: "Erfolgreich aktualisierter Entwurf der IIV",
                        update: "Erfolgreich aktualisierte IIV"
                      },
                      failure: "Fehler beim Speichern von Änderungen."
                    },
                  },
                  errors: {
                    part1: "Sie müssen einen Partner SCHAC auswählen, um fortzufahren",
                    part2: "Sie müssen mindestens eine Kooperationsbedingung hinzufügen, um fortzufahren"
                  },
                  next: "Weiter",
                  back: "Zurück",
                  saveAsDraft: "SPEICHERN ALS ENTWURF",
                  submit: "Einreichen",
                  firstStep: {
                    header: "IHRE DETAILS",
                    institutionData: {
                      title: "Daten Ihrer Einrichtung",
                      name: "Name: ",
                      country: "Land: ",
                      schacCode: "SCHAC-Code: ",
                      erasmusCode: "Erasmus-Kodex: ",
                      orgUnit: "Organisatorische Einheit",
                      placeholder: "Wählen Sie"
                    },
                    contactPerson: {
                      title: "Kontaktpersonen",
                      name: {
                        title: "Name ",
                        placeholder: "Name eingeben.."
                      },
                      email: {
                        title: "E-Mail",
                        placeholder: "E-Mail eingeben.",
                        error: "Bitte geben Sie eine gültige E-Mail Adresse an."
                      },
                      phone: {
                        title: "Telefon",
                        placeholder: "Telefon eingeben..",
                      },
                      deleteButton: "Kontakt löschen",
                      addContactPerson: "Kontaktperson hinzufügen"
                    }
                  },
                  secondStep: {
                    header: "PARTNER-DETAILS",
                    institutionData: {
                      title: "Daten der Partnereinrichtung",
                      institution: {
                        title: "Einrichtung ",
                        loading: "Laden...",
                        choose: "Institution wählen...",
                        error: "Bitte wählen Sie Ihre Einrichtung aus."
                      },
                      schac: {
                        title: "SCHAC-Code",
                        loading: "Laden...",
                        choose: "SCHAC-Code wählen...",
                        error: "Bitte wählen Sie Ihren SCHAC-Code."
                      },
                      country: "Land: ",
                      schacCode: "SCHAC-Code: ",
                      erasmusCode: "Erasmus-Kodex: ",
                      orgUnit: "Organisatorische Einheit",
                      placeholder: "Wählen Sie"
                    },
                    erasmusCode: {
                      title: "Erasmus-Kodex ",
                      loading: "Laden...",
                      choose: "Erasmus-Code wählen...",
                      error: "Bitte wählen Sie Ihren Erasmus-Code."
                    },
                    orgUnit: {
                      title: "Organisatorische Einheit",
                      loading: "Laden...",
                      choose: "OUnit wählen...",
                      error: "Bitte wählen Sie Ihre OUnit."
                    },
                    contactPersons: {
                      title: "Kontaktpersonen",
                      name: {
                        title: "Name ",
                        placeholder: "Name eingeben.."
                      },
                      email: {
                        title: "E-Mail",
                        placeholder: "E-Mail eingeben..",
                        error: "Bitte geben Sie eine gültige E-Mail Adresse an."
                      },
                      phone: {
                        title: "Telefon",
                        placeholder: "Telefon eingeben..",
                      },
                      deleteButton: "Kontakt löschen",
                      addContactPerson: "Kontaktperson hinzufügen"
                    }
                  },
                  thirdStep: {
                    mobilityTypes: {
                      studentStudies: "Studentische Studien",
                      studentTraineeships: "Studentische Praktika",
                      staffTeachers: "Personal Lehrkräfte",
                      staffTrainings: "Personalschulungen"
                    },
                    blendedMobility: {
                      yes: "Ja",
                      no: "Nein"
                    },
                    eqfLevels: {
                      lvl5: "EQF Stufe 5 - Assoziierter Abschluss",
                      lvl6: "EQF Stufe 6 - Bachelor-Abschluss",
                      lvl7: "EQF Stufe 7 - Master-Abschluss",
                      lvl8: "EQF Stufe 8 - Doktorat",
                    },
                    typeOfCoopCondition: {
                      fillAllFields: "Bitte füllen Sie alle erforderlichen Felder aus",
                      validEmailSending: "Bitte geben Sie eine gültige E-Mail-Adresse bei den Sendekontakten an",
                      validEmailReceiving: "Bitte geben Sie eine gültige E-Mail-Adresse bei den Empfangskontakten an",
                      invalidISCED: "Ungültige ISCED F-Codes werden in der Kooperationsbedingung erkannt. Bitte korrigieren Sie die ISCED F-Codes, die weniger als 4 Ziffern enthalten, indem Sie den entsprechenden Code aus der ISCED F-Code Dropdown-Liste auswählen.",
                    },
                    cooperationConditions: {
                      header: "KOOPERATIONSBEDINGUNGEN",
                      staffTeacher: "Lehrkraft",
                      studyFields: "Studienbereiche",
                      staffTraining: "Ausbildung des Personals",
                      studentStudy: "Studentische Studie",
                      studentTraineeship: "Studentenpraktikum"
                    },
                    daysPerYear: " Tage pro Jahr",
                    monthsPerYear: " Monate pro Jahr",
                    heis: {
                      sender: "SENDER",
                      receiver: "EMPFÄNGER"
                    },
                    conditionDetails: {
                      header: "Zustand Details",
                      mobilityType: "MOBILITÄTSTYP",
                      duration: "DAUER",
                      academicYears: "ACADEMIC YEARS",
                      eqfLevel: "EQF-Niveau",
                      spots: "SPOTS"
                    },
                    otherInfo: "ANDERE INFO",
                    languageRequirements: {
                      header: "Sprachliche Voraussetzungen",
                      language: "SPRACHE",
                      studyField: "STUDIENBEREICH",
                      clarification: "CLARIFICATION"
                    },
                    studyFields: {
                      header: "Studienbereiche",
                      studyField: "STUDIENBEREICH",
                      clarification: "CLARIFICATION"
                    },
                    contacts: {
                      header: "Kontakte",
                      sendingContacts: "KONTAKTE SENDEN",
                      receivingContacts: "EMPFANGSKONTAKTE"
                    },
                    editConditionButton: "Bedingung bearbeiten",
                    deleteCoopConditionButton: "Coop-Bedingung löschen",
                    addNewConditionButton: "eine neue Bedingung hinzufügen",
                    modal: {
                      title: "Mobilitätszahlen pro Studienjahr",
                      subTitle: "Die Partner verpflichten sich, die nachstehende Tabelle im Falle von Änderungen der Mobilitätsdaten bis spätestens Ende September des vorangegangenen akademischen Jahres zu ändern. ",
                      schacCode: {
                        sender: {
                          title: "Absender SCHAC-Code",
                          placeholder: "Wählen Sie SCHAC..."
                        },
                        receiver: {
                          title: "Empfänger SCHAC-Code",
                          placeholder: "Wählen Sie SCHAC..."
                        }
                      },
                      orgUnit: {
                        sender: {
                          title: "Absender-Organisationseinheit",
                          placeholder: "Organisationseinheit auswählen..."
                        },
                        receiver: {
                          title: "Empfänger-Organisationseinheit",
                          placeholder: "Organisationseinheit auswählen..."
                        }
                      },
                      mobilitiesPerYear: {
                        title: "Mobilitäten pro Jahr",
                        description: "Höchstzahl der zu entsendenden Personen pro Studienjahr"
                      },
                      academicYear: {
                        start: {
                          title: "Beginn Akademisches Jahr",
                          placeholder: "Wählen Sie Start Akademisches Jahr...",
                          error: "Bitte wählen Sie Ihren SCHAC-Code."
                        },
                        end: {
                          title: "Ende des akademischen Jahres",
                          placeholder: "Wählen Sie Ende Akademisches Jahr...",
                          error: "Bitte wählen Sie Ihren SCHAC-Code."
                        }
                      },
                      subjectArea: {
                        header: "Themenbereich",
                        fCode: {
                          title: "ISCED F-Code",
                          placeholder: "ISCED-Code auswählen"
                        },
                        clarification: {
                          title: "ISCED-Klärung",
                          placeholder: "Text eingeben.."
                        },
                        deleteButton: "Themenbereich löschen",
                        addSubjectArea: "Themenbereich hinzufügen"
                      },
                      mobilityType: {
                        header: "Mobilität Typ",
                        description: "Die Partner verpflichten sich, die nachstehende Tabelle im Falle von Änderungen der Mobilitätsdaten bis spätestens Ende September des vorangegangenen akademischen Jahres zu ändern.",
                        duration: {
                          title: "Dauer",
                          totalMonths: " (Gesamtmonate pro Jahr)",
                          totalDays: " (Gesamttage pro Jahr)",
                          fill: {
                            allStudents: "Geben Sie die Summe der Monate für alle Studierenden ein (z. B. 10 Studierende, die an einer 6-monatigen Mobilität teilnehmen, ergeben eine Dauer von 60 Monaten)",
                            staff: "Tragen Sie die Summe der Monate für das Personal ein (z. B. 4 Mitarbeiter für eine 20-tägige Mobilität ergeben eine Dauer von 80 Tagen)"
                          }
                        },
                        studyCycle: {
                          header: "Studienzyklus",
                          placeholder: "Wählen Sie eine oder mehrere..."
                        },
                        blendedMobility: {
                          header: "Blended Mobility Option",
                          confirmation: "*Durch die Wahl von „Ja“ bei der Option „gemischte Mobilität“ bestätigen die Partner, dass sie bereit sind, Studierende auszutauschen, die ihre Mobilität in einem gemischten Format durchführen möchten, d. h. in einer Kombination aus einer kurzfristigen physischen Mobilität und einer virtuellen Komponente. "
                        }
                      },
                      languageSkills: {
                        header: "Empfohlene Sprachkenntnisse",
                        description: "Die entsendende Einrichtung ist nach Absprache mit der aufnehmenden Einrichtung dafür verantwortlich, die von ihr benannten Bewerber zu unterstützen, damit sie zu Beginn des Studien- oder Lehrzeitraums über die empfohlenen Sprachkenntnisse verfügen (Mobilität von Studierenden zu Studienzwecken - empfohlenes Mindestniveau: B1):",
                        languageOfInstruction: {
                          title: "Sprache der Unterweisung",
                          placeholder: "Wählen Sie eine Sprache"
                        },
                        level: {
                          title: "Unterrichtssprache Niveau",
                          placeholder: "Wählen Sie eine Sprachstufe"
                        },
                        fCode: {
                          title: "ISCED F-Code",
                          placeholder: "ISCED-Code auswählen"
                        },
                        clarification: {
                          title: "ISCED-Klärung",
                          placeholder: "Text eingeben.."
                        },
                        deleteButton: "Sprache löschen",
                        addLanguage: "SPRACHE hinzufügen"
                      },
                      contact: {
                        sending: {
                          header: "Entsendende Kontaktpersonen",
                          description: "Bitte geben Sie die Daten der entsendenden Kontaktpersonen an",
                          addContact: "Entsprechende Kontaktperson hinzufügen"
                        },
                        receiving: {
                          header: "Empfangene Kontaktpersonen",
                          description: "Bitte geben Sie die Informationen der empfangenden Kontaktpersonen an",
                          addContact: "empfangende Kontaktperson hinzufügen"
                        },
                        name: {
                          title: "Name",
                          placeholder: "Name eingeben.."
                        },
                        email: {
                          title: "E-Mail",
                          placeholder: "E-Mail eingeben.",
                          error: "Bitte geben Sie eine gültige E-Mail Adresse an."
                        },
                        phone: {
                          title: "Telefon",
                          placeholder: "Telefon eingeben.."
                        },
                        deleteContact: "Kontakt löschen"
                      },
                      otherInfo: {
                        header: "Andere Informationen",
                        description: "Bitte machen Sie weitere Angaben zu den Bedingungen des Abkommens (maximal 5000 Zeichen)",
                        placeholder: "Weitere Informationen eingeben."
                      },
                      addCondition: "diese Bedingung hinzufügen",
                      addNewCondition: "Eine neue Kooperationsbedingung hinzufügen",
                      fillNew: "Füllen Sie die folgenden Felder aus, um eine neue Kooperationsbedingung hinzuzufügen ",
                      saveChanges: "Änderungen der Bedingungen speichern",
                      editCondition: "Kooperationsbedingung bearbeiten",
                      fillEdit: "Füllen Sie die folgenden Felder aus, um die Kooperationsbedingung zu bearbeiten"
                    }
                  },
                  forthStep: {
                    overview: {
                      header: "ÜBERSICHT & EINREICHEN",
                      staticInfo: {
                        header: "Statische Informationen, die für alle Interinstitutionellen Vereinbarungen gelten",
                        body: "Die Einrichtungen vereinbaren eine Zusammenarbeit für den Austausch von Studierenden und/oder Personal im Rahmen des Erasmus-Programms. Sie verpflichten sich, die Qualitätsanforderungen der Erasmus-Charta für die Hochschulbildung in allen Aspekten zu erfüllen, die mit der Organisation und Verwaltung der Mobilität zusammenhängen, insbesondere die automatische Anerkennung der Leistungspunkte, die den Studierenden von der Partnereinrichtung gemäß der Lernvereinbarung und der Abschrift der Unterlagen oder gemäß den Lernergebnissen der im Ausland absolvierten Module, wie im Vorlesungsverzeichnis beschrieben, im Einklang mit dem Europäischen Leistungspunktesystem zuerkannt werden. Die Einrichtungen vereinbaren, ihre mobilitätsbezogenen Daten im Einklang mit den technischen Standards der Europäischen Studentenausweisinitiative auszutauschen.",
                      },
                      gradingSystem: {
                        header: "Benotungssysteme der Organe",
                        body: "Es wird empfohlen, dass die aufnehmenden Einrichtungen einen Link zur statistischen Verteilung der Noten bereitstellen oder die Informationen über EGRACONS gemäß den Beschreibungen im ECTS-Nutzerleitfaden verfügbar machen. Die Informationen erleichtern die Interpretation der einzelnen Noten, die den Studierenden verliehen wurden, und erleichtern die Übertragung von Leistungspunkten durch die entsendende Einrichtung."
                      }
                    },
                    generalInfo: {
                      header: "Allgemeine Informationen",
                      institutionData: {
                        header: "DATEN IHRER EINRICHTUNG",
                        headerPartner: "DATEN DER PARTNEREINRICHTUNG",
                        name: "Name",
                        country: "Land",
                        schacCode: "SCHAC-Code",
                        erasmusCode: "Erasmus-Code",
                        orgUnit: "Org. Unit",
                        approvingPerson: "Genehmigende Person",
                        contactPerson: "Kontaktpersonen"
                      }
                    },
                    factSheet: {
                      yourFactSheet: {
                        header: "Ihr Factsheet Daten",
                        calendar: {
                          title: "KALENDER",
                          studentNominations: "Die Nominierungen der Studenten müssen bei der empfangenden Einrichtung bis zu diesem Zeitpunkt eingehen: ",
                          autumnTerm: "Herbstsemester: ",
                          springTerm: "Frühjahrssemester: ",
                          applicationsForNominated: "Bewerbungen für nominierte Studierende müssen bei der aufnehmenden Einrichtung bis zu diesem Zeitpunkt eingehen: ",
                          applicationProcedure: "Bewerbungsverfahren für nominierte Studenten: ",
                          email: "Kontakt E-Mail: ",
                          phone: "Kontakt Telefon: ",
                          websiteForInfo: "Website für Informationen: "
                        },
                        additionalRequirements: {
                          title: "ZUSÄTZLICHE VORAUSSETZUNGEN",
                          requirements: "ANFORDERUNG",
                          details: "DETAILS",
                          ectsNumber: "ECTS ANZAHL",
                          subjectFieldISCED: "FACHGEBIET (ISCED)",
                          studyLevelEQF: "STUDIENNIVEAU (EQF)",
                          website: "WEBSITE",
                          message: "Das Organ übermittelt seine Entscheidung innerhalb von 4 Wochen, spätestens jedoch innerhalb von 5 Wochen."
                        },
                        inclusionAndAccessibility: {
                          title: "EINGLIEDERUNG UND ZUGÄNGLICHKEIT",
                          descriptionMain: {
                            part1: "Die Einrichtung wird ankommende mobile Teilnehmer mit besonderen Bedürfnissen gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung unterstützen.",
                            part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          infrastructure: "Infrastruktur",
                          name: "NAME",
                          description: "BESCHREIBUNG",
                          contact: "KONTAKT",
                          services: "Dienstleistungen"
                        },
                        housing: {
                          title: "UNTERBRINGUNG",
                          description: {
                            part1: "Die Einrichtung unterstützt die ankommenden mobilen Teilnehmer bei der Suche nach einer Unterkunft, entsprechend den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                            part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          contact: "Kontakt"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "Die Einrichtung leistet bei Bedarf Unterstützung bei der Beschaffung von Visa für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                            part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          contact: "Kontakt"
                        },
                        insurance: {
                          title: "VERSICHERUNG",
                          description: {
                            part1: "Die Einrichtung bietet Unterstützung beim Abschluss einer Versicherung für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                            part2: " Die aufnehmende Einrichtung informiert die mobilen Teilnehmer über die Fälle, in denen der Versicherungsschutz nicht automatisch gewährt wird.",
                            part3: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          contact: "Kontakt"
                        },
                        additionalInfo: {
                          title: "ZUSÄTZLICHE INFORMATIONEN",
                          description: {
                            part1: "Die Einrichtung wird ankommende mobile Teilnehmer mit besonderen Bedürfnissen gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung unterstützen.",
                            part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          contact: "KONTAKT"
                        }
                      },
                      partnerFactSheet: {
                        header: "Partner Factsheet Daten",
                        calendar: {
                          title: "KALENDER",
                          studentNominations: "Die Nominierungen der Studenten müssen bei der empfangenden Einrichtung bis zu diesem Zeitpunkt eingehen: ",
                          autumnTerm: "Herbstsemester: ",
                          springTerm: "Frühjahrssemester: ",
                          applicationsForNominated: "Bewerbungen für nominierte Studierende müssen bei der aufnehmenden Einrichtung bis zu diesem Zeitpunkt eingehen: ",
                          applicationProcedure: "Bewerbungsverfahren für nominierte Studenten: ",
                          email: "Kontakt E-Mail: ",
                          phone: "Kontakt Telefon: ",
                          websiteForInfo: "Website für Informationen: "
                        },
                        additionalRequirements: {
                          title: "ZUSÄTZLICHE VORAUSSETZUNGEN",
                          requirements: "ANFORDERUNG",
                          details: "DETAILS",
                          ectsNumber: "ECTS ANZAHL",
                          subjectFieldISCED: "FACHGEBIET (ISCED)",
                          studyLevelEQF: "STUDIENNIVEAU (EQF)",
                          website: "WEBSITE",
                          message: "Das Organ übermittelt seine Entscheidung innerhalb von 4 Wochen, spätestens jedoch innerhalb von 5 Wochen."
                        },
                        inclusionAndAccessibility: {
                          title: "EINGLIEDERUNG UND ZUGÄNGLICHKEIT",
                          descriptionMain: {
                            part1: "Die Einrichtung wird ankommende mobile Teilnehmer mit besonderen Bedürfnissen gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung unterstützen.",
                            part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          infrastructure: "Infrastruktur",
                          name: "NAME",
                          description: "BESCHREIBUNG",
                          contact: "KONTAKT",
                          services: "Dienstleistungen"
                        },
                        housing: {
                          title: "UNTERBRINGUNG",
                          description: {
                            part1: "Die Einrichtung unterstützt die ankommenden mobilen Teilnehmer bei der Suche nach einer Unterkunft, entsprechend den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                            part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          contact: "Kontakt"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "Die Einrichtung leistet bei Bedarf Unterstützung bei der Beschaffung von Visa für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                            part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          contact: "Kontakt"
                        },
                        insurance: {
                          title: "VERSICHERUNG",
                          description: {
                            part1: "Die Einrichtung bietet Unterstützung beim Abschluss einer Versicherung für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung.",
                            part2: " Die aufnehmende Einrichtung informiert die mobilen Teilnehmer über die Fälle, in denen der Versicherungsschutz nicht automatisch gewährt wird.",
                            part3: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          contact: "Kontakt"
                        },
                        additionalInfo: {
                          title: "ZUSÄTZLICHE INFORMATIONEN",
                          description: {
                            part1: "Die Einrichtung wird ankommende mobile Teilnehmer mit besonderen Bedürfnissen gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung unterstützen.",
                            part2: " Informationen und Unterstützung können Sie bei den folgenden Kontaktstellen und Informationsquellen erhalten:"
                          },
                          contact: "KONTAKT"
                        }
                      }
                    },
                    cooperationConditions: {
                      header: "Bedingungen der Zusammenarbeit",
                      blendedMobility: {
                        yes: "Ja",
                        no: "Nein"
                      },
                      eqfLevels: {
                        lvl5: "EQF Stufe 5 - Assoziierter Abschluss",
                        lvl6: "EQF Stufe 6 - Bachelor-Abschluss",
                        lvl7: "EQR-Stufe 7 - Master-Abschluss",
                        lvl8: "EQR-Stufe 8 - Doktorat",
                      },
                      typeOfCoopCondition: {
                        fillAllFields: "Bitte füllen Sie alle erforderlichen Felder aus",
                        validEmailSending: "Bitte geben Sie eine gültige E-Mail-Adresse bei den Sendekontakten an",
                        validEmailReceiving: "Bitte geben Sie eine gültige E-Mail-Adresse bei den Empfangskontakten an",
                        invalidISCED: "Ungültige ISCED F-Codes werden in der Kooperationsbedingung erkannt. Bitte korrigieren Sie die ISCED F-Codes, die weniger als 4 Ziffern enthalten, indem Sie den entsprechenden Code aus der ISCED F-Code Dropdown-Liste auswählen.",
                      },
                      cooperationConditions: {
                        header: "KOOPERATIONSBEDINGUNGEN",
                        staffTeacher: "Lehrkraft",
                        staffTeachers: "Personal Lehrkräfte",
                        studyFields: "Studienbereiche",
                        staffTraining: "Ausbildung des Personals",
                        staffTrainings: "Personalschulungen",
                        studentStudy: "Studentische Studie",
                        studentTraineeship: "Studentenpraktikum"
                      },
                      daysPerYear: " Tage pro Jahr",
                      monthsPerYear: " Monate pro Jahr",
                      heis: {
                        sender: "SENDER",
                        receiver: "EMPFÄNGER"
                      },
                      conditionDetails: {
                        header: "Zustand Details",
                        mobilityType: "MOBILITÄTSTYP",
                        duration: "DAUER",
                        academicYears: "ACADEMIC YEARS",
                        eqfLevel: "EQF-Niveau",
                        spots: "SPOTS"
                      },
                      otherInfo: "ANDERE INFO",
                      languageRequirements: {
                        header: "Sprachliche Voraussetzungen",
                        language: "SPRACHE",
                        studyField: "STUDIENBEREICH",
                        clarification: "CLARIFICATION"
                      },
                      studyFields: {
                        header: "Studienbereiche",
                        studyField: "STUDIENBEREICH",
                        clarification: "CLARIFICATION"
                      },
                      contacts: {
                        header: "Kontakte",
                        sendingContacts: "KONTAKTE SENDEN",
                        receivingContacts: "EMPFANGSKONTAKTE"
                      }
                    },
                    confirmation: "Ich bestätige, auch im Namen meiner HEI, dass ich über alle Genehmigungen, einschließlich der Zustimmung der betreffenden natürlichen Personen, verfüge, die für das Hochladen der von mir bereitgestellten personenbezogenen Daten und Informationen gemäß den Allgemeinen Geschäftsbedingungen für das Dashboard und der Datenschutzrichtlinie erforderlich sind.",
                    confirmationCheck: "Bitte bestätigen Sie, dass Sie die Datenschutzrichtlinie und die Allgemeinen Geschäftsbedingungen gelesen haben."
                  }
                }, 
                iiaImport: {
                  subTitle: "Einfuhr IIV",
                  alerts: {
                    incorrectFileType: "Es tut mir leid, aber die ausgewählte Datei hat nicht die richtige Erweiterung. Bitte wählen Sie eine .csv-Datei.",
                    invalidTemplate: "Es tut mir leid, aber die verwendete Vorlage ist nicht gültig. Sie können die offizielle Vorlage auf der Seite Import IIV abrufen.",
                    errorMessage: { // fix sentence structure
                      part1: "Derzeit ist die Einfuhr von interinstitutionellen Vereinbarungen nur bis zu ",
                      part2: " Vereinbarungen und die soeben ausgewählte Datei ",
                      part3: "haben insgesamt ",
                      part4: " Vereinbarungen, ",
                      part5: "also ",
                      part6: "die letzte ",
                      part7: " gültige Vereinbarungen werden verworfen.",
                      message1: "mit ",
                      message2: "diese ungültigen Zeilen und ",
                      emptyRows: " leere Zeilen, ",
                      wrongPartnerEC: " Vereinbarungen mit falschen PartnerEC (Partner können nicht denselben Erasmus-Code wie Sie oder einen ungültigen Erasmus-Code haben), "
                    },
                    errorMessage2: {
                      message:  "Dieser Fehler kann durch eine der folgenden Ursachen verursacht werden:\n" +
                                " - Alle Zeilen in der hochgeladenen Datei sind leer oder ungültig;\n" +
                                " - Es gibt keine Partner-EC;" +
                                " - Sie haben Ihre eigene EC als Partner EC verwendet.\n" +
                                "Bitte überprüfen Sie Ihre Datei. Wenn diese Fehler nicht behoben sind, ist es nicht möglich, die übrigen Felder zu überprüfen."
                    },
                  },
                  header: "CSV-Datei hochladen, um interinstitutionelle Vereinbarungen zu importieren",
                  loadingMessage: "Dies kann eine Weile dauern, bitte schließen Sie diese Seite nicht!",
                  returnToIIAListing: "Zurück zu IIV Listing",
                  returnToUploadPage: "Zurück zur Upload-Seite",
                  description: "Die Importfunktion für interinstitutionelle Vereinbarungen unterstützt jetzt den CSV-Upload. Nach Eingabe der Daten können Sie die Vereinbarungen einzeln überprüfen, um sicherzustellen, dass alle Informationen korrekt sind, und die Daten übermitteln. Bei dieser Aktion wird eine vorausgefüllte Interinstitutionelle Vereinbarung erstellt und der Partner erhält eine Benachrichtigung, in der er aufgefordert wird, das Dokument zu bearbeiten oder zu unterzeichnen. Es ist auch möglich, alle Vereinbarungen gleichzeitig zu übermitteln, indem Sie auf die Schaltfläche „Alle importieren“ klicken.",
                  verifyFormat: "Das Format der CSV-Datei kann mit Hilfe eines Muster-Tabellenblatts überprüft werden, das auf dem EWP CC  ", // fix sentence structure
                  here: "hier zur Verfügung steht.",
                  confirmation: "Ich bestätige, auch im Namen meiner HEI, dass ich über alle Genehmigungen, einschließlich der Zustimmung der betreffenden natürlichen Personen, verfüge, die für das Hochladen der von mir bereitgestellten personenbezogenen Daten und Informationen gemäß den Allgemeinen Geschäftsbedingungen für das Dashboard und der Datenschutzrichtlinie erforderlich sind.                        ",
                  consentError: "Bitte geben Sie Ihr Einverständnis zur Verarbeitung der Daten",
                  uploadButton: "Hochladen",
                  importConfirmation: "Sind Sie sicher, dass Sie alle Verträge importieren wollen?"
                },
                iiaExport: {
                  subTitle: "IIV exportieren",
                  header: "CSV-Datei herunterladen, um interinstitutionelle Vereinbarungen zu exportieren",
                  description: "Die Exportfunktion der interinstitutionellen Vereinbarung unterstützt jetzt den CSV-Download. Die CSV-Exportdatei für Ihre Einrichtung wird asynchron und in regelmäßigen Abständen erstellt. Die neueste CSV-Exportdatei steht unten zum Download bereit.",
                  message: {
                    part1: "CSV-Exportdatei wurde erfolgreich auf",
                    part2: " erstellt und steht zum Download bereit"
                  },
                  downloadButton: "Herunterladen"
                },
                editFactsheet: {
                  subTitle: "Factsheet bearbeiten",
                  loadingMessage: "Laden...",
                  errorMessage: "Es ist ein Fehler aufgetreten",
                  factsheetFeedBack: {
                    update: {
                      success: "Factsheet-Daten erfolgreich aktualisiert.",
                      failure: "Bei der Aktualisierung der Daten im Factsheet ist ein Fehler aufgetreten.",
                      alert: "Bei der Aktualisierung der Daten im Factsheet ist ein Fehler aufgetreten: "
                    }
                  },
                  calendar: {
                    header: "KALENDER",
                    studentNominations: "Die Nominierungen der Studenten müssen bei der empfangenden Einrichtung bis zu diesem Zeitpunkt eingehen:",
                    autumnTerm: "Herbsttermin Datum ",
                    springTerm: "Frühjahrssemester Datum ",
                    invalidDateFormat: "Ungültiges Datumsformat",
                    applicationsForNominated: "Bewerbungen für nominierte Studierende müssen bei der aufnehmenden Einrichtung bis zu diesem Zeitpunkt eingehen:",
                    applicationProcedure: "Bewerbungsverfahren für nominierte Studenten:",
                    email: {
                      title: "Kontakt E-Mail ",
                      error: "Nicht gültig"
                    },
                    phone: {
                      title: "Kontakt Telefon ",
                      error: " Bitte geben Sie eine Telefonnummer ein, die mit Ihrer Landesvorwahl beginnt (z. B. +000)."
                    },
                    websiteForInfo: {
                      title: "Website für Informationen ",
                      error: "Bitte geben Sie eine URL ein, die mit http oder https beginnt."
                    },
                    defaultWeekValues: "Standardwochenwerte:",
                    weeksForDecision: {
                      title: "Wochen für die Entscheidung über zusätzliche Voraussetzungen ",
                      error: "Nicht gültig"
                    },
                    weeksForTranscript: {
                      title: "Wochen für die Ausstellung einer Abschrift der Unterlagen  ",
                      error: "Nicht gültig"
                    }
                  },
                  additionalRequirements: {
                    title: "ZUSÄTZLICHE VORAUSSETZUNGEN",
                    requirement: {
                      title: "Anforderung ",
                      placeholder: "Anforderung auswählen"
                    },
                    details: {
                      title: "Einzelheiten",
                    },
                    ectsNumber: {
                      title: "Anzahl der absolvierten ECTS",
                    },
                    studyFieldISCED: {
                      title: "Fachrichtung (ISCED)",
                      placeholder: "ISCED auswählen"
                    },
                    studyLevelEQF: {
                      title: "Studienniveau (EQF)",
                      placeholder: "EQF auswählen"
                    },
                    website: {
                      title: "Website für Informationen",
                      error: "Bitte geben Sie eine URL ein, die mit http oder https beginnt."
                    },
                    deleteButton: "Zusätzliche Anforderung streichen",
                    addRequirement: "Zusätzliche Anforderung hinzufügen"
                  },
                  inclusionAndAccessibility: {
                    title: "EINGLIEDERUNG UND ZUGÄNGLICHKEIT",
                    descriptionMain: "Die Einrichtung bietet ankommenden mobilen Teilnehmern mit besonderen Bedürfnissen Unterstützung gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung. Informationen und Unterstützung können von den folgenden Kontaktstellen und Informationsquellen bereitgestellt werden:",
                    infrastructure: "Infrastruktur",
                    name: "Name ",
                    description: "Beschreibung",
                    contactEmail: {
                      title: "Kontakt E-Mail ",
                      error: "Nicht gültig"
                    },
                    contactPhone: {
                      title: "Kontakt Telefon ",
                      error: "Bitte geben Sie eine Telefonnummer ein, die mit Ihrer Landesvorwahl beginnt (z. B. +000)."
                    },
                    website: {
                      title: "Website für Informationen ",
                      error: "Bitte geben Sie eine URL ein, die mit http oder https beginnt."
                    },
                    addInfrastucture: "Infrastruktur hinzufügen",
                    services: "Dienstleistungen",
                    addService: "Dienst hinzufügen"
                  },
                  deleteInfrastructure: "Infrastruktur löschen",
                  deleteService: "Dienst löschen",
                  housing: {
                    title: "UNTERBRINGUNG",
                    description: "Die Einrichtung unterstützt die ankommenden mobilen Teilnehmer bei der Suche nach einer Unterkunft gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung. Informationen und Unterstützung können von den folgenden Kontaktstellen und Informationsquellen bereitgestellt werden:",
                    contact: "Kontakt",
                    contactEmail: {
                      title: "Kontakt E-Mail ",
                      error: "Nicht gültig"
                    },
                    contactPhone: {
                      title: "Kontakt Telefon ",
                      error: "Bitte geben Sie eine Telefonnummer ein, die mit Ihrer Landesvorwahl beginnt (z. B. +000)."
                    },
                    website: {
                      title: "Website für Informationen ",
                      error: "Bitte geben Sie eine URL ein, die mit http oder https beginnt."
                    }
                  },
                  visa: {
                    title: "VISA",
                    description: "Die Einrichtung bietet bei Bedarf Unterstützung bei der Beschaffung von Visa für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung. Informationen und Unterstützung können von den folgenden Kontaktstellen und Informationsquellen bereitgestellt werden:",
                    contact: "Kontakt",
                    contactEmail: {
                      title: "Kontakt E-Mail ",
                      error: "Nicht gültig"
                    },
                    contactPhone: {
                      title: "Kontakt Telefon ",
                      error: "Bitte geben Sie eine Telefonnummer ein, die mit Ihrer Landesvorwahl beginnt (z. B. +000)."
                    },
                    website: {
                      title: "Website für Informationen ",
                      error: "Bitte geben Sie eine URL ein, die mit http oder https beginnt."
                    }
                  },
                  insurance: {
                    title: "VERSICHERUNG",
                    description: "Die Einrichtung bietet Unterstützung beim Abschluss einer Versicherung für einreisende und ausreisende mobile Teilnehmer gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung. Die aufnehmende Einrichtung informiert die mobilen Teilnehmer über Fälle, in denen der Versicherungsschutz nicht automatisch gewährt wird. Informationen und Unterstützung können von den folgenden Kontaktstellen und Informationsquellen bereitgestellt werden:",
                    contact: "Kontakt",
                    contactEmail: {
                      title: "Kontakt E-Mail ",
                      error: "Nicht gültig"
                    },
                    contactPhone: {
                      title: "Kontakt Telefon ",
                      error: "Bitte geben Sie eine Telefonnummer ein, die mit Ihrer Landesvorwahl beginnt (z. B. +000)."
                    },
                    website: {
                      title: "Website für Informationen ",
                      error: "Bitte geben Sie eine URL ein, die mit http oder https beginnt."
                    }
                  },
                  additionalInfo: {
                    title: "ZUSÄTZLICHE INFORMATIONEN",
                    description: "Die Einrichtung bietet ankommenden mobilen Teilnehmern mit besonderen Bedürfnissen Unterstützung gemäß den Voraussetzungen der Erasmus-Charta für die Hochschulbildung. Informationen und Unterstützung können von den folgenden Kontaktstellen und Informationsquellen bereitgestellt werden:",
                    recognitionProcess: "Anerkennungsprozess",
                    contact: "KONTAKT",
                    contactEmail: {
                      title: "Kontakt E-Mail ",
                      error: "Nicht gültig"
                    },
                    contactPhone: {
                      title: "Kontakt Telefon ",
                      error: "Bitte geben Sie eine Telefonnummer ein, die mit Ihrer Landesvorwahl beginnt (z. B. +000)."
                    },
                    website: {
                      title: "Website für Informationen ",
                      error: "Bitte geben Sie eine URL ein, die mit http oder https beginnt."
                    },
                    deleteRecognitionProcess: "Erkennungsvorgang löschen",
                    addRecognitionProcess: "Informationen zum Anerkennungsprozess hinzufügen",
                    otherInfo: "Weitere nützliche Informationen",
                    deleteInfo: "Andere Informationen löschen",
                    addInfo: "Weitere nützliche Informationen hinzufügen"
                  },
                  saveFactsheet: "Faktenblatt Daten speichern",
                  containsErrors: "Das Formular enthält Fehler",
                  confirmation: "Ich bestätige, auch im Namen meiner HEI, dass ich über alle Genehmigungen, einschließlich der Zustimmung der betreffenden natürlichen Personen, verfüge, die für das Hochladen der von mir bereitgestellten personenbezogenen Daten und Informationen gemäß den Allgemeinen Geschäftsbedingungen für das Dashboard und der Datenschutzrichtlinie erforderlich sind.",
                  confirmationError: "Bitte geben Sie Ihr Einverständnis zur Verarbeitung der Daten",
                  saveConfirmation: "Sie sind dabei, die Daten Ihres Factsheets zu bearbeiten"
                },
                filter: {
                  title: "Filtern & Sortieren",
                  clearButton: "Alle löschen",
                  applyButton: "Filter anwenden",
                  academicTerms: {
                    firstSemester: "Erstes Semester (Winter/Herbst)",
                    secondSemester: "Zweites Semester (Sommer/Frühjahr)",
                    firstTrimester: "Erstes Trimester",
                    secondTrimester: "Zweites Trimester",
                    fullYear: "Ganzes Studienjahr",
                  },
                  sortingList: {
                    status: "Sortierung nach Status",
                    lastChangedAscending: "Sortierung nach letzter Änderung aufsteigend",
                    lastChangedDescending: "Sortierung nach letzter Änderung Absteigend",
                    name: "Sortierung nach Name"
                  },
                  pendingRequests: {
                    pending: "Anhängig",
                    accepted: "Angenommen",
                    declined: "Abgelehnt"
                  },
                  sortBy: {
                    title: "Sortieren nach",
                    placeholder: "Wählen Sie Sortieren nach..."
                  },
                  status: {
                    title: "Status",
                    placeholder: "Status auswählen..."
                  },
                  iia: {
                    title: "IIV ID",
                    placeholder: "IIV ID",
                    label: "Suchen Sie nach Ihrer ID oder Partner-ID:"
                  },
                  academicYear: {
                    title: "Akademisches Jahr",
                    placeholder: "Studienjahr auswählen..."
                  },
                  academicTerm: {
                    title: "Akademischer Begriff",
                    placeholder: "Studienjahr auswählen..."
                  },
                  country: {
                    title: "Land",
                    placeholder: "Wählen Sie ein oder mehrere Länder aus..."
                  },
                  institution: {
                    title: "Einrichtung",
                    erasmusCode: "Erasmus-Code:",
                    esasmusCodePlaceholder: "Erasmus-Code auswählen...",
                    institutionName: "Name der Einrichtung:",
                    institutionNamePlaceholder: "Name der Einrichtung auswählen...",
                  },
                  approvingEmail: {
                    title: "E-Mail der genehmigenden Person",
                    placeholder: "E-Mail der genehmigenden Person"
                  },
                  createdBy: {
                    title: "Erstellt von",
                    placeholder: "Select erstellt von...",
                    student: "Erstellt von Student",
                    institution: "Erstellt von Institution"
                  },
                  studentName: {
                    title: "Name des Schülers",
                    firstNameLabel: "Erster Name:",
                    firstNamePlaceholder: "Vorname",
                    lastNameLabel: "Nachname:",
                    lastNamePlaceholder: "Nachname"
                  },
                  organizationalUnit: {
                    title: "Organisatorische Einheit",
                    placeholder: "Organisationseinheit auswählen..."
                  },
                  name: {
                    title: "Name",
                  },
                  email: {
                    title: "E-Mail",
                    error: "Bitte geben Sie einen gültigen Wert an."
                  }
                },
                modal: {
                  cancelButton: "Abbrechen"
                },
                selectPhoneInput: {
                  label: "Telefon-Code",
                  placeholder: "Telefonvorwahl wählen...",
                  error: {
                    a: "Bitte geben Sie einen Telefoncode an",
                    b: "Bitte geben Sie eine gültige Telefonnummer an."
                  }
                },
                table: {
                  filterLabel: "Filter",
                  totalAgreements: "Vereinbarungen insgesamt",
                  activeFilters: "Aktive Filter:",
                  noResults: "Leider konnten wir keine Ergebnisse finden"
                },
                coursesTable: {
                  olaReasons: {
                    part1: "Die zuvor gewählte Bildungskomponente ist an der aufnehmenden Einrichtung nicht verfügbar",
                    part2: "Die Komponente ist in einer anderen Sprache als im Kurskatalog angegeben.",
                    part3: "Fahrplankonflikt",
                    part4: "Sonstiges (bitte angeben)",
                    part5: "Ersetzen einer gelöschten Komponente",
                    part6: "Verlängerung der Mobilitätsphase",
                    part7: "Sonstiges (bitte angeben)",
                    other: "Andere "
                  },
                  noCourses: "Keine Kurse zu zeigen",
                  totalECTs: "ECTS-Punkte insgesamt: ",
                  labels: {
                    code: "CODE",
                    subject: "SUBJEKT",
                    ects: "ECTS",
                    semester: "SEMESTER",
                    reason: "REASON",
                    autoRecognition: "AUTOMATISCHE ERKENNUNG",
                    description: "BESCHREIBUNG"
                  }
                },
                olaPdf: {
                  download: { // fix sentence structure
                    part1: "Laden Sie die PDF-Datei herunter, indem Sie ",
                    part2: "hier klicken"
                  }
                },
                institutionInfoTable: {
                  name: "Name:",
                  country: "Land:",
                  erasmusCode: "Erasmus-Kodex:",
                  address: "Adresse:",
                  faculty: "Fakultät:",
                  contactPerson: "Kontaktperson: ",
                  resPerson: "Res. Person:"
                },
                learningAgreementInfoTable: {
                  status: "STATUS",
                  academicYear: "AKADEMIEJAHR",
                  plannedPeriod: "GEPLANTER ZEITRAUM",
                  created: "GESCHAFFEN",
                  pdfExportText: "In PDF exportieren"
                },
                studentInfo: {
                  firstName: "Vornamen:",
                  lastName: "Nachname:",
                  email: "E-Mail:",
                  birthDate: "Geburtsdatum:",
                  gender: "Geschlecht:",
                  nationality: "Nationalität:",
                  foeISCED: "Bereich Bildung (ISCED):",
                  foeClarification: "Bereich Bildung (Clarification):"
                },
                tor: {
                  totalECTs: "ECTS insgesamt: ",
                  form: {
                    componentCode: {
                      title: {
                        part1: "Komponente Code",
                        part2: "*"
                      },
                      error: "Bitte geben Sie einen Code an.",
                    },
                    componentTitle: {
                      title: {
                        part1: "Komponente Titel",
                        part2: "*"
                      },
                      error: "Bitte geben Sie einen Titel an."
                    },
                    grade: {
                      title: {
                        part1: "Klasse",
                        part2: "*"
                      },
                      error: "Bitte geben Sie eine Note an."
                    },
                    ectNum: {
                      title: {
                        part1: "Anzahl der ECTS-Punkte",
                        part2: "*"
                      },
                      error: "Bitte geben Sie ects Credits an."
                    },
                    success: {
                      part1: "Erfolgreich abgeschlossen",
                      part2: "*"
                    }
                  },
                  torFeedback: {
                    create: {
                      loading: "Erstellen einer neuen ToR...",
                      success: "Erfolgreich erstellte ToR",
                      failure: "Fehler beim Erstellen der ToR."
                    },
                    update: {
                      loading: "Aktualisierung einer neuen ToR...",
                      success: "Erfolgreich aktualisierte ToR",
                      failure: "Fehler beim Aktualisieren der ToR."
                    }
                  },
                  componentFeedback: {
                    create: {
                      loading: "Erstellen einer neuen Komponente...",
                      success: "Erfolgreich erstellte Komponente",
                      failure: "Fehler beim Erstellen der Komponente."
                    },
                    edit: {
                      loading: "Bearbeiten einer neuen Komponente...",
                      success: "Erfolgreich bearbeitete Komponente",
                      failure: "Fehler beim Bearbeiten der Komponente."
                    },
                    delete: {
                      loading: "Löschen einer neuen Komponente...",
                      success: "Erfolgreich gelöschte Komponente",
                      failure: "Fehler beim Löschen der Komponente."
                    }
                  },
                  createTor: "ToR erstellen",
                  mobilityStart: "Mobilität Startdatum:",
                  mStart: "Mobilität Startdatum",
                  mEnd: "Mobilität Enddatum",
                  mobilityEnd: "Mobilität Enddatum:",
                  invalidInput: "Bitte geben Sie einen gültigen Wert an.",
                  transcript: "ABSCHRIFT DER UNTERLAGEN AN DER AUFNEHMENDEN EINRICHTUNG",
                  recognition: "ANERKENNUNG DURCH DIE ENTSENDENDE EINRICHTUNG",
                  addComponentTitle: { // fix sentence structure
                    part1: "Eine neue ",
                    part2: "Komponente hinzufügen"
                  },
                  editComponentTitle: {
                    part1: "Edit ",
                    part2: "Component"
                  },
                  deleteComponentTitle: {
                    confirmMessage: "Are you sure?",
                    part1: "Delete ",
                    part2: "Component"
                  },
                  updateTor: {
                    part1: "Update ",
                    part2: "ToR"
                  },
                  addComponent: "eine neue Komponente hinzufügen",
                  editComponent: "Komponente bearbeiten",
                  deleteComponent: "Komponente löschen",
                  submitButton: "Einreichen",
                  updateButton: "Update"
                },
                destinationHeader: {
                  nominated: "NOMINIERT",
                  rejected: "ABGELEHNT",
                  nominate: "Nominieren Sie"
                },
                destinationInfo: {
                  studyLevel: "STUDIENSTUFE",
                  studyField: "STUDIENBEREICH",
                  reqDocuments: "ERFORDERLICHE DOKUMENTE",
                  otherReq: "ANDERE VORAUSSETZUNGEN"
                },
            }
          },
          fr: {
            translation: {
                loadingMessage: "Chargement...",
                errorMessage: "Une erreur s'est produite",
                home: {
                  header: "Bienvenue sur le tableau de bord EWP!",
                  title: "Page d'accueil",
                  tab: "Accueil",
                  description: {
                    part1: "Bienvenue dans le tableau de bord du programme EWP, l'outil de gestion des mobilités de vos étudiants dans le cadre du programme Erasmus+. En tant qu'IRO, vous jouez un rôle essentiel en aidant vos étudiants à tirer le meilleur parti de leurs expériences à l'étranger, et EWP Dashboard est là pour vous soutenir dans cet effort.", 
                    part2: "Nous sommes heureux d'annoncer que nous avons apporté des améliorations significatives à l'interface utilisateur et à l'expérience utilisateur de l'application. Nous avons travaillé dur pour rendre le tableau de bord EWP plus intuitif et convivial, et nous pensons que ces changements vous permettront de gérer plus facilement les mobilités de vos étudiants et de tirer le meilleur parti des opportunités offertes par le programme Erasmus+."
                  }
                },
                sidebar: {
                  institutions: "Institutions",
                  requests: "Demandes",
                  myUniversity: "Mon université",
                  accountsAndAccess: "Comptes et accès",
                  organizationalUnits: "Unités organisationnelles",
                  ukraineAssistance: "Assistance à l'Ukraine",
                  ola: "OLA",
                  outgoingStudents: "Étudiants sortants",
                  incomingStudents: "Étudiants entrants",
                  upload: "Télécharger",
                  shortTerm: "Courte durée",
                  applications: "Candidatures",
                  requirements: "Exigences",
                  iia: "AII",
                  iiaList: "Listes des AII",
                  newDigitalIIA: "Nouvel AII numérique",
                  importIIA: "Import des AII",
                  exportIIAs: "Export des AII",
                  defaultIIAData: "Données des AII par défaut",
                  erasmusApp: "L'application Erasmus+",
                  usefulInfo: "Informations utiles",
                  deals: "Marchés",
                  events: "Evénements",
                  coursesEvaluations: "Evaluations des cours",
                  ewpSettings: "Réglages EWP",
                },
                articleSlider: {
                  loadingMessage: "Chargement...",
                  errorMessage: "Une erreur s'est produite",
                  created: "Créée: "
                },
                articleSingleView: {
                  title: "Article de blog",
                  subTitle: "Vue unique",
                  created: "Créée: ",
                  lastUpdated: "Dernière mise à jour: ",
                  tags: "Tags: "
                },
                ewpSettings: {
                  header: "ÉCHANGE D'INFORMATIONS PAR LE BIAIS DU TABLEAU DE BORD EWP",
                  title: "RÉGLAGES DE L'EWP",
                  subTitle: {
                    part1: "Veuillez ",
                    part2: " cocher/décocher  ",
                    part3: "en fonction de ce qui s'applique à votre HEI"
                  },
                  ewpSettingType: {
                    updatingSettings: "Mise à jour des paramètres de l'EWP...",
                    updatedSuccessfully: `Mise à jour réussie des paramètres de l'EWP`,
                    unableToUpdate: `Impossible de mettre à jour les paramètres de l'EWP`,
                    exchangeOfIIAs: {
                      title: "Échange d'AII par le biais du tableau de bord EWP",
                      checkbox: "J'accepte que le tableau de bord du EWP représente mon établissement d'enseignement supérieur dans le réseau du EWP afin d'échanger des accords interinstitutionnels avec vos partenaires."
                    },
                    exchangeOfOUnits: {
                      title: "Échange d'unités d'œuvre par le biais du tableau de bord EWP",
                      checkbox: "J'accepte que le tableau de bord du EWP représente mon établissement d'enseignement supérieur dans le réseau du EWP afin de partager les informations sur les unités organisationnelles avec vos partenaires."
                    },
                    exchangeOfStaticInfo: {
                      title: "Échange d'informations statiques pour les AII par le biais du tableau de bord EWP",
                      checkbox: "J'accepte que le tableau de bord du EWP représente mon établissement d'enseignement supérieur dans le réseau du EWP afin d'échanger les informations statiques pour les accords interinstitutionnels avec vos partenaires."
                    },
                    exchangeOfOLAs: {
                      title: "Échange d'OLA par le biais du tableau de bord EWP",
                      checkbox: "J'accepte que le tableau de bord EWP représente mon établissement d'enseignement supérieur dans le réseau EWP afin de permettre l'échange électronique d'informations sur le contrat d'apprentissage en ligne."
                    }
                  },
                  cancelButton: "Annuler",
                  saveSettingsButton: "Sauvegarder les paramètres de l'EWP"
                },
                accountsAndAccess: {
                  title: "Comptes et accès",
                  accountsTab: "Comptes",
                  rolesTab: "Rôles",
                  saveChangesButton: "Enregistrer les modifications",
                  accountForm: {
                    accountName: {
                      label: "Nom du compte",
                      error: "Veuillez indiquer un nom de compte."
                    },
                    accountEmail: {
                      label: "Courriel du compte",
                      error: "Veuillez fournir un courriel valide pour votre compte."
                    },
                    accountPhone: {
                      label: "Numéro de téléphone",
                      error: "Le numéro de téléphone enregistré pour ce compte n'est pas valide. Veuillez fournir un numéro de téléphone valide."
                    },
                    accountRole: {
                      label: "Rôle",
                      placeholder: "Sélectionner le rôle...",
                      error: "Veuillez indiquer un rôle pour le compte."
                    },
                  },
                  roleForm: {
                    roleName: {
                      label: "Nom du rôle",
                      error: "Veuillez indiquer un nom de rôle."
                    },
                    rolePermissions: {
                      label: "Permissions",
                      placeholder: "Sélectionner les autorisations...",
                      error: {
                        a: "Veuillez sélectionner au moins une autorisation pour ce rôle.",
                        b: "Vous devez avoir choisi au moins une autorisation pour un rôle."
                      }
                    }
                  },
                  accountFeedback: {
                    create: {
                      loading: "Création d'un compte...",
                      success: "Création réussie du compte ",
                      failure: "Impossible de créer le compte "
                    },
                    update: {
                      loading: "Mise à jour du compte...",
                      success: "Mise à jour du compte réussie ",
                      failure: "Impossible de mettre à jour le compte "
                    },
                    delete: {
                      loading: "Suppression d'un compte...",
                      success: "Suppression réussie du compte ",
                      failure: "Impossible de supprimer le compte "
                    }
                  },
                  addNewAccount: {
                    title: "Ajouter un nouveau compte",
                    subTitle: "Ajoutez les informations nécessaires pour créer un nouveau compte pour l'un de vos collaborateurs.",
                    buttonText: "Ajouter un nouveau compte"
                  },
                  editAccount: {
                    title: "Modification d'un compte existant",
                    subTitle: "Modifier les informations d'un compte existant et ",
                    buttonText: "Modifier le compte"
                  },
                  deleteAccount: {
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "supprimer",
                      part3: " supprimer le compte "
                    },
                    subTitle: "Après avoir supprimé le compte, votre collaborateur ne pourra plus se connecter.",
                    buttonText: "Supprimer le compte"
                  },
                  form: {
                    accountName: {
                      label: "Nom du compte",
                      error: "Veuillez indiquer un nom de compte."
                    },
                    accountEmail: {
                      label: "Courriel du compte",
                      error: "Veuillez fournir un courriel valide pour votre compte."
                    },
                    accountPhone: {
                      label: "Numéro de téléphone",
                      error: "Le numéro de téléphone enregistré pour ce compte n'est pas valide. Veuillez fournir un numéro de téléphone valide."
                    },
                    accountRole: {
                      label: "Rôle",
                      placeholder: "Sélectionner le rôle...",
                      error: "Veuillez indiquer un rôle pour le compte."
                    }
                  },
                  roleFeedback: {
                    create: {
                      loading: "Créer un rôle...",
                      success: "Création réussie du rôle ",
                      failure: "Impossible de créer le rôle "
                    },
                    update: {
                      loading: "Mise à jour du rôle...",
                      success: "Mise à jour réussie du rôle ",
                      failure: "Impossible de mettre à jour le rôle "
                    },
                    delete: {
                      loading: "Suppression du rôle...",
                      success: "Suppression réussie du rôle ",
                      failure: "Impossible de supprimer le rôle  ",
                      warning: {
                        part1: "Le rôle ",
                        part2: " est utilisé par ",
                        part3: " compte",
                        part4: {
                          a: "s",
                          b: ""
                        },
                        part5: ". Vous ne pouvez donc pas le supprimer."
                      }
                    }
                  },
                  addNewRole: {
                    title: "Ajouter un nouveau rôle",
                    subTitle: "Ajoutez les informations nécessaires pour ajouter un nouveau rôle que vous pourrez ensuite attribuer aux membres de votre personnel.",
                    buttonText: "Ajouter un nouveau rôle"
                  },
                  editRole: {
                    title: "Modifier un rôle existant",
                    subTitle: "Modifier les informations d'un rôle existant et ",
                    buttonText: "Modifier le rôle"
                  },
                  deleteRole: {
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "supprimer",
                      part3: " le rôle "
                    },
                    subTitle: "Après avoir supprimé le rôle, vous ne pourrez plus le voir.",
                    buttonText: "Supprimer un rôle"
                  }
                },
                organizationalUnits: {
                  title: "Unités organisationnelles",
                  saveChangesButton: "Enregistrer les modifications",
                  unitForm: {
                    unitLanguage: {
                      label: "Nom de l'unité Langue",
                      placeholder: "Sélectionner la langue...",
                    },
                    unitName: {
                      label: "Nom de l'unité",
                      placeholder: "Nom de l'Ounit",
                      error: "Veuillez fournir un nom d'unité organisationnelle valide."
                    },
                    unitAbbreviation: {
                      label: "Abréviation de l'unité",
                      placeholder: "Abréviation d'Ounit"
                    },
                    unitContactName: {
                      label: "Nom du contact Contrats d'apprentissage sortants",
                    },
                    unitContactEmail: {
                      label: "Contact Email Contrats d'apprentissage sortants",
                      error: "Veuillez fournir un courriel de contact valide."
                    },
                    incomingDifferent: {
                      label: "La personne de contact pour les contrats d'apprentissage entrants est différente"
                    },
                    incomingContactName:{
                      label: "Nom du contact Contrats d'apprentissage entrants",
                    },
                    incomingContactEmail:{
                      label: "Contact Email Contrats d'apprentissage entrants",
                      error: "Veuillez fournir un courriel de contact valide."
                    }
                  },
                  unitFeedback: {
                    create: {
                      loading: "Création d'une unité organisationnelle...",
                      success: "Création réussie de l'unité d'organisation avec le nom ",
                      failure: "Impossible de créer l'unité d'organisation"
                    },
                    update: {
                      loading: "Mise à jour de l'unité d'organisation...",
                      success: "Mise à jour réussie de l'unité d'organisation ",
                      failure: "Impossible de mettre à jour l'unité d'organisation"
                    },
                    delete: {
                      loading: "Suppression d'une unité d'organisation...",
                      success: "Suppression réussie de l'unité d'organisation",
                      failure: "Impossible de supprimer l'unité d'organisation"
                    }
                  },
                  addNewUnit: {
                    title: "Ajouter une nouvelle unité organisationnelle",
                    subTitle: "Ajoutez les informations nécessaires à l'ajout d'une nouvelle unité organisationnelle pour votre HEI.",
                    buttonText: "Ajouter une nouvelle unité organisationnelle"
                  },
                  editUnit: {
                    title: "Modifier une unité d'organisation existante",
                    subTitle: "Ajoutez les informations nécessaires pour modifier une unité organisationnelle pour votre HEI.",
                    buttonText: "Modifier l'unité d'organisation"
                  },
                  deleteUnit: {
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "supprimer",
                      part3: " une unité d'organisation"
                    },
                    subTitle: "Après avoir supprimé une unité d'organisation, vous ne pourrez plus la voir.",
                    buttonText: "Supprimer une unité organisationnelle",
                    buttonLabel: "Supprimer",
                  },
                },
                ukraineAssistance: {
                  title: "Assistance à l'Ukraine",
                  infoFeedback: {
                    loading: "Mise à jour des informations sur l'assistance à l'Ukraine...",
                    success: "Mise à jour réussie de l'information sur l'assistance à l'Ukraine",
                    failure: "Impossible de mettre à jour les informations sur l'assistance à l'Ukraine"
                  },
                  validateError: "Veuillez sélectionner au moins une option « Oui » ou fournir un lien valide.",
                  invalidUrl: "URL invalide",
                  header: "Guerre en Ukraine - Centre de soutien aux étudiants",
                  description: "En réponse aux événements dramatiques qui se déroulent en Ukraine, nous avons développé un portail pour fournir des informations sur l'aide offerte par les établissements d'enseignement supérieur aux étudiants fuyant la guerre. Si vous souhaitez nous aider à diffuser la façon dont votre université intervient et fait la différence, veuillez l'indiquer :",
                  form: {
                    shelter: "Votre institution fournit-elle un hébergement d'urgence ?",
                    health: "Un soutien sanitaire et psychologique est-il disponible dans votre institution ?",
                    fastTrack: "Votre établissement propose-t-il une procédure de candidature accélérée pour les études ?",
                    scholarship: "Votre institution offre-t-elle des bourses d'études spéciales ?",
                    options: {
                      yes: "Oui",
                      no: "Non",
                      unkown: "Inconnu"
                    }
                  },
                  moreInfo: {
                    header: "Veuillez fournir un lien vers la page web de votre institution qui contient plus d'informations sur ce sujet, ou vers la page de contact de votre institution.",
                    error: "Veuillez fournir une URL valide.",
                    buttonText: "Save Ukraine Assistance Information"
                  }
                },
                incomingStudents: {
                  title: "Accord sur l'apprentissage en ligne",
                  subTitle: "Étudiants entrants",
                  tab: "Contrats d'apprentissage en ligne pour les étudiants entrants",
                  tabSingleView: "Voir le contrat d'apprentissage en ligne unique",
                },
                outgoingStudents: {
                  title: "Accord sur l'apprentissage en ligne",
                  subTitle: "Étudiants sortants",
                  tab: "Contrats d'apprentissage en ligne sortants",
                  tabSingleView: "Voir le contrat de formation en ligne sortant",
                },
                applications: {
                  title: "Candidatures pour Erasmus+",
                  tab: {
                    out: "Nominations sortantes",
                    in: "Nominations à venir"
                  }
                },
                learningAgreements: {
                  loadingMessage: "Chargement...",
                  signFeedback: {
                    loading: "Signature de l'OLA...",
                    success: "Contrat d'apprentissage signé avec succès",
                    failure: "Erreur lors de la signature."
                  },
                  declineOlaFeedback: {
                    loading: "Déclin de l'OLA...",
                    success: "Refus d'un contrat d'apprentissage",
                    failure: "Erreur lors du déclin."
                  }, 
                  editTableFailed: "L'édition du tableau des cours OLA a échoué.",
                  LearningAgreementInfo: {
                    title: "INFO SUR LE CONTRAT D'APPRENTISSAGE"
                  },
                  generalInfo: {
                    title: "Informations générales",
                    studentPersonalData: {
                      title: "DONNÉES PERSONNELLES DES ÉTUDIANTS"
                    },
                    sendingInstInfo: {
                      title: "ENVOI D'INFORMATIONS SUR L'INSTITUTION"
                    },
                    receivingInstInfo: {
                      title: "INFORMATIONS SUR L'INSTITUTION D'ACCUEIL"
                    }
                  },
                  courses: {
                    title: "Cours",
                    tableA: "TABLEAU A",
                    tableB: "TABLEAU B",
                    tableC: "TABLEAU C",
                  },
                  changes: {
                    title: "Changements",
                    tableA: "MODIFICATIONS DU TABLEAU A",
                    tableB: "MODIFICATIONS DU TABLEAU B",
                    tableC: "MODIFICATIONS DU TABLEAU C",
                  },
                  modalReject: {
                    declineButton: "Déclin",
                    body: {
                      part1: "Après avoir refusé la LLO, l'étudiant pourra la modifier et introduire les changements que vous proposez. Veuillez indiquer dans le champ ci-dessous les commentaires que vous souhaitez envoyer par courrier électronique à l'étudiant : ",
                      part2: "Raison du déclin",
                      part3: " Note",
                    },
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "refuser",
                      part3: " la OLA d'un élève"
                    }
                  },
                  modalSign: {
                    signButton: "Signer OLA",
                    body: "En signant numériquement ce document, l'étudiant, l'établissement d'envoi et l'établissement d'accueil confirment qu'ils approuvent le contrat d'études et qu'ils se conformeront à toutes les dispositions convenues par toutes les parties. Les établissements d'envoi et d'accueil s'engagent à appliquer tous les principes de la Charte Erasmus pour l'enseignement supérieur relatifs à la mobilité pour les études (ou les principes convenus dans l'accord interinstitutionnel pour les établissements situés dans les pays partenaires). L'établissement bénéficiaire et l'étudiant doivent également s'engager à respecter les dispositions de la convention de subvention Erasmus+. L'établissement d'accueil confirme que les éléments pédagogiques énumérés sont conformes à son catalogue de cours et doivent être mis à la disposition de l'étudiant. L'établissement d'envoi s'engage à reconnaître tous les crédits ou unités équivalentes obtenus dans l'établissement d'accueil pour les éléments de formation achevés avec succès et à les prendre en compte pour l'obtention du diplôme de l'étudiant. L'étudiant et l'établissement d'accueil communiqueront à l'établissement d'envoi tout problème ou changement concernant le programme d'études, les personnes responsables et/ou la période d'études.",
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "signer",
                      part3: " le OLA d'un élève"
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Confirmer",
                    body: {
                      part1: "Conformément aux lignes directrices officielles de la Commission européenne, si la reconnaissance automatique n'a pas lieu, veuillez fournir une justification claire et indiquer quel autre type de reconnaissance formelle sera appliqué. ",
                      part2: "Indiquer le motif du rejet"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "Conformément aux lignes directrices officielles de la Commission européenne, si la reconnaissance automatique n'a pas lieu, veuillez fournir une justification claire et indiquer quel autre type de reconnaissance formelle sera appliqué. ",
                      part2: "Indiquer le motif du rejet",
                    }
                  },
                  upload: {
                    titleMain: "Accord sur l'apprentissage en ligne",
                    subTitle: "Télécharger le CSV",
                    tab: "Télécharger un fichier CSV pour les contrats d'apprentissage en ligne",
                    validateData: {
                      invalidCode: {
                        part1: "Code erasmus invalide ",
                        part2: " à la ligne "
                      },
                      emailError: {
                        part1: "Erreur de courrier électronique à la ligne ",
                        part2: ": Vérifiez le format de l'email de vos données."
                      },
                      dataError: {
                        part1: "Erreur de date à la ligne ",
                        part2: ": Veillez à ce que les dates soient au format jj/mm/aaaa."
                      },
                      fileError: "Erreur de fichier : Vous ne pouvez télécharger que 25 LA à la fois."
                    },
                    uploadOla: {
                      loading: "Téléchargement des OLA...",
                      success: "Télécharger avec succès les accords d'apprentissage",
                      failure: "Des erreurs se sont produites lors du téléchargement des OLA."
                    },
                    header: "Téléchargement des contrats d'apprentissage",
                    cancel: "Annuler",
                    upload: "Télécharger",
                    modalSign: {
                      part1: "Veuillez vous assurer que les données sont conformes au ",
                      part2: "modèle"
                    },
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "télécharger",
                      part3: " des OLA"
                    },
                    dontClosePage: "Cela peut prendre un certain temps, mais ne fermez pas cette page !",
                    commonErrors: {
                      part1: "Si vous constatez des erreurs, veuillez consulter la page des erreurs courantes disponible ",
                      part2: "ici"
                    },
                    returnToUploadPage: "Retour à la page de téléchargement",
                    header2: "Télécharger un fichier CSV pour pré-remplir les contrats d'apprentissage en ligne",
                    bodyUpload: {
                      part1: "La fonctionnalité de pré-remplissage des contrats d'apprentissage en ligne prend désormais en charge le téléchargement de fichiers CSV. Une fois les données saisies, vous pouvez consulter la liste des étudiants pour vous assurer que toutes les informations sont correctes et soumettre les données. Cette action déclenchera la création de contrats d'apprentissage en ligne pré-remplis et une notification aux étudiants les invitant à finaliser le document.",
                      part2: "Le format du fichier CSV peut être vérifié à l'aide d'un modèle de feuille de calcul disponible sur le CC EWP ",
                      part3: "à l'adresse suivante"
                    },
                    checkBox: "Je confirme, également au nom de mon HEI, que je dispose de toutes les autorisations, y compris le consentement des personnes physiques concernées, nécessaires pour télécharger les données à caractère personnel et les informations que je fournis, conformément aux conditions générales du tableau de bord et à la politique de confidentialité.",
                    consent: "Veuillez donner votre accord sur le traitement des données"
                  }
                },
                nominationList: {
                  totalApplications: "Total des demandes",
                  downloadFiles: "Télécharger tous les fichiers",
                  outSignleView: {
                    loadingMessage: "Chargement...",
                    errorMessage: "Une erreur s'est produite",
                    applicationFeedback: {
                      nominate: {
                        loading: "Demande de nomination...",
                        success: "Candidature retenue",
                        failure: "Échec de la nomination de la demande"
                      },
                      reject: {
                        loading: "Rejet de la demande...",
                        success: "Demande rejetée avec succès",
                        failure: "Le rejet de la demande n'a pas abouti"
                      },
                      wait: {
                        loading: "Inscription sur la liste d'attente...",
                        success: "Mise à jour réussie de la demande",
                        failure: "La mise à jour de la demande a échoué"
                      },
                      delete: {
                        loading: "Suppression d'une application...",
                        success: "Suppression réussie de l'application",
                        failure: "La suppression de la demande a échoué"
                      },
                    },
                    applicationInfo: {
                      title: "INFORMATIONS SUR LA DEMANDE",
                      status: "STATUT",
                      academicYear: "ANNÉE ACADÉMIQUE",
                      academicTerm: "TERME ACADEMIQUE",
                      submissionTime: "DÉLAI DE SOUMISSION",
                      downloadFiles: "Télécharger tous les fichiers",
                    },
                    generalInfo: {
                      title: "Informations générales",
                      studentInfo: {
                        title: "INFO ÉTUDIANTS",
                        name: "NOM",
                        email: "EMAIL",
                        studyLevel: "NIVEAU D'ÉTUDE",
                        studyField: "CHAMP D'ÉTUDE"
                      },
                      destinationheis: {
                        title: "DESTINATION HEIs",
                        institutionFaculty: "INSTITUTION / FACULTÉ",
                        nominatedStudents: "ÉTUDIANTS NOMMÉS"
                      }
                    },
                    documents: {
                      title: "Documents",
                      viewPdf: "Voir Pdf",
                      files: { 
                        portfolio: "PORTFOLIO",
                        externalCertification: "CERTIFICATION EXTERNE",
                        referenceContact: "CONTACT DE RÉFÉRENCE",
                        motivationLetter: "LETTRE DE MOTIVATION",
                        languageCert: "CERTIFICATS DE LANGUE",
                        other: "AUTRES DOCUMENTS"
                      }
                    },
                    waitingList: "LISTE D'ATTENTE",
                    reject: "REJET",
                    delete: "DELETE",
                    modalReject: {
                      body: "Après le rejet de la demande, l'étudiant ne pourra pas se porter candidat à un autre programme de mobilité pour l'année académique en cours.",
                      reject: "Rejeter",
                      title: {
                        part1: "Vous êtes sur le point de ",
                        part2: "rejeter",
                        part3: " la candidature d'un étudiant"
                      }
                    },
                    modalWaiting: {
                      body: "Après avoir inscrit la candidature de l'étudiant sur la liste d'attente, vous pourrez toujours désigner l'étudiant plus tard, si vous le souhaitez.",
                      waitingList: "Liste d'attente",
                      title: {
                        part1: "Vous êtes sur le point d'inscrire la candidature d'un élève sur la ",
                        part2: "liste d'attente"
                      }
                    },
                    modalDelete: {
                      body: "Après avoir supprimé la candidature de l'étudiant, vous ne pourrez plus la voir.",
                      delete: "Supprimer",
                      title: {
                        part1: "Vous êtes sur le point de ",
                        part2: "supprimer",
                        part3: " la candidature d'un étudiant"
                      }
                    },
                    modalNominate: {
                      body: "Après avoir proposé la mobilité de l'étudiant à l'HEI d'accueil, l'établissement d'accueil pourra accepter ou rejeter la proposition. En cas de rejet par l'HEI d'accueil, vous pourrez proposer à l'étudiant une mobilité dans un autre HEI, si celui-ci est disponible.",
                      nominate: "Nommer",
                      title: {
                        part1: "Vous êtes sur le point de ",
                        part2: "proposer",
                        part3: " la candidature d'un étudiant"
                      }
                    }
                  },
                  inSingleView: {
                    loadingMessage: "Chargement...",
                    errorMessage: "Une erreur s'est produite",
                    applicationFeedback: {
                      nominate: {
                        loading: "Demande de nomination...",
                        success: "Candidature retenue",
                        failure: "Échec de la nomination de la demande"
                      },
                      reject: {
                        loading: "Rejet de la demande...",
                        success: "Demande rejetée avec succès",
                        failure: "Le rejet de la demande n'a pas abouti"
                      }
                    },
                    applicationInfo: {
                      title: "INFORMATIONS SUR LA DEMANDE",
                      status: "STATUT",
                      academicYear: "ANNÉE ACADÉMIQUE",
                      academicTerm: "TERME ACADEMIQUE",
                      submissionTime: "DÉLAI DE SOUMISSION",
                      downloadFiles: "Télécharger tous les fichiers",
                    },
                    generalInfo: {
                      title: "Informations générales",
                      studentInfo: {
                        title: "INFO ÉTUDIANTS",
                        name: "NOM",
                        email: "EMAIL",
                        studyLevel: "NIVEAU D'ÉTUDE",
                        studyField: "CHAMP D'ÉTUDE"
                      },
                      homeHei: {
                        title: "HOME HEI",
                        name: "NOM",
                        orgUnit: "UNITÉ D'ORGANISATION"
                      },
                      destinationheis: {
                        title: "DESTINATION HEIs",
                        institutionFaculty: "INSTITUTION / FACULTÉ",
                        nominatedStudents: "ÉTUDIANTS NOMMÉS"
                      }
                    },
                    documents: {
                      title: "Documents",
                      viewPdf: "Voir Pdf",
                      files: { 
                        portfolio: "PORTFOLIO",
                        externalCertification: "CERTIFICATION EXTERNE",
                        referenceContact: "CONTACT DE RÉFÉRENCE",
                        motivationLetter: "LETTRE DE MOTIVATION",
                        languageCert: "CERTIFICATS DE LANGUE",
                        other: "AUTRES DOCUMENTS"
                      }
                    },
                    reject: "Rejeter",
                    nominate: "Nommer",
                    modalReject: {
                      body: "Après le rejet de la candidature, l'étudiant ne pourra pas poser sa candidature à un autre programme de mobilité dans votre établissement d'enseignement supérieur pour l'année universitaire en cours. Toutefois, l'IRO d'envoi peut être en mesure de nommer cet étudiant pour un programme de mobilité dans un autre HEI, s'il est disponible.",
                      title: {
                        part1: "Vous êtes sur le point de ",
                        part2: "rejeter",
                        part3: " la candidature d'un étudiant"
                      }
                    },
                    modalNominate: {
                      body: "En désignant l'étudiant, vous vous assurez qu'il pourra commencer sa mobilité dans HEI que vous représentez.",
                      title: {
                        part1: "Vous êtes sur le point de ",
                        part2: "proposer",
                        part3: " la candidature d'un étudiant"
                      }
                    }
                  }
                },
                shortTerm: {
                  title: "Mobilités à courte durée",
                  tabOutgoing: "Mobilités sortantes à courte durée",
                  tabIncoming: "Mobilités à courte durée entrantes",
                },
                shortTermMobilities: {
                  loadingMessage: "Chargement...",
                  errorMessage: "Une erreur s'est produite",
                  title: "Mobilités à courte durée",
                  tabOutgoing: "Voir une seule mobilité sortante à courte durée",
                  tabIncoming: "Voir une seule mobilité à courte durée entrante",
                  studentType: {
                    out: "Personne responsable au sein de l'institution expéditrice",
                    in: "Personne responsable dans l'institution destinataire"
                  },
                  editTableFail: "L'édition du tableau des cours OLA a échoué.",
                  signFeedback: {
                    loading: "Signature de l'OLA...",
                    success: "Contrat d'apprentissage signé avec succès",
                    failure: "Erreur lors de la signature."
                  },
                  declineOlaFeedback: {
                    loading: "Déclin de l'OLA...",
                    success: "Refus d'un contrat d'apprentissage",
                    failure: "Erreur lors du déclin."
                  }, 
                  LearningAgreementInfo: {
                    title: "INFO SUR LE CONTRAT D'APPRENTISSAGE"
                  },
                  generalInfo: {
                    title: "Informations générales",
                    studentPersonalData: {
                      title: "DONNÉES PERSONNELLES DES ÉTUDIANTS"
                    },
                    sendingInstInfo: {
                      title: "ENVOI D'INFORMATIONS SUR L'INSTITUTION"
                    },
                    receivingInstInfo: {
                      title: "INFORMATIONS SUR L'INSTITUTION D'ACCUEIL"
                    }
                  },
                  courses: {
                    title: "Cours",
                    table: "TABLE DE MOBILITÉ À COURT DURÉE"
                  },
                  modalReject: {
                    declineButton: "Déclin",
                    body: {
                      part1: "Après avoir refusé la LLO, l'étudiant pourra la modifier et introduire les changements que vous proposez. Veuillez indiquer dans le champ ci-dessous les commentaires que vous souhaitez envoyer par courrier électronique à l'étudiant : ",
                      part2: "Raison du déclin",
                      part3: " Note",
                    },
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "refuser",
                      part3: " LLO d'un élève"
                    }
                  },
                  modalSign: {
                    signButton: "Signer OLA",
                    body: "En signant numériquement ce document, l'étudiant, l'établissement d'envoi et l'établissement d'accueil confirment qu'ils approuvent le contrat d'études et qu'ils se conformeront à toutes les dispositions convenues par toutes les parties. Les établissements d'envoi et d'accueil s'engagent à appliquer tous les principes de la Charte Erasmus pour l'enseignement supérieur relatifs à la mobilité pour les études (ou les principes convenus dans l'accord interinstitutionnel pour les établissements situés dans les pays partenaires). L'établissement bénéficiaire et l'étudiant doivent également s'engager à respecter les dispositions de la convention de subvention Erasmus+. L'établissement d'accueil confirme que les éléments éducatifs énumérés sont conformes à son catalogue de cours et doivent être mis à la disposition de l'étudiant. L'établissement d'envoi s'engage à reconnaître tous les crédits ou unités équivalentes obtenus dans l'établissement d'accueil pour les éléments de formation achevés avec succès et à les prendre en compte pour l'obtention du diplôme de l'étudiant. L'étudiant et l'établissement d'accueil communiqueront à l'établissement d'envoi tout problème ou changement concernant le programme d'études, les personnes responsables et/ou la période d'études.",
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "signer",
                      part3: " le LLO d'un élève"
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Confirmer",
                    body: {
                      part1: "Conformément aux lignes directrices officielles de la Commission européenne, si la reconnaissance automatique n'a pas lieu, veuillez fournir une justification claire et indiquer quel autre type de reconnaissance formelle sera appliqué. ",
                      part2: "Indiquer le motif du rejet"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "Conformément aux lignes directrices officielles de la Commission européenne, si la reconnaissance automatique n'a pas lieu, veuillez fournir une justification claire et indiquer quel autre type de reconnaissance formelle sera appliqué. ",
                      part2: "Indiquer le motif du rejet",
                    }
                  },
                },
                requirements: {
                  applicationSettings: {
                    feedback: {
                      success: "Les paramètres de l'application ont été mis à jour avec succès.",
                      error1: "Une erreur s'est produite lors de la mise à jour des exigences de la demande.",
                      error2: "Une erreur s'est produite lors de la mise à jour des paramètres de l'application : "
                    },
                    title: "Paramètres de l'application",
                    description: "Veuillez choisir le nombre maximum d'HEI d'accueil pour vos étudiants sortants et fixer les dates limites de dépôt des candidatures.",
                    numOfHeis: {
                      title: "Nombre d'HEI ",
                      placeholder: "Sélectionner le nombre d'HEI",
                      error: "Veuillez sélectionner un pays pour votre institution",
                      message: "Nombre maximal de priorités de HEI d'accueil par candidature d'étudiant"
                    },
                    semesterApplications: {
                      titleAw: "Période de candidature pour le semestre d'automne/hiver",
                      titleSs: "Période de candidature pour le semestre de printemps/été",
                      startDate: "Date de début ",
                      endDate: "Date de fin ",
                      error: "Veuillez fournir une valeur valide à l'invite."
                    },
                    cancel: "Annuler",
                    saveSettings: "Enregistrer les paramètres de l'application"
                  },
                  applicationRequirements: {
                    feedback: {
                      success: "Les exigences de la demande ont été mises à jour avec succès.",
                      error1: "Une erreur s'est produite lors de la mise à jour des exigences de la demande.",
                      error2: "Une erreur s'est produite lors de la mise à jour des exigences de la demande: "
                    },
                    title: "Exigences (sortantes)",
                    header: "Documents requis",
                    descriptionMain: "Veuillez activer les commutateurs à côté des fichiers nécessaires aux étudiants sortants de votre établissement d'enseignement supérieur et fournir les URL et les descriptions correspondantes.",
                    description: "Description",
                    motivationLetter: "Lettre de motivation",
                    transcriptOfRecords: "Transcription des dossiers",
                    externalCertification: "Certification externe",
                    recommendationLetter: "Lettre de recommandation",
                    languageLevel: {
                      title: "Niveau de langue",
                      languageRequired: {
                        title: "Langue requise",
                        placeholder: "Sélectionner une langue"
                      },
                      languageL: {
                        title: "Niveau de langue",
                        placeholder: "Sélectionner un niveau de langue"
                      },
                      languageMoreInfo: "Langue Plus d'informations",
                      delete: "Supprimer la langue",
                      languageCertRequired: "Certification linguistique requise",
                      addLanguage: "Ajouter une langue"
                    },
                    otherDocument: "Autre document",
                    referenceContact: "Contact de référence",
                    portfolio: "Le portefeuille",
                    other: "Autre exigence",
                    addOther: "Ajouter une autre exigence",
                    addDocument: "Ajouter un autre document",
                    deleteRequirement: "Supprimer l'exigence",
                    cancel: "Annuler",
                    saveRequirements: "sauvegarder les exigences"
                  }
                },
                iiaList: {
                  title: "Accords interinstitutionnels",
                  subTitle: "Liste des accords",
                  iiaFeedback: {
                    delete: {
                      loading: "Suppression de l'AII...",
                      success: "Suppression réussie de l'AII",
                      failure: "La suppression de l'AII a échoué"
                    },
                    terminate: {
                      loading: "Terminer AII...",
                      success: "Terminé avec succès AII",
                      failure: "La résiliation de l'AII a échoué"
                    }
                  },
                  modifyIIA: "Modifier AII",
                  editIIA: "Editer AII",
                  deleteIIA: "Supprimer AII",
                  modalDelete: {
                    body: "Après avoir supprimé un AII, vous ne pourrez plus le voir.",
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "supprimer",
                      part3: " un accord interinstitutionnel"
                    },
                    deleteButton: "Supprimer"
                  },
                  modalTerminate: {
                    body: {
                      part1: "L'action que vous vous apprêtez à entreprendre met ",
                      part2: "fin",
                      part3: " à l'accord interinstitutionnel en vigueur et est irréversible. Souhaitez-vous continuer?"
                    },
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "résilier",
                      part3: " un accord interinstitutionnel"
                    },
                    terminateButton: "Terminer"
                  }
                },
                iiaSingleView: {
                  loadingMessage: "Chargement...",
                  errorMessage: "Une erreur s'est produite",
                  iiaFeedback: {
                    approve: {
                      loading: "Sauvegarde des modifications...",
                      success: "Approuvé avec succès par l'AII",
                      failure: "Une erreur s'est produite lors de l'approbation de l'AII: ",
                      error: "Erreur lors de l'enregistrement des modifications."
                    },
                    revert: {
                      loading: "Sauvegarde des modifications...",
                      success: "Réintégration réussie de l'AII",
                      failure: "Une erreur s'est produite lors de la réinitialisation de l'AII: ",
                      error: "Erreur lors de l'enregistrement des modifications."
                    },
                    terminate: {
                      loading: "Terminer AII...",
                      success: "Terminé avec succès AII",
                      failure: "La résiliation de l'AII a échoué"
                    },
                    notifyPartner: {
                      loading: "Notifier le partenaire...",
                      success: "Partenaire notifié avec succès",
                      failure: "Notification de l'échec du partenaire"
                    },
                  },
                  iiaInformation: {
                    title: "INFORMATIONS SUR L'AII",
                    yourId: "VOTRE ID",
                    partnerId: "ID DU PARTENAIRE",
                    partner: "PARTENAIRE",
                    lastEdited: "DERNIÈRE ÉDITION",
                    status: "STATUT",
                    terminatedByYou: "Résiliation par vous",
                    terminatedByPartner: "Résilié par le partenaire",
                    terminationApprovedByYou: "Résiliation approuvée par vous",
                    terminationApprovedByPartner: "Résiliation approuvée par le partenaire",
                    submittedByYou: "Soumis par vous",
                    submittedByPartner: "Soumis par le partenaire",
                    approvedByYou: "Approuvé par vous",
                    approvedByPartner: "Approuvé par le partenaire",
                    terminated: "Terminé",
                    draft: "Projet",
                    terminatedByAll: "Résiliation approuvée par tous",
                    approvedByAll: "Approuvé par tous",
                    invalid: {
                      invalid1: "Cet AII contient des ISCED F-Codes non valides ou des exigences linguistiques manquantes. Les approbations sont toujours valables. Si vous souhaitez mettre fin à cet AII, vous devez le modifier et l'approuver mutuellement.",
                      invalid2: "Cet AII contient des ISCED F-Codes non valides ou des exigences linguistiques manquantes. Veuillez éditer/modifier l'AII."
                    },
                    thirdParty: "Veuillez noter que les données de l'HEI partenaire sont gérées par un fournisseur tiers. Cela signifie que cet AII particulier est représenté par un identifiant différent dans leur système.",
                    exportPdf: "Exporter Pdf"
                  },
                  generalInfo: {
                    title: "Informations générales",
                    institutionData: "LES DONNÉES DE VOTRE INSTITUTION",
                    name: "Nom:",
                    country: "Pays:",
                    schacCode: "Code SCHAC:",
                    erasmusCode: "Code Erasmus:",
                    orgUnit: "Org. Unité:",
                    approvingPerson: "Personne approbatrice:",
                    contactPersons: "Personnes de contact:",
                    signDate: "Date de signature:",
                    partnerInstitutionData: "DONNÉES DE L'INSTITUTION PARTENAIRE",
                  },
                  yourData: {
                    title: "Vos données",
                    calendar: {
                      title: "CALENDRIER",
                      studentNominations: "Les candidatures des étudiants doivent parvenir à l'institution destinataire au plus tard: ",
                      autumnTerm: "trimestre d'automne: ",
                      springTerm: "trimestre de printemps: ",
                      applicationsForNominated: "Les candidatures des étudiants nommés doivent parvenir à l'institution destinataire au plus tard: ",
                      applicationProcedure: "Procédure de candidature pour les étudiants désignés: ",
                      email: "Contact Email: ",
                      phone: "Téléphone de contact: ",
                      websiteForInfo: "Site web pour information: "
                    },
                    additionalRequirements: {
                      title: "EXIGENCES SUPPLÉMENTAIRES",
                      requirements: "EXIGENCE",
                      details: "DÉTAILS",
                      ectsNumber: "NOMBRE D'ECTS",
                      subjectFieldISCED: "CHAMP DU SUJET (ISCED)",
                      studyLevelEQF: "NIVEAU D'ÉTUDE (EQF)",
                      website: "SITE WEB",
                      message: "L'institution envoie sa décision dans un délai de 4 semaines et au plus tard dans un délai de 5 semaines."
                    },
                    inclusionAndAccessibility: {
                      title: "INCLUSION ET ACCESSIBILITÉ",
                      descriptionMain: {
                        part1: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                      },
                      infrastructure: "Infrastructure",
                      name: "NOM",
                      description: "DESCRIPTION",
                      contact: "CONTACT",
                      services: "Services"
                    },
                    housing: {
                      title: "LOGEMENT",
                      description: {
                        part1: "L'établissement guidera les participants mobiles entrants dans la recherche d'un logement, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance:"
                      },
                      contact: "Contact"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "L'établissement fournira une assistance, le cas échéant, pour l'obtention de visas pour les participants mobiles entrants et sortants, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance:"
                      },
                      contact: "Contact"
                    },
                    insurance: {
                      title: "ASSURANCE",
                      description: {
                        part1: "L'établissement fournira une assistance pour l'obtention d'une assurance pour les participants mobiles entrants et sortants, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " L'institution d'accueil informera les participants mobiles des cas dans lesquels la couverture d'assurance n'est pas automatiquement fournie.",
                        part3: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                      },
                      contact: "Contact"
                    },
                    additionalInfo: {
                      title: "INFORMATIONS COMPLÉMENTAIRES",
                      description: {
                        part1: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                      },
                      contact: "CONTACT"
                    },
                    message: {
                      part1: "Un relevé de notes sera délivré par l'institution au plus tard 6 semaines après la fin de la période d'évaluation.",
                      part2: "[Elle ne devrait normalement pas dépasser cinq semaines, conformément aux lignes directrices de la Charte Erasmus pour l'enseignement supérieur.]",
                      part3: "*Après avoir créé le profil de l'établissement d'enseignement supérieur et ajouté les informations générales pertinentes, l'établissement d'enseignement supérieur peut générer des accords interinstitutionnels avec les partenaires sélectionnés."
                    }
                  },
                  partnerData: {
                    title: "Données sur les partenaires",
                    calendar: {
                      title: "CALENDRIER",
                      studentNominations: "Les candidatures des étudiants doivent parvenir à l'institution destinataire au plus tard: ",
                      autumnTerm: "trimestre d'automne: ",
                      springTerm: "trimestre de printemps: ",
                      applicationsForNominated: "Les candidatures des étudiants nommés doivent parvenir à l'institution destinataire au plus tard: ",
                      applicationProcedure: "Procédure de candidature pour les étudiants désignés: ",
                      email: "Contact Email: ",
                      phone: "Téléphone de contact: ",
                      websiteForInfo: "Site web pour information: "
                    },
                    additionalRequirements: {
                      title: "EXIGENCES SUPPLÉMENTAIRES",
                      requirements: "EXIGENCE",
                      details: "DÉTAILS",
                      ectsNumber: "NOMBRE D'ECTS",
                      subjectFieldISCED: "CHAMP DU SUJET (ISCED)",
                      studyLevelEQF: "NIVEAU D'ÉTUDE (EQF)",
                      website: "SITE WEB",
                      message: "L'institution envoie sa décision dans un délai de 4 semaines et au plus tard dans un délai de 5 semaines."
                    },
                    inclusionAndAccessibility: {
                      title: "INCLUSION ET ACCESSIBILITÉ",
                      descriptionMain: {
                        part1: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                      },
                      infrastructure: "Infrastructure",
                      name: "NOM",
                      description: "DESCRIPTION",
                      contact: "CONTACT",
                      services: "Services"
                    },
                    housing: {
                      title: "LOGEMENT",
                      description: {
                        part1: "L'établissement guidera les participants mobiles entrants dans la recherche d'un logement, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance:"
                      },
                      contact: "Contact"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "L'établissement fournira une assistance, le cas échéant, pour l'obtention de visas pour les participants mobiles entrants et sortants, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance:"
                      },
                      contact: "Contact"
                    },
                    insurance: {
                      title: "ASSURANCE",
                      description: {
                        part1: "L'établissement fournira une assistance pour l'obtention d'une assurance pour les participants mobiles entrants et sortants, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " L'institution d'accueil informera les participants mobiles des cas dans lesquels la couverture d'assurance n'est pas automatiquement fournie.",
                        part3: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                      },
                      contact: "Contact"
                    },
                    additionalInfo: {
                      title: "INFORMATIONS COMPLÉMENTAIRES",
                      description: {
                        part1: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                        part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                      },
                      contact: "CONTACT"
                    },
                    message: {
                      part1: "Un relevé de notes sera délivré par l'institution au plus tard 6 semaines après la fin de la période d'évaluation.",
                      part2: "[Elle ne devrait normalement pas dépasser cinq semaines, conformément aux lignes directrices de la Charte Erasmus pour l'enseignement supérieur.]",
                      part3: "*Après avoir créé le profil de l'établissement d'enseignement supérieur et ajouté les informations générales pertinentes, l'établissement d'enseignement supérieur peut générer des accords interinstitutionnels avec les partenaires sélectionnés."
                    }
                  },
                  conditions: {
                    title: "Conditions",
                    header: {
                      part1: "Personnel enseignant:",
                      part2: " / Champs d'études: ",
                      part3: "Formation du personnel:",
                      part4: "Étude des étudiants:",
                      part5: "Stage pour étudiants:"
                    },
                    heis: {
                      sender: "ENVOYEUR",
                      receiver: "RÉCEPTEUR"
                    },
                    conditionDetails: {
                      title: "Détails de l'état ",
                      mobilityType: "TYPE DE MOBILITÉ",
                      duration: "DURÉE",
                      academicYears: "ANNÉES ACADÉMIQUES",
                      spots: "SPOTS",
                      eqfLevel: "Niveau du CEC",
                      staffTeachers: "Personnel enseignant",
                      staffTrainings: "Formation du personnel",
                      otherInfo: "AUTRES INFORMATIONS",
                    },
                    languageRequirements: {
                      title: "Exigences linguistiques ",
                      language: "LANGUE",
                      studyField: "CHAMP D'ÉTUDE",
                      clarification: "CLARIFICATION"
                    },
                    studyFields: {
                      title: "Champs d'études ",
                      studyField: "CHAMP D'ÉTUDE",
                      clarification: "CLARIFICATION"
                    },
                    contacts: {
                      title: "Contacts ",
                      sendingContacts: "ENVOI DE CONTACTS",
                      receivingContacts: "RÉCEPTION DES CONTACTS"
                    }
                  },
                  notifyPartner: {
                    text: "Notifier le partenaire",
                    part1: "Vous pourrez cliquer sur le bouton et notifier à nouveau le partenaire prochainement.",
                    part2: "Notifier le partenaire est désactivé parce qu'il manque des exigences linguistiques ou que les conditions de coopération de l'AII contiennent des ISCED codes de moins de 4 chiffres.",
                    part3: "Informer le partenaire de l'état actuel de l'AII."
                  },
                  terminate: {
                    invalidISCEDormissingLang: "Cet AII ne peut être résilié car il contient des ISCED F-Codes non valides ou des exigences linguistiques manquantes. Veuillez modifier et approuver mutuellement l'AII afin d'y mettre fin.",
                    invalidISCEDormissingLang2: "Cet AII ne peut être approuvé car il contient des ISCED F-Codes non valides ou des exigences linguistiques manquantes. Veuillez éditer/modifier l'AII.",
                    approvedByAll: "Vous ne pouvez mettre fin à un AII que s'il est approuvé par tous.",
                    iiaTerminationApproved: "La résiliation de l'AII est déjà approuvée par tous.",
                    terminateButton: "terminer aii"
                  },
                  revert: {
                    noApproval: "Vous ne pouvez pas annuler l'AII car il n'y a pas encore d'approbation.",
                    approvedByAll: "Il n'est pas possible de revenir sur l'accord AII car il a déjà été approuvé par tous les États membres.",
                    revertButton: "revenir à l'aii"
                  },
                  edit: {
                    cannotEditWhileTerminating: "Vous ne pouvez pas modifier l'AII pendant la procédure de résiliation.",
                    editButton: "edit aii",
                    modifyButton: "modifier aii"
                  },
                  approve: {
                    approveButton: "Approuver",
                    confirm: {
                      part1: "Vous êtes sur le point d'",
                      part2: "approuver",
                      part3Terminate: " la résiliation d'un accord interinstitutionnel.",
                      part3Approve: " un accord interinstitutionnel"
                    }
                  },
                  modalTerminate: {
                    body: "Après avoir mis fin à l'AII, votre partenaire en sera informé. La dernière approbation reste valable et vous pouvez revenir à l'état antérieur à tout moment jusqu'à ce que vous approuviez tous les deux la résiliation de l'AII.",
                    title: {
                      part1: "Vous êtes sur le point de ",
                      part2: "résilier",
                      part3: " un accord interinstitutionnel"
                    },
                    terminateButton: "Terminer"
                  },
                  modalRevert: {
                    title: "Vous êtes sur le point de rétablir l'accord interinstitutionnel tel qu'il a été approuvé par tous les États.",
                    revertButton: "Revenir en arrière"
                  },
                  modalModify: {
                    body: "Après avoir modifié l'AII, votre partenaire sera informé de vos changements. La dernière approbation reste valable et vous pouvez revenir à l'état antérieur à tout moment jusqu'à ce que vous approuviez à nouveau tous les deux l'AII.",
                    title: "Vous êtes sur le point de modifier et d'apporter des changements à l'accord interinstitutionnel.",
                    modifyButton: "Modifier"
                  },
                  iiaApprovedByAll: "L'AII est déjà approuvé par tous ou le partenaire n'a pas partagé son identifiant local de l'AII.",
                  approveTermination: "approuver la résiliation",
                  approveIIA: "approuver aii"
                },
                iiasEdit: {
                  title: "Accords interinstitutionnels",
                  subTitle: "Modifier un AII",
                  createNewSubTitle: "Créer un nouvel AII",
                  createTab: "Créer l'AII",
                  tab: "Editer AII",
                  steps: {
                    yourDetails: "VOS DÉTAILS",
                    partnerDetails: "COORDONNÉES DU PARTENAIRE",
                    conditions: "CONDITIONS",
                    overviewAndSubmit: "VUE D'ENSEMBLE ET SOUMISSION"
                  },
                  alerts: {
                    part1: "Des ISCED F-Codes non valides sont détectés dans les conditions de coopération. Veuillez corriger les codes F de la ISCED F-Codes qui contiennent moins de 4 chiffres en sélectionnant le code approprié dans la liste déroulante des ISCED F-Codes.",
                    part2: "Vous devez remplir au moins une langue d'enseignement dans les conditions de coopération des études pour continuer.",
                    part3: "Vous devez remplir au moins une langue d'enseignement dans les conditions de coopération avec les enseignants du personnel pour continuer.",
                    part4: "L'établissement d'enseignement supérieur que vous avez sélectionné n'est pas encore prêt à échanger des accords interinstitutionnels via le réseau Erasmus sans papier.",
                    missingName: "Vous devez indiquer le nom du contact pour continuer",
                    invalidEmail: "Vous devez indiquer une adresse électronique valide pour continuer",
                  },
                  iiaFeedback: {
                    saving: {
                      loading: "Sauvegarde des modifications...",
                      success: {
                        savedDraft: "Sauvegardé avec succès en tant que projet AII",
                        submit: "Soumission réussie à l'AII",
                        updateDraft: "Mise à jour réussie du projet d'AII",
                        update: "Mise à jour avec succès AII"
                      },
                      failure: "Erreur lors de l'enregistrement des modifications."
                    },
                  },
                  errors: {
                    part1: "Vous devez sélectionner un partenaire SCHAC pour continuer",
                    part2: "Vous devez ajouter au moins une condition de coopération pour continuer."
                  },
                  next: "Suivant",
                  back: "Retour",
                  saveAsDraft: "ENREGISTRER EN TANT QUE PROJET",
                  submit: "Soumettre",
                  firstStep: {
                    header: "VOS DÉTAILS",
                    institutionData: {
                      title: "Les données de votre institution",
                      name: "Nom: ",
                      country: "Pays: ",
                      schacCode: "Code SCHAC: ",
                      erasmusCode: "Code Erasmus: ",
                      orgUnit: "Unité d'organisation",
                      placeholder: "Sélectionner"
                    },
                    contactPerson: {
                      title: "Personnes de contact",
                      name: {
                        title: "Nom ",
                        placeholder: "Saisir le nom.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Saisir l'email..",
                        error: "Veuillez fournir une adresse électronique valide."
                      },
                      phone: {
                        title: "Téléphone",
                        placeholder: "Saisir le numéro de téléphone..",
                      },
                      deleteButton: "Supprimer un contact",
                      addContactPerson: "ajouter une personne de contact"
                    }
                  },
                  secondStep: {
                    header: "COORDONNÉES DU PARTENAIRE",
                    institutionData: {
                      title: "Données sur les institutions partenaires",
                      institution: {
                        title: "Institution ",
                        loading: "Chargement...",
                        choose: "Choisir une institution...",
                        error: "Veuillez sélectionner votre institution."
                      },
                      schac: {
                        title: "Code SCHAC",
                        loading: "Chargement...",
                        choose: "Choisissez le code SCHAC...",
                        error: "Veuillez sélectionner votre code SCHAC."
                      },
                      country: "Pays: ",
                      schacCode: "Code SCHAC: ",
                      erasmusCode: "Code Erasmus: ",
                      orgUnit: "Unité d'organisation",
                      placeholder: "Sélectionner"
                    },
                    erasmusCode: {
                      title: "Code Erasmus ",
                      loading: "Chargement...",
                      choose: "Choisir le code Erasmus...",
                      error: "Veuillez sélectionner votre code Erasmus."
                    },
                    orgUnit: {
                      title: "Unité d'organisation",
                      loading: "Chargement...",
                      choose: "Choisir OUnit...",
                      error: "Veuillez sélectionner votre OUnit."
                    },
                    contactPersons: {
                      title: "Personnes de contact",
                      name: {
                        title: "Nom ",
                        placeholder: "Saisir le nom.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Saisir l'email..",
                        error: "Veuillez fournir une adresse électronique valide."
                      },
                      phone: {
                        title: "Téléphone",
                        placeholder: "Saisir le numéro de téléphone..",
                      },
                      deleteButton: "Supprimer un contact",
                      addContactPerson: "ajouter une personne de contact"
                    }
                  },
                  thirdStep: {
                    mobilityTypes: {
                      studentStudies: "Études des étudiants",
                      studentTraineeships: "Stages pour étudiants",
                      staffTeachers: "Personnel enseignant",
                      staffTrainings: "Formation du personnel"
                    },
                    blendedMobility: {
                      yes: "Oui",
                      no: "Non"
                    },
                    eqfLevels: {
                      lvl5: "Niveau 5 du EQF - Associate Degree",
                      lvl6: "Niveau 6 du EQF - Licence",
                      lvl7: "Niveau 7 du EQF - Master",
                      lvl8: "Niveau 8 du EQF - Doctorat",
                    },
                    typeOfCoopCondition: {
                      fillAllFields: "Veuillez remplir tous les champs obligatoires",
                      validEmailSending: "Veuillez indiquer une adresse électronique valide lors de l'envoi des contacts",
                      validEmailReceiving: "Veuillez indiquer une adresse électronique valide lors de la réception des contacts",
                      invalidISCED: "Des ISCED F-Codes non valides sont détectés dans la condition de coopération. Veuillez corriger les ISCED F-Codes qui contiennent moins de 4 chiffres en sélectionnant le code approprié dans la liste déroulante des ISCED F-Code"
                    },
                    cooperationConditions: {
                      header: "CONDITIONS DE COOPÉRATION",
                      staffTeacher: "Personnel enseignant",
                      studyFields: "Champs d'études",
                      staffTraining: "Formation du personnel",
                      studentStudy: "Étude des étudiants",
                      studentTraineeship: "Stage pour étudiants"
                    },
                    daysPerYear: " jours par an",
                    monthsPerYear: " mois par an",
                    heis: {
                      sender: "ENVOYEUR",
                      receiver: "RÉCEPTEUR"
                    },
                    conditionDetails: {
                      header: "Détails de l'état",
                      mobilityType: "TYPE DE MOBILITÉ",
                      duration: "DURÉE",
                      academicYears: "ANNÉES ACADÉMIQUES",
                      eqfLevel: "Niveau du EQF",
                      spots: "SPOTS"
                    },
                    otherInfo: "AUTRES INFORMATIONS",
                    languageRequirements: {
                      header: "Exigences linguistiques",
                      language: "LANGUE",
                      studyField: "CHAMP D'ÉTUDE",
                      clarification: "CLARIFICATION"
                    },
                    studyFields: {
                      header: "Champs d'études",
                      studyField: "CHAMP D'ÉTUDE",
                      clarification: "CLARIFICATION"
                    },
                    contacts: {
                      header: "Contacts",
                      sendingContacts: "ENVOI DE CONTACTS",
                      receivingContacts: "RÉCEPTION DES CONTACTS"
                    },
                    editConditionButton: "modifier la condition",
                    deleteCoopConditionButton: "Supprimer la condition Coop",
                    addNewConditionButton: "ajouter une nouvelle condition",
                    modal: {
                      title: "Nombre de mobilités par année académique",
                      subTitle: "Les partenaires s'engagent à modifier le tableau ci-dessous en cas de changements dans les données relatives à la mobilité, au plus tard à la fin du mois de septembre de l'année académique précédente. ",
                      schacCode: {
                        sender: {
                          title: "Code SCHAC de l'expéditeur",
                          placeholder: "Sélectionnez SCHAC..."
                        },
                        receiver: {
                          title: "Récepteur Code SCHAC",
                          placeholder: "Sélectionnez SCHAC..."
                        }
                      },
                      orgUnit: {
                        sender: {
                          title: "Unité organisationnelle de l'expéditeur",
                          placeholder: "Sélectionner l'unité organisationnelle..."
                        },
                        receiver: {
                          title: "Unité organisationnelle du récepteur",
                          placeholder: "Sélectionner l'unité organisationnelle..."
                        }
                      },
                      mobilitiesPerYear: {
                        title: "Mobilités par an",
                        description: "Nombre maximum de personnes à envoyer chaque année académique"
                      },
                      academicYear: {
                        start: {
                          title: "Début de l'année académique",
                          placeholder: "Choisissez Commencer l'année académique...",
                          error: "Veuillez sélectionner votre code SCHAC."
                        },
                        end: {
                          title: "Fin de l'année académique",
                          placeholder: "Choisir Fin de l'année académique...",
                          error: "Veuillez sélectionner votre code SCHAC."
                        }
                      },
                      subjectArea: {
                        header: "Domaine d'activité",
                        fCode: {
                          title: "ISCED F-Code",
                          placeholder: "Sélectionnez le code ISCED"
                        },
                        clarification: {
                          title: "Clarification de la ISCED",
                          placeholder: "Saisir le texte..."
                        },
                        deleteButton: "Supprimer un domaine",
                        addSubjectArea: "ajouter un domaine"
                      },
                      mobilityType: {
                        header: "Type de mobilité",
                        description: "Les partenaires s'engagent à modifier le tableau ci-dessous en cas de changements dans les données relatives à la mobilité, au plus tard à la fin du mois de septembre de l'année académique précédente.",
                        duration: {
                          title: "La durée",
                          totalMonths: " (nombre total de mois par an)",
                          totalDays: " (nombre total de jours par an)",
                          fill: {
                            allStudents: "Indiquez la somme des mois pour tous les étudiants (par exemple, 10 étudiants effectuant une mobilité de 6 mois donnent une durée de 60 mois).",
                            staff: "Remplir la somme des mois pour le personnel (par exemple, 4 membres du personnel en mobilité de 20 jours donnent une durée de 80 jours)."
                          }
                        },
                        studyCycle: {
                          header: "Cycle d'étude",
                          placeholder: "Sélectionnez un ou plusieurs..."
                        },
                        blendedMobility: {
                          header: "Option de mobilité mixte",
                          confirmation: "*En choisissant « Oui » dans l'option de mobilité mixte, les partenaires confirment qu'ils sont prêts à échanger des étudiants qui souhaitent effectuer leur mobilité dans un format mixte, une combinaison d'une mobilité physique à courte durée et d'une composante virtuelle. "
                        }
                      },
                      languageSkills: {
                        header: "Compétences linguistiques recommandées",
                        description: "L'établissement d'envoi, après accord avec l'établissement d'accueil, est chargé de soutenir les candidats qu'il a désignés afin qu'ils puissent avoir les compétences linguistiques recommandées au début de la période d'études ou d'enseignement (Mobilité des étudiants pour les études - Niveau minimum recommandé : B1) :",
                        languageOfInstruction: {
                          title: "Langue d'enseignement",
                          placeholder: "Sélectionner une langue"
                        },
                        level: {
                          title: "Langue d'enseignement Niveau",
                          placeholder: "Sélectionner un niveau de langue"
                        },
                        fCode: {
                          title: "ISCED F-Code",
                          placeholder: "Sélectionnez le code ISCED"
                        },
                        clarification: {
                          title: "Clarification de la ISCED",
                          placeholder: "Saisir le texte..."
                        },
                        deleteButton: "Supprimer la langue",
                        addLanguage: "ajouter LANGUE"
                      },
                      contact: {
                        sending: {
                          header: "Personnes de contact pour l'envoi",
                          description: "Veuillez indiquer les coordonnées des personnes de contact pour l'envoi.",
                          addContact: "ajouter une personne de contact pour l'envoi"
                        },
                        receiving: {
                          header: "Personnes de contact pour la réception",
                          description: "Veuillez indiquer les coordonnées des personnes de contact destinataires.",
                          addContact: "ajouter une personne de contact destinataire"
                        },
                        name: {
                          title: "Nom",
                          placeholder: "Entrer le nom..."
                        },
                        email: {
                          title: "Email",
                          placeholder: "Saisir l'email..",
                          error: "Veuillez fournir une adresse électronique valide."
                        },
                        phone: {
                          title: "Téléphone",
                          placeholder: "Saisir le numéro de téléphone..."
                        },
                        deleteContact: "Supprimer un contact"
                      },
                      otherInfo: {
                        header: "Autres informations",
                        description: "Veuillez indiquer toute autre information concernant les termes de l'accord (5000 caractères maximum)",
                        placeholder: "Saisir d'autres informations..."
                      },
                      addCondition: "ajouter cette condition",
                      addNewCondition: "Ajouter une nouvelle condition de coopération",
                      fillNew: "Remplissez les champs suivants pour ajouter une nouvelle condition de coopération ",
                      saveChanges: "enregistrer les modifications de conditions",
                      editCondition: "Modifier la condition de coopération",
                      fillEdit: "Remplissez les champs suivants pour modifier la condition de coopération"
                    }
                  },
                  forthStep: {
                    overview: {
                      header: "VUE D'ENSEMBLE ET SOUMISSION",
                      staticInfo: {
                        header: "Informations statiques applicables à tous les accords interinstitutionnels",
                        body: "Les établissements conviennent de coopérer pour l'échange d'étudiants et/ou de personnel dans le cadre du programme Erasmus. Ils s'engagent à respecter les exigences de qualité de la Charte Erasmus pour l'enseignement supérieur dans tous les aspects liés à l'organisation et à la gestion de la mobilité, en particulier la reconnaissance automatique des crédits accordés aux étudiants par l'établissement partenaire, comme convenu dans le contrat d'études et confirmé dans le relevé de notes, ou en fonction des acquis d'apprentissage des modules suivis à l'étranger, comme décrit dans le catalogue des cours, conformément au système européen de crédits et d'accumulation. Les établissements conviennent d'échanger leurs données relatives à la mobilité conformément aux normes techniques de l'initiative relative à la carte d'étudiant européenne.",
                      },
                      gradingSystem: {
                        header: "Systèmes de notation des institutions",
                        body: "Il est recommandé aux établissements d'accueil de fournir un lien vers la distribution statistique des notes ou de rendre les informations disponibles via EGRACONS conformément aux descriptions figurant dans le guide de l'utilisateur de l'ECTS. Ces informations faciliteront l'interprétation de chaque note attribuée aux étudiants et le transfert de crédits par l'établissement d'origine."
                      }
                    },
                    generalInfo: {
                      header: "Informations générales",
                      institutionData: {
                        header: "LES DONNÉES DE VOTRE INSTITUTION",
                        headerPartner: "DONNÉES DE L'INSTITUTION PARTENAIRE",
                        name: "Nom",
                        country: "Pays",
                        schacCode: "Code SCHAC",
                        erasmusCode: "Code Erasmus",
                        orgUnit: "Org. Unit",
                        approvingPerson: "Personne approbatrice",
                        contactPerson: "Personnes de contact"
                      }
                    },
                    factSheet: {
                      yourFactSheet: {
                        header: "Données de votre fiche d'information",
                        calendar: {
                          title: "CALENDRIER",
                          studentNominations: "Les candidatures des étudiants doivent parvenir à l'institution destinataire au plus tard : ",
                          autumnTerm: "trimestre d'automne : ",
                          springTerm: "trimestre de printemps : ",
                          applicationsForNominated: "Les candidatures des étudiants nommés doivent parvenir à l'institution destinataire au plus tard : ",
                          applicationProcedure: "Procédure de candidature pour les étudiants désignés : ",
                          email: "Courriel de contact : ",
                          phone: "Téléphone de contact : ",
                          websiteForInfo: "Site web pour information : "
                        },
                        additionalRequirements: {
                          title: "EXIGENCES SUPPLÉMENTAIRES",
                          requirements: "EXIGENCE",
                          details: "DÉTAILS",
                          ectsNumber: "NOMBRE D'ECTS",
                          subjectFieldISCED: "CHAMP DU SUJET (ISCED)",
                          studyLevelEQF: "NIVEAU D'ÉTUDE (EQF)",
                          website: "WEBSITE",
                          message: "L'institution envoie sa décision dans un délai de 4 semaines et au plus tard dans un délai de 5 semaines."
                        },
                        inclusionAndAccessibility: {
                          title: "INCLUSION ET ACCESSIBILITÉ",
                          descriptionMain: {
                            part1: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          infrastructure: "Infrastructure",
                          name: "NOM",
                          description: "DESCRIPTION",
                          contact: "CONTACT",
                          services: "Services"
                        },
                        housing: {
                          title: "LOGEMENT",
                          description: {
                            part1: "L'établissement guidera les participants mobiles entrants dans la recherche d'un logement, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          contact: "Contact"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "L'établissement fournira une assistance, le cas échéant, pour l'obtention de visas pour les participants mobiles entrants et sortants, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          contact: "Contact"
                        },
                        insurance: {
                          title: "ASSURANCE",
                          description: {
                            part1: "L'établissement fournira une assistance pour l'obtention d'une assurance pour les participants mobiles entrants et sortants, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " L'institution d'accueil informera les participants mobiles des cas dans lesquels la couverture d'assurance n'est pas automatiquement fournie.",
                            part3: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          contact: "Contact"
                        },
                        additionalInfo: {
                          title: "INFORMATIONS COMPLÉMENTAIRES",
                          description: {
                            part1: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          contact: "CONTACT"
                        }
                      },
                      partnerFactSheet: {
                        header: "Fiche d'information sur les partenaires",
                        calendar: {
                          title: "CALENDRIER",
                          studentNominations: "Les candidatures des étudiants doivent parvenir à l'institution destinataire au plus tard : ",
                          autumnTerm: "trimestre d'automne : ",
                          springTerm: "trimestre de printemps : ",
                          applicationsForNominated: "Les candidatures des étudiants nommés doivent parvenir à l'institution destinataire au plus tard : ",
                          applicationProcedure: "Procédure de candidature pour les étudiants désignés : ",
                          email: "Courriel de contact : ",
                          phone: "Téléphone de contact : ",
                          websiteForInfo: "Site web pour information : "
                        },
                        additionalRequirements: {
                          title: "EXIGENCES SUPPLÉMENTAIRES",
                          requirements: "EXIGENCE",
                          details: "DÉTAILS",
                          ectsNumber: "NOMBRE D'ECTS",
                          subjectFieldISCED: "CHAMP DU SUJET (ISCED)",
                          studyLevelEQF: "NIVEAU D'ÉTUDE (EQF)",
                          website: "WEBSITE",
                          message: "L'institution envoie sa décision dans un délai de 4 semaines et au plus tard dans un délai de 5 semaines."
                        },
                        inclusionAndAccessibility: {
                          title: "INCLUSION ET ACCESSIBILITÉ",
                          descriptionMain: {
                            part1: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          infrastructure: "Infrastructure",
                          name: "NOM",
                          description: "DESCRIPTION",
                          contact: "CONTACT",
                          services: "Services"
                        },
                        housing: {
                          title: "LOGEMENT",
                          description: {
                            part1: "L'établissement guidera les participants mobiles entrants dans la recherche d'un logement, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          contact: "Contact"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "L'établissement fournira une assistance, le cas échéant, pour l'obtention de visas pour les participants mobiles entrants et sortants, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          contact: "Contact"
                        },
                        insurance: {
                          title: "ASSURANCE",
                          description: {
                            part1: "L'établissement fournira une assistance pour l'obtention d'une assurance pour les participants mobiles entrants et sortants, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " L'institution d'accueil informera les participants mobiles des cas dans lesquels la couverture d'assurance n'est pas automatiquement fournie.",
                            part3: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          contact: "Contact"
                        },
                        additionalInfo: {
                          title: "INFORMATIONS COMPLÉMENTAIRES",
                          description: {
                            part1: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la charte Erasmus pour l'enseignement supérieur.",
                            part2: " Les points de contact et les sources d'information suivants peuvent fournir des informations et une assistance :"
                          },
                          contact: "CONTACT"
                        }
                      }
                    },
                    cooperationConditions: {
                      header: "Conditions de coopération",
                      blendedMobility: {
                        yes: "Oui",
                        no: "Non"
                      },
                      eqfLevels: {
                        lvl5: "Niveau 5 du EQF - Associate Degree",
                        lvl6: "Niveau 6 du EQF - Licence",
                        lvl7: "Niveau 7 du EQF - Master",
                        lvl8: "Niveau 8 du EQF - Doctorat",
                      },
                      typeOfCoopCondition: {
                        fillAllFields: "Veuillez remplir tous les champs obligatoires",
                        validEmailSending: "Veuillez indiquer une adresse électronique valide lors de l'envoi des contacts",
                        validEmailReceiving: "Veuillez indiquer une adresse électronique valide lors de la réception des contacts",
                        invalidISCED: "Des ISCED F-Codes non valides sont détectés dans la condition de coopération. Veuillez corriger les ISCED F-Codes qui contiennent moins de 4 chiffres en sélectionnant le code approprié dans la liste déroulante des ISCED F-Code.",
                      },
                      cooperationConditions: {
                        header: "CONDITIONS DE COOPÉRATION",
                        staffTeacher: "Personnel enseignant",
                        staffTeachers: "Personnel enseignant",
                        studyFields: "Champs d'études",
                        staffTraining: "Formation du personnel",
                        staffTrainings: "Formation du personnel",
                        studentStudy: "Étude des étudiants",
                        studentTraineeship: "Stage pour étudiants"
                      },
                      daysPerYear: " jours par an",
                      monthsPerYear: " mois par an",
                      heis: {
                        sender: "ENVOYEUR",
                        receiver: "RÉCEPTEUR"
                      },
                      conditionDetails: {
                        header: "Détails de l'état",
                        mobilityType: "TYPE DE MOBILITÉ",
                        duration: "DURÉE",
                        academicYears: "ANNÉES ACADÉMIQUES",
                        eqfLevel: "Niveau du EQF",
                        spots: "SPOTS"
                      },
                      otherInfo: "AUTRES INFORMATIONS",
                      languageRequirements: {
                        header: "Exigences linguistiques",
                        language: "LANGUE",
                        studyField: "CHAMP D'ÉTUDE",
                        clarification: "CLARIFICATION"
                      },
                      studyFields: {
                        header: "Champs d'études",
                        studyField: "CHAMP D'ÉTUDE",
                        clarification: "CLARIFICATION"
                      },
                      contacts: {
                        header: "Contacts",
                        sendingContacts: "ENVOI DE CONTACTS",
                        receivingContacts: "RÉCEPTION DES CONTACTS"
                      }
                    },
                    confirmation: "Je confirme, également au nom de mon HEI, que je dispose de toutes les autorisations, y compris le consentement des personnes physiques concernées, nécessaires pour télécharger les données à caractère personnel et les informations que je fournis, conformément aux conditions générales du tableau de bord et à la politique de confidentialité.",
                    confirmationCheck: "Vous reconnaissez avoir pris connaissance de la politique de confidentialité et des conditions générales."
                  }
                }, 
                iiaImport: {
                  subTitle: "Import des AII",
                  alerts: {
                    incorrectFileType: "Désolé, mais le fichier sélectionné n'a pas l'extension correcte. Veuillez sélectionner un fichier .csv.",
                    invalidTemplate: "Désolé, mais le modèle utilisé n'est pas valide. Vous pouvez obtenir le modèle officiel sur la page Import AII.",
                    errorMessage: { // fix sentence structure
                      part1: "Actuellement, l'importation d'accords interinstitutionnels n'accepte que jusqu'à ",
                      part2: " accords, et le fichier que vous venez de sélectionner ",
                      part3: "contient un total de ",
                      part4: " accords, ",
                      part5: "donc",
                      part6: "de sorte que les ",
                      part7: " derniers accords valides seront rejetés",
                      message1: "avec ",
                      message2: "ces lignes non valides et ",
                      emptyRows: " lignes vides, ",
                      wrongPartnerEC: " accords avec un mauvais PartnerEC (les partenaires ne peuvent pas avoir le même code Erasmus que vous ou un code Erasmus non valide), "
                    },
                    errorMessage2: {
                      message:  "Cette erreur peut être causée par l'un des éléments suivants:\n" +
                                " - Toutes les lignes du fichier téléchargé sont vides ou non valides;\n" +
                                " - Il n'y a pas de CE partenaire;" +
                                " - Vous avez utilisé votre propre CE comme CE partenaire.\n" +
                                "Veuillez vérifier votre fichier. Si ces erreurs ne sont pas corrigées, il n'est pas possible de vérifier les autres champs."
                    },
                  },
                  header: "Télécharger un fichier CSV pour importer les accords interinstitutionnels",
                  loadingMessage: "Cela peut prendre un certain temps, mais ne fermez pas cette page !",
                  returnToIIAListing: "Retour à la liste de l'AII",
                  returnToUploadPage: "Retour à la page de téléchargement",
                  description: "La fonctionnalité d'importation d'accords interinstitutionnels prend désormais en charge le téléchargement de fichiers CSV. Une fois les données saisies, vous pouvez examiner les accords un par un pour vous assurer que toutes les informations sont correctes et soumettre les données. Cette action déclenchera la création d'un accord interinstitutionnel prérempli et une notification au partenaire l'invitant à modifier ou à signer le document. Il est également possible de soumettre tous les accords en même temps en cliquant sur le bouton « Importer tout ».",
                  verifyFormat: "Le format du fichier CSV peut être vérifié à l'aide d'un modèle de feuille de calcul disponible sur le CC EWP.  ",
                  here: "à l'adresse suivante",
                  confirmation: "Je confirme, également au nom de mon HEI, que je dispose de toutes les autorisations, y compris le consentement des personnes physiques concernées, nécessaires pour télécharger les données à caractère personnel et les informations que je fournis, conformément aux conditions générales du tableau de bord et à la politique de confidentialité.                        ",
                  consentError: "Veuillez donner votre accord sur le traitement des données",
                  uploadButton: "Télécharger",
                  importConfirmation: "Êtes-vous sûr de vouloir importer tous les accords ?"
                },
                iiaExport: {
                  subTitle: "AII à l'exportation",
                  header: "Télécharger le fichier CSV pour exporter les accords interinstitutionnels",
                  description: "La fonctionnalité d'exportation de l'accord interinstitutionnel prend désormais en charge le téléchargement de fichiers CSV. Le fichier d'exportation CSV pour votre institution est créé de manière asynchrone et périodique. Le dernier fichier d'exportation CSV peut être téléchargé ci-dessous.",
                  message: {
                    part1: "Le fichier d'exportation CSV a été créé avec succès le",
                    part2: " et peut être téléchargé"
                  },
                  downloadButton: "Télécharger"
                },
                editFactsheet: {
                  subTitle: "Editer la fiche d'information",
                  loadingMessage: "Chargement...",
                  errorMessage: "Une erreur s'est produite",
                  factsheetFeedBack: {
                    update: {
                      success: "Les données de la fiche d'information ont été mises à jour avec succès.",
                      failure: "Une erreur s'est produite lors de la mise à jour des données de la fiche d'information.",
                      alert: "Une erreur s'est produite lors de la mise à jour des données de la fiche d'information : "
                    }
                  },
                  calendar: {
                    header: "CALENDRIER",
                    studentNominations: "Les candidatures des étudiants doivent parvenir à l'institution destinataire au plus tard :",
                    autumnTerm: "Période d'automne Date ",
                    springTerm: "Date du trimestre de printemps ",
                    invalidDateFormat: "Format de date non valide",
                    applicationsForNominated: "Les candidatures des étudiants nommés doivent parvenir à l'institution destinataire au plus tard :",
                    applicationProcedure: "Procédure de candidature pour les étudiants désignés :",
                    email: {
                      title: "Contact Email ",
                      error: "Non valide"
                    },
                    phone: {
                      title: "Téléphone de contact ",
                      error: " Veuillez saisir un numéro de téléphone commençant par l'indicatif de votre pays (ex : +000)."
                    },
                    websiteForInfo: {
                      title: "Site web pour information ",
                      error: "Veuillez saisir une URL qui commence par http ou https."
                    },
                    defaultWeekValues: "Valeurs par défaut des semaines :",
                    weeksForDecision: {
                      title: "Semaines pour la décision sur les exigences supplémentaires ",
                      error: "Non valide"
                    },
                    weeksForTranscript: {
                      title: "Semaines pour la question de la transcription des dossiers  ",
                      error: "Non valide"
                    }
                  },
                  additionalRequirements: {
                    title: "EXIGENCES SUPPLÉMENTAIRES",
                    requirement: {
                      title: "Exigence ",
                      placeholder: "Sélectionner une exigence"
                    },
                    details: {
                      title: "Détails",
                    },
                    ectsNumber: {
                      title: "Nombre d'ECTS obtenus",
                    },
                    studyFieldISCED: {
                      title: "Domaine d'étude (ISCED)",
                      placeholder: "Sélectionner la ISCED"
                    },
                    studyLevelEQF: {
                      title: "Niveau d'étude (EQF)",
                      placeholder: "Sélectionner EQF"
                    },
                    website: {
                      title: "Site web pour information",
                      error: "Veuillez saisir une URL qui commence par http ou https."
                    },
                    deleteButton: "Supprimer l'exigence supplémentaire",
                    addRequirement: "Ajouter une exigence supplémentaire"
                  },
                  inclusionAndAccessibility: {
                    title: "INCLUSION ET ACCESSIBILITÉ",
                    descriptionMain: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la Charte Erasmus pour l'enseignement supérieur. Les informations et l'assistance peuvent être fournies par les points de contact et les sources d'information suivants :",
                    infrastructure: "Infrastructure",
                    name: "Nom ",
                    description: "Description",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Non valide"
                    },
                    contactPhone: {
                      title: "Téléphone de contact ",
                      error: "Veuillez saisir un numéro de téléphone commençant par l'indicatif de votre pays (ex : +000)."
                    },
                    website: {
                      title: "Site web pour information ",
                      error: "Veuillez saisir une URL qui commence par http ou https."
                    },
                    addInfrastucture: "Ajouter une infrastructure",
                    services: "Services",
                    addService: "Ajouter un service"
                  },
                  deleteInfrastructure: "Supprimer l'infrastructure",
                  deleteService: "Supprimer le service",
                  housing: {
                    title: "LOGEMENT",
                    description: "L'établissement guidera les participants mobiles entrants dans la recherche d'un logement, conformément aux exigences de la Charte Erasmus pour l'enseignement supérieur. Les informations et l'assistance peuvent être fournies par les points de contact et les sources d'information suivants :",
                    contact: "Contact",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Non valide"
                    },
                    contactPhone: {
                      title: "Téléphone de contact ",
                      error: "Veuillez saisir un numéro de téléphone commençant par l'indicatif de votre pays (ex : +000)."
                    },
                    website: {
                      title: "Site web pour information ",
                      error: "Veuillez saisir une URL qui commence par http ou https."
                    }
                  },
                  visa: {
                    title: "VISA",
                    description: "L'établissement fournira une assistance, le cas échéant, pour l'obtention de visas pour les participants mobiles entrants et sortants, conformément aux exigences de la Charte Erasmus pour l'enseignement supérieur. Les informations et l'assistance peuvent être fournies par les points de contact et les sources d'information suivants :",
                    contact: "Contact",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Non valide"
                    },
                    contactPhone: {
                      title: "Téléphone de contact ",
                      error: "Veuillez saisir un numéro de téléphone commençant par l'indicatif de votre pays (ex : +000)."
                    },
                    website: {
                      title: "Site web pour information ",
                      error: "Veuillez saisir une URL qui commence par http ou https."
                    }
                  },
                  insurance: {
                    title: "ASSURANCE",
                    description: "L'établissement fournira une assistance pour l'obtention d'une assurance pour les participants mobiles entrants et sortants, conformément aux exigences de la Charte Erasmus pour l'enseignement supérieur. L'établissement d'accueil informera les participants mobiles des cas dans lesquels la couverture d'assurance n'est pas automatiquement fournie. Les informations et l'assistance peuvent être fournies par les points de contact et les sources d'information suivants :",
                    contact: "Contact",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Non valide"
                    },
                    contactPhone: {
                      title: "Téléphone de contact ",
                      error: "Veuillez saisir un numéro de téléphone commençant par l'indicatif de votre pays (ex : +000)."
                    },
                    website: {
                      title: "Site web pour information ",
                      error: "Veuillez saisir une URL qui commence par http ou https."
                    }
                  },
                  additionalInfo: {
                    title: "INFORMATIONS COMPLÉMENTAIRES",
                    description: "L'établissement fournira un soutien aux participants mobiles entrants ayant des besoins particuliers, conformément aux exigences de la Charte Erasmus pour l'enseignement supérieur. Les informations et l'assistance peuvent être fournies par les points de contact et les sources d'information suivants :",
                    recognitionProcess: "Processus de reconnaissance",
                    contact: "CONTACT",
                    contactEmail: {
                      title: "Contact Email ",
                      error: "Non valide"
                    },
                    contactPhone: {
                      title: "Téléphone de contact ",
                      error: "Veuillez saisir un numéro de téléphone commençant par l'indicatif de votre pays (ex : +000)."
                    },
                    website: {
                      title: "Site web pour information ",
                      error: "Veuillez saisir une URL qui commence par http ou https."
                    },
                    deleteRecognitionProcess: "Supprimer le processus de reconnaissance",
                    addRecognitionProcess: "Ajouter des informations sur le processus de reconnaissance",
                    otherInfo: "Autres informations utiles",
                    deleteInfo: "Supprimer d'autres informations",
                    addInfo: "Ajouter d'autres informations utiles"
                  },
                  saveFactsheet: "sauvegarder les données de la fiche d'information",
                  containsErrors: "Le formulaire contient des erreurs",
                  confirmation: "Je confirme, également au nom de mon HEI, que je dispose de toutes les autorisations, y compris le consentement des personnes physiques concernées, nécessaires pour télécharger les données à caractère personnel et les informations que je fournis, conformément aux conditions générales du tableau de bord et à la politique de confidentialité.",
                  confirmationError: "Veuillez donner votre accord sur le traitement des données",
                  saveConfirmation: "Vous êtes sur le point de modifier les données de votre fiche d'information"
                },
                filter: {
                  title: "Filtres et tris",
                  clearButton: "Tout effacer",
                  applyButton: "Appliquer les filtres",
                  academicTerms: {
                    firstSemester: "Premier semestre (hiver/automne)",
                    secondSemester: "Deuxième semestre (été/printemps)",
                    firstTrimester: "Premier trimestre",
                    secondTrimester: "Deuxième trimestre",
                    fullYear: "Année académique complète",
                  },
                  sortingList: {
                    status: "Tri par statut",
                    lastChangedAscending: "Classement par ordre croissant de la dernière modification",
                    lastChangedDescending: "Tri par dernière modification, ordre décroissant",
                    name: "Tri par nom"
                  },
                  pendingRequests: {
                    pending: "En attente",
                    accepted: "Accepté",
                    declined: "Refusé"
                  },
                  sortBy: {
                    title: "Trier par",
                    placeholder: "Sélectionner le tri par..."
                  },
                  status: {
                    title: "Statut",
                    placeholder: "Sélectionner l'état..."
                  },
                  iia: {
                    title: "AII ID",
                    placeholder: "AII ID",
                    label: "Recherche par votre ID ou l'ID du partenaire :"
                  },
                  academicYear: {
                    title: "Année académique",
                    placeholder: "Sélectionnez l'année académique..."
                  },
                  academicTerm: {
                    title: "Période académique",
                    placeholder: "Sélectionnez l'année académique..."
                  },
                  country: {
                    title: "Pays",
                    placeholder: "Sélectionnez un ou plusieurs pays..."
                  },
                  institution: {
                    title: "Institution",
                    erasmusCode: "Code Erasmus:",
                    esasmusCodePlaceholder: "Sélectionnez le code Erasmus...",
                    institutionName: "Nom de l'institution:",
                    institutionNamePlaceholder: "Sélectionner le nom de l'institution...",
                  },
                  approvingEmail: {
                    title: "Courriel de la personne approbatrice",
                    placeholder: "Courriel de la personne approbatrice"
                  },
                  createdBy: {
                    title: "Créé par",
                    placeholder: "Sélection créée par...",
                    student: "Créé par un étudiant",
                    institution: "Créé par l'institution"
                  },
                  studentName: {
                    title: "Nom de l'élève",
                    firstNameLabel: "Prénom:",
                    firstNamePlaceholder: "Prénom",
                    lastNameLabel: "Nom de famille:",
                    lastNamePlaceholder: "Nom de famille"
                  },
                  organizationalUnit: {
                    title: "Unité d'organisation",
                    placeholder: "Sélectionner l'unité organisationnelle..."
                  },
                  name: {
                    title: "Nom",
                  },
                  email: {
                    title: "Email",
                    error: "Veuillez fournir une valeur valide à l'invite."
                  }
                },
                modal: {
                  cancelButton: "Annuler"
                },
                selectPhoneInput: {
                  label: "Code téléphonique",
                  placeholder: "Choisissez le code téléphonique...",
                  error: {
                    a: "Veuillez indiquer un code téléphonique",
                    b: "Veuillez fournir un numéro de téléphone valide."
                  }
                },
                table: {
                  filterLabel: "Filtres",
                  totalAgreements: "Total des accords",
                  activeFilters: "Filtres actifs:",
                  noResults: "Désolé, nous n'avons pas trouvé de résultats"
                },
                coursesTable: {
                  olaReasons: {
                    part1: "La composante éducative choisie précédemment n'est pas disponible dans l'établissement d'accueil.",
                    part2: "La composante est dans une langue différente de celle spécifiée dans le catalogue des cours.",
                    part3: "Conflit d'horaire",
                    part4: "Autre (à préciser)",
                    part5: "Remplacement d'un composant supprimé",
                    part6: "Prolongation de la période de mobilité",
                    part7: "Autre (à préciser)",
                    other: "Autres "
                  },
                  noCourses: "Pas de cours à montrer",
                  totalECTs: "Total des crédits ECTS: ",
                  labels: {
                    code: "CODE",
                    subject: "OBJET",
                    ects: "ECTS",
                    semester: "SEMESTRE",
                    reason: "RAISON",
                    autoRecognition: "RECONNAISSANCE AUTOMATIQUE",
                    description: "DESCRIPTION"
                  }
                },
                olaPdf: {
                  download: {
                    part1: "Télécharger le PDF en cliquant ",
                    part2: "ici"
                  }
                },
                institutionInfoTable: {
                  name: "Nom:",
                  country: "Pays:",
                  erasmusCode: "Code Erasmus:",
                  address: "Adresse:",
                  faculty: "Faculté:",
                  contactPerson: "Personne de contact: ",
                  resPerson: "Res. Personne:"
                },
                learningAgreementInfoTable: {
                  status: "STATUT",
                  academicYear: "ANNÉE ACADÉMIQUE",
                  plannedPeriod: "PÉRIODE PRÉVUE",
                  created: "CRÉÉ",
                  pdfExportText: "Exporter au format PDF"
                },
                studentInfo: {
                  firstName: "Prénom:",
                  lastName: "Nom de famille:",
                  email: "Email:",
                  birthDate: "Date de naissance:",
                  gender: "Genre:",
                  nationality: "Nationalité:",
                  foeISCED: "Domaine de l'éducation (ISCED):",
                  foeClarification: "Domaine de l'éducation (Clarification):"
                },
                tor: {
                  totalECTs: "Total ECTS: ",
                  form: {
                    componentCode: {
                      title: {
                        part1: "Code du composant",
                        part2: "*"
                      },
                      error: "Veuillez fournir un code.",
                    },
                    componentTitle: {
                      title: {
                        part1: "Titre de la composante",
                        part2: "*"
                      },
                      error: "Veuillez fournir un titre."
                    },
                    grade: {
                      title: {
                        part1: "Grade",
                        part2: "*"
                      },
                      error: "Veuillez indiquer une note."
                    },
                    ectNum: {
                      title: {
                        part1: "Nombre de crédits ECTS",
                        part2: "*"
                      },
                      error: "Veuillez fournir les crédits d'ects."
                    },
                    success: {
                      part1: "Terminé avec succès",
                      part2: "*"
                    }
                  },
                  torFeedback: {
                    create: {
                      loading: "Création d'un nouveau ToR...",
                      success: "Création réussie des ToR",
                      failure: "Erreur lors de la création de ToR."
                    },
                    update: {
                      loading: "Mise à jour d'un nouveau ToR...",
                      success: "Mise à jour réussie des ToR",
                      failure: "Erreur lors de la mise à jour des ToR."
                    }
                  },
                  componentFeedback: {
                    create: {
                      loading: "Création d'un nouveau composant...",
                      success: "Composant créé avec succès",
                      failure: "Erreur lors de la création du composant."
                    },
                    edit: {
                      loading: "Édition d'un nouveau composant...",
                      success: "Composant édité avec succès",
                      failure: "Erreur lors de l'édition du composant."
                    },
                    delete: {
                      loading: "Suppression d'un nouveau composant...",
                      success: "Suppression réussie du composant",
                      failure: "Erreur lors de la suppression d'un composant."
                    }
                  },
                  createTor: "Créer des ToR",
                  mobilityStart: "Mobilité Date de début :",
                  mStart: "Date de début de la mobilité",
                  mEnd: "Date de fin de la mobilité",
                  mobilityEnd: "Date de fin de la mobilité:",
                  invalidInput: "Veuillez fournir une valeur valide à l'invite.",
                  transcript: "TRANSCRIPTION DES DOSSIERS DANS L'INSTITUTION D'ACCUEIL",
                  recognition: "RECONNAISSANCE DANS L'INSTITUTION D'ORIGINE",
                  addComponentTitle: { 
                    part1: "Ajouter un nouveau ",
                    part2: "composant"
                  },
                  editComponentTitle: {
                    part1: "Modifier ",
                    part2: "le composant"
                  },
                  deleteComponentTitle: {
                    confirmMessage: "Êtes-vous sûr ?",
                    part1: "Supprimer ",
                    part2: "un composant"
                  },
                  updateTor: {
                    part1: "Mise à jour ",
                    part2: "des ToR"
                  },
                  addComponent: "ajouter un nouveau composant",
                  editComponent: "Modifier le composant",
                  deleteComponent: "Supprimer un composant",
                  submitButton: "Soumettre",
                  updateButton: "Mise à jour"
                },
                destinationHeader: {
                  nominated: "NOMINÉS",
                  rejected: "REJETÉ",
                  nominate: "Nommer"
                },
                destinationInfo: {
                  studyLevel: "STUDIENSTUFE",
                  studyField: "STUDIENBEREICH",
                  reqDocuments: "ERFORDERLICHE DOKUMENTE",
                  otherReq: "ANDERE VORAUSSETZUNGENVoraussetzungen"
                },
            }
          },
          span: {
            translation: {
                loadingMessage: "Cargando...",
                errorMessage: "Se ha producido un error",
                sidebar: {
                  institutions: "Instituciones",
                  requests: "Solicitudes",
                  myUniversity: "Mi Universidad",
                  accountsAndAccess: "Cuentas y acceso",
                  organizationalUnits: "Unidades organizativas",
                  ukraineAssistance: "Ayuda a Ucrania",
                  ola: "OLA",
                  outgoingStudents: "Estudiantes salientes",
                  incomingStudents: "Estudiantes entrantes",
                  upload: "Cargar",
                  shortTerm: "Corta duración",
                  applications: "Solicitudes",
                  requirements: "Requisitos",
                  iia: "Acuerdos Interinstitucionales (AII)",
                  iiaList: "Lista AII",
                  newDigitalIIA: "Nuevo AII Digital",
                  importIIA: "Importar AII",
                  exportIIAs: "Exportar AII",
                  defaultIIAData: "Datos AII por defecto",
                  erasmusApp: "Aplicación Erasmus+",
                  usefulInfo: "Información útil",
                  deals: "Ofertas",
                  events: "Eventos",
                  coursesEvaluations: "Evaluación de los cursos",
                  ewpSettings: "Ajustes de EWP",
                },
                home: {
                    header: "¡Bienvenido a EWP Dashboard!",
                    title: "Página de inicio",
                    tab: "Inicio",
                    description: {
                      part1: "Bienvenido a EWP Dashboard, la herramienta para gestionar las movilidades de tus estudiantes a través del programa Erasmus+. Como Responsable de Relaciones Internacionales (RRI), desempeñas un papel fundamental a la hora de ayudar a tus estudiantes a sacar el máximo partido de sus experiencias en el extranjero, y EWP Dashboard está aquí para apoyarte en ese esfuerzo.", 
                      part2: "Nos complace anunciar que hemos realizado algunas mejoras significativas en la interfaz y la experiencia de usuario de la aplicación. Hemos trabajado duro para que EWP Dashboard sea más intuitivo y fácil de usar, y creemos que estos cambios te facilitarán la gestión de las movilidades de tus estudiantes y te permitirán aprovechar al máximo las oportunidades que ofrece el programa Erasmus+."
                    }
                },
                articleSlider: {
                  loadingMessage: "Cargando...",
                  errorMessage: "Se ha producido un error",
                  created: "Creado: "
                },
                articleSingleView: {
                  title: "Entrada de blog",
                  subTitle: "Vista única",
                  created: "Creado: ",
                  lastUpdated: "Última actualización: ",
                  tags: "Etiquetas: "
                },
                ewpSettings: {
                  header: "INTERCAMBIO DE INFORMACIÓN A TRAVÉS DEL CUADRO DE MANDOS DEL EWP",
                  title: "CONFIGURACIÓN DE LA EWP",
                  subTitle: {
                    part1: "Por favor, ",
                    part2: " marque / desmarque  ",
                    part3: "según lo que corresponda a su HEI."
                  },
                  ewpSettingType: {
                    updatingSettings: "Actualización de la configuración de EWP...",
                    updatedSuccessfully: `Ajustes de EWP actualizados correctamente`,
                    unableToUpdate: `No se ha podido actualizar la configuración de EWP`,
                    exchangeOfIIAs: {
                      title: "Intercambio de IIA a través del cuadro de mandos de EWP",
                      checkbox: "Estoy de acuerdo en que el Cuadro de mando del EWP represente a mi institución de educación superior en la Red EWP para intercambiar acuerdos interinstitucionales con sus socios."
                    },
                    exchangeOfOUnits: {
                      title: "Intercambio de OUnits a través de EWP Dashboard",
                      checkbox: "Acepto que el Cuadro de mando del EWP represente a mi institución de educación superior en la Red EWP con el fin de compartir la información de las Unidades organizativas con sus socios."
                    },
                    exchangeOfStaticInfo: {
                      title: "Intercambio de información estática para los IIA a través del cuadro de mandos del EWP",
                      checkbox: "Estoy de acuerdo en que el Cuadro de mandos del EWP represente a mi institución de educación superior en la Red EWP para intercambiar la información estática de los acuerdos interinstitucionales con sus socios."
                    },
                    exchangeOfOLAs: {
                      title: "Intercambio de OLA a través del cuadro de mandos de EWP",
                      checkbox: "Acepto que el Panel de control del EWP represente a mi institución de educación superior en la Red EWP con el fin de permitir el intercambio electrónico de la información del Acuerdo de Aprendizaje en Línea."
                    }
                  },
                  cancelButton: "Cancelar",
                  saveSettingsButton: "Guardar ajustes de EWP"
                },
                accountsAndAccess: {
                  title: "Cuentas y acceso",
                  accountsTab: "Cuentas",
                  rolesTab: "Funciones",
                  saveChangesButton: "Guardar cambios",
                  accountForm: {
                    accountName: {
                      label: "Nombre de la cuenta",
                      error: "Indique un nombre de cuenta."
                    },
                    accountEmail: {
                      label: "Correo electrónico de la cuenta",
                      error: "Proporcione una dirección de correo electrónico válida."
                    },
                    accountPhone: {
                      label: "Número de teléfono",
                      error: "El número de teléfono registrado de esta cuenta no es un número de teléfono válido. Por favor, proporcione un teléfono válido."
                    },
                    accountRole: {
                      label: "Papel",
                      placeholder: "Seleccione función...",
                      error: "Indique una función para la cuenta."
                    },
                  },
                  roleForm: {
                    roleName: {
                      label: "Nombre del rol",
                      error: "Indique el nombre de la función."
                    },
                    rolePermissions: {
                      label: "Permisos",
                      placeholder: "Seleccionar permisos...",
                      error: {
                        a: "Seleccione al menos un permiso para esta función.",
                        b: "Debe haber elegido al menos un permiso para un papel."
                      }
                    }
                  },
                  accountFeedback: {
                    create: {
                      loading: "Crear cuenta...",
                      success: "Cuenta creada correctamente ",
                      failure: "No se ha podido crear la cuenta  "
                    },
                    update: {
                      loading: "Actualizando cuenta...",
                      success: "La cuenta se ha actualizado correctamente ",
                      failure: "No se ha podido actualizar la cuenta "
                    },
                    delete: {
                      loading: "Borrar cuenta...",
                      success: "Cuenta eliminada correctamente ",
                      failure: "No se ha podido eliminar la cuenta "
                    }
                  },
                  addNewAccount: {
                    title: "Añadir una nueva cuenta",
                    subTitle: "Añada la información necesaria para añadir una nueva Cuenta para alguno de sus empleados",
                    buttonText: "Añadir nueva cuenta"
                  },
                  editAccount: {
                    title: "Editar una cuenta existente",
                    subTitle: "Editar la información de una Cuenta existente y ",
                    buttonText: "Editar cuenta"
                  },
                  deleteAccount: {
                    title: {
                      part1: "Estás a punto de ",
                      part2: "eliminar",
                      part3: " la cuenta "
                    },
                    subTitle: "Tras eliminar la cuenta, el empleado no podrá iniciar sesión.",
                    buttonText: "Eliminar cuenta"
                  },
                  form: {
                    accountName: {
                      label: "Nombre de la cuenta",
                      error: "Indique un nombre de cuenta."
                    },
                    accountEmail: {
                      label: "Correo electrónico de la cuenta",
                      error: "Proporcione una dirección de correo electrónico válida."
                    },
                    accountPhone: {
                      label: "Número de teléfono",
                      error: "El número de teléfono registrado de esta cuenta no es un número de teléfono válido. Por favor, proporcione un teléfono válido."
                    },
                    accountRole: {
                      label: "Papel",
                      placeholder: "Seleccione función...",
                      error: "Indique una función para la cuenta."
                    }
                  },
                  roleFeedback: {
                    create: {
                      loading: "Crear papel...",
                      success: "Función creada con éxito ",
                      failure: "No se ha podido crear el rol "
                    },
                    update: {
                      loading: "Actualizar rol...",
                      success: "Actualizar con éxito el papel ",
                      failure: "No se ha podido actualizar el rol "
                    },
                    delete: {
                      loading: "Borrar rol...",
                      success: "Eliminado con éxito el rol ",
                      failure: "No se ha podido eliminar el rol ",
                      warning: {
                        part1: "El rol ",
                        part2: " es utilizado por ",
                        part3: " cuenta",
                        part4: {
                          a: "s",
                          b: ""
                        },
                        part5: ". Por lo tanto, no se puede eliminar."
                      }
                    }
                  },
                  addNewRole: {
                    title: "Añadir un nuevo rol",
                    subTitle: "Añada la información necesaria para añadir una nueva función que podrá asignar posteriormente a sus empleados.",
                    buttonText: "Añadir nueva función"
                  },
                  editRole: {
                    title: "Editar un rol existente",
                    subTitle: "Editar la información de un Rol existente y ",
                    buttonText: "Editar rol"
                  },
                  deleteRole: {
                    title: {
                      part1: "Estás a punto de ",
                      part2: "borrar",
                      part3: " el rol "
                    },
                    subTitle: "Después de borrar el rol, no podrás volver a verlo.",
                    buttonText: "Borrar rol"
                  }
                },
                organizationalUnits: {
                  title: "Unidades organizativas",
                  saveChangesButton: "Guardar cambios",
                  unitForm: {
                    unitLanguage: {
                      label: "Nombre de la unidad Idioma",
                      placeholder: "Seleccionar idioma...",
                    },
                    unitName: {
                      label: "Nombre de la unidad",
                      placeholder: "Nombre de la Ounit",
                      error: "Indique un nombre de unidad organizativa válido."
                    },
                    unitAbbreviation: {
                      label: "Unidad Abreviatura",
                      placeholder: "Abreviatura Ounit"
                    },
                    unitContactName: {
                      label: "Nombre de contacto Acuerdos de Aprendizaje Saliente",
                    },
                    unitContactEmail: {
                      label: "Contacto Correo electrónico Acuerdos de aprendizaje saliente",
                      error: "Proporcione un correo electrónico de contacto válido."
                    },
                    incomingDifferent: {
                      label: "La persona de contacto de los Acuerdos de Aprendizaje Incoming es diferente"
                    },
                    incomingContactName:{
                      label: "Nombre de contacto Acuerdos de aprendizaje entrantes",
                    },
                    incomingContactEmail:{
                      label: "Correo electrónico de contacto Acuerdos de aprendizaje entrantes",
                      error: "Proporcione un correo electrónico de contacto válido."
                    }
                  },
                  unitFeedback: {
                    create: {
                      loading: "Crear una unidad organizativa...",
                      success: "Se ha creado correctamente la unidad organizativa con el nombre ",
                      failure: "No se ha podido crear la unidad organizativa"
                    },
                    update: {
                      loading: "Actualización de la unidad organizativa...",
                      success: "Actualizada con éxito la Unidad Organizativa ",
                      failure: "No se ha podido actualizar la Unidad Organizativa"
                    },
                    delete: {
                      loading: "Borrar unidad organizativa...",
                      success: "Se ha eliminado correctamente la unidad organizativa",
                      failure: "No se ha podido eliminar la unidad organizativa"
                    }
                  },
                  addNewUnit: {
                    title: "Añadir una nueva unidad organizativa",
                    subTitle: "Añada la información necesaria para añadir una nueva Unidad Organizativa para su HEI",
                    buttonText: "Añadir una nueva unidad organizativa"
                  },
                  editUnit: {
                    title: "Editar una unidad organizativa existente",
                    subTitle: "Añada la información necesaria para editar una unidad organizativa para su HEI",
                    buttonText: "Editar unidad organizativa"
                  },
                  deleteUnit: {
                    title: {
                      part1: "Está a punto de ",
                      part2: "eliminar",
                      part3: " una Unidad Organizativa"
                    },
                    subTitle: "Después de eliminar una unidad organizativa, no podrás volver a verla.",
                    buttonText: "Borrar unidad organizativa",
                    buttonLabel: "Borrar",
                  },
                },
                ukraineAssistance: {
                  title: "Ayuda a Ucrania",
                  infoFeedback: {
                    loading: "Actualización de la información de asistencia de Ucrania...",
                    success: "Información de asistencia a Ucrania actualizada con éxito",
                    failure: "No se ha podido actualizar la información de asistencia de Ucrania"
                  },
                  validateError: "Seleccione al menos una opción «Sí» o proporcione un enlace válido",
                  invalidUrl: "URL no válida",
                  header: "Guerra en Ucrania - Centro de Apoyo al Estudiante",
                  description: "En respuesta a los dramáticos acontecimientos que se están produciendo en Ucrania, hemos creado un portal para facilitar información sobre la ayuda que ofrecen las instituciones de enseñanza superior a los estudiantes que huyen de la guerra. Si desea ayudarnos a difundir cómo su universidad está dando un paso al frente y marcando la diferencia, indíquenoslo:",
                  form: {
                    shelter: "¿Proporciona su institución alojamiento de emergencia?",
                    health: "¿Se ofrece apoyo sanitario y psicológico en su institución?",
                    fastTrack: "¿Ofrece su institución un procedimiento rápido de solicitud de estudios?",
                    scholarship: "¿Ofrece su institución becas especiales de estudios?",
                    options: {
                      yes: "Sí",
                      no: "No",
                      unkown: "Desconocido"
                    }
                  },
                  moreInfo: {
                    header: "Por favor, facilite un enlace a la página web de su institución con más información sobre este tema, o a la página de contactos de su institución.",
                    error: "Proporcione una URL válida.",
                    buttonText: "Salvar Ucrania Información sobre asistencia"
                  }
                },
                incomingStudents: {
                  title: "Acuerdo de aprendizaje en línea",
                  subTitle: "Estudiantes entrantes",
                  tab: "Acuerdos de aprendizaje en línea",
                  tabSingleView: "Ver un único Acuerdo de Aprendizaje en Línea Entrante",
                },
                outgoingStudents: {
                  title: "Acuerdo de aprendizaje en línea",
                  subTitle: "Estudiantes salientes",
                  tab: "Acuerdos de aprendizaje en línea saliente",
                  tabSingleView: "Ver un único Acuerdo de Aprendizaje en Línea Saliente",
                },
                applications: {
                  title: "Solicitudes para Erasmus",
                  tab: {
                    out: "Candidaturas salientes",
                    in: "Candidaturas entrantes"
                  }
                },
                learningAgreements: {
                  loadingMessage: "Cargando...",
                  signFeedback: {
                    loading: "Firmando OLA...",
                    success: "Acuerdo de aprendizaje firmado con éxito",
                    failure: "Error al firmar."
                  },
                  declineOlaFeedback: {
                    loading: "OLA en declive...",
                    success: "Acuerdo de aprendizaje rechazado con éxito",
                    failure: "Error al declinar."
                  }, 
                  editTableFailed: "Error en la edición de la tabla de cursos OLA.",
                  LearningAgreementInfo: {
                    title: "INFORMACIÓN SOBRE EL ACUERDO DE APRENDIZAJE"
                  },
                  generalInfo: {
                    title: "Información general",
                    studentPersonalData: {
                      title: "DATOS PERSONALES DE LOS ESTUDIANTES"
                    },
                    sendingInstInfo: {
                      title: "ENVÍO DE INFORMACIÓN INSTITUCIONAL"
                    },
                    receivingInstInfo: {
                      title: "INFORMACIÓN SOBRE LA INSTITUCIÓN RECEPTORA"
                    }
                  },
                  courses: {
                    title: "Cursos",
                    tableA: "TABLA A",
                    tableB: "TABLA B",
                    tableC: "TABLA C",
                  },
                  changes: {
                    title: "Cambios",
                    tableA: "CAMBIOS EN EL CUADRO A",
                    tableB: "CAMBIOS EN EL CUADRO B",
                    tableC: "CAMBIOS EN EL CUADRO C",
                  },
                  modalReject: {
                    declineButton: "Disminución",
                    body: {
                      part1: "Después de rechazar el OLA, el estudiante podrá editarlo e introducir los cambios que usted proponga. Por favor, rellene los comentarios que desea enviar por correo electrónico al estudiante en el campo de abajo: ",
                      part2: "Motivo del rechazo",
                      part3: " Nota",
                    },
                    title: {
                      part1: "Está a punto de ",
                      part2: "rechazar",
                      part3: " el OLA de un estudiante"
                    }
                  },
                  modalSign: {
                    signButton: "Firmar OLA",
                    body: "Al firmar digitalmente este documento, el estudiante, la Institución de Envío y la Institución de Destino confirman que aprueban el Acuerdo de Aprendizaje y que cumplirán todas las disposiciones acordadas por todas las partes. Las Instituciones de Envío y de Acogida se comprometen a aplicar todos los principios de la Carta Erasmus de Educación Superior relativos a la movilidad para estudios (o los principios acordados en el Acuerdo Interinstitucional para instituciones situadas en Países Asociados). La Institución Beneficiaria y el estudiante también deben comprometerse a lo establecido en el convenio de subvención Erasmus+. La Institución Receptora confirma que los componentes educativos enumerados se ajustan a su catálogo de cursos y deben estar a disposición del estudiante. La Institución de envío se compromete a reconocer todos los créditos o unidades equivalentes obtenidos en la Institución de acogida por los componentes educativos completados con éxito y a contabilizarlos en la titulación del estudiante. El estudiante y la Institución de destino comunicarán a la Institución de origen cualquier problema o cambio relacionado con el programa de estudios, las personas responsables y/o el periodo de estudios.",
                    title: {
                      part1: "Está a punto de ",
                      part2: "firmar",
                      part3: " el OLA de un estudiante"
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Confirme",
                    body: {
                      part1: "De acuerdo con las directrices oficiales de la Comisión Europea, si no se produce el reconocimiento automático, facilite una justificación clara y una indicación sobre qué otro tipo de reconocimiento formal se aplicará. ",
                      part2: "Rellene el motivo del rechazo"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "De acuerdo con las directrices oficiales de la Comisión Europea, si no se produce el reconocimiento automático, facilite una justificación clara y una indicación sobre qué otro tipo de reconocimiento formal se aplicará. ",
                      part2: "Rellene el motivo del rechazo",
                    }
                  },
                  upload: {
                    titleMain: "Acuerdo de aprendizaje en línea",
                    subTitle: "Cargar CSV",
                    tab: "Cargar CSV para acuerdos de aprendizaje en línea",
                    validateData: {
                      invalidCode: {
                        part1: "Código Erasmus no válido ",
                        part2: " en la línea "
                      },
                      emailError: {
                        part1: "Error de correo electrónico en la línea ",
                        part2: ": Compruebe el formato de correo electrónico de sus datos."
                      },
                      dataError: {
                        part1: "Error de fecha en la línea ",
                        part2: ": Asegúrate de que las fechas están en formato dd/mm/aaaa."
                      },
                      fileError: "Error de archivo: Sólo puedes subir 25 LA a la vez."
                    },
                    uploadOla: {
                      loading: "Cargando OLAs...",
                      success: "Acuerdos de aprendizaje cargados correctamente",
                      failure: "Se han producido algunos errores al cargar los OLA."
                    },
                    header: "Cargar acuerdos de aprendizaje",
                    cancel: "Cancelar",
                    upload: "Cargar",
                    modalSign: {
                      part1: "Asegúrese de que los datos se ajustan a la ",
                      part2: "plantilla"
                    },
                    title: {
                      part1: "Está a punto de ",
                      part2: "cargar",
                      part3: " algunos OLAs"
                    },
                    dontClosePage: "Esto puede tardar un poco, por favor, no cierre esta página.",
                    commonErrors: {
                      part1: "Si observa algún error, consulte la página de Errores comunes disponible ",
                      part2: "aquí"
                    },
                    returnToUploadPage: "Volver a la página de carga",
                    header2: "Cargar archivo CSV para rellenar previamente los acuerdos de aprendizaje en línea",
                    bodyUpload: {
                      part1: "La función de cumplimentación previa del Acuerdo de Aprendizaje en Línea ahora admite la carga de CSV. Una vez introducidos los datos, puede revisar la lista de estudiantes para asegurarse de que toda la información es correcta y enviar los datos. Esta acción activará la creación de Acuerdos de Aprendizaje en Línea pre-rellenados y una notificación para los estudiantes invitándoles a finalizar el documento.",
                      part2: "El formato del archivo CSV puede verificarse utilizando una plantilla de hoja de cálculo disponible en el CC del EWP ",
                      part3: "aquí"
                    },
                    checkBox: "Confirmo, también en nombre de mi HEI, que dispongo de todas las autorizaciones, incluido el consentimiento de las personas físicas pertinentes, necesarias para cargar los datos personales y la información que estoy proporcionando, de conformidad con los Términos y Condiciones del Panel y la Política de Privacidad.",
                    consent: "Consentimiento para el tratamiento de datos"
                  }
                },
                nominationList: {
                  totalApplications: "Total de solicitudes",
                  downloadFiles: "Descargar todos los archivos",
                  outSignleView: {
                    loadingMessage: "Cargando...",
                    errorMessage: "Se ha producido un error",
                    applicationFeedback: {
                      nominate: {
                        loading: "Solicitud de nominación...",
                        success: "Solicitud nominada con éxito",
                        failure: "Nominación de candidatura fallida"
                      },
                      reject: {
                        loading: "Rechazar la solicitud...",
                        success: "Solicitud rechazada con éxito",
                        failure: "Rechazo de solicitud fallido"
                      },
                      wait: {
                        loading: "Solicitud puesta en lista de espera...",
                        success: "Aplicación actualizada correctamente",
                        failure: "Error en la actualización de la aplicación"
                      },
                      delete: {
                        loading: "Borrar aplicación...",
                        success: "Aplicación eliminada correctamente",
                        failure: "Error en la eliminación de la aplicación"
                      },
                    },
                    applicationInfo: {
                      title: "INFORMACIÓN SOBRE LA SOLICITUD",
                      status: "ESTADO",
                      academicYear: "AÑO ACADÉMICO",
                      academicTerm: "TRIMESTRE ACADÉMICO",
                      submissionTime: "PLAZO DE PRESENTACIÓN",
                      downloadFiles: "Descargar todos los archivos",
                    },
                    generalInfo: {
                      title: "Información general",
                      studentInfo: {
                        title: "INFORMACIÓN PARA ESTUDIANTES",
                        name: "NOMBRE",
                        email: "EMAIL",
                        studyLevel: "NIVEL DE ESTUDIOS",
                        studyField: "CAMPO DE ESTUDIO"
                      },
                      destinationheis: {
                        title: "DESTINO HEI",
                        institutionFaculty: "INSTITUCIÓN / FACULTAD",
                        nominatedStudents: "ESTUDIANTES NOMINADOS"
                      }
                    },
                    documents: {
                      title: "Documentos",
                      viewPdf: "Ver pdf",
                      files: { 
                        portfolio: "CARTERA",
                        externalCertification: "CERTIFICACIÓN EXTERNA",
                        referenceContact: "CONTACTO DE REFERENCIA",
                        motivationLetter: "CARTA DE MOTIVACIÓN",
                        languageCert: "CERTIFICADOS DE IDIOMAS",
                        other: "OTROS DOCUMENTOS"
                      }
                    },
                    waitingList: "LISTA DE ESPERA",
                    reject: "RECHAZAR",
                    delete: "BORRAR",
                    modalReject: {
                      body: "Una vez rechazada la solicitud, el estudiante no podrá solicitar ningún otro programa de movilidad en el curso académico en curso.",
                      reject: "Rechazar",
                      title: {
                        part1: "Está a punto de ",
                        part2: "rechazar",
                        part3: " la solicitud de un estudiante"
                      }
                    },
                    modalWaiting: {
                      body: "Después de inscribir la solicitud del estudiante en la lista de espera, aún podrá nominar al estudiante más adelante, si así lo desea.",
                      waitingList: "Lista de espera",
                      title: {
                        part1: "Está a punto de inscribir la solicitud de un estudiante en la ",
                        part2: "Lista de espera"
                      }
                    },
                    modalDelete: {
                      body: "Después de borrar la solicitud del estudiante, no podrá volver a verla.",
                      delete: "Borrar",
                      title: {
                        part1: "Está a punto de ",
                        part2: "borrar",
                        part3: " la solicitud de un estudiante"
                      }
                    },
                    modalNominate: {
                      body: "Después de proponer al estudiante para una movilidad a la HEI de acogida, la institución de acogida podrá aceptar o rechazar la propuesta. En caso de rechazo por parte de la HEI de acogida, podrá proponer al estudiante para una movilidad en otra HEI, si está disponible.",
                      nominate: "Nominar",
                      title: {
                        part1: "Está a punto de ",
                        part2: "nominar",
                        part3: " la candidatura de un estudiante"
                      }
                    }
                  },
                  inSingleView: {
                    loadingMessage: "Cargando...",
                    errorMessage: "Se ha producido un error",
                    applicationFeedback: {
                      nominate: {
                        loading: "Solicitud de nominación...",
                        success: "Solicitud nominada con éxito",
                        failure: "Nominación de candidatura fallida"
                      },
                      reject: {
                        loading: "Rechazar la solicitud...",
                        success: "Solicitud rechazada con éxito",
                        failure: "Rechazo de solicitud fallido"
                      }
                    },
                    applicationInfo: {
                      title: "INFORMACIÓN SOBRE LA SOLICITUD",
                      status: "ESTADO",
                      academicYear: "AÑO ACADÉMICO",
                      academicTerm: "TRIMESTRE ACADÉMICO",
                      submissionTime: "PLAZO DE PRESENTACIÓN",
                      downloadFiles: "Descargar todos los archivos",
                    },
                    generalInfo: {
                      title: "Información general",
                      studentInfo: {
                        title: "INFORMACIÓN PARA ESTUDIANTES",
                        name: "NOMBRE",
                        email: "EMAIL",
                        studyLevel: "NIVEL DE ESTUDIOS",
                        studyField: "CAMPO DE ESTUDIO"
                      },
                      homeHei: {
                        title: "INICIO HEI",
                        name: "NOMBRE",
                        orgUnit: "UNIDAD ORGANIZATIVA"
                      },
                      destinationheis: {
                        title: "DESTINO HEI",
                        institutionFaculty: "INSTITUCIÓN / FACULTAD",
                        nominatedStudents: "ESTUDIANTES NOMINADOS"
                      }
                    },
                    documents: {
                      title: "Documentos",
                      viewPdf: "Ver pdf",
                      files: { 
                        portfolio: "CARTERA",
                        externalCertification: "CERTIFICACIÓN EXTERNA",
                        referenceContact: "CONTACTO DE REFERENCIA",
                        motivationLetter: "CARTA DE MOTIVACIÓN",
                        languageCert: "CERTIFICADOS DE IDIOMAS",
                        other: "OTROS DOCUMENTOS"
                      }
                    },
                    reject: "Rechazar",
                    nominate: "Nominar",
                    modalReject: {
                      body: "Tras rechazar la solicitud, el estudiante no podrá solicitar ningún otro programa de movilidad en su HEI, en el año académico en curso. Sin embargo, la Responsable de Relaciones Internacionales de origen podrá proponer a este estudiante para una movilidad en otra HEI, si está disponible.",
                      title: {
                        part1: "Está a punto de ",
                        part2: "rechazar",
                        part3: " la solicitud de un estudiante"
                      }
                    },
                    modalNominate: {
                      body: "Al nominar al estudiante, usted está haciendo que el estudiante sea elegible para iniciar su movilidad en la HEI que usted representa.",
                      title: {
                        part1: "Está a punto de ",
                        part2: "nominar",
                        part3: " la candidatura de un estudiante"
                      }
                    }
                  }
                },
                shortTerm: {
                  title: "Movilidades de corta duración",
                  tabOutgoing: "Movilidades salientes de corta duración",
                  tabIncoming: "Movilidades entrantes de corta duración",
                },
                shortTermMobilities: {
                  loadingMessage: "Cargando...",
                  errorMessage: "Se ha producido un error",
                  title: "Movilidades de corta duración",
                  tabOutgoing: "Ver una sola Movilidad Saliente de Corta Duración",
                  tabIncoming: "Ver solo Movilidad de corta duración entrante",
                  studentType: {
                    out: "Persona responsable en la institución remitente",
                    in: "Persona responsable en la institución receptora"
                  },
                  editTableFail: "Error en la edición de la tabla de cursos OLA.",
                  signFeedback: {
                    loading: "Firmando OLA...",
                    success: "Acuerdo de aprendizaje firmado con éxito",
                    failure: "Error al firmar."
                  },
                  declineOlaFeedback: {
                    loading: "OLA en declive...",
                    success: "Acuerdo de aprendizaje rechazado con éxito",
                    failure: "Error al declinar."
                  }, 
                  LearningAgreementInfo: {
                    title: "INFORMACIÓN SOBRE EL ACUERDO DE APRENDIZAJE"
                  },
                  generalInfo: {
                    title: "Información general",
                    studentPersonalData: {
                      title: "DATOS PERSONALES DE LOS ESTUDIANTES"
                    },
                    sendingInstInfo: {
                      title: "ENVÍO DE INFORMACIÓN INSTITUCIONAL"
                    },
                    receivingInstInfo: {
                      title: "INFORMACIÓN SOBRE LA INSTITUCIÓN RECEPTORA"
                    }
                  },
                  courses: {
                    title: "Cursos",
                    table: "TABLA DE MOVILIDAD A CORTO PLAZO"
                  },
                  modalReject: {
                    declineButton: "Disminución",
                    body: {
                      part1: "Después de rechazar el OLA, el estudiante podrá editarlo e introducir los cambios que usted proponga. Por favor, rellene los comentarios que desea enviar por correo electrónico al estudiante en el campo de abajo: ",
                      part2: "Motivo del rechazo",
                      part3: " Nota",
                    },
                    title: {
                      part1: "Está a punto de ",
                      part2: "rechazar",
                      part3: " el OLA de un estudiante"
                    }
                  },
                  modalSign: {
                    signButton: "Firmar OLA",
                    body: "Al firmar digitalmente este documento, el estudiante, la Institución de Envío y la Institución de Destino confirman que aprueban el Acuerdo de Aprendizaje y que cumplirán todas las disposiciones acordadas por todas las partes. Las Instituciones de Envío y de Acogida se comprometen a aplicar todos los principios de la Carta Erasmus de Educación Superior relativos a la movilidad para estudios (o los principios acordados en el Acuerdo Interinstitucional para instituciones situadas en Países Asociados). La Institución Beneficiaria y el estudiante también deben comprometerse a lo establecido en el convenio de subvención Erasmus+. La Institución Receptora confirma que los componentes educativos enumerados se ajustan a su catálogo de cursos y deben estar a disposición del estudiante. La Institución de envío se compromete a reconocer todos los créditos o unidades equivalentes obtenidos en la Institución de acogida por los componentes educativos completados con éxito y a contabilizarlos en la titulación del estudiante. El estudiante y la Institución de destino comunicarán a la Institución de origen cualquier problema o cambio relacionado con el programa de estudios, las personas responsables y/o el periodo de estudios.",
                    title: {
                      part1: "Está a punto de ",
                      part2: "firmar",
                      part3: " el OLA de un estudiante"
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Confirme",
                    body: {
                      part1: "De acuerdo con las directrices oficiales de la Comisión Europea, si no se produce el reconocimiento automático, facilite una justificación clara y una indicación sobre qué otro tipo de reconocimiento formal se aplicará. ",
                      part2: "Rellene el motivo del rechazo"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "De acuerdo con las directrices oficiales de la Comisión Europea, si no se produce el reconocimiento automático, facilite una justificación clara y una indicación sobre qué otro tipo de reconocimiento formal se aplicará. ",
                      part2: "Rellene el motivo del rechazo",
                    }
                  },
                },
                requirements: {
                  applicationSettings: {
                    feedback: {
                      success: "La configuración de la aplicación se ha actualizado correctamente.",
                      error1: "Se ha producido un error al actualizar los requisitos de la solicitud.",
                      error2: "Se ha producido un error al actualizar la configuración de la aplicación: "
                    },
                    title: "Configuración de la aplicación",
                    description: "Elija el número máximo de HEI de acogida para sus estudiantes salientes y establezca los plazos para las solicitudes.",
                    numOfHeis: {
                      title: "Número de HEI ",
                      placeholder: "Seleccione el número de HEI",
                      error: "Seleccione un país para su institución",
                      message: "Número máximo de prioridades de la HEI de acogida por solicitud de estudiante"
                    },
                    semesterApplications: {
                      titleAw: "Plazo de solicitud para el semestre de otoño/invierno",
                      titleSs: "Plazo de solicitud para el semestre de primavera/verano",
                      startDate: "Fecha de inicio ",
                      endDate: "Fecha final ",
                      error: "Por favor, indique un valor válido."
                    },
                    cancel: "Cancelar",
                    saveSettings: "Guardar la configuración de la aplicación"
                  },
                  applicationRequirements: {
                    feedback: {
                      success: "Requisitos de solicitud actualizados correctamente.",
                      error1: "Se ha producido un error al actualizar los requisitos de la solicitud.",
                      error2: "Se ha producido un error al actualizar los requisitos de la solicitud: "
                    },
                    title: "Requisitos (salientes)",
                    header: "Documentos necesarios",
                    descriptionMain: "Active los interruptores situados junto a los archivos necesarios para los estudiantes salientes de su HEI y facilite las URL y descripciones correspondientes.",
                    description: "Descripción",
                    motivationLetter: "Carta de motivación",
                    transcriptOfRecords: "Transcripción de actas",
                    externalCertification: "Certificación externa",
                    recommendationLetter: "Carta de recomendación",
                    languageLevel: {
                      title: "Nivel de lengua",
                      languageRequired: {
                        title: "Idioma requerido",
                        placeholder: "Seleccione un idioma"
                      },
                      languageL: {
                        title: "Nivel de lengua",
                        placeholder: "Seleccione un nivel de idioma"
                      },
                      languageMoreInfo: "Idioma Más información",
                      delete: "Borrar idioma",
                      languageCertRequired: "Certificación lingüística exigida",
                      addLanguage: "Añadir idioma"
                    },
                    otherDocument: "Otro documento",
                    referenceContact: "Contacto de referencia",
                    portfolio: "Cartera",
                    other: "Otros requisitos",
                    addOther: "Añadir otro requisito",
                    addDocument: "Añadir otro documento",
                    deleteRequirement: "Eliminar requisito",
                    cancel: "Cancelar",
                    saveRequirements: "guardar requisitos"
                  }
                },
                iiaList: {
                  title: "Acuerdos interinstitucionales",
                  subTitle: "Lista de acuerdos",
                  iiaFeedback: {
                    delete: {
                      loading: "Borrar IIA...",
                      success: "IIA eliminado con éxito",
                      failure: "Fracaso de la supresión de IIA"
                    },
                    terminate: {
                      loading: "Terminando IIA...",
                      success: "Finalizado con éxito el IIA",
                      failure: "Fracaso del IIA"
                    }
                  },
                  modifyIIA: "Modificar IIA",
                  editIIA: "Editar IIA",
                  deleteIIA: "Suprimir IIA",
                  modalDelete: {
                    body: "Después de borrar un IIA, no podrás volver a verlo.",
                    title: {
                      part1: "Está a punto de ",
                      part2: "suprimir",
                      part3: " un Acuerdo Interinstitucional"
                    },
                    deleteButton: "Borrar"
                  },
                  modalTerminate: {
                    body: {
                      part1: "La acción que está a punto de emprender supone la ",
                      part2: "terminación",
                      part3: " del AII en vigor y es irreversible. ¿Aún desea continuar?"
                    },
                    title: {
                      part1: "Está a punto de ",
                      part2: "rescindir",
                      part3: " un Acuerdo Interinstitucional"
                    },
                    terminateButton: "Terminar"
                  }
                },
                iiaSingleView: {
                  loadingMessage: "Cargando...",
                  errorMessage: "Se ha producido un error",
                  iiaFeedback: {
                    approve: {
                      loading: "Guardar cambios...",
                      success: "Aprobado el IIA",
                      failure: "Se ha producido un error al aprobar el IIA: ",
                      error: "Error al guardar los cambios."
                    },
                    revert: {
                      loading: "Guardar cambios...",
                      success: "IIA revertido con éxito",
                      failure: "Hubo un error al revertir IIA: ",
                      error: "Error al guardar los cambios."
                    },
                    terminate: {
                      loading: "Terminando IIA...",
                      success: "Finalizado con éxito el IIA",
                      failure: "Fracaso del IIA"
                    },
                    notifyPartner: {
                      loading: "Notificar al socio...",
                      success: "Socio notificado con éxito",
                      failure: "Notificación de socio fallida"
                    },
                  },
                  iiaInformation: {
                    title: "IIA INFORMACIÓN",
                    yourId: "SU ID",
                    partnerId: "ID DEL SOCIO",
                    partner: "SOCIO",
                    lastEdited: "ÚLTIMO EDITADO",
                    status: "ESTADO",
                    terminatedByYou: "Rescisión por su parte",
                    terminatedByPartner: "Rescindido por el socio",
                    terminationApprovedByYou: "Rescisión aprobada por usted",
                    terminationApprovedByPartner: "Baja aprobada por el socio",
                    submittedByYou: "Enviado por Usted",
                    submittedByPartner: "Presentado por Partner",
                    approvedByYou: "Aprobado por usted",
                    approvedByPartner: "Aprobado por el socio",
                    terminated: "Terminado",
                    draft: "Borrador",
                    terminatedByAll: "Terminación aprobada por todos",
                    approvedByAll: "Aprobado por todos",
                    invalid: {
                      invalid1: "Este IIA contiene códigos ISCED F-Codes no válidos o le faltan requisitos lingüísticos. Las aprobaciones siguen siendo válidas. Si desea dar de baja este IIA, deberá modificarlo y aprobarlo mutuamente.",
                      invalid2: "Este IIA contiene códigos ISCED F-Codes no válidos o le faltan requisitos lingüísticos. Por favor, edite/modifique el IIA."
                    },
                    thirdParty: "Tenga en cuenta que los datos del HEI asociado son gestionados por un proveedor externo. Eso significa que este IIA concreto está representado por un ID diferente en su sistema.",
                    exportPdf: "Exportar Pdf"
                  },
                  generalInfo: {
                    title: "Información general",
                    institutionData: "LOS DATOS DE SU INSTITUCIÓN",
                    name: "Nombre:",
                    country: "País:",
                    schacCode: "Código SCHAC:",
                    erasmusCode: "Código Erasmus:",
                    orgUnit: "Org. Unidad:",
                    approvingPerson: "Persona autorizadora:",
                    contactPersons: "Personas de contacto:",
                    signDate: "Fecha de la firma:",
                    partnerInstitutionData: "DATOS DE LA INSTITUCIÓN ASOCIADA",
                  },
                  yourData: {
                    title: "Sus datos",
                    calendar: {
                      title: "CALENDARIO",
                      studentNominations: "Las candidaturas de estudiantes deben llegar a la institución receptora antes del: ",
                      autumnTerm: "Trimestre de otoño: ",
                      springTerm: "Trimestre de primavera: ",
                      applicationsForNominated: "Las solicitudes de los estudiantes propuestos deben llegar a la institución receptora a más tardar el: ",
                      applicationProcedure: "Procedimiento de solicitud para estudiantes designados: ",
                      email: "Email de contacto:",
                      phone: "Teléfono de contacto:",
                      websiteForInfo: "Sitio web para información:"
                    },
                    additionalRequirements: {
                      title: "REQUISITOS ADICIONALES",
                      requirements: "REQUISITO",
                      details: "DETALLES",
                      ectsNumber: "NÚMERO ECTS",
                      subjectFieldISCED: "CAMPO SUJETO (ISCED)",
                      studyLevelEQF: "NIVEL DE ESTUDIOS (EQF)",
                      website: "WEBSITE",
                      message: "La institución enviará su decisión en un plazo de 4 semanas, y a más tardar en 5 semanas."
                    },
                    inclusionAndAccessibility: {
                      title: "INCLUSIÓN Y ACCESIBILIDAD",
                      descriptionMain: {
                        part1: "La institución proporcionará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      infrastructure: "Infraestructura",
                      name: "NOMBRE",
                      description: "DESCRIPCIÓN",
                      contact: "CONTACTO",
                      services: "Servicios"
                    },
                    housing: {
                      title: "VIVIENDA",
                      description: {
                        part1: "La institución guiará a los participantes móviles entrantes en la búsqueda de alojamiento, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      contact: "Contacto"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "La institución prestará asistencia, cuando sea necesario, en la obtención de visados para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      contact: "Contacto"
                    },
                    insurance: {
                      title: "SEGURO",
                      description: {
                        part1: "La institución prestará asistencia en la obtención de un seguro para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " La institución receptora informará a los participantes móviles de los casos en que no se proporcione automáticamente la cobertura del seguro.",
                        part3: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      contact: "Contacto"
                    },
                    additionalInfo: {
                      title: "INFORMACIÓN ADICIONAL",
                      description: {
                        part1: "La institución proporcionará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      contact: "CONTACT"
                    },
                    message: {
                      part1: "La institución expedirá un certificado de notas a más tardar 6 semanas después de que haya finalizado el periodo de evaluación.",
                      part2: "[The institution will issue a transcript no later than 6 weeks after the end of the evaluation period.]",
                      part3: "*Tras crear el perfil de la institución de educación superior y añadir la información general pertinente, la institución de educación superior puede proceder a generar Acuerdos Interinstitucionales con sus socios seleccionados."
                    }
                  },
                  partnerData: {
                    title: "Datos del socio",
                    calendar: {
                      title: "CALENDARIO",
                      studentNominations: "Las candidaturas de estudiantes deben llegar a la institución receptora antes del: ",
                      autumnTerm: "Trimestre de otoño: ",
                      springTerm: "Trimestre de primavera: ",
                      applicationsForNominated: "Las solicitudes de los estudiantes propuestos deben llegar a la institución receptora a más tardar el: ",
                      applicationProcedure: "Procedimiento de solicitud para estudiantes designados: ",
                      email: "Email de contacto:",
                      phone: "Teléfono de contacto:",
                      websiteForInfo: "Sitio web para información:"
                    },
                    additionalRequirements: {
                      title: "REQUISITOS ADICIONALES",
                      requirements: "REQUISITO",
                      details: "DETALLES",
                      ectsNumber: "NÚMERO ECTS",
                      subjectFieldISCED: "CAMPO SUJETO (ISCED)",
                      studyLevelEQF: "NIVEL DE ESTUDIOS (EQF)",
                      website: "WEBSITE",
                      message: "La institución enviará su decisión en un plazo de 4 semanas, y a más tardar en 5 semanas."
                    },
                    inclusionAndAccessibility: {
                      title: "INCLUSIÓN Y ACCESIBILIDAD",
                      descriptionMain: {
                        part1: "La institución proporcionará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      infrastructure: "Infraestructura",
                      name: "NOMBRE",
                      description: "DESCRIPCIÓN",
                      contact: "CONTACTO",
                      services: "Servicios"
                    },
                    housing: {
                      title: "VIVIENDA",
                      description: {
                        part1: "La institución guiará a los participantes móviles entrantes en la búsqueda de alojamiento, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      contact: "Contacto"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "La institución prestará asistencia, cuando sea necesario, en la obtención de visados para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      contact: "Contacto"
                    },
                    insurance: {
                      title: "SEGURO",
                      description: {
                        part1: "La institución prestará asistencia en la obtención de un seguro para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " La institución receptora informará a los participantes móviles de los casos en que no se proporcione automáticamente la cobertura del seguro.",
                        part3: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      contact: "Contacto"
                    },
                    additionalInfo: {
                      title: "INFORMACIÓN ADICIONAL",
                      description: {
                        part1: "La institución proporcionará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                        part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                      },
                      contact: "CONTACT"
                    },
                    message: {
                      part1: "La institución expedirá un certificado de notas a más tardar 6 semanas después de que haya finalizado el periodo de evaluación.",
                      part2: "[Normalmente no debería exceder de cinco semanas, según las directrices de la Carta Erasmus de Educación Superior.]",
                      part3: "*Tras crear el perfil de la institución de educación superior y añadir la información general pertinente, la institución de educación superior puede proceder a generar Acuerdos Interinstitucionales con sus socios seleccionados."
                    }
                  },
                  conditions: {
                    title: "Condiciones",
                    header: {
                      part1: "Profesor de plantilla:",
                      part2: " / Campos de estudio: ",
                      part3: "Formación del personal:",
                      part4: "Estudio de estudiantes:",
                      part5: "Prácticas para estudiantes:"
                    },
                    heis: {
                      sender: "REMITENTE",
                      receiver: "RECEPTOR"
                    },
                    conditionDetails: {
                      title: "Detalles del estado ",
                      mobilityType: "TIPO DE MOVILIDAD",
                      duration: "DURACIÓN",
                      academicYears: "AÑOS ACADÉMICOS",
                      spots: "PUNTOS",
                      eqfLevel: "Nivel del EQF",
                      staffTeachers: "Personal docente",
                      staffTrainings: "Formación del personal",
                      otherInfo: "OTROS DATOS",
                    },
                    languageRequirements: {
                      title: "Requisitos lingüísticos ",
                      language: "IDIOMA",
                      studyField: "CAMPO DE ESTUDIO",
                      clarification: "ACLARACIÓN"
                    },
                    studyFields: {
                      title: "Campos de estudio ",
                      studyField: "CAMPO DE ESTUDIO",
                      clarification: "ACLARACIÓN"
                    },
                    contacts: {
                      title: "Contactos ",
                      sendingContacts: "ENVÍO DE CONTACTOS",
                      receivingContacts: "RECEPCIÓN DE CONTACTOS"
                    }
                  },
                  notifyPartner: {
                    text: "Notificar al socio",
                    part1: "Pronto podrá hacer clic en el botón y notificar de nuevo al socio.",
                    part2: "Notificar al socio está inhabilitado porque faltan requisitos lingüísticos o hay códigos ISCED con menos de 4 dígitos en las condiciones de cooperación del IIA.",
                    part3: "Notifique al socio su estado actual del IIA."
                  },
                  terminate: {
                    invalidISCEDormissingLang: "Este AII no puede darse por terminado porque contiene códigos F de la ISCED no válidos o porque le faltan requisitos lingüísticos. Por favor, modifique y apruebe mutuamente el IIA para poder finalizarlo.",
                    invalidISCEDormissingLang2: "Este AI no puede aprobarse porque contiene códigos ISCED F no válidos o le faltan requisitos lingüísticos. Por favor, edite/modifique el IIA.",
                    approvedByAll: "Sólo se puede rescindir un IIA si lo aprueban todos.",
                    iiaTerminationApproved: "La rescisión del IIA ya ha sido aprobada por todos.",
                    terminateButton: "terminar iia"
                  },
                  revert: {
                    noApproval: "No se puede revertir el IIA porque aún no está aprobado",
                    approvedByAll: "No se puede revertir el IIA porque ya está aprobado por todos",
                    revertButton: "revertir iia"
                  },
                  edit: {
                    cannotEditWhileTerminating: "No se puede editar el IIA durante el proceso de terminación.",
                    editButton: "editar iia",
                    modifyButton: "modificar iia"
                  },
                  approve: {
                    approveButton: "Aprobar",
                    confirm: {
                      part1: "Está a punto de ",
                      part2: "aprobar",
                      part3Terminate: " la rescisión de un Acuerdo Interinstitucional.",
                      part3Approve: " un Acuerdo Interinstitucional"
                    }
                  },
                  modalTerminate: {
                    body: "Una vez finalizado el IIA, su pareja recibirá una notificación sobre su finalización. La última aprobación sigue siendo válida y puede volver a su estado anterior en cualquier momento hasta que ambos aprueben la rescisión del IIA.",
                    title: {
                      part1: "Está a punto de ",
                      part2: "rescindir",
                      part3: " un Acuerdo Interinstitucional"
                    },
                    terminateButton: "Terminar"
                  },
                  modalRevert: {
                    title: "Está a punto de revertir el Acuerdo Interinstitucional a su estado anterior aprobado por todos los estados.",
                    revertButton: "Volver a"
                  },
                  modalModify: {
                    body: "Después de modificar el IIA, su socio recibirá una notificación sobre sus cambios. La última aprobación sigue siendo válida y puede revertirla a su estado anterior en cualquier momento hasta que ambos vuelvan a aprobar el IIA.",
                    title: "Está a punto de modificar e introducir cambios en el Acuerdo Interinstitucional.",
                    modifyButton: "Modifique"
                  },
                  iiaApprovedByAll: "El IIA ya está Aprobado por todos o el socio no ha compartido su ID de IIA local.",
                  approveTermination: "aprobar la rescisión",
                  approveIIA: "aprobar iia"
                },
                iiasEdit: {
                  title: "Acuerdos interinstitucionales",
                  subTitle: "Editar un IIA",
                  createNewSubTitle: "Crear un nuevo IIA",
                  createTab: "Crear IIA",
                  tab: "Editar IIA",
                  steps: {
                    yourDetails: "SUS DATOS",
                    partnerDetails: "DATOS DEL SOCIO",
                    conditions: "CONDICIONES",
                    overviewAndSubmit: "RESUMEN Y ENVÍO"
                  },
                  alerts: {
                    part1: "Se han detectado Códigos F ISCED no válidos en las condiciones de cooperación. Corrija los Códigos F ISCED que contengan menos de 4 dígitos seleccionando el apropiado en la lista desplegable de Códigos F ISCED.",
                    part2: "Debe rellenar al menos una Lengua de Instrucción en las condiciones de cooperación de Estudios de Estudiantes para continuar",
                    part3: "Debe rellenar al menos una Lengua de enseñanza en las condiciones de cooperación de los Profesores de plantilla para continuar",
                    part4: "La institución de enseñanza superior que ha seleccionado aún no está preparada para intercambiar acuerdos interinstitucionales a través de la red Erasmus sin papeles.",
                    missingName: "Debe rellenar el nombre del contacto para continuar",
                    invalidEmail: "Debe rellenar una dirección de correo electrónico válida para continuar",
                  },
                  iiaFeedback: {
                    saving: {
                      loading: "Guardar cambios...",
                      success: {
                        savedDraft: "Guardado con éxito como borrador de IIA",
                        submit: "Presentado con éxito el IIA",
                        updateDraft: "Proyecto de IIA actualizado con éxito",
                        update: "IIA actualizado con éxito"
                      },
                      failure: "Error al guardar los cambios."
                    },
                  },
                  errors: {
                    part1: "Debe seleccionar un Socio SCHAC para continuar",
                    part2: "Debe añadir al menos una condición de cooperación para continuar"
                  },
                  next: "Siguiente",
                  back: "Volver",
                  saveAsDraft: "GUARDAR COMO BORRADOR",
                  submit: "Enviar",
                  firstStep: {
                    header: "SUS DATOS",
                    institutionData: {
                      title: "Datos de su institución",
                      name: "Nombre: ",
                      country: "País: ",
                      schacCode: "Código SCHAC: ",
                      erasmusCode: "Código Erasmus: ",
                      orgUnit: "Unidad organizativa",
                      placeholder: "Seleccione"
                    },
                    contactPerson: {
                      title: "Personas de contacto",
                      name: {
                        title: "Nombre ",
                        placeholder: "Introduzca el nombre.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Introducir email..",
                        error: "Indique una dirección de correo electrónico válida."
                      },
                      phone: {
                        title: "Teléfono",
                        placeholder: "Introducir teléfono..",
                      },
                      deleteButton: "Borrar contacto",
                      addContactPerson: "añadir persona de contacto"
                    }
                  },
                  secondStep: {
                    header: "DATOS DEL SOCIO",
                    institutionData: {
                      title: "Datos de las instituciones asociadas",
                      institution: {
                        title: "Institución ",
                        loading: "Cargando...",
                        choose: "Elegir institución...",
                        error: "Seleccione su institución."
                      },
                      schac: {
                        title: "Código SCHAC",
                        loading: "Cargando...",
                        choose: "Elija el código SCHAC...",
                        error: "Seleccione su código SCHAC."
                      },
                      country: "País: ",
                      schacCode: "Código SCHAC: ",
                      erasmusCode: "Código Erasmus: ",
                      orgUnit: "Unidad organizativa",
                      placeholder: "Seleccione"
                    },
                    erasmusCode: {
                      title: "Código Erasmus ",
                      loading: "Cargando...",
                      choose: "Elija el código Erasmus...",
                      error: "Seleccione su código Erasmus."
                    },
                    orgUnit: {
                      title: "Unidad organizativa",
                      loading: "Cargando...",
                      choose: "Elija OUnit...",
                      error: "Seleccione su OUnit."
                    },
                    contactPersons: {
                      title: "Personas de contacto",
                      name: {
                        title: "Nombre ",
                        placeholder: "Introduzca el nombre.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Introducir email..",
                        error: "Indique una dirección de correo electrónico válida."
                      },
                      phone: {
                        title: "Teléfono",
                        placeholder: "Introducir teléfono..",
                      },
                      deleteButton: "Borrar contacto",
                      addContactPerson: "añadir persona de contacto"
                    }
                  },
                  thirdStep: {
                    mobilityTypes: {
                      studentStudies: "Estudios de estudiantes",
                      studentTraineeships: "Prácticas para estudiantes",
                      staffTeachers: "Personal docente",
                      staffTrainings: "Formación del personal"
                    },
                    blendedMobility: {
                      yes: "Sí",
                      no: "No"
                    },
                    eqfLevels: {
                      lvl5: "Nivel 5 del EQF - Título de asociado",
                      lvl6: "Nivel 6 del EQF - Licenciatura",
                      lvl7: "Nivel 7 del EQF - Máster",
                      lvl8: "Nivel 8 del EQF - Doctorado",
                    },
                    typeOfCoopCondition: {
                      fillAllFields: "Rellene todos los campos obligatorios",
                      validEmailSending: "Introduzca una dirección de correo electrónico válida al enviar contactos",
                      validEmailReceiving: "Introduzca una dirección de correo electrónico válida en la recepción de contactos",
                      invalidISCED: "Se han detectado Códigos F ISCED no válidos en la condición de cooperación. Corrija los Códigos F ISCED que contengan menos de 4 dígitos seleccionando el apropiado en la lista desplegable de Códigos F ISCED.",
                    },
                    cooperationConditions: {
                      header: "CONDICIONES DE COOPERACIÓN",
                      staffTeacher: "Profesor de plantilla",
                      studyFields: "Campos de estudio",
                      staffTraining: "Formación del personal",
                      studentStudy: "Estudio de estudiantes",
                      studentTraineeship: "Prácticas para estudiantes"
                    },
                    daysPerYear: " días al año",
                    monthsPerYear: " meses al año",
                    heis: {
                      sender: "REMITENTE",
                      receiver: "RECEPTOR"
                    },
                    conditionDetails: {
                      header: "Detalles del estado",
                      mobilityType: "TIPO DE MOVILIDAD",
                      duration: "DURACIÓN",
                      academicYears: "AÑOS ACADÉMICOS",
                      eqfLevel: "Nivel EQF",
                      spots: "PUNTOS"
                    },
                    otherInfo: "OTROS DATOS",
                    languageRequirements: {
                      header: "Requisitos lingüísticos",
                      language: "IDIOMA",
                      studyField: "CAMPO DE ESTUDIO",
                      clarification: "ACLARACIÓN"
                    },
                    studyFields: {
                      header: "Campos de estudio",
                      studyField: "CAMPO DE ESTUDIO",
                      clarification: "ACLARACIÓN"
                    },
                    contacts: {
                      header: "Contactos",
                      sendingContacts: "ENVÍO DE CONTACTOS",
                      receivingContacts: "RECEPCIÓN DE CONTACTOS"
                    },
                    editConditionButton: "condición de edición",
                    deleteCoopConditionButton: "Suprimir condición Coop",
                    addNewConditionButton: "añadir una nueva condición",
                    modal: {
                      title: "Números de movilidad por curso académico",
                      subTitle: "Los socios se comprometen a modificar el cuadro que figura a continuación en caso de que se produzcan cambios en los datos de movilidad, a más tardar a finales de septiembre del curso académico anterior. ",
                      schacCode: {
                        sender: {
                          title: "Remitente Código SCHAC",
                          placeholder: "Seleccione SCHAC..."
                        },
                        receiver: {
                          title: "Receptor Código SCHAC",
                          placeholder: "Seleccione SCHAC..."
                        }
                      },
                      orgUnit: {
                        sender: {
                          title: "Unidad organizativa remitente",
                          placeholder: "Seleccionar unidad organizativa..."
                        },
                        receiver: {
                          title: "Unidad organizativa receptora",
                          placeholder: "Seleccionar unidad organizativa..."
                        }
                      },
                      mobilitiesPerYear: {
                        title: "Movilidades por año",
                        description: "Número máximo de personas a enviar cada curso académico"
                      },
                      academicYear: {
                        start: {
                          title: "Inicio del año académico",
                          placeholder: "Elija Año académico de inicio...",
                          error: "Seleccione su código SCHAC."
                        },
                        end: {
                          title: "Fin de año académico",
                          placeholder: "Elija Fin de Año Académico...",
                          error: "Seleccione su código SCHAC."
                        }
                      },
                      subjectArea: {
                        header: "Área temática",
                        fCode: {
                          title: "Código F de la ISCED",
                          placeholder: "Seleccione el código ISCED"
                        },
                        clarification: {
                          title: "Aclaración sobre la ISCED",
                          placeholder: "Introducir texto.."
                        },
                        deleteButton: "Suprimir área temática",
                        addSubjectArea: "añadir área temática"
                      },
                      mobilityType: {
                        header: "Tipo de movilidad",
                        description: "Los socios se comprometen a modificar el cuadro que figura a continuación en caso de que se produzcan cambios en los datos de movilidad, a más tardar a finales de septiembre del curso académico anterior.",
                        duration: {
                          title: "Duración",
                          totalMonths: " (total meses por año)",
                          totalDays: " (días totales al año)",
                          fill: {
                            allStudents: "Rellene la suma de meses para todos los estudiantes (por ejemplo, 10 estudiantes en una movilidad de 6 meses da una duración de 60 meses)",
                            staff: "Rellene la suma de meses para el personal (por ejemplo, 4 miembros del personal en una movilidad de 20 días da una duración de 80 días)"
                          }
                        },
                        studyCycle: {
                          header: "Ciclo de estudios",
                          placeholder: "Seleccione uno o varios..."
                        },
                        blendedMobility: {
                          header: "Opción de movilidad combinada",
                          confirmation: "*Al elegir «Sí» en la opción de movilidad semipresencial, los socios confirman que están dispuestos a intercambiar estudiantes que deseen realizar su movilidad en un formato semipresencial, una combinación de una movilidad física de corta duración con un componente virtual. "
                        }
                      },
                      languageSkills: {
                        header: "Conocimientos lingüísticos recomendados",
                        description: "La institución de envío, previo acuerdo con la institución de acogida, es responsable de proporcionar apoyo a sus candidatos designados para que puedan tener las competencias lingüísticas recomendadas al inicio del periodo de estudios o enseñanza (Movilidad de estudiantes para estudios - Nivel mínimo recomendado: B1):",
                        languageOfInstruction: {
                          title: "Lengua de enseñanza",
                          placeholder: "Seleccione un idioma"
                        },
                        level: {
                          title: "Lengua de enseñanza Nivel",
                          placeholder: "Seleccione un nivel de idioma"
                        },
                        fCode: {
                          title: "Código F de la ISCED",
                          placeholder: "Seleccione el código ISCED"
                        },
                        clarification: {
                          title: "Aclaración sobre la ISCED",
                          placeholder: "Introducir texto.."
                        },
                        deleteButton: "Borrar idioma",
                        addLanguage: "añadir IDIOMA"
                      },
                      contact: {
                        sending: {
                          header: "Personas de contacto remitentes",
                          description: "Rellene los datos de las personas de contacto remitentes",
                          addContact: "añadir persona de contacto remitente"
                        },
                        receiving: {
                          header: "Personas de contacto receptoras",
                          description: "Rellene los datos de las personas de contacto receptoras",
                          addContact: "añadir persona de contacto receptora"
                        },
                        name: {
                          title: "Nombre",
                          placeholder: "Introduzca el nombre.."
                        },
                        email: {
                          title: "Email",
                          placeholder: "Introducir email..",
                          error: "Indique una dirección de correo electrónico válida."
                        },
                        phone: {
                          title: "Teléfono",
                          placeholder: "Introducir teléfono.."
                        },
                        deleteContact: "Borrar contacto"
                      },
                      otherInfo: {
                        header: "Información adicional",
                        description: "Rellene cualquier otra información relativa a los términos del Acuerdo (5000 caracteres como máximo)",
                        placeholder: "Introduzca otra información.."
                      },
                      addCondition: "añada esta condición",
                      addNewCondition: "Añadir una nueva condición de cooperación",
                      fillNew: "Rellene los siguientes campos para añadir una nueva condición de cooperación ",
                      saveChanges: "guardar cambios de condición",
                      editCondition: "Editar la condición de cooperación",
                      fillEdit: "Rellene los siguientes campos para editar la condición de cooperación"
                    }
                  },
                  forthStep: {
                    overview: {
                      header: "RESUMEN Y ENVÍO",
                      staticInfo: {
                        header: "Información estática aplicable a todos los acuerdos interinstitucionales",
                        body: "Las instituciones acuerdan cooperar para el intercambio de estudiantes y/o personal en el contexto del programa Erasmus. Se comprometen a respetar los requisitos de calidad de la Carta Erasmus de Educación Superior en todos los aspectos relacionados con la organización y la gestión de la movilidad, en particular el reconocimiento automático de los créditos concedidos a los estudiantes por la institución asociada según lo acordado en el Acuerdo de Aprendizaje y confirmado en el Expediente Académico, o de acuerdo con los resultados de aprendizaje de los módulos realizados en el extranjero, según lo descrito en el Catálogo de Cursos, en consonancia con el Sistema Europeo de Créditos y Acumulación. Las instituciones acuerdan intercambiar sus datos relacionados con la movilidad de acuerdo con las normas técnicas de la Iniciativa de la Tarjeta de Estudiante Europeo.",
                      },
                      gradingSystem: {
                        header: "Sistemas de clasificación de las instituciones",
                        body: "Se recomienda que las instituciones receptoras proporcionen un enlace a la distribución estadística de las calificaciones o pongan a disposición la información a través de EGRACONS de acuerdo con las descripciones de la guía del usuario del ECTS. La información facilitará la interpretación de cada calificación otorgada a los estudiantes y facilitará la transferencia de créditos por parte de la institución de envío."
                      }
                    },
                    generalInfo: {
                      header: "Información general",
                      institutionData: {
                        header: "LOS DATOS DE SU INSTITUCIÓN",
                        headerPartner: "DATOS DE LA INSTITUCIÓN ASOCIADA",
                        name: "Nombre",
                        country: "País",
                        schacCode: "Código SCHAC",
                        erasmusCode: "Código Erasmus",
                        orgUnit: "Org. Unidad",
                        approvingPerson: "Persona autorizadora",
                        contactPerson: "Personas de contacto"
                      }
                    },
                    factSheet: {
                      yourFactSheet: {
                        header: "Sus datos",
                        calendar: {
                          title: "CALENDARIO",
                          studentNominations: "Las candidaturas de estudiantes deben llegar a la institución receptora antes del: ",
                          autumnTerm: "Trimestre de otoño: ",
                          springTerm: "Trimestre de primavera: ",
                          applicationsForNominated: "Las solicitudes de los estudiantes propuestos deben llegar a la institución receptora antes del: ",
                          applicationProcedure: "Procedimiento de solicitud para estudiantes designados: ",
                          email: "Email de contacto: ",
                          phone: "Teléfono de contacto: ",
                          websiteForInfo: "Sitio web de información: "
                        },
                        additionalRequirements: {
                          title: "REQUISITOS ADICIONALES",
                          requirements: "REQUISITO",
                          details: "DETALLES",
                          ectsNumber: "NÚMERO ECTS",
                          subjectFieldISCED: "CAMPO TEMÁTICO (ISCED)",
                          studyLevelEQF: "NIVEL DE ESTUDIOS (EQF)",
                          website: "PÁGINA WEB",
                          message: "La institución enviará su decisión en un plazo de 4 semanas, y a más tardar en 5 semanas."
                        },
                        inclusionAndAccessibility: {
                          title: "INCLUSIÓN Y ACCESIBILIDAD",
                          descriptionMain: {
                            part1: "La institución proporcionará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          infrastructure: "Infraestructura",
                          name: "NOMBRE",
                          description: "DESCRIPCIÓN",
                          contact: "CONTACTO",
                          services: "Servicios"
                        },
                        housing: {
                          title: "VIVIENDA",
                          description: {
                            part1: "La institución guiará a los participantes móviles entrantes en la búsqueda de alojamiento, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          contact: "Contacto"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "La institución prestará asistencia, cuando sea necesario, en la obtención de visados para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          contact: "Contacto"
                        },
                        insurance: {
                          title: "SEGURO",
                          description: {
                            part1: "La institución prestará asistencia en la obtención de un seguro para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " La institución receptora informará a los participantes móviles de los casos en que no se proporcione automáticamente la cobertura del seguro.",
                            part3: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          contact: "Contacto"
                        },
                        additionalInfo: {
                          title: "INFORMACIÓN ADICIONAL",
                          description: {
                            part1: "La institución proporcionará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          contact: "CONTACTO"
                        }
                      },
                      partnerFactSheet: {
                        header: "Datos de la ficha de socio",
                        calendar: {
                          title: "CALENDARIO",
                          studentNominations: "Las candidaturas de estudiantes deben llegar a la institución receptora antes del: ",
                          autumnTerm: "Trimestre de otoño: ",
                          springTerm: "Trimestre de primavera: ",
                          applicationsForNominated: "Las solicitudes de los estudiantes propuestos deben llegar a la institución receptora antes del: ",
                          applicationProcedure: "Procedimiento de solicitud para estudiantes designados: ",
                          email: "Email de contacto: ",
                          phone: "Teléfono de contacto: ",
                          websiteForInfo: "Sitio web de información: "
                        },
                        additionalRequirements: {
                          title: "REQUISITOS ADICIONALES",
                          requirements: "REQUISITO",
                          details: "DETALLES",
                          ectsNumber: "NÚMERO ECTS",
                          subjectFieldISCED: "CAMPO TEMÁTICO (ISCED)",
                          studyLevelEQF: "NIVEL DE ESTUDIOS (EQF)",
                          website: "PÁGINA WEB",
                          message: "La institución enviará su decisión en un plazo de 4 semanas, y a más tardar en 5 semanas."
                        },
                        inclusionAndAccessibility: {
                          title: "INCLUSIÓN Y ACCESIBILIDAD",
                          descriptionMain: {
                            part1: "La institución proporcionará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          infrastructure: "Infraestructura",
                          name: "NOMBRE",
                          description: "DESCRIPCIÓN",
                          contact: "CONTACTO",
                          services: "Servicios"
                        },
                        housing: {
                          title: "VIVIENDA",
                          description: {
                            part1: "La institución guiará a los participantes móviles entrantes en la búsqueda de alojamiento, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          contact: "Contacto"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "La institución prestará asistencia, cuando sea necesario, en la obtención de visados para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          contact: "Contacto"
                        },
                        insurance: {
                          title: "SEGURO",
                          description: {
                            part1: "La institución prestará asistencia en la obtención de un seguro para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " La institución receptora informará a los participantes móviles de los casos en que no se proporcione automáticamente la cobertura del seguro.",
                            part3: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          contact: "Contacto"
                        },
                        additionalInfo: {
                          title: "INFORMACIÓN ADICIONAL",
                          description: {
                            part1: "La institución proporcionará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior.",
                            part2: " Los siguientes puntos de contacto y fuentes de información pueden facilitar información y asistencia:"
                          },
                          contact: "CONTACTO"
                        }
                      }
                    },
                    cooperationConditions: {
                      header: "Condiciones de cooperación",
                      blendedMobility: {
                        yes: "Yes",
                        no: "No"
                      },
                      eqfLevels: {
                        lvl5: "EQF Level 5 - Associate Degree",
                        lvl6: "EQF Level 6 - Bachelor's Degree",
                        lvl7: "EQF Level 7 - Master's Degree",
                        lvl8: "EQF Level 8 - Doctorate Degree",
                      },
                      typeOfCoopCondition: {
                        fillAllFields: "Please fill all the required fields",
                        validEmailSending: "Please fill a valid email address at sending contacts",
                        validEmailReceiving: "Please fill a valid email address at receiving contacts",
                        invalidISCED: "Invalid ISCED F-Codes are detected in the cooperation condition. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.",
                      },
                      cooperationConditions: {
                        header: "CONDICIONES DE COOPERACIÓN",
                        staffTeacher: "Profesor de plantilla",
                        staffTeachers: "Personal docente",
                        studyFields: "Campos de estudio",
                        staffTraining: "Formación del personal",
                        staffTrainings: "Formación del personal",
                        studentStudy: "Estudio de estudiantes",
                        studentTraineeship: "Prácticas para estudiantes"
                      },
                      daysPerYear: " días al año",
                      monthsPerYear: " meses al año",
                      heis: {
                        sender: "REMITENTE",
                        receiver: "RECEPTOR"
                      },
                      conditionDetails: {
                        header: "Detalles del estado",
                        mobilityType: "TIPO DE MOVILIDAD",
                        duration: "DURACIÓN",
                        academicYears: "AÑOS ACADÉMICOS",
                        eqfLevel: "Nivel EQF",
                        spots: "PUNTOS"
                      },
                      otherInfo: "OTROS DATOS",
                      languageRequirements: {
                        header: "Requisitos lingüísticos",
                        language: "IDIOMA",
                        studyField: "CAMPO DE ESTUDIO",
                        clarification: "ACLARACIÓN"
                      },
                      studyFields: {
                        header: "Campos de estudio",
                        studyField: "CAMPO DE ESTUDIO",
                        clarification: "ACLARACIÓN"
                      },
                      contacts: {
                        header: "Contactos",
                        sendingContacts: "ENVÍO DE CONTACTOS",
                        receivingContacts: "RECEPCIÓN DE CONTACTOS"
                      }
                    },
                    confirmation: "Confirmo, también en nombre de mi HEI, que dispongo de todas las autorizaciones, incluido el consentimiento de las personas físicas pertinentes, necesarias para cargar los datos personales y la información que estoy proporcionando, de conformidad con los Términos y Condiciones del Panel y la Política de Privacidad.",
                    confirmationCheck: "Por favor, acepte que ha leído la Política de Privacidad y los Términos y Condiciones."
                  }
                },
                iiaImport: {
                  subTitle: "Importar IIA",
                  alerts: {
                    incorrectFileType: "Lo sentimos, pero el archivo seleccionado no tiene la extensión correcta. Por favor, seleccione un archivo .csv.",
                    invalidTemplate: "Lo sentimos, pero la plantilla utilizada no es válida. Puede obtener la plantilla oficial en la página de Import IIA.",
                    errorMessage: {
                      part1: "Actualmente, la importación de acuerdos interinstitucionales solo acepta hasta ",
                      part2: " acuerdos y el archivo que acaba de seleccionar ",
                      part3: "tener un total de ",
                      part4: " acuerdos, ",
                      part5: "entonces ",
                      part6: "el ultimo ",
                      part7: " Los acuerdos válidos serán descartados.",
                      message1: "con ",
                      message2: "estas filas no válidas y ",
                      emptyRows: " filas vacías, ",
                      wrongPartnerEC: " acuerdos con PartnerEC incorrectos (los socios no pueden tener el mismo Código Erasmus que usted o un Código Erasmus no válido), "
                    },
                    errorMessage2: {
                      message:  "Este error puede deberse a una de las siguientes razones:\n" +
                                " - Todas las filas del archivo cargado están vacías o no son válidas;\n" +
                                " - There is no Partner EC;" +
                                " - Ha utilizado su propia EC como EC socia.\n" +
                                "Por favor, revise su archivo. Si no se corrigen estos errores, no será posible revisar los campos restantes."
                    },
                  },
                  header: "Subir archivo CSV para importar Convenios Interinstitucionales",
                  loadingMessage: "Esto puede tardar un poco, por favor no cierre esta página!",
                  returnToIIAListing: "Regresar al listado de IIA",
                  returnToUploadPage: "Regresar a la página de carga",
                  description: "La función de importación de acuerdos interinstitucionales ahora permite cargar archivos CSV. Una vez introducidos los datos, puede revisar los acuerdos uno por uno para asegurarse de que toda la información es correcta y enviar los datos. Esta acción desencadenará la creación de un acuerdo interinstitucional precumplimentado y una notificación al socio invitándole a editar o firmar el documento. También es posible enviar todos los acuerdos al mismo tiempo haciendo clic en el botón «Importar todos».",
                  verifyFormat: "El formato del archivo CSV se puede verificar mediante el uso de una plantilla de hoja de cálculo disponible en el EWP CC  ",
                  here: "aquí",
                  confirmation: "Confirmo, también en nombre de mi HEI, que dispongo de todas las autorizaciones, incluido el consentimiento de las personas físicas pertinentes, necesarias para cargar los datos personales y la información que estoy proporcionando, de conformidad con los Términos y Condiciones del Panel y la Política de Privacidad.                        ",
                  consentError: "Por favor, dé su consentimiento para el tratamiento de datos",
                  uploadButton: "Subir",
                  importConfirmation: "Está seguro de que desea importar todos los acuerdos?"
                },
                iiaExport: {
                  subTitle: "IIA de exportación",
                  header: "Descargar archivo CSV para exportar Convenios Interinstitucionales",
                  description: "La función de exportación del Acuerdo Interinstitucional ahora admite la descarga en formato CSV. El archivo CSV de exportación para su institución se crea de forma asíncrona y periódica. El archivo CSV más reciente está disponible para su descarga a continuación.",
                  message: {
                    part1: "Archivo de exportación CSV creado exitosamente el",
                    part2: " y está disponible para descargar"
                  },
                  downloadButton: "Descargar"
                },
                editFactsheet: {
                  subTitle: "Editar hoja informativa",
                  loadingMessage: "Cargando...",
                  errorMessage: "Se produjo un error",
                  factsheetFeedBack: {
                    update: {
                      success: "Datos de la hoja informativa actualizados correctamente.",
                      failure: "Se produjo un error al actualizar los datos de la hoja informativa.",
                      alert: "Se produjo un error al actualizar los datos de la hoja informativa: "
                    }
                  },
                  calendar: {
                    header: "CALENDARIO",
                    studentNominations: "Las nominaciones de estudiantes deben llegar a la institución receptora antes del:",
                    autumnTerm: "Fecha del semestre de otoño ",
                    springTerm: "Fecha del semestre de primavera ",
                    invalidDateFormat: "Formato de fecha no válido",
                    applicationsForNominated: "Las solicitudes de estudiantes nominados deben llegar a la institución receptora antes del:",
                    applicationProcedure: "Procedimiento de solicitud para estudiantes nominados:",
                    email: {
                      title: "Email de contacto ",
                      error: "No válida"
                    },
                    phone: {
                      title: "Teléfono de contacto ",
                      error: " Ingrese un número de teléfono que comience con el código de su país (ej: +000)."
                    },
                    websiteForInfo: {
                      title: "Sitio web para información ",
                      error: "Ingrese una URL que comience con http o https."
                    },
                    defaultWeekValues: "Valores de semanas predeterminados:",
                    weeksForDecision: {
                      title: "Semanas para la decisión sobre requisitos adicionales ",
                      error: "No válida"
                    },
                    weeksForTranscript: {
                      title: "Weeks for transcript of records issue  ",
                      error: "No válida"
                    }
                  },
                  additionalRequirements: {
                    title: "REQUISITOS ADICIONALES",
                    requirement: {
                      title: "Requisito ",
                      placeholder: "Seleccionar requisito"
                    },
                    details: {
                      title: "Detalles",
                    },
                    ectsNumber: {
                      title: "Número de ECTS completados",
                    },
                    studyFieldISCED: {
                      title: "Campo de estudio (ISCED)",
                      placeholder: "Seleccionar ISCED"
                    },
                    studyLevelEQF: {
                      title: "Nivel de estudio (EQF)",
                      placeholder: "Seleccionar EQF"
                    },
                    website: {
                      title: "Sitio web para información",
                      error: "Ingrese una URL que comience con http o https."
                    },
                    deleteButton: "Eliminar requisito adicional",
                    addRequirement: "Agregar requisito adicional"
                  },
                  inclusionAndAccessibility: {
                    title: "INCLUSIÓN Y ACCESIBILIDAD",
                    descriptionMain: "La institución brindará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior. Se puede proporcionar información y asistencia a través de los siguientes puntos de contacto y fuentes de información:",
                    infrastructure: "Infraestructura",
                    name: "Nombre ",
                    description: "Descripción",
                    contactEmail: {
                      title: "Email de contacto ",
                      error: "No válida"
                    },
                    contactPhone: {
                      title: "Teléfono de contacto ",
                      error: "Ingrese un número de teléfono que comience con el código de su país (ej: +000)."
                    },
                    website: {
                      title: "Sitio web para información ",
                      error: "Ingrese una URL que comience con http o https."
                    },
                    addInfrastucture: "Agregar infraestructura",
                    services: "Servicios",
                    addService: "Agregar servicio"
                  },
                  deleteInfrastructure: "Eliminar infraestructura",
                  deleteService: "Eliminar servicio",
                  housing: {
                    title: "ALOJAMIENTO",
                    description: "La institución orientará a los participantes móviles entrantes en la búsqueda de alojamiento, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior. Se puede proporcionar información y asistencia a través de los siguientes puntos de contacto y fuentes de información:",
                    contact: "Contacto",
                    contactEmail: {
                      title: "Email de contacto ",
                      error: "No válida"
                    },
                    contactPhone: {
                      title: "Teléfono de contacto ",
                      error: "Ingrese un número de teléfono que comience con el código de su país (ej: +000)."
                    },
                    website: {
                      title: "Sitio web para información ",
                      error: "Ingrese una URL que comience con http o https."
                    }
                  },
                  visa: {
                    title: "VISA",
                    description: "La institución brindará asistencia, cuando sea necesario, para obtener visados ​​para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior. Se puede proporcionar información y asistencia a través de los siguientes puntos de contacto y fuentes de información:",
                    contact: "Contacto",
                    contactEmail: {
                      title: "Email de contacto ",
                      error: "No válida"
                    },
                    contactPhone: {
                      title: "Teléfono de contacto ",
                      error: "Ingrese un número de teléfono que comience con el código de su país (ej: +000)."
                    },
                    website: {
                      title: "Sitio web para información ",
                      error: "Ingrese una URL que comience con http o https."
                    }
                  },
                  insurance: {
                    title: "SEGURO",
                    description: "La institución proporcionará asistencia para la obtención de un seguro para los participantes móviles entrantes y salientes, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior. La institución de acogida informará a los participantes móviles sobre los casos en los que no se proporciona cobertura de seguro automáticamente. Se puede proporcionar información y asistencia a través de los siguientes puntos de contacto y fuentes de información:",
                    contact: "Contacto",
                    contactEmail: {
                      title: "Email de contacto ",
                      error: "No válida"
                    },
                    contactPhone: {
                      title: "Teléfono de contacto ",
                      error: "Ingrese un número de teléfono que comience con el código de su país (ej: +000)."
                    },
                    website: {
                      title: "Sitio web para información ",
                      error: "Ingrese una URL que comience con http o https."
                    }
                  },
                  additionalInfo: {
                    title: "INFORMACIÓN ADICIONAL",
                    description: "La institución brindará apoyo a los participantes móviles entrantes con necesidades especiales, de acuerdo con los requisitos de la Carta Erasmus de Educación Superior. Se puede proporcionar información y asistencia a través de los siguientes puntos de contacto y fuentes de información:",
                    recognitionProcess: "Proceso de reconocimiento",
                    contact: "CONTACTO",
                    contactEmail: {
                      title: "Email de contacto ",
                      error: "No válida"
                    },
                    contactPhone: {
                      title: "Teléfono de contacto ",
                      error: "Ingrese un número de teléfono que comience con el código de su país (ej: +000)."
                    },
                    website: {
                      title: "Sitio web para información ",
                      error: "Ingrese una URL que comience con http o https."
                    },
                    deleteRecognitionProcess: "Eliminar proceso de reconocimiento",
                    addRecognitionProcess: "Agregar información del proceso de reconocimiento",
                    otherInfo: "Otra información útil",
                    deleteInfo: "Eliminar otra información",
                    addInfo: "Agregar otra información útil"
                  },
                  saveFactsheet: "guardar datos de la hoja informativa",
                  containsErrors: "El formulario contiene errores",
                  confirmation: "Confirmo, también en nombre de mi HEI, que dispongo de todas las autorizaciones, incluido el consentimiento de las personas físicas pertinentes, necesarias para cargar los datos personales y la información que estoy proporcionando, de conformidad con los Términos y Condiciones del Panel y la Política de Privacidad.",
                  confirmationError: "Por favor, dé su consentimiento para el tratamiento de datos",
                  saveConfirmation: "Estás a punto de editar los datos de tu hoja informativa"
                },
                filter: {
                  title: "Filtros y clasificación",
                  clearButton: "Borrar todo",
                  applyButton: "Aplicar filtros",
                  academicTerms: {
                    firstSemester: "Primer semestre (invierno/otoño)",
                    secondSemester: "Segundo semestre (verano/primavera)",
                    firstTrimester: "Primer trimestre",
                    secondTrimester: "Segundo trimestre",
                    fullYear: "Año académico completo",
                  },
                  sortingList: {
                    status: "Clasificación por estado",
                    lastChangedAscending: "Ordenación por Última modificación Ascendente",
                    lastChangedDescending: "Ordenación por Última modificación Descendente",
                    name: "Ordenar por nombre"
                  },
                  pendingRequests: {
                    pending: "Pendiente",
                    accepted: "Aceptado",
                    declined: "Rechazado"
                  },
                  sortBy: {
                    title: "Ordenar por",
                    placeholder: "Seleccione ordenar por..."
                  },
                  status: {
                    title: "Estado",
                    placeholder: "Seleccionar estado..."
                  },
                  iia: {
                    title: "IIA ID",
                    placeholder: "IIA ID",
                    label: "Búsqueda por ID o ID de socio:"
                  },
                  academicYear: {
                    title: "Año académico",
                    placeholder: "Seleccione el año académico..."
                  },
                  academicTerm: {
                    title: "Período lectivo",
                    placeholder: "Seleccione el año académico..."
                  },
                  country: {
                    title: "País",
                    placeholder: "Seleccione uno o varios países..."
                  },
                  institution: {
                    title: "Institución",
                    erasmusCode: "Código Erasmus:",
                    esasmusCodePlaceholder: "Seleccione el código Erasmus...",
                    institutionName: "Nombre de la institución:",
                    institutionNamePlaceholder: "Seleccione el nombre de la institución...",
                  },
                  approvingEmail: {
                    title: "Email de la persona autorizada",
                    placeholder: "Email de la persona autorizada"
                  },
                  createdBy: {
                    title: "Creado por",
                    placeholder: "Selección creada por...",
                    student: "Creado por un estudiante",
                    institution: "Creado por Institución"
                  },
                  studentName: {
                    title: "Nombre del alumno",
                    firstNameLabel: "Nombre:",
                    firstNamePlaceholder: "Nombre",
                    lastNameLabel: "Apellido:",
                    lastNamePlaceholder: "Apellido"
                  },
                  organizationalUnit: {
                    title: "Unidad organizativa",
                    placeholder: "Seleccionar unidad organizativa..."
                  },
                  name: {
                    title: "Nombre",
                  },
                  email: {
                    title: "Email",
                    error: "Por favor, indique un valor válido."
                  }
                },
                modal: {
                  cancelButton: "Cancelar"
                },
                selectPhoneInput: {
                  label: "Código telefónico",
                  placeholder: "Elija el código telefónico...",
                  error: {
                    a: "Indique un prefijo telefónico",
                    b: "Indique un teléfono válido."
                  }
                },
                table: {
                  filterLabel: "Filtros",
                  totalAgreements: "Acuerdos totales",
                  activeFilters: "Filtros activos:",
                  noResults: "Lo sentimos, no hemos encontrado ningún resultado"
                },
                coursesTable: {
                  olaReasons: {
                    part1: "El componente educativo seleccionado previamente no está disponible en la institución receptora",
                    part2: "El componente está en un idioma diferente al especificado previamente en el catálogo del curso",
                    part3: "Conflicto de horarios",
                    part4: "Otros (especifique)",
                    part5: "Sustitución de un componente suprimido",
                    part6: "Ampliar el periodo de movilidad",
                    part7: "Otros (especifique)",
                    other: "Otros "
                  },
                  noCourses: "No hay cursos que mostrar",
                  totalECTs: "Total créditos ECTS: ",
                  labels: {
                    code: "CÓDIGO",
                    subject: "ASUNTO",
                    ects: "ECTS",
                    semester: "SEMESTRE",
                    reason: "RAZÓN",
                    autoRecognition: "RECONOCIMIENTO AUTOMÁTICO",
                    description: "DESCRIPCIÓN"
                  }
                },
                olaPdf: {
                  download: {
                    part1: "Descargue el PDF haciendo clic en ",
                    part2: "aquí"
                  }
                },
                institutionInfoTable: {
                  name: "Nombre:",
                  country: "País:",
                  erasmusCode: "Código Erasmus:",
                  address: "Dirección:",
                  faculty: "Facultad:",
                  contactPerson: "Persona de contacto: ",
                  resPerson: "Res. Persona:"
                },
                learningAgreementInfoTable: {
                  status: "ESTADO",
                  academicYear: "AÑO ACADÉMICO",
                  plannedPeriod: "PERÍODO PLANIFICADO",
                  created: "CREADO",
                  pdfExportText: "Exportar a PDF"
                },
                studentInfo: {
                  firstName: "Nombre:",
                  lastName: "Apellido:",
                  email: "Email:",
                  birthDate: "Fecha de nacimiento:",
                  gender: "Género:",
                  nationality: "Nacionalidad:",
                  foeISCED: "Ámbito de la educación (ISCED):",
                  foeClarification: "Ámbito de la educación (Aclaración):"
                },
                tor: {
                  totalECTs: "Total ECTS: ",
                  form: {
                    componentCode: {
                      title: {
                        part1: "Código de componente",
                        part2: "*"
                      },
                      error: "Indique un código.",
                    },
                    componentTitle: {
                      title: {
                        part1: "Componente Título",
                        part2: "*"
                      },
                      error: "Indique un título."
                    },
                    grade: {
                      title: {
                        part1: "Grado",
                        part2: "*"
                      },
                      error: "Indique una calificación."
                    },
                    ectNum: {
                      title: {
                        part1: "Número de créditos ECTS",
                        part2: "*"
                      },
                      error: "Proporcione créditos ects."
                    },
                    success: {
                      part1: "Completado con éxito",
                      part2: "*"
                    }
                  },
                  torFeedback: {
                    create: {
                      loading: "Creación de nuevos ToR...",
                      success: "ToR creado con éxito",
                      failure: "Error al crear los ToR."
                    },
                    update: {
                      loading: "Actualización de un nuevo ToR...",
                      success: "ToR actualizado con éxito",
                      failure: "Error al actualizar los ToR."
                    }
                  },
                  componentFeedback: {
                    create: {
                      loading: "Creación de un nuevo componente...",
                      success: "Componente creado con éxito",
                      failure: "Error al crear el componente."
                    },
                    edit: {
                      loading: "Editar un nuevo componente...",
                      success: "Componente editado correctamente",
                      failure: "Error al editar el componente."
                    },
                    delete: {
                      loading: "Suprimir un nuevo componente...",
                      success: "Componente eliminado correctamente",
                      failure: "Error al borrar un componente."
                    }
                  },
                  createTor: "Crear ToR",
                  mobilityStart: "Fecha de inicio de la movilidad:",
                  mStart: "Fecha de inicio de la movilidad",
                  mEnd: "Fecha final de movilidad",
                  mobilityEnd: "Fecha final de movilidad:",
                  invalidInput: "Por favor, indique un valor válido.",
                  transcript: "EXPEDIENTE ACADÉMICO EN LA INSTITUCIÓN RECEPTORA",
                  recognition: "RECONOCIMIENTO EN LA INSTITUCIÓN DE ORIGEN",
                  addComponentTitle: { 
                    part1: "Añadir un nuevo ",
                    part2: "Componente"
                  },
                  editComponentTitle: {
                    part1: "Editar ",
                    part2: "Componente"
                  },
                  deleteComponentTitle: {
                    confirmMessage: "Estás seguro de que?",
                    part1: "Borrar ",
                    part2: "Componente"
                  },
                  updateTor: {
                    part1: "Actualización ",
                    part2: "ToR"
                  },
                  addComponent: "añadir un nuevo componente",
                  editComponent: "Editar componente",
                  deleteComponent: "Eliminar componente",
                  submitButton: "Enviar",
                  updateButton: "Actualización"
                },
                destinationHeader: {
                  nominated: "NOMINADO",
                  rejected: "RECHAZADO",
                  nominate: "Nominar"
                },
                destinationInfo: {
                  studyLevel: "NIVEL DE ESTUDIOS",
                  studyField: "CAMPO DE ESTUDIO",
                  reqDocuments: "DOCUMENTOS REQUERIDOS",
                  otherReq: "OTROS REQUISITOS"
                },
            }
          },
        it: {
            translation: {
                loadingMessage: "Caricamento...",
                errorMessage: "Si è verificato un errore",
                sidebar: {
                  institutions: "Istituzioni",
                  requests: "Richieste",
                  myUniversity: "La mia università",
                  accountsAndAccess: "Conti e accesso",
                  organizationalUnits: "Unità organizzative",
                  ukraineAssistance: "Assistenza all'Ucraina",
                  ola: "OLA",
                  outgoingStudents: "Studenti in mobilità in uscita",
                  incomingStudents: "Studenti in mobilità in entrata",
                  upload: "Carica",
                  shortTerm: "A breve termine",
                  applications: "Domande",
                  requirements: "Requisiti",
                  iia: "IIA",
                  iiaList: "Elenco degli IIA",
                  newDigitalIIA: "Nuovo IIA digitale",
                  importIIA: "Importa IIA",
                  exportIIAs: "Esporta IIA",
                  defaultIIAData: "Dati predefiniti dell’IIA",
                  erasmusApp: "App Erasmus+",
                  usefulInfo: "Informazioni utili",
                  deals: "Offerte",
                  events: "Eventi",
                  coursesEvaluations: "Valutazioni dei corsi",
                  ewpSettings: "Impostazioni EWP",
                },
                home: {
                    header: "Benvenuti nel cruscotto EWP!",
                    title: "Pagina iniziale",
                    tab: "Casa",
                    description: {
                      part1: "Benvenuti in EWP Dashboard, lo strumento per gestire la mobilità dei vostri studenti attraverso il programma Erasmus+. In qualità di IRO, svolgete un ruolo fondamentale nell'aiutare i vostri studenti a trarre il massimo dalle loro esperienze all'estero, e EWP Dashboard è qui per sostenervi in questo sforzo.", 
                      part2: "Siamo lieti di annunciare che abbiamo apportato alcuni miglioramenti significativi all'interfaccia utente e all'esperienza d'uso dell'applicazione. Abbiamo lavorato per rendere EWP Dashboard ancora più intuitivo e facile da usare e crediamo che questi cambiamenti vi renderanno più facile gestire la mobilità dei vostri studenti e sfruttare al meglio le opportunità offerte dal programma Erasmus+."
                    }
                },
                articleSlider: {
                  loadingMessage: "Caricamento...",
                  errorMessage: "Si è verificato un errore",
                  created: "Creato: "
                },
                articleSingleView: {
                  title: "Post sul blog",
                  subTitle: "Vista singola",
                  created: "Creato: ",
                  lastUpdated: "Ultimo aggiornamento: ",
                  tags: "Tag: "
                },
                ewpSettings: {
                  header: "SCAMBIO DI INFORMAZIONI ATTRAVERSO IL CRUSCOTTO EWP",
                  title: "IMPOSTAZIONI EWP",
                  subTitle: {
                    part1: "",
                    part2: "Selezionare/deselezionare ",
                    part3: " in base a ciò che si applica alla propria HEI."
                  },
                  ewpSettingType: {
                    updatingSettings: "Aggiornamento delle impostazioni EWP...",
                    updatedSuccessfully: `Impostazioni EWP aggiornate con successo`,
                    unableToUpdate: `Impossibile aggiornare le impostazioni EWP`,
                    exchangeOfIIAs: {
                      title: "Scambio di IIA attraverso il cruscotto EWP",
                      checkbox: "Accetto che il cruscotto EWP rappresenti il mio istituto di istruzione superiore nella rete EWP per lo scambio di accordi interistituzionali con i vostri partner."
                    },
                    exchangeOfOUnits: {
                      title: "Scambio di Ounits attraverso il cruscotto EWP",
                      checkbox: "Accetto che l'EWP Dashboard rappresenti il mio istituto di istruzione superiore nella rete EWP allo scopo di condividere le informazioni sulle Unità Organizzative con i vostri partner."
                    },
                    exchangeOfStaticInfo: {
                      title: "Scambio di informazioni statiche per le IIA attraverso l'EWP Dashboard",
                      checkbox: "Accetto che il cruscotto EWP rappresenti il mio istituto di istruzione superiore nella rete EWP per lo scambio di informazioni statiche per gli accordi interistituzionali con i vostri partner."
                    },
                    exchangeOfOLAs: {
                      title: "Scambio di OLA attraverso il cruscotto EWP",
                      checkbox: "Accetto che l'EWP Dashboard rappresenti il mio istituto di istruzione superiore nella rete EWP allo scopo di consentire lo scambio elettronico di informazioni sull'Online Learning Agreement."
                    }
                  },
                  cancelButton: "Annullamento",
                  saveSettingsButton: "Salvare le impostazioni dell'EWP"
                },
                accountsAndAccess: {
                  title: "Conti e accesso",
                  accountsTab: "Conti",
                  rolesTab: "Ruoli",
                  saveChangesButton: "Salva le modifiche",
                  accountForm: {
                    accountName: {
                      label: "Nome del conto",
                      error: "Indicare il nome del conto."
                    },
                    accountEmail: {
                      label: "Email del conto",
                      error: "Si prega di fornire un'e-mail valida per l'account."
                    },
                    accountPhone: {
                      label: "Numero di telefono",
                      error: "Il numero di telefono registrato di questo account non è un numero di telefono valido. Si prega di fornire un telefono valido."
                    },
                    accountRole: {
                      label: "Ruolo",
                      placeholder: "Seleziona il ruolo...",
                      error: "Indicare un ruolo per l'account."
                    },
                  },
                  roleForm: {
                    roleName: {
                      label: "Nome del ruolo",
                      error: "Indicare il nome del ruolo."
                    },
                    rolePermissions: {
                      label: "Permessi",
                      placeholder: "Selezionare le autorizzazioni...",
                      error: {
                        a: "Selezionare almeno un permesso per questo ruolo.",
                        b: "È necessario aver scelto almeno un permesso per un ruolo."
                      }
                    }
                  },
                  accountFeedback: {
                    create: {
                      loading: "Creare un account...",
                      success: "L'account è stato creato con successo ",
                      failure: "Impossibile creare l'account "
                    },
                    update: {
                      loading: "Aggiornamento dell'account...",
                      success: "L'aggiornamento dell'account è riuscito ",
                      failure: "Impossibile aggiornare l'account "
                    },
                    delete: {
                      loading: "Eliminazione dell'account...",
                      success: "Eliminato con successo l'account ",
                      failure: "Impossibile eliminare l'account "
                    }
                  },
                  addNewAccount: {
                    title: "Aggiungere un nuovo account",
                    subTitle: "Aggiungete le informazioni necessarie per aggiungere un nuovo account per uno dei vostri collaboratori.",
                    buttonText: "Aggiungi un nuovo account"
                  },
                  editAccount: {
                    title: "Modifica di un conto esistente",
                    subTitle: "Modificare le informazioni di un Conto esistente e ",
                    buttonText: "Modifica account"
                  },
                  deleteAccount: {
                    title: {
                      part1: "State per ",
                      part2: "cancellare",
                      part3: " il conto "
                    },
                    subTitle: "Dopo aver eliminato l'account, il collaboratore non potrà più accedere.",
                    buttonText: "Cancellare l'account"
                  },
                  form: {
                    accountName: {
                      label: "Nome del conto",
                      error: "Indicare il nome del conto."
                    },
                    accountEmail: {
                      label: "Email del conto",
                      error: "Si prega di fornire un'e-mail valida per l'account."
                    },
                    accountPhone: {
                      label: "Numero di telefono",
                      error: "Il numero di telefono registrato di questo account non è un numero di telefono valido. Si prega di fornire un telefono valido."
                    },
                    accountRole: {
                      label: "Ruolo",
                      placeholder: "Seleziona il ruolo...",
                      error: "Indicare un ruolo per l'account."
                    }
                  },
                  roleFeedback: {
                    create: {
                      loading: "Creare un ruolo...",
                      success: "Il ruolo è stato creato con successo ",
                      failure: "Impossibile creare il ruolo "
                    },
                    update: {
                      loading: "Aggiornamento del ruolo...",
                      success: "Aggiornato con successo il ruolo ",
                      failure: "Impossibile aggiornare il ruolo "
                    },
                    delete: {
                      loading: "Eliminazione del ruolo...",
                      success: "Eliminato con successo il ruolo ",
                      failure: "Impossibile eliminare il ruolo ",
                      warning: {
                        part1: "Il ruolo ",
                        part2: " è utilizzato da ",
                        part3: " conto",
                        part4: {
                          a: "s",
                          b: ""
                        },
                        part5: ". Non è quindi possibile eliminarlo."
                      }
                    }
                  },
                  addNewRole: {
                    title: "Aggiungere un nuovo ruolo",
                    subTitle: "Aggiungete le informazioni necessarie per aggiungere un nuovo Ruolo che potrete poi assegnare ai vostri collaboratori.",
                    buttonText: "Aggiungere un nuovo ruolo"
                  },
                  editRole: {
                    title: "Modifica di un ruolo esistente",
                    subTitle: "Modificare le informazioni di un Ruolo esistente e ",
                    buttonText: "Modifica ruolo"
                  },
                  deleteRole: {
                    title: {
                      part1: "State per ",
                      part2: "cancellare",
                      part3: " il ruolo "
                    },
                    subTitle: "Dopo aver eliminato il ruolo, non sarà più possibile vederlo.",
                    buttonText: "Eliminare il ruolo"
                  }
                },
                organizationalUnits: {
                  title: "Unità organizzative",
                  saveChangesButton: "Salva le modifiche",
                  unitForm: {
                    unitLanguage: {
                      label: "Nome dell'unità Lingua",
                      placeholder: "Selezionare la lingua...",
                    },
                    unitName: {
                      label: "Nome dell'unità",
                      placeholder: "Ounit Nome",
                      error: "Indicare il nome di un'unità organizzativa valida."
                    },
                    unitAbbreviation: {
                      label: "Abbreviazione dell'unità",
                      placeholder: "Ounit Abbreviazione"
                    },
                    unitContactName: {
                      label: "Nome del contatto Accordi di apprendimento in uscita",
                    },
                    unitContactEmail: {
                      label: "Contatto Email Accordi di apprendimento in uscita",
                      error: "Si prega di fornire un'e-mail di contatto valida."
                    },
                    incomingDifferent: {
                      label: "La persona di contatto per i Learning Agreement in arrivo è diversa"
                    },
                    incomingContactName:{
                      label: "Nome del contatto Accordi di apprendimento in entrata",
                    },
                    incomingContactEmail:{
                      label: "Contatto Email Accordi di apprendimento in arrivo",
                      error: "Si prega di fornire un'e-mail di contatto valida."
                    }
                  },
                  unitFeedback: {
                    create: {
                      loading: "Creare unità organizzative...",
                      success: "Creata con successo l'unità organizzativa con nome ",
                      failure: "Impossibile creare l'unità organizzativa"
                    },
                    update: {
                      loading: "Aggiornamento dell'unità organizzativa...",
                      success: "Aggiornamento riuscito dell'unità organizzativa ",
                      failure: "Impossibile aggiornare l'unità organizzativa"
                    },
                    delete: {
                      loading: "Eliminazione dell'unità organizzativa...",
                      success: "L'unità organizzativa è stata eliminata con successo",
                      failure: "Impossibile eliminare l'unità organizzativa"
                    }
                  },
                  addNewUnit: {
                    title: "Aggiungere una nuova unità organizzativa",
                    subTitle: "Aggiungete le informazioni necessarie per aggiungere una nuova Unità Organizzativa per il vostro HEI",
                    buttonText: "Aggiungere una nuova unità organizzativa"
                  },
                  editUnit: {
                    title: "Modificare un'unità organizzativa esistente",
                    subTitle: "Aggiungete le informazioni necessarie per modificare un'unità organizzativa per il vostro HEI",
                    buttonText: "Modifica dell'unità organizzativa"
                  },
                  deleteUnit: {
                    title: {
                      part1: "State per ",
                      part2: "cancellare",
                      part3: " un'unità organizzativa"
                    },
                    subTitle: "Dopo aver eliminato un'unità organizzativa, non sarà più possibile vederla.",
                    buttonText: "Cancellare l'unità organizzativa",
                    buttonLabel: "Cancellare",
                  },
                },
                ukraineAssistance: {
                  title: "Assistenza all'Ucraina",
                  infoFeedback: {
                    loading: "Aggiornamento delle informazioni sull'assistenza in Ucraina...",
                    success: "Informazioni sull'assistenza in Ucraina aggiornate con successo",
                    failure: "Impossibile aggiornare le informazioni sull'assistenza in Ucraina"
                  },
                  validateError: "Selezionare almeno un'opzione “Sì” o fornire un link valido",
                  invalidUrl: "URL non valido",
                  header: "Guerra in Ucraina - Centro di supporto agli studenti",
                  description: "In risposta ai drammatici eventi che si stanno verificando in Ucraina, abbiamo sviluppato un portale per fornire informazioni sull'assistenza offerta dagli istituti di istruzione superiore agli studenti in fuga dalla guerra. Se desiderate aiutarci a diffondere il modo in cui la vostra università si sta facendo avanti e sta facendo la differenza, vi preghiamo di indicarlo:",
                  form: {
                    shelter: "La vostra istituzione offre un rifugio d'emergenza?",
                    health: "Nella vostra istituzione è disponibile un supporto sanitario e psicologico?",
                    fastTrack: "La vostra istituzione prevede una procedura di candidatura accelerata per gli studi?",
                    scholarship: "La vostra istituzione offre borse di studio speciali?",
                    options: {
                      yes: "Sì",
                      no: "No",
                      unkown: "Sconosciuto"
                    }
                  },
                  moreInfo: {
                    header: "Si prega di fornire un link alla pagina web dedicata della vostra istituzione con maggiori informazioni su questo argomento, o alla pagina dei contatti della vostra istituzione.",
                    error: "Si prega di fornire un URL valido.",
                    buttonText: "Informazioni sull'assistenza Save Ukraine"
                  }
                },
                incomingStudents: {
                  title: "Accordo di apprendimento online",
                  subTitle: "Studenti in mobilità in entrata",
                  tab: "Accordi di apprendimento online in entrata",
                  tabSingleView: "Visualizza il singolo Accordo di apprendimento online in entrata",
                },
                outgoingStudents: {
                  title: "Accordo di apprendimento online",
                  subTitle: "Studenti in mobilità in uscita",
                  tab: "Accordi di apprendimento online in uscita",
                  tabSingleView: "Visualizza il singolo Accordo di apprendimento online in uscita",
                },
                applications: {
                  title: "Candidature per Erasmus+",
                  tab: {
                    out: "Nomine uscenti",
                    in: "Nomine in arrivo"
                  }
                },
                learningAgreements: {
                  loadingMessage: "Caricamento...",
                  signFeedback: {
                    loading: "Firma dell'OLA...",
                    success: "Accordo di apprendimento firmato con successo",
                    failure: "Errore nella firma."
                  },
                  declineOlaFeedback: {
                    loading: "OLA in calo...",
                    success: "Accordo di apprendimento rifiutato con successo",
                    failure: "Errore in fase di declino."
                  }, 
                  editTableFailed: "Modifica tabella corsi OLA non riuscita.",
                  LearningAgreementInfo: {
                    title: "INFO SUL CONTRATTO DI APPRENDIMENTO"
                  },
                  generalInfo: {
                    title: "Informazioni generali",
                    studentPersonalData: {
                      title: "DATI PERSONALI DEGLI STUDENTI"
                    },
                    sendingInstInfo: {
                      title: "INFORMAZIONI SULL'ISTITUZIONE DI INVIO"
                    },
                    receivingInstInfo: {
                      title: "INFORMAZIONI SULL'ISTITUTO RICEVENTE"
                    }
                  },
                  courses: {
                    title: "Corsi",
                    tableA: "TABELLA A",
                    tableB: "TABELLA B",
                    tableC: "TABELLA c",
                  },
                  changes: {
                    title: "Cambiamenti",
                    tableA: "MODIFICHE ALLA TABELLA A",
                    tableB: "MODIFICHE ALLA TABELLA B",
                    tableC: "MODIFICHE ALLA TABELLA C",
                  },
                  modalReject: {
                    declineButton: "Declino",
                    body: {
                      part1: "Dopo aver rifiutato l'OLA, lo studente potrà modificarlo e introdurre le modifiche proposte. Inserite nel campo sottostante i commenti che desiderate siano inviati via e-mail allo studente: ",
                      part2: "Motivo del rifiuto",
                      part3: " Nota",
                    },
                    title: {
                      part1: "State per ",
                      part2: "declino",
                      part3: " l'OLA di uno studente"
                    }
                  },
                  modalSign: {
                    signButton: "Firma OLA",
                    body: "Firmando digitalmente questo documento, lo studente, l'Istituzione di Invio e l'Istituzione di Ricevimento confermano di approvare il Learning Agreement e di rispettare tutte le disposizioni concordate da tutte le parti. Le Istituzioni di Invio e di Ricevimento si impegnano ad applicare tutti i principi della Carta Erasmus per l'Istruzione Superiore relativi alla mobilità per studio (o i principi concordati nell'Accordo Interistituzionale per le istituzioni situate nei Paesi Partner). L'Istituzione beneficiaria e lo studente devono inoltre impegnarsi a rispettare quanto stabilito nell'accordo di sovvenzione Erasmus+. L'Istituzione beneficiaria conferma che le componenti didattiche elencate sono in linea con il proprio catalogo dei corsi e devono essere disponibili per lo studente. L'Istituzione di provenienza si impegna a riconoscere tutti i crediti o le unità equivalenti acquisiti presso l'Istituzione ricevente per le componenti formative completate con successo e a conteggiarle ai fini del diploma dello studente. Lo studente e l'Istituzione ricevente comunicheranno all'Istituzione di destinazione eventuali problemi o cambiamenti relativi al programma di studio, alle persone responsabili e/o al periodo di studio.",
                    title: {
                      part1: "State per ",
                      part2: "segno",
                      part3: " l'OLA di uno studente"
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Confermare",
                    body: {
                      part1: "Secondo le linee guida ufficiali della Commissione Europea, se il riconoscimento automatico non avrà luogo, si prega di fornire una chiara giustificazione e un'indicazione su quale altro tipo di riconoscimento formale sarà applicato. ",
                      part2: "Inserire il motivo del rifiuto"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "Secondo le linee guida ufficiali della Commissione Europea, se il riconoscimento automatico non avrà luogo, si prega di fornire una chiara giustificazione e un'indicazione su quale altro tipo di riconoscimento formale sarà applicato. ",
                      part2: "Inserire il motivo del rifiuto",
                    }
                  },
                  upload: {
                    titleMain: "Accordo di apprendimento online",
                    subTitle: "Carica CSV",
                    tab: "Carica CSV per gli accordi di apprendimento online",
                    validateData: {
                      invalidCode: {
                        part1: "Codice erasmus non valido ",
                        part2: " in linea "
                      },
                      emailError: {
                        part1: "Errore di posta elettronica nella linea ",
                        part2: ": Controllare il formato e-mail dei dati."
                      },
                      dataError: {
                        part1: "Errore di data nella riga ",
                        part2: ": Assicuratevi che le date siano nel formato gg/mm/aaaa."
                      },
                      fileError: "Errore di file: È possibile carica solo 25 LA alla volta."
                    },
                    uploadOla: {
                      loading: "Caricamento degli OLAs...",
                      success: "Accordi di apprendimento caricati con successo",
                      failure: "Si sono verificati alcuni errori durante il caricamento degli OLAs."
                    },
                    header: "Caricamento dei contratti di apprendimento",
                    cancel: "Annullamento",
                    upload: "Carica",
                    modalSign: {
                      part1: "Assicurarsi che i dati siano conformi alle norme di legge ",
                      part2: "modello"
                    },
                    title: {
                      part1: "State per ",
                      part2: "arica",
                      part3: " alcuni OLA"
                    },
                    dontClosePage: "L'operazione può richiedere un po' di tempo, ma non chiudete questa pagina!",
                    commonErrors: {
                      part1: "Se si riscontrano degli errori, consultare la pagina Errori comuni disponibile ",
                      part2: "qui"
                    },
                    returnToUploadPage: "Ritorno alla pagina di caricamento",
                    header2: "Carica il file CSV per precompilare gli Accordi di apprendimento online",
                    bodyUpload: {
                      part1: "La funzionalità di precompilazione dell'Accordo di apprendimento online supporta ora il caricamento di CSV. Dopo aver inserito i dati, è possibile controllare l'elenco degli studenti per verificare che tutte le informazioni siano corrette e inviare i dati. Questa azione attiverà la creazione di Accordi di apprendimento online precompilati e una notifica per gli studenti che li inviterà a finalizzare il documento.",
                      part2: "Il formato del file CSV può essere verificato attraverso l'uso di un foglio di calcolo modello messo a disposizione sul CC dell'EWP ",
                      part3: "qui"
                    },
                    checkBox: "Confermo, anche a nome del mio IIS, di disporre di tutte le autorizzazioni, compreso il consenso delle persone fisiche interessate, necessarie per carica i dati personali e le informazioni che sto fornendo, ai sensi dei Termini e condizioni del cruscotto e dell'Informativa sulla privacy.",
                    consent: "Si prega di dare il consenso al trattamento dei dati"
                  }
                },
                nominationList: {
                  totalApplications: "Totale domande",
                  downloadFiles: "Scarica tutti i file",
                  outSignleView: {
                    loadingMessage: "Caricamento...",
                    errorMessage: "Si è verificato un errore",
                    applicationFeedback: {
                      nominate: {
                        loading: "Domanda di candidatura...",
                        success: "Candidatura con esito positivo",
                        failure: "Candidatura fallita"
                      },
                      reject: {
                        loading: "Rifiuto della domanda...",
                        success: "Domanda respinta con successo",
                        failure: "Rifiuto della domanda non riuscito"
                      },
                      wait: {
                        loading: "Inserimento in lista d'attesa...",
                        success: "Applicazione aggiornata con successo",
                        failure: "Aggiornamento dell'applicazione non riuscito"
                      },
                      delete: {
                        loading: "Eliminazione dell'applicazione...",
                        success: "Applicazione eliminata con successo",
                        failure: "Eliminazione dell'applicazione non riuscita"
                      },
                    },
                    applicationInfo: {
                      title: "INFORMAZIONI SULL'APPLICAZIONE",
                      status: "STATO",
                      academicYear: "ANNO ACCADEMICO",
                      academicTerm: "TERMINE ACCADEMICO",
                      submissionTime: "TEMPO DI PRESENTAZIONE",
                      downloadFiles: "Scarica tutti i file",
                    },
                    generalInfo: {
                      title: "Informazioni generali",
                      studentInfo: {
                        title: "INFO STUDENTI",
                        name: "NOME",
                        email: "EMAIL",
                        studyLevel: "LIVELLO DI STUDIO",
                        studyField: "CAMPO DI STUDIO"
                      },
                      destinationheis: {
                        title: "DESTINAZIONE HEI",
                        institutionFaculty: "ISTITUZIONE / FACOLTÀ",
                        nominatedStudents: "STUDENTI NOMINATI"
                      }
                    },
                    documents: {
                      title: "Documenti",
                      viewPdf: "Visualizza Pdf",
                      files: { 
                        portfolio: "PORTAFOGLIO",
                        externalCertification: "CERTIFICAZIONE ESTERNA",
                        referenceContact: "CONTATTO DI RIFERIMENTO",
                        motivationLetter: "LETTERA DI MOTIVAZIONE",
                        languageCert: "CERTIFICATI LINGUISTICI",
                        other: "ALTRI DOCUMENTI"
                      }
                    },
                    waitingList: "LISTA D'ATTESA",
                    reject: "RIFIUTO",
                    delete: "CANCELLARE",
                    modalReject: {
                      body: "Una volta respinta la domanda, lo studente non potrà candidarsi ad altri programmi di mobilità nell'anno accademico in corso.",
                      reject: "Rifiuto",
                      title: {
                        part1: "State per ",
                        part2: "scarto",
                        part3: " la domanda di uno studente"
                      }
                    },
                    modalWaiting: {
                      body: "Dopo aver inserito la domanda dello studente nella Lista d'attesa, sarà comunque possibile nominare lo studente in un secondo momento, se lo si desidera.",
                      waitingList: "Lista d'attesa",
                      title: {
                        part1: "State per inserire la domanda di iscrizione di uno studente nella ",
                        part2: "Lista d'attesa"
                      }
                    },
                    modalDelete: {
                      body: "Dopo aver eliminato la domanda dello studente, non sarà più possibile vederla.",
                      delete: "Cancellare",
                      title: {
                        part1: "State per ",
                        part2: "cancellare",
                        part3: " la domanda di uno studente"
                      }
                    },
                    modalNominate: {
                      body: "Dopo aver nominato lo studente per una mobilità presso l'HEI ospitante, l'istituto ricevente potrà accettare o rifiutare la nomina. In caso di rifiuto da parte dell'HEI ospitante, sarà possibile nominare lo studente per una mobilità in un altro HEI, se disponibile.",
                      nominate: "Nominare",
                      title: {
                        part1: "State per ",
                        part2: "nominare",
                        part3: " la domanda di uno studente"
                      }
                    }
                  },
                  inSingleView: {
                    loadingMessage: "Caricamento...",
                    errorMessage: "Si è verificato un errore",
                    applicationFeedback: {
                      nominate: {
                        loading: "Domanda di candidatura...",
                        success: "Candidatura con esito positivo",
                        failure: "Candidatura fallita"
                      },
                      reject: {
                        loading: "Rifiuto della domanda...",
                        success: "Domanda respinta con successo",
                        failure: "Rifiuto della domanda non riuscito"
                      }
                    },
                    applicationInfo: {
                      title: "INFORMAZIONI SULL'APPLICAZIONE",
                      status: "STATO",
                      academicYear: "ANNO ACCADEMICO",
                      academicTerm: "TERMINE ACCADEMICO",
                      submissionTime: "TEMPO DI PRESENTAZIONE",
                      downloadFiles: "Scarica tutti i file",
                    },
                    generalInfo: {
                      title: "Informazioni generali",
                      studentInfo: {
                        title: "INFO STUDENTI",
                        name: "NOME",
                        email: "EMAIL",
                        studyLevel: "LIVELLO DI STUDIO",
                        studyField: "CAMPO DI STUDIO"
                      },
                      homeHei: {
                        title: "CASA HEI",
                        name: "NOME",
                        orgUnit: "UNITÀ ORGANIZZATIVA"
                      },
                      destinationheis: {
                        title: "DESTINAZIONE HEI",
                        institutionFaculty: "ISTITUZIONE / FACOLTÀ",
                        nominatedStudents: "STUDENTI NOMINATI"
                      }
                    },
                    documents: {
                      title: "Documenti",
                      viewPdf: "Visualizza Pdf",
                      files: { 
                        portfolio: "PORTAFOGLIO",
                        externalCertification: "CERTIFICAZIONE ESTERNA",
                        referenceContact: "CONTATTO DI RIFERIMENTO",
                        motivationLetter: "LETTERA DI MOTIVAZIONE",
                        languageCert: "CERTIFICATI LINGUISTICI",
                        other: "ALTRI DOCUMENTI"
                      }
                    },
                    reject: "Rifiuto",
                    nominate: "Nominare",
                    modalReject: {
                      body: "Una volta respinta la domanda, lo studente non potrà candidarsi ad altri programmi di mobilità presso il vostro HEI nell'anno accademico in corso. Tuttavia, l'IRO inviante può nominare questo studente per una mobilità in un altro HEI, se disponibile.",
                      title: {
                        part1: "State per ",
                        part2: "scarto",
                        part3: " la domanda di uno studente"
                      }
                    },
                    modalNominate: {
                      body: "Nominando lo studente, si fa in modo che lo studente sia idoneo a iniziare la sua mobilità nell'HEI che si rappresenta.",
                      title: {
                        part1: "State per ",
                        part2: "nominare",
                        part3: " la domanda di uno studente"
                      }
                    }
                  }
                },
                shortTerm: {
                  title: "Mobilità a breve termine",
                  tabOutgoing: "Mobilità a breve termine in uscita",
                  tabIncoming: "Mobilità a breve termine in arrivo",
                },
                shortTermMobilities: {
                  loadingMessage: "Caricamento...",
                  errorMessage: "Si è verificato un errore",
                  title: "Mobilità a breve termine",
                  tabOutgoing: "Visualizza la singola mobilità a breve termine in uscita",
                  tabIncoming: "Visualizza la singola mobilità a breve termine in arrivo",
                  studentType: {
                    out: "Persona responsabile presso l'istituzione di invio",
                    in: "Persona responsabile dell'istituzione ricevente"
                  },
                  editTableFail: "Modifica tabella corsi OLA non riuscita.",
                  signFeedback: {
                    loading: "Firma dell'OLA...",
                    success: "Accordo di apprendimento firmato con successo",
                    failure: "Errore nella firma."
                  },
                  declineOlaFeedback: {
                    loading: "OLA in calo...",
                    success: "Accordo di apprendimento rifiutato con successo",
                    failure: "Errore in fase di declino."
                  }, 
                  LearningAgreementInfo: {
                    title: "INFO SUL CONTRATTO DI APPRENDIMENTO"
                  },
                  generalInfo: {
                    title: "Informazioni generali",
                    studentPersonalData: {
                      title: "DATI PERSONALI DEGLI STUDENTI"
                    },
                    sendingInstInfo: {
                      title: "INFORMAZIONI SULL'ISTITUZIONE DI INVIO"
                    },
                    receivingInstInfo: {
                      title: "INFORMAZIONI SULL'ISTITUTO RICEVENTE"
                    }
                  },
                  courses: {
                    title: "Corsi",
                    table: "TABELLA DELLA MOBILITÀ A BREVE TERMINE"
                  },
                  modalReject: {
                    declineButton: "Declino",
                    body: {
                      part1: "Dopo aver rifiutato l'OLA, lo studente potrà modificarlo e introdurre le modifiche proposte. Inserite nel campo sottostante i commenti che desiderate siano inviati via e-mail allo studente: ",
                      part2: "Motivo del rifiuto",
                      part3: " Nota",
                    },
                    title: {
                      part1: "State per ",
                      part2: "declino",
                      part3: " l'OLA di uno studente"
                    }
                  },
                  modalSign: {
                    signButton: "Firma OLA",
                    body: "Firmando digitalmente questo documento, lo studente, l'Istituzione di Invio e l'Istituzione di Ricevimento confermano di approvare il Learning Agreement e di rispettare tutte le disposizioni concordate da tutte le parti. Le Istituzioni di Invio e di Ricevimento si impegnano ad applicare tutti i principi della Carta Erasmus per l'Istruzione Superiore relativi alla mobilità per studio (o i principi concordati nell'Accordo Interistituzionale per le istituzioni situate nei Paesi Partner). L'Istituzione beneficiaria e lo studente devono inoltre impegnarsi a rispettare quanto stabilito nell'accordo di sovvenzione Erasmus+. L'Istituzione beneficiaria conferma che le componenti didattiche elencate sono in linea con il proprio catalogo dei corsi e devono essere disponibili per lo studente. L'Istituzione di provenienza si impegna a riconoscere tutti i crediti o le unità equivalenti acquisiti presso l'Istituzione ricevente per le componenti formative completate con successo e a conteggiarle ai fini del diploma dello studente. Lo studente e l'Istituzione ricevente comunicheranno all'Istituzione di destinazione eventuali problemi o cambiamenti relativi al programma di studio, alle persone responsabili e/o al periodo di studio.",
                    title: {
                      part1: "State per ",
                      part2: "segno",
                      part3: " l'OLA di uno studente"
                    }
                  },
                  modalPrelimReject: {
                    confirmButton: "Confermare",
                    body: {
                      part1: "Secondo le linee guida ufficiali della Commissione Europea, se il riconoscimento automatico non avrà luogo, si prega di fornire una chiara giustificazione e un'indicazione su quale altro tipo di riconoscimento formale sarà applicato. ",
                      part2: "Inserire il motivo del rifiuto"
                    }
                  },
                  modalFinalReject: {
                    body: {
                      part1: "Secondo le linee guida ufficiali della Commissione Europea, se il riconoscimento automatico non avrà luogo, si prega di fornire una chiara giustificazione e un'indicazione su quale altro tipo di riconoscimento formale sarà applicato. ",
                      part2: "Inserire il motivo del rifiuto",
                    }
                  },
                },
                requirements: {
                  applicationSettings: {
                    feedback: {
                      success: "Le impostazioni dell'applicazione sono state aggiornate con successo.",
                      error1: "Si è verificato un errore nell'aggiornamento dei requisiti della domanda.",
                      error2: "Si è verificato un errore nell'aggiornamento delle impostazioni dell'applicazione: "
                    },
                    title: "Impostazioni dell'applicazione",
                    description: "Scegliere il numero massimo di HEI ospitanti per gli studenti in partenza e fissare le scadenze per le domande",
                    numOfHeis: {
                      title: "Numero di HEI ",
                      placeholder: "Selezionare il numero di HEI",
                      error: "Selezionare un paese per la propria istituzione",
                      message: "Numero massimo di priorità dell'HEI ospitante per domanda dello studente"
                    },
                    semesterApplications: {
                      titleAw: "Semestre autunnale/invernale Periodo di iscrizione",
                      titleSs: "Semestre primavera/estate Periodo di iscrizione",
                      startDate: "Data di inizio ",
                      endDate: "Data di fine ",
                      error: "Si prega di fornire una richiesta di valore valida."
                    },
                    cancel: "Annullamento",
                    saveSettings: "Salvare le impostazioni dell'applicazione"
                  },
                  applicationRequirements: {
                    feedback: {
                      success: "I requisiti di candidatura sono stati aggiornati con successo.",
                      error1: "Si è verificato un errore nell'aggiornamento dei requisiti della domanda.",
                      error2: "Si è verificato un errore nell'aggiornamento dei requisiti della domanda: "
                    },
                    title: "Requisiti (in uscita)",
                    header: "Documenti richiesti",
                    descriptionMain: "Attivare gli interruttori accanto ai file necessari per gli Studenti in mobilità in uscita del proprio istituto di istruzione superiore e fornire le relative URL e descrizioni",
                    description: "Descrizione",
                    motivationLetter: "Lettera di motivazione",
                    transcriptOfRecords: "Trascrizione dei registri",
                    externalCertification: "Certificazione esterna",
                    recommendationLetter: "Lettera di raccomandazione",
                    languageLevel: {
                      title: "Livello linguistico",
                      languageRequired: {
                        title: "Lingua richiesta",
                        placeholder: "Selezionare una lingua"
                      },
                      languageL: {
                        title: "Livello linguistico",
                        placeholder: "Selezionare un livello di lingua"
                      },
                      languageMoreInfo: "Lingua Maggiori informazioni",
                      delete: "Cancellare la lingua",
                      languageCertRequired: "Certificazione linguistica richiesta",
                      addLanguage: "Aggiungi lingua"
                    },
                    otherDocument: "Altro documento",
                    referenceContact: "Contatto di riferimento",
                    portfolio: "Portafoglio",
                    other: "Altri requisiti",
                    addOther: "Aggiungere altri requisiti",
                    addDocument: "Aggiungi altro documento",
                    deleteRequirement: "Eliminare il requisito",
                    cancel: "Annullamento",
                    saveRequirements: "salvare i requisiti"
                  }
                },
                iiaList: {
                  title: "Accordi interistituzionali",
                  subTitle: "Elenco degli accordi",
                  iiaFeedback: {
                    delete: {
                      loading: "Eliminazione di IIA...",
                      success: "Eliminato con successo IIA",
                      failure: "Cancellazione di IIA non riuscita"
                    },
                    terminate: {
                      loading: "Terminazione IIA...",
                      success: "Terminato con successo l'IIA",
                      failure: "Cessazione dell'IIA fallita"
                    }
                  },
                  modifyIIA: "Modifica IIA",
                  editIIA: "Modifica IIA",
                  deleteIIA: "Cancellare IIA",
                  modalDelete: {
                    body: "Dopo aver eliminato una IIA, non sarà più possibile vederla.",
                    title: {
                      part1: "State per ",
                      part2: "cancellare",
                      part3: " un accordo interistituzionale"
                    },
                    deleteButton: "Cancellare"
                  },
                  modalTerminate: {
                    body: {
                      part1: "L'azione che si sta per intraprendere ha come risultato ",
                      part2: "terminare",
                      part3: " l'effetto IIA e la sua irreversibilità. Volete ancora continuare?"
                    },
                    title: {
                      part1: "State per ",
                      part2: "terminare",
                      part3: " un accordo interistituzionale"
                    },
                    terminateButton: "Terminare"
                  }
                },
                iiaSingleView: {
                  loadingMessage: "Caricamento...",
                  errorMessage: "Si è verificato un errore",
                  iiaFeedback: {
                    approve: {
                      loading: "Salvataggio delle modifiche...",
                      success: "Approvato con successo IIA",
                      failure: "Si è verificato un errore nell'approvazione dell'IIA: ",
                      error: "Errore nel salvataggio delle modifiche."
                    },
                    revert: {
                      loading: "Salvataggio delle modifiche...",
                      success: "Ripristinato con successo l'IIA",
                      failure: "Si è verificato un errore nel ripristino di IIA: ",
                      error: "Errore nel salvataggio delle modifiche."
                    },
                    terminate: {
                      loading: "Terminazione IIA...",
                      success: "Terminato con successo l'IIA",
                      failure: "Cessazione dell'IIA fallita"
                    },
                    notifyPartner: {
                      loading: "Notifica al partner...",
                      success: "Partner notificato con successo",
                      failure: "Notifica del partner fallita"
                    },
                  },
                  iiaInformation: {
                    title: "INFORMAZIONI IIA",
                    yourId: "IL TUO ID",
                    partnerId: "ID PARTNER",
                    partner: "PARTNER",
                    lastEdited: "ULTIMA MODIFICA",
                    status: "STATO",
                    terminatedByYou: "Cessazione da parte dell'utente",
                    terminatedByPartner: "Cessazione da parte del partner",
                    terminationApprovedByYou: "Cessazione approvata dal cliente",
                    terminationApprovedByPartner: "Risoluzione approvata dal partner",
                    submittedByYou: "Presentato da Voi",
                    submittedByPartner: "Presentato dal Partner",
                    approvedByYou: "Approvato da Voi",
                    approvedByPartner: "Approvato dal partner",
                    terminated: "Terminato",
                    draft: "Bozza",
                    terminatedByAll: "Risoluzione approvata da tutti",
                    approvedByAll: "Approvato da tutti",
                    invalid: {
                      invalid1: "Questo IIA contiene codici F ISCED non validi o manca dei requisiti linguistici. Le approvazioni sono ancora valide. Se si desidera terminare questo IIA, è necessario modificarlo e approvarlo reciprocamente.",
                      invalid2: "Questo IIA contiene codici F ISCED non validi o manca dei requisiti linguistici. Si prega di modificare l'IIA."
                    },
                    thirdParty: "Si noti che i dati dell'HEI partner sono gestiti da un fornitore terzo. Ciò significa che questo particolare IIA è rappresentato da un ID diverso nel loro sistema.",
                    exportPdf: "Esporta Pdf"
                  },
                  generalInfo: {
                    title: "Informazioni generali",
                    institutionData: "I DATI DELLA VOSTRA ISTITUZIONE",
                    name: "Nome:",
                    country: "Paese:",
                    schacCode: "Codice SCHAC:",
                    erasmusCode: "Codice Erasmus:",
                    orgUnit: "Org. Unità:",
                    approvingPerson: "Persona che approva:",
                    contactPersons: "Persone di contatto:",
                    signDate: "Data della firma:",
                    partnerInstitutionData: "DATI DELL'ISTITUTO PARTNER",
                  },
                  yourData: {
                    title: "I vostri dati",
                    calendar: {
                      title: "CALENDARIO",
                      studentNominations: "Le candidature degli studenti devono pervenire all'istituzione ricevente entro: ",
                      autumnTerm: "Periodo autunnale: ",
                      springTerm: "Periodo di primavera: ",
                      applicationsForNominated: "Le domande per gli studenti nominati devono pervenire all'istituzione ricevente entro: ",
                      applicationProcedure: "Procedura di candidatura per gli studenti nominati: ",
                      email: "Email di contatto: ",
                      phone: "Telefono di contatto: ",
                      websiteForInfo: "Sito web per informazioni: "
                    },
                    additionalRequirements: {
                      title: "REQUISITI AGGIUNTIVI",
                      requirements: "REQUISITI",
                      details: "DETTAGLI",
                      ectsNumber: "NUMERO DI ECTS",
                      subjectFieldISCED: "CAMPO OGGETTO (ISCED)",
                      studyLevelEQF: "LIVELLO DI STUDIO (EQF)",
                      website: "SITO WEB",
                      message: "L'istituzione invierà la propria decisione entro 4 settimane e non oltre 5 settimane."
                    },
                    inclusionAndAccessibility: {
                      title: "INCLUSIONE E ACCESSIBILITÀ",
                      descriptionMain: {
                        part1: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      infrastructure: "Infrastrutture",
                      name: "NOME",
                      description: "DESCRIZIONE",
                      contact: "CONTATTO",
                      services: "Servizi"
                    },
                    housing: {
                      title: "ALLOGGIO",
                      description: {
                        part1: "L'istituzione guiderà i partecipanti in mobilità in arrivo nella ricerca di un alloggio, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      contact: "Contatto"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "L'istituzione fornirà assistenza, se necessario, per ottenere i visti per i partecipanti in mobilità in entrata e in uscita, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      contact: "Contatto"
                    },
                    insurance: {
                      title: "ASSICURAZIONE",
                      description: {
                        part1: "L'istituzione fornirà assistenza per l'ottenimento di un'assicurazione per i partecipanti in mobilità in entrata e in uscita, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " L'istituto ricevente informerà i partecipanti alla mobilità dei casi in cui la copertura assicurativa non viene fornita automaticamente.",
                        part3: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      contact: "Contatto"
                    },
                    additionalInfo: {
                      title: "INFORMAZIONI AGGIUNTIVE",
                      description: {
                        part1: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      contact: "CONTATTO"
                    },
                    message: {
                      part1: "Il Transcript of Records sarà rilasciato dall'istituto entro 6 settimane dalla fine del periodo di valutazione.",
                      part2: "[Di norma non dovrebbe superare le cinque settimane, secondo le linee guida della Carta Erasmus per l'istruzione superiore.]",
                      part3: "*Dopo aver creato il profilo dell'istituto di istruzione superiore e aver aggiunto le informazioni generali pertinenti, l'istituto di istruzione superiore può procedere alla generazione di Accordi interistituzionali con i partner selezionati."
                    }
                  },
                  partnerData: {
                    title: "Dati dei partner",
                    calendar: {
                      title: "CALENDARIO",
                      studentNominations: "Le candidature degli studenti devono pervenire all'istituzione ricevente entro: ",
                      autumnTerm: "Periodo autunnale: ",
                      springTerm: "Periodo di primavera: ",
                      applicationsForNominated: "Le domande per gli studenti nominati devono pervenire all'istituzione ricevente entro: ",
                      applicationProcedure: "Procedura di candidatura per gli studenti nominati: ",
                      email: "Email di contatto: ",
                      phone: "Telefono di contatto: ",
                      websiteForInfo: "Sito web per informazioni: "
                    },
                    additionalRequirements: {
                      title: "REQUISITI AGGIUNTIVI",
                      requirements: "REQUISITI",
                      details: "DETTAGLI",
                      ectsNumber: "NUMERO DI ECTS",
                      subjectFieldISCED: "CAMPO OGGETTO (ISCED)",
                      studyLevelEQF: "LIVELLO DI STUDIO (EQF)",
                      website: "SITO WEB",
                      message: "L'istituzione invierà la propria decisione entro 4 settimane e non oltre 5 settimane."
                    },
                    inclusionAndAccessibility: {
                      title: "INCLUSIONE E ACCESSIBILITÀ",
                      descriptionMain: {
                        part1: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      infrastructure: "Infrastrutture",
                      name: "NOME",
                      description: "DESCRIZIONE",
                      contact: "CONTATTO",
                      services: "Servizi"
                    },
                    housing: {
                      title: "ALLOGGIO",
                      description: {
                        part1: "L'istituzione guiderà i partecipanti in mobilità in arrivo nella ricerca di un alloggio, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      contact: "Contatto"
                    },
                    visa: {
                      title: "VISA",
                      description: {
                        part1: "L'istituzione fornirà assistenza, se necessario, per ottenere i visti per i partecipanti in mobilità in entrata e in uscita, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      contact: "Contatto"
                    },
                    insurance: {
                      title: "ASSICURAZIONE",
                      description: {
                        part1: "L'istituzione fornirà assistenza per l'ottenimento di un'assicurazione per i partecipanti in mobilità in entrata e in uscita, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " L'istituto ricevente informerà i partecipanti alla mobilità dei casi in cui la copertura assicurativa non viene fornita automaticamente.",
                        part3: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      contact: "Contatto"
                    },
                    additionalInfo: {
                      title: "INFORMAZIONI AGGIUNTIVE",
                      description: {
                        part1: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                        part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                      },
                      contact: "CONTATTO"
                    },
                    message: {
                      part1: "Il Transcript of Records sarà rilasciato dall'istituto entro 6 settimane dalla fine del periodo di valutazione.",
                      part2: "[Di norma non dovrebbe superare le cinque settimane, secondo le linee guida della Carta Erasmus per l'istruzione superiore.]",
                      part3: "*Dopo aver creato il profilo dell'istituto di istruzione superiore e aver aggiunto le informazioni generali pertinenti, l'istituto di istruzione superiore può procedere alla generazione di Accordi interistituzionali con i partner selezionati."
                    }
                  },
                  conditions: {
                    title: "Le condizioni",
                    header: {
                      part1: "Insegnante personale:",
                      part2: " / Campi di studio: ",
                      part3: "Formazione del personale:",
                      part4: "Studio dello studente:",
                      part5: "Tirocinio per studenti:"
                    },
                    heis: {
                      sender: "MITTENTE",
                      receiver: "RICEVITORE"
                    },
                    conditionDetails: {
                      title: "Dettagli sulle condizioni ",
                      mobilityType: "TIPO DI MOBILITÀ",
                      duration: "DURATA",
                      academicYears: "ANNI ACCADEMICI",
                      spots: "SPOTS",
                      eqfLevel: "Livello EQF",
                      staffTeachers: "Personale docente",
                      staffTrainings: "Formazione del personale",
                      otherInfo: "ALTRE INFO",
                    },
                    languageRequirements: {
                      title: "Requisiti linguistici ",
                      language: "LINGUA",
                      studyField: "CAMPO DI STUDIO",
                      clarification: "CHIARIMENTO"
                    },
                    studyFields: {
                      title: "Campi di studio ",
                      studyField: "CAMPO DI STUDIO",
                      clarification: "CHIARIMENTO"
                    },
                    contacts: {
                      title: "Contatti ",
                      sendingContacts: "INVIO DI CONTATTI",
                      receivingContacts: "CONTATTI DI RICEZIONE"
                    }
                  },
                  notifyPartner: {
                    text: "Avvisare il partner",
                    part1: "Sarà possibile fare clic sul pulsante e notificare nuovamente il partner a breve.",
                    part2: "La notifica al partner è disabilitata perché mancano i requisiti linguistici o ci sono codici ISCED con meno di 4 cifre nelle condizioni di cooperazione dell'IIA.",
                    part3: "Informare il partner sullo stato attuale dell'IIA."
                  },
                  terminate: {
                    invalidISCEDormissingLang: "Questo IIA non può essere terminato perché contiene codici F ISCED non validi o manca dei requisiti linguistici. Si prega di modificare e approvare reciprocamente l'IIA per terminarlo.",
                    invalidISCEDormissingLang2: "Questo IIA non può essere approvato perché contiene codici ISCED F non validi o manca dei requisiti linguistici. Si prega di modificare l'IIA.",
                    approvedByAll: "È possibile terminare una IIA solo se è stata approvata da tutti.",
                    iiaTerminationApproved: "La cessazione dell'IIA è già approvata da tutti.",
                    terminateButton: "terminare iia"
                  },
                  revert: {
                    noApproval: "Non è possibile ripristinare l'IIA perché non c'è ancora l'approvazione",
                    approvedByAll: "Non è possibile revocare l'IIA perché è già stata approvata da tutti",
                    revertButton: "revert iia"
                  },
                  edit: {
                    cannotEditWhileTerminating: "Non è possibile modificare l'IIA durante il processo di terminazione.",
                    editButton: "modifica iia",
                    modifyButton: "modificare iia"
                  },
                  approve: {
                    approveButton: "Approvare",
                    confirm: {
                      part1: "State per ",
                      part2: "approvare",
                      part3Terminate: " la risoluzione di un accordo interistituzionale.",
                      part3Approve: " un accordo interistituzionale"
                    }
                  },
                  modalTerminate: {
                    body: "Dopo aver terminato l'IIA, il vostro partner riceverà una notifica di tale terminazione. L'ultima approvazione rimane valida e può essere ripristinata in qualsiasi momento fino a quando entrambi non approverete la cessazione dell'IIA.",
                    title: {
                      part1: "State per ",
                      part2: "terminare",
                      part3: " un accordo interistituzionale"
                    },
                    terminateButton: "Terminare"
                  },
                  modalRevert: {
                    title: "State per riportare l'Accordo interistituzionale alla versione precedentemente approvata da tutti gli Stati.",
                    revertButton: "Invertire"
                  },
                  modalModify: {
                    body: "Dopo aver modificato l'IIA, il partner riceverà una notifica delle modifiche apportate. L'ultima approvazione rimane valida e si può tornare allo stato precedente in qualsiasi momento fino a quando entrambi non approverete nuovamente la IIA.",
                    title: "State per modificare e apportare cambiamenti all'Accordo interistituzionale.",
                    modifyButton: "Modificare"
                  },
                  iiaApprovedByAll: "La IIA è già approvata da tutti o il partner non ha condiviso il proprio ID IIA locale.",
                  approveTermination: "approvare la risoluzione",
                  approveIIA: "approvare iia"
                },
                iiasEdit: {
                  title: "Accordi interistituzionali",
                  subTitle: "Modificare una IIA",
                  createNewSubTitle: "Creare una nuova IIA",
                  createTab: "Creare IIA",
                  tab: "Modifica IIA",
                  steps: {
                    yourDetails: "I VOSTRI DETTAGLI",
                    partnerDetails: "DETTAGLI DEL PARTNER",
                    conditions: "CONDIZIONI",
                    overviewAndSubmit: "PANORAMICA E PRESENTAZIONE"
                  },
                  alerts: {
                    part1: "Nelle condizioni di cooperazione vengono rilevati codici F ISCED non validi. Correggere i codici F ISCED che contengono meno di 4 cifre selezionando quello appropriato dall'elenco a discesa dei codici F ISCED.",
                    part2: "Per procedere è necessario compilare almeno una lingua d'insegnamento nelle condizioni di cooperazione degli studenti",
                    part3: "Per procedere è necessario inserire almeno una lingua d'insegnamento nelle condizioni di cooperazione del personale docente",
                    part4: "L'istituto di istruzione superiore selezionato non è ancora pronto per lo scambio di accordi interistituzionali tramite la rete Erasmus Senza Carta.",
                    missingName: "Per procedere è necessario inserire il nome del contatto",
                    invalidEmail: "Per procedere è necessario inserire un indirizzo e-mail valido",
                  },
                  iiaFeedback: {
                    saving: {
                      loading: "Salvataggio delle modifiche...",
                      success: {
                        savedDraft: "Salvata con successo come bozza IIA",
                        submit: "Presentato con successo l'IIA",
                        updateDraft: "Bozza di IIA aggiornata con successo",
                        update: "Aggiornato con successo IIA"
                      },
                      failure: "Errore nel salvataggio delle modifiche."
                    },
                  },
                  errors: {
                    part1: "Per procedere è necessario selezionare un Partner SCHAC",
                    part2: "Per procedere è necessario aggiungere almeno una Condizione di cooperazione"
                  },
                  next: "Avanti",
                  back: "Indietro",
                  saveAsDraft: "SALVARE COME BOZZA",
                  submit: "Invia",
                  firstStep: {
                    header: "I VOSTRI DETTAGLI",
                    institutionData: {
                      title: "I dati della vostra istituzione",
                      name: "Nome: ",
                      country: "Paese: ",
                      schacCode: "Codice SCHAC: ",
                      erasmusCode: "Codice Erasmus: ",
                      orgUnit: "Unità organizzativa",
                      placeholder: "Selezionare"
                    },
                    contactPerson: {
                      title: "Persone di contatto",
                      name: {
                        title: "Nome ",
                        placeholder: "Inserire il nome.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Inserire l'e-mail..",
                        error: "Si prega di fornire un indirizzo e-mail valido."
                      },
                      phone: {
                        title: "Telefono",
                        placeholder: "Inserire il telefono..",
                      },
                      deleteButton: "Cancellare il contatto",
                      addContactPerson: "aggiungere una persona di contatto"
                    }
                  },
                  secondStep: {
                    header: "DETTAGLI DEL PARTNER",
                    institutionData: {
                      title: "Dati dell'istituto partner",
                      institution: {
                        title: "Istituzione ",
                        loading: "Caricamento...",
                        choose: "Scegliere l'istituzione...",
                        error: "Selezionare la propria istituzione."
                      },
                      schac: {
                        title: "Codice SCHAC",
                        loading: "Caricamento...",
                        choose: "Scegliere il codice SCHAC...",
                        error: "Selezionare il codice SCHAC."
                      },
                      country: "Paese: ",
                      schacCode: "Codice SCHAC: ",
                      erasmusCode: "Codice Erasmus: ",
                      orgUnit: "Unità organizzativa",
                      placeholder: "Selezionare"
                    },
                    erasmusCode: {
                      title: "Codice Erasmus ",
                      loading: "Caricamento...",
                      choose: "Scegliere il codice Erasmus...",
                      error: "Selezionare il codice Erasmus."
                    },
                    orgUnit: {
                      title: "Unità organizzativa",
                      loading: "Caricamento...",
                      choose: "Scegliere OUnit...",
                      error: "Selezionare la propria OUnit."
                    },
                    contactPersons: {
                      title: "Persone di contatto",
                      name: {
                        title: "Nome ",
                        placeholder: "Inserire il nome.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Inserire l'e-mail..",
                        error: "Si prega di fornire un indirizzo e-mail valido."
                      },
                      phone: {
                        title: "Telefono",
                        placeholder: "Inserire il telefono..",
                      },
                      deleteButton: "Cancellare il contatto",
                      addContactPerson: "aggiungere una persona di contatto"
                    }
                  },
                  thirdStep: {
                    mobilityTypes: {
                      studentStudies: "Studi degli studenti",
                      studentTraineeships: "Tirocini per studenti",
                      staffTeachers: "Personale docente",
                      staffTrainings: "Formazione del personale"
                    },
                    blendedMobility: {
                      yes: "Sì",
                      no: "No"
                    },
                    eqfLevels: {
                      lvl5: "Livello EQF 5 - Laurea associata",
                      lvl6: "Livello EQF 6 - Laurea triennale",
                      lvl7: "Livello EQF 7 - Laurea magistrale",
                      lvl8: "Livello EQF 8 - Dottorato di ricerca",
                    },
                    typeOfCoopCondition: {
                      fillAllFields: "Compilare tutti i campi richiesti",
                      validEmailSending: "Inserire un indirizzo e-mail valido nei contatti di invio",
                      validEmailReceiving: "Inserire un indirizzo e-mail valido nei contatti di ricezione",
                      invalidISCED: "Nella condizione di cooperazione sono stati rilevati codici F ISCED non validi. Correggere i codici F ISCED che contengono meno di 4 cifre selezionando quello appropriato dall'elenco a discesa dei codici F ISCED.",
                    },
                    cooperationConditions: {
                      header: "CONDIZIONI DI COOPERAZIONE",
                      staffTeacher: "Insegnante personale",
                      studyFields: "Campi di studio",
                      staffTraining: "Formazione del personale",
                      studentStudy: "Studio per studenti",
                      studentTraineeship: "Studente in tirocinio"
                    },
                    daysPerYear: " giorni all'anno",
                    monthsPerYear: " mesi all'anno",
                    heis: {
                      sender: "MITTENTE",
                      receiver: "RICEVITORE"
                    },
                    conditionDetails: {
                      header: "Dettagli sulle condizioni",
                      mobilityType: "TIPO DI MOBILITÀ",
                      duration: "DURATA",
                      academicYears: "ANNI ACCADEMICI",
                      eqfLevel: "Livello EQF",
                      spots: "SPOTS"
                    },
                    otherInfo: "ALTRE INFO",
                    languageRequirements: {
                      header: "Requisiti linguistici",
                      language: "LINGUA",
                      studyField: "CAMPO DI STUDIO",
                      clarification: "CHIARIMENTO"
                    },
                    studyFields: {
                      header: "Campi di studio",
                      studyField: "CAMPO DI STUDIO",
                      clarification: "CHIARIMENTO"
                    },
                    contacts: {
                      header: "Contatti",
                      sendingContacts: "INVIO DI CONTATTI",
                      receivingContacts: "CONTATTI DI RICEZIONE"
                    },
                    editConditionButton: "modifica condizione",
                    deleteCoopConditionButton: "Eliminare la condizione Coop",
                    addNewConditionButton: "aggiungere una nuova condizione",
                    modal: {
                      title: "Numero di mobilità per anno accademico",
                      subTitle: "I partner si impegnano a modificare la tabella sottostante in caso di variazioni dei dati sulla mobilità entro e non oltre la fine di settembre dell'anno accademico precedente. ",
                      schacCode: {
                        sender: {
                          title: "Codice SCHAC del mittente",
                          placeholder: "Seleziona SCHAC..."
                        },
                        receiver: {
                          title: "Ricevitore Codice SCHAC",
                          placeholder: "Seleziona SCHAC..."
                        }
                      },
                      orgUnit: {
                        sender: {
                          title: "Unità organizzativa mittente",
                          placeholder: "Selezionare l'unità organizzativa..."
                        },
                        receiver: {
                          title: "Unità organizzativa ricevente",
                          placeholder: "Selezionare l'unità organizzativa..."
                        }
                      },
                      mobilitiesPerYear: {
                        title: "Mobilità all'anno",
                        description: "Numero massimo di persone da inviare ogni anno accademico"
                      },
                      academicYear: {
                        start: {
                          title: "Inizio anno accademico",
                          placeholder: "Scegliere l'inizio dell'anno accademico...",
                          error: "Selezionare il codice SCHAC."
                        },
                        end: {
                          title: "Fine anno accademico",
                          placeholder: "Scegliere Fine Anno Accademico...",
                          error: "Selezionare il codice SCHAC."
                        }
                      },
                      subjectArea: {
                        header: "Area tematica",
                        fCode: {
                          title: "Codice F ISCED",
                          placeholder: "Selezionare il codice ISCED"
                        },
                        clarification: {
                          title: "Chiarimento ISCED",
                          placeholder: "Inserire il testo.."
                        },
                        deleteButton: "Cancellare l'area tematica",
                        addSubjectArea: "aggiungere l'area tematica"
                      },
                      mobilityType: {
                        header: "Tipo di mobilità",
                        description: "I partner si impegnano a modificare la tabella sottostante in caso di variazioni dei dati sulla mobilità entro e non oltre la fine di settembre dell'anno accademico precedente.",
                        duration: {
                          title: "Durata",
                          totalMonths: " (totale mesi all'anno)",
                          totalDays: " (giorni totali all'anno)",
                          fill: {
                            allStudents: "Inserire la somma dei mesi per tutti gli studenti (ad esempio, 10 studenti in mobilità per 6 mesi hanno una durata di 60 mesi)",
                            staff: "Compilare la somma dei mesi per il personale (ad esempio, 4 membri del personale con una mobilità di 20 giorni danno una durata di 80 giorni)"
                          }
                        },
                        studyCycle: {
                          header: "Ciclo di studio",
                          placeholder: "Selezionare uno o più..."
                        },
                        blendedMobility: {
                          header: "Opzione di mobilità mista",
                          confirmation: "*Scegliendo “Sì” nell'opzione Mobilità mista, i partner confermano di essere disposti a scambiare studenti che desiderano svolgere la loro mobilità in un formato misto, una combinazione di una mobilità fisica a breve termine con una componente virtuale. "
                        }
                      },
                      languageSkills: {
                        header: "Competenze linguistiche consigliate",
                        description: "L'istituto di provenienza, previo accordo con l'istituto ricevente, è responsabile di fornire assistenza ai propri candidati nominati in modo che possano avere le competenze linguistiche raccomandate all'inizio del periodo di studio o di insegnamento (Mobilità studentesca per studio - Livello minimo raccomandato: B1):",
                        languageOfInstruction: {
                          title: "Lingua di insegnamento",
                          placeholder: "Selezionare una lingua"
                        },
                        level: {
                          title: "Lingua di insegnamento Livello",
                          placeholder: "Selezionare un livello di lingua"
                        },
                        fCode: {
                          title: "Codice F ISCED",
                          placeholder: "Selezionare il codice ISCED"
                        },
                        clarification: {
                          title: "Chiarimento ISCED",
                          placeholder: "Inserire il testo.."
                        },
                        deleteButton: "Cancellare la lingua",
                        addLanguage: "aggiungere LINGUA"
                      },
                      contact: {
                        sending: {
                          header: "Referenti per l'invio",
                          description: "Si prega di inserire i dati dei referenti per l'invio",
                          addContact: "aggiungere un referente per l'invio"
                        },
                        receiving: {
                          header: "Referenti per la ricezione",
                          description: "Si prega di inserire i dati dei referenti di ricezione",
                          addContact: "aggiungere la persona di contatto ricevente"
                        },
                        name: {
                          title: "Nome",
                          placeholder: "Inserire il nome.."
                        },
                        email: {
                          title: "Email",
                          placeholder: "Inserire l'e-mail..",
                          error: "Si prega di fornire un indirizzo e-mail valido."
                        },
                        phone: {
                          title: "Telefono",
                          placeholder: "Inserire il telefono.."
                        },
                        deleteContact: "Cancellare il contatto"
                      },
                      otherInfo: {
                        header: "Altre informazioni",
                        description: "Inserire qualsiasi altra informazione relativa ai termini dell'Accordo (massimo 5000 caratteri)",
                        placeholder: "Inserire altre informazioni.."
                      },
                      addCondition: "aggiungere questa condizione",
                      addNewCondition: "Aggiungere una nuova condizione di cooperazione",
                      fillNew: "Compilare i seguenti campi per aggiungere una nuova Condizione di cooperazione ",
                      saveChanges: "salvare le modifiche alle condizioni",
                      editCondition: "Modifica della condizione di cooperazione",
                      fillEdit: "Compilare i seguenti campi per modificare la Condizione di cooperazione"
                    }
                  },
                  forthStep: {
                    overview: {
                      header: "PANORAMICA E PRESENTAZIONE",
                      staticInfo: {
                        header: "Informazioni statiche applicabili a tutti gli Accordi Interistituzionali",
                        body: "Le istituzioni convengono di cooperare per lo scambio di studenti e/o personale nel contesto del programma Erasmus. Si impegnano a rispettare i requisiti di qualità della Carta Erasmus per l'istruzione superiore in tutti gli aspetti relativi all'organizzazione e alla gestione della mobilità, in particolare il riconoscimento automatico dei crediti assegnati agli studenti dall'istituto partner, come concordato nell'Accordo di apprendimento e confermato nel Transcript of Records, o in base ai risultati di apprendimento dei moduli completati all'estero, come descritto nel Catalogo dei corsi, in linea con il Sistema europeo di crediti e accumulo. Le istituzioni concordano di scambiare i dati relativi alla mobilità in linea con gli standard tecnici della European Student Card Initiative.",
                      },
                      gradingSystem: {
                        header: "Sistemi di classificazione delle istituzioni",
                        body: "Si raccomanda alle istituzioni riceventi di fornire un link alla distribuzione statistica dei voti o di rendere disponibili le informazioni attraverso EGRACONS, secondo le descrizioni contenute nella guida agli utenti ECTS. Le informazioni faciliteranno l'interpretazione di ogni voto assegnato agli studenti e agevoleranno il trasferimento dei crediti da parte dell'istituzione di provenienza."
                      }
                    },
                    generalInfo: {
                      header: "Informazioni generali",
                      institutionData: {
                        header: "I DATI DELLA VOSTRA ISTITUZIONE",
                        headerPartner: "DATI DELL'ISTITUTO PARTNER",
                        name: "Nome",
                        country: "Paese",
                        schacCode: "Codice SCHAC",
                        erasmusCode: "Codice Erasmus",
                        orgUnit: "Org. Unità",
                        approvingPerson: "Persona che approva",
                        contactPerson: "Persone di contatto"
                      }
                    },
                    factSheet: {
                      yourFactSheet: {
                        header: "I dati del vostro Factsheet",
                        calendar: {
                          title: "CALENDARIO",
                          studentNominations: "Le candidature degli studenti devono pervenire all'istituzione ricevente entro: ",
                          autumnTerm: "Periodo autunnale: ",
                          springTerm: "Periodo di primavera: ",
                          applicationsForNominated: "Le domande per gli studenti nominati devono pervenire all'istituzione ricevente entro: ",
                          applicationProcedure: "Procedura di candidatura per gli studenti nominati: ",
                          email: "Email di contatto: ",
                          phone: "Telefono di contatto: ",
                          websiteForInfo: "Sito web per informazioni: "
                        },
                        additionalRequirements: {
                          title: "REQUISITI AGGIUNTIVI",
                          requirements: "REQUISITI",
                          details: "DETTAGLI",
                          ectsNumber: "NUMERO DI ECTS",
                          subjectFieldISCED: "CAMPO OGGETTO (ISCED)",
                          studyLevelEQF: "LIVELLO DI STUDIO (EQF)",
                          website: "SITO WEB",
                          message: "L'istituzione invierà la propria decisione entro 4 settimane e non oltre 5 settimane."
                        },
                        inclusionAndAccessibility: {
                          title: "INCLUSIONE E ACCESSIBILITÀ",
                          descriptionMain: {
                            part1: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          infrastructure: "Infrastrutture",
                          name: "NOME",
                          description: "DESCRIZIONE",
                          contact: "CONTATTO",
                          services: "Servizi"
                        },
                        housing: {
                          title: "ALLOGGIO",
                          description: {
                            part1: "L'istituzione guiderà i partecipanti in mobilità in arrivo nella ricerca di un alloggio, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          contact: "Contatto"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "L'istituzione fornirà assistenza, quando necessario, per ottenere i visti per i partecipanti in mobilità in entrata e in uscita, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          contact: "Contatto"
                        },
                        insurance: {
                          title: "ASSICURAZIONE",
                          description: {
                            part1: "L'istituzione fornirà assistenza per l'ottenimento dell'assicurazione per i partecipanti in mobilità in entrata e in uscita, in base ai requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " L'istituto ricevente informerà i partecipanti alla mobilità dei casi in cui la copertura assicurativa non viene fornita automaticamente.",
                            part3: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          contact: "Contatto"
                        },
                        additionalInfo: {
                          title: "INFORMAZIONI AGGIUNTIVE",
                          description: {
                            part1: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          contact: "CONTATTO"
                        }
                      },
                      partnerFactSheet: {
                        header: "Dati della scheda partner",
                        calendar: {
                          title: "CALENDARIO",
                          studentNominations: "Le candidature degli studenti devono pervenire all'istituzione ricevente entro: ",
                          autumnTerm: "Periodo autunnale: ",
                          springTerm: "Periodo di primavera: ",
                          applicationsForNominated: "Le domande per gli studenti nominati devono pervenire all'istituzione ricevente entro: ",
                          applicationProcedure: "Procedura di candidatura per gli studenti nominati: ",
                          email: "Email di contatto: ",
                          phone: "Telefono di contatto: ",
                          websiteForInfo: "Sito web per informazioni: "
                        },
                        additionalRequirements: {
                          title: "REQUISITI AGGIUNTIVI",
                          requirements: "REQUISITI",
                          details: "DETTAGLI",
                          ectsNumber: "NUMERO DI ECTS",
                          subjectFieldISCED: "CAMPO OGGETTO (ISCED)",
                          studyLevelEQF: "LIVELLO DI STUDIO (EQF)",
                          website: "SITO WEB",
                          message: "L'istituzione invierà la propria decisione entro 4 settimane e non oltre 5 settimane."
                        },
                        inclusionAndAccessibility: {
                          title: "INCLUSIONE E ACCESSIBILITÀ",
                          descriptionMain: {
                            part1: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          infrastructure: "Infrastrutture",
                          name: "NOME",
                          description: "DESCRIZIONE",
                          contact: "CONTATTO",
                          services: "Servizi"
                        },
                        housing: {
                          title: "ALLOGGIO",
                          description: {
                            part1: "L'istituzione guiderà i partecipanti in mobilità in arrivo nella ricerca di un alloggio, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          contact: "Contatto"
                        },
                        visa: {
                          title: "VISA",
                          description: {
                            part1: "L'istituzione fornirà assistenza, quando necessario, per ottenere i visti per i partecipanti in mobilità in entrata e in uscita, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          contact: "Contatto"
                        },
                        insurance: {
                          title: "ASSICURAZIONE",
                          description: {
                            part1: "L'istituzione fornirà assistenza per l'ottenimento dell'assicurazione per i partecipanti in mobilità in entrata e in uscita, in base ai requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " L'istituto ricevente informerà i partecipanti alla mobilità dei casi in cui la copertura assicurativa non viene fornita automaticamente.",
                            part3: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          contact: "Contatto"
                        },
                        additionalInfo: {
                          title: "INFORMAZIONI AGGIUNTIVE",
                          description: {
                            part1: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, secondo i requisiti della Carta Erasmus per l'istruzione superiore.",
                            part2: " Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:"
                          },
                          contact: "CONTATTO"
                        }
                      }
                    },
                    cooperationConditions: {
                      header: "Condizioni di cooperazione",
                      blendedMobility: {
                        yes: "Yes",
                        no: "No"
                      },
                      eqfLevels: {
                        lvl5: "EQF Level 5 - Associate Degree",
                        lvl6: "EQF Level 6 - Bachelor's Degree",
                        lvl7: "EQF Level 7 - Master's Degree",
                        lvl8: "EQF Level 8 - Doctorate Degree",
                      },
                      typeOfCoopCondition: {
                        fillAllFields: "Please fill all the required fields",
                        validEmailSending: "Please fill a valid email address at sending contacts",
                        validEmailReceiving: "Please fill a valid email address at receiving contacts",
                        invalidISCED: "Invalid ISCED F-Codes are detected in the cooperation condition. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.",
                      },
                      cooperationConditions: {
                        header: "CONDIZIONI DI COOPERAZIONE",
                        staffTeacher: "Insegnante personale",
                        staffTeachers: "Personale docente",
                        studyFields: "Campi di studio",
                        staffTraining: "Formazione del personale",
                        staffTrainings: "Formazione del personale",
                        studentStudy: "Studio per studenti",
                        studentTraineeship: "Studenti in tirocinio"
                      },
                      daysPerYear: " giorni all'anno",
                      monthsPerYear: " mesi all'anno",
                      heis: {
                        sender: "MITTENTE",
                        receiver: "RICEVITORE"
                      },
                      conditionDetails: {
                        header: "Dettagli sulle condizioni",
                        mobilityType: "TIPO DI MOBILITÀ",
                        duration: "DURATA",
                        academicYears: "ANNI ACCADEMICI",
                        eqfLevel: "Livello EQF",
                        spots: "SPOTS"
                      },
                      otherInfo: "ALTRE INFO",
                      languageRequirements: {
                        header: "Requisiti linguistici",
                        language: "LINGUA",
                        studyField: "CAMPO DI STUDIO",
                        clarification: "CHIARIMENTO"
                      },
                      studyFields: {
                        header: "Campi di studio",
                        studyField: "CAMPO DI STUDIO",
                        clarification: "CHIARIMENTO"
                      },
                      contacts: {
                        header: "Contatti",
                        sendingContacts: "INVIO DI CONTATTI",
                        receivingContacts: "CONTATTI DI RICEZIONE"
                      }
                    },
                    confirmation: "Confermo, anche a nome del mio HEI, di disporre di tutte le autorizzazioni, compreso il consenso delle persone fisiche interessate, necessarie per carica i dati personali e le informazioni che sto fornendo, ai sensi dei Termini e condizioni del cruscotto e dell'Informativa sulla privacy.",
                    confirmationCheck: "Accettate di aver letto l'Informativa sulla privacy e i Termini e condizioni."
                  }
                }, 
                iiaImport: {
                  subTitle: "Importa IIA",
                  alerts: {
                    incorrectFileType: "Siamo spiacenti, ma il file selezionato non ha l'estensione corretta. Selezionare un file .csv.",
                    invalidTemplate: "Siamo spiacenti, ma il modello utilizzato non è valido. È possibile ottenere il modello ufficiale nella pagina Import IIA.",
                    errorMessage: {
                      part1: "Attualmente, l'importazione di accordi interistituzionali accetta solo fino a ",
                      part2: " e il file appena selezionato ",
                      part3: "hanno un totale di ",
                      part4: " accordi, ",
                      part5: "così ",
                      part6: "l'ultimo ",
                      part7: " Gli accordi validi saranno scartati.",
                      message1: "con ",
                      message2: "queste righe non valide e ",
                      emptyRows: " righe vuote, ",
                      wrongPartnerEC: " accordi con PartnerEC errati (i partner non possono avere lo stesso codice Erasmus dell'utente o un codice Erasmus non valido), "
                    },
                    errorMessage2: {
                      message:  "Questo errore potrebbe essere causato da una delle seguenti cause:\n" +
                                " - Tutte le righe nel file caricato sono vuote o non valide;\n" +
                                " - Non esiste un partner EC;" +
                                " - Avete usato la vostra EC come EC del partner.\n" +
                                "Controllare il file. Senza la correzione di questi errori, non è possibile verificare i campi rimanenti."
                    },
                  },
                  header: "Carica il file CSV per importare gli Accordi interistituzionali",
                  loadingMessage: "L'operazione può richiedere un po' di tempo, ma non chiudete questa pagina!",
                  returnToIIAListing: "Torna all'elenco degli IIA",
                  returnToUploadPage: "Ritorno alla pagina di caricamento",
                  description: "La funzionalità di importazione degli accordi interistituzionali supporta ora il caricamento di CSV. Dopo aver inserito i dati, è possibile rivedere gli accordi uno per uno per assicurarsi che tutte le informazioni siano corrette e inviare i dati. Quest'azione attiverà la creazione di un accordo interistituzionale precompilato e una notifica per il partner che lo inviterà a modificare o firmare il documento. È anche possibile inviare tutti gli accordi contemporaneamente facendo clic sul pulsante “Importa tutti”.",
                  verifyFormat: "Il formato del file CSV può essere verificato attraverso l'uso di un foglio di calcolo modello messo a disposizione sul CC dell'EWP  ",
                  here: "qui",
                  confirmation: "Confermo, anche a nome del mio IIS, di disporre di tutte le autorizzazioni, compreso il consenso delle persone fisiche interessate, necessarie per carica i dati personali e le informazioni che sto fornendo, ai sensi dei Termini e condizioni del cruscotto e dell'Informativa sulla privacy.                        ",
                  consentError: "Si prega di dare il consenso al trattamento dei dati",
                  uploadButton: "Carica",
                  importConfirmation: "È sicuro di voler importare tutti gli accordi?"
                },
                iiaExport: {
                  subTitle: "Esporta IIA",
                  header: "Scaricare il file CSV per esportare gli Accordi interistituzionali",
                  description: "La funzionalità di esportazione dell'Accordo interistituzionale supporta ora il download di CSV. Il file di esporta CSV per la vostra istituzione viene creato in modo asincrono e periodico. L'ultimo file di esportazione CSV è disponibile per il download qui sotto.",
                  message: {
                    part1: "File di esporta CSV creato con successo su",
                    part2: " ed è disponibile per il download"
                  },
                  downloadButton: "Scarica"
                },
                editFactsheet: {
                  subTitle: "Modifica della scheda informativa",
                  loadingMessage: "Caricamento...",
                  errorMessage: "Si è verificato un errore",
                  factsheetFeedBack: {
                    update: {
                      success: "I dati del Factsheet sono stati aggiornati con successo.",
                      failure: "Si è verificato un errore nell'aggiornamento dei dati della scheda informativa.",
                      alert: "Si è verificato un errore nell'aggiornamento dei dati della scheda informativa: "
                    }
                  },
                  calendar: {
                    header: "CALENDARIO",
                    studentNominations: "Le candidature degli studenti devono pervenire all'istituzione ricevente entro:",
                    autumnTerm: "Termine autunnale Data ",
                    springTerm: "Termine di primavera Data ",
                    invalidDateFormat: "Formato della data non valido",
                    applicationsForNominated: "Le domande per gli studenti nominati devono pervenire all'istituzione ricevente entro:",
                    applicationProcedure: "Procedura di candidatura per gli studenti nominati:",
                    email: {
                      title: "Contatto e-mail ",
                      error: "Non valido"
                    },
                    phone: {
                      title: "Telefono di contatto ",
                      error: " Inserire un numero di telefono che inizi con il prefisso del proprio Paese (es.: +000)."
                    },
                    websiteForInfo: {
                      title: "Sito web per informazioni ",
                      error: "Inserire un URL che inizi con http o https."
                    },
                    defaultWeekValues: "Valori settimanali predefiniti:",
                    weeksForDecision: {
                      title: "Settimane per la decisione sui requisiti aggiuntivi ",
                      error: "Non valido"
                    },
                    weeksForTranscript: {
                      title: "Settimane per la trascrizione dei documenti  ",
                      error: "Non valido"
                    }
                  },
                  additionalRequirements: {
                    title: "REQUISITI AGGIUNTIVI",
                    requirement: {
                      title: "Requisiti ",
                      placeholder: "Selezionare il requisito"
                    },
                    details: {
                      title: "Dettagli",
                    },
                    ectsNumber: {
                      title: "Numero di ECTS completati",
                    },
                    studyFieldISCED: {
                      title: "Settore di studio (ISCED)",
                      placeholder: "Selezionare ISCED"
                    },
                    studyLevelEQF: {
                      title: "Livello di studio (EQF)",
                      placeholder: "Selezionare EQF"
                    },
                    website: {
                      title: "Sito web per informazioni",
                      error: "Inserire un URL che inizi con http o https."
                    },
                    deleteButton: "Eliminare il requisito aggiuntivo",
                    addRequirement: "Aggiungere un requisito aggiuntivo"
                  },
                  inclusionAndAccessibility: {
                    title: "INCLUSIONE E ACCESSIBILITÀ",
                    descriptionMain: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, in base ai requisiti della Carta Erasmus per l'istruzione superiore. Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:",
                    infrastructure: "Infrastrutture",
                    name: "Nome ",
                    description: "Descrizione",
                    contactEmail: {
                      title: "Contatto e-mail ",
                      error: "Non valido"
                    },
                    contactPhone: {
                      title: "Telefono di contatto ",
                      error: "Inserire un numero di telefono che inizi con il prefisso del proprio Paese (es.: +000)."
                    },
                    website: {
                      title: "Sito web per informazioni ",
                      error: "Inserire un URL che inizi con http o https."
                    },
                    addInfrastucture: "Aggiungi infrastruttura",
                    services: "Servizi",
                    addService: "Aggiungi servizio"
                  },
                  deleteInfrastructure: "Cancellare l'infrastruttura",
                  deleteService: "Cancellare il servizio",
                  housing: {
                    title: "ALLOGGIO",
                    description: "L'istituzione guiderà i partecipanti in mobilità in arrivo nella ricerca di un alloggio, secondo i requisiti della Carta Erasmus per l'istruzione superiore. Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:",
                    contact: "Contatto",
                    contactEmail: {
                      title: "Contatto e-mail ",
                      error: "Non valido"
                    },
                    contactPhone: {
                      title: "Telefono di contatto ",
                      error: "Inserire un numero di telefono che inizi con il prefisso del proprio Paese (es.: +000)."
                    },
                    website: {
                      title: "Sito web per informazioni ",
                      error: "Inserire un URL che inizi con http o https."
                    }
                  },
                  visa: {
                    title: "VISA",
                    description: "L'istituzione fornirà assistenza, quando necessario, per ottenere i visti per i partecipanti in mobilità in entrata e in uscita, secondo i requisiti della Carta Erasmus per l'istruzione superiore. Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:",
                    contact: "Contatto",
                    contactEmail: {
                      title: "Contatto e-mail ",
                      error: "Non valido"
                    },
                    contactPhone: {
                      title: "Telefono di contatto ",
                      error: "Inserire un numero di telefono che inizi con il prefisso del proprio Paese (es.: +000)."
                    },
                    website: {
                      title: "Sito web per informazioni ",
                      error: "Inserire un URL che inizi con http o https."
                    }
                  },
                  insurance: {
                    title: "ASSICURAZIONE",
                    description: "L'istituzione fornirà assistenza per l'ottenimento di un'assicurazione per i partecipanti mobili in entrata e in uscita, in base ai requisiti della Carta Erasmus per l'istruzione superiore. L'istituzione ricevente informerà i partecipanti in mobilità dei casi in cui la copertura assicurativa non viene fornita automaticamente. Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:",
                    contact: "Contatto",
                    contactEmail: {
                      title: "Contatto e-mail ",
                      error: "Non valido"
                    },
                    contactPhone: {
                      title: "Telefono di contatto ",
                      error: "Inserire un numero di telefono che inizi con il prefisso del proprio Paese (es.: +000)."
                    },
                    website: {
                      title: "Sito web per informazioni ",
                      error: "Inserire un URL che inizi con http o https."
                    }
                  },
                  additionalInfo: {
                    title: "INFORMAZIONI AGGIUNTIVE",
                    description: "L'istituzione fornirà supporto ai partecipanti in mobilità in arrivo con esigenze speciali, in base ai requisiti della Carta Erasmus per l'istruzione superiore. Informazioni e assistenza possono essere fornite dai seguenti punti di contatto e fonti di informazione:",
                    recognitionProcess: "Processo di riconoscimento",
                    contact: "CONTATTO",
                    contactEmail: {
                      title: "Contatto e-mail ",
                      error: "Non valido"
                    },
                    contactPhone: {
                      title: "Telefono di contatto ",
                      error: "Inserire un numero di telefono che inizi con il prefisso del proprio Paese (es.: +000)."
                    },
                    website: {
                      title: "Sito web per informazioni ",
                      error: "Inserire un URL che inizi con http o https."
                    },
                    deleteRecognitionProcess: "Cancellare il processo di riconoscimento",
                    addRecognitionProcess: "Aggiungi informazioni sul processo di riconoscimento",
                    otherInfo: "Altre informazioni utili",
                    deleteInfo: "Cancellare altre informazioni",
                    addInfo: "Aggiungere altre informazioni utili"
                  },
                  saveFactsheet: "salvare i dati della scheda informativa",
                  containsErrors: "Il modulo contiene errori",
                  confirmation: "Confermo, anche a nome del mio HEI, di disporre di tutte le autorizzazioni, compreso il consenso delle persone fisiche interessate, necessarie per carica i dati personali e le informazioni che sto fornendo, ai sensi dei Termini e condizioni del cruscotto e dell'Informativa sulla privacy.",
                  confirmationError: "Si prega di dare il consenso al trattamento dei dati",
                  saveConfirmation: "State per modificare i dati della vostra scheda informativa"
                },
                filter: {
                  title: "Filtri e ordinamento",
                  clearButton: "Cancella tutto",
                  applyButton: "Applicare i filtri",
                  academicTerms: {
                    firstSemester: "Primo semestre (inverno/autunno)",
                    secondSemester: "Secondo semestre (estate/primavera)",
                    firstTrimester: "Primo trimestre",
                    secondTrimester: "Secondo trimestre",
                    fullYear: "Anno accademico completo",
                  },
                  sortingList: {
                    status: "Ordinamento per stato",
                    lastChangedAscending: "Ordinamento per ultima modifica crescente",
                    lastChangedDescending: "Ordinamento per Ultima modifica decrescente",
                    name: "Ordinamento per nome"
                  },
                  pendingRequests: {
                    pending: "In attesa",
                    accepted: "Accettato",
                    declined: "Declinato"
                  },
                  sortBy: {
                    title: "Ordina per",
                    placeholder: "Selezionare l'ordinamento per..."
                  },
                  status: {
                    title: "Stato",
                    placeholder: "Selezionare lo stato..."
                  },
                  iia: {
                    title: "IIA ID",
                    placeholder: "IIA ID",
                    label: "Cercare in base al proprio ID o all'ID del partner:"
                  },
                  academicYear: {
                    title: "Anno accademico",
                    placeholder: "Selezionare l'anno accademico..."
                  },
                  academicTerm: {
                    title: "Termine accademico",
                    placeholder: "Selezionare l'anno accademico..."
                  },
                  country: {
                    title: "Paese",
                    placeholder: "Selezionare uno o più paesi..."
                  },
                  institution: {
                    title: "Istituzione",
                    erasmusCode: "Codice Erasmus:",
                    esasmusCodePlaceholder: "Seleziona il codice erasmus...",
                    institutionName: "Nome dell'istituzione:",
                    institutionNamePlaceholder: "Selezionare il nome dell'istituto...",
                  },
                  approvingEmail: {
                    title: "Email della persona che approva",
                    placeholder: "Email della persona che approva"
                  },
                  createdBy: {
                    title: "Creato da",
                    placeholder: "Seleziona creato da...",
                    student: "Creato da uno studente",
                    institution: "Creato dall'Istituzione"
                  },
                  studentName: {
                    title: "Nome dello studente",
                    firstNameLabel: "Nome:",
                    firstNamePlaceholder: "Nome",
                    lastNameLabel: "Cognome:",
                    lastNamePlaceholder: "Cognome"
                  },
                  organizationalUnit: {
                    title: "Unità organizzativa",
                    placeholder: "Selezionare l'unità organizzativa..."
                  },
                  name: {
                    title: "Nome",
                  },
                  email: {
                    title: "Email",
                    error: "Si prega di fornire una richiesta di valore valida."
                  }
                },
                modal: {
                  cancelButton: "Annullamento"
                },
                selectPhoneInput: {
                  label: "Codice telefono",
                  placeholder: "Scegliere il prefisso telefonico...",
                  error: {
                    a: "Si prega di fornire un prefisso telefonico",
                    b: "Si prega di fornire un telefono valido."
                  }
                },
                table: {
                  filterLabel: "Filtri",
                  totalAgreements: "Totale accordi",
                  activeFilters: "Filtri attivi:",
                  noResults: "Spiacenti, non abbiamo trovato alcun risultato"
                },
                coursesTable: {
                  olaReasons: {
                    part1: "La componente formativa selezionata in precedenza non è disponibile presso l'istituto ricevente",
                    part2: "l componente è in una lingua diversa da quella precedentemente specificata nel catalogo dei corsi",
                    part3: "Conflitto di orari",
                    part4: "Altro (specificare)",
                    part5: "Sostituzione di un componente eliminato",
                    part6: "Estensione del periodo di mobilità",
                    part7: "Altro (specificare)",
                    other: "Altro "
                  },
                  noCourses: "Nessun corso da mostrare",
                  totalECTs: "Totale crediti ECTS: ",
                  labels: {
                    code: "CODICE",
                    subject: "OGGETTO",
                    ects: "ECTS",
                    semester: "SEMESTRE",
                    reason: "MOTIVO",
                    autoRecognition: "RICONOSCIMENTO AUTOMATICO",
                    description: "DESCRIZIONE"
                  }
                },
                olaPdf: {
                  download: {
                    part1: "Scaricare il PDF facendo clic su ",
                    part2: "qui"
                  }
                },
                institutionInfoTable: {
                  name: "Nome:",
                  country: "Paese:",
                  erasmusCode: "Codice Erasmus:",
                  address: "Indirizzo:",
                  faculty: "Facoltà:",
                  contactPerson: "Persona di contatto: ",
                  resPerson: "Res. Persona:"
                },
                learningAgreementInfoTable: {
                  status: "STATO",
                  academicYear: "ANNO ACCADEMICO",
                  plannedPeriod: "PERIODO PREVISTO",
                  created: "CREATO",
                  pdfExportText: "Esporta PDF"
                },
                studentInfo: {
                  firstName: "Nome:",
                  lastName: "Cognome:",
                  email: "Email:",
                  birthDate: "Data di nascita:",
                  gender: "Genere:",
                  nationality: "Nazionalità:",
                  foeISCED: "Settore di istruzione (ISCED):",
                  foeClarification: "Settore dell'istruzione (chiarimento):"
                },
                tor: {
                  totalECTs: "Totale ECTS: ",
                  form: {
                    componentCode: {
                      title: {
                        part1: "Codice componente",
                        part2: "*"
                      },
                      error: "Si prega di fornire un codice.",
                    },
                    componentTitle: {
                      title: {
                        part1: "Titolo del componente",
                        part2: "*"
                      },
                      error: "Si prega di fornire un titolo."
                    },
                    grade: {
                      title: {
                        part1: "Grado",
                        part2: "*"
                      },
                      error: "Si prega di fornire un voto."
                    },
                    ectNum: {
                      title: {
                        part1: "Numero di crediti ECTS",
                        part2: "*"
                      },
                      error: "Si prega di fornire i crediti ects."
                    },
                    success: {
                      part1: "Completato con successo",
                      part2: "*"
                    }
                  },
                  torFeedback: {
                    create: {
                      loading: "Creare un nuovo ToR...",
                      success: "Creazione di ToR riuscita",
                      failure: "Errore durante la creazione del ToR."
                    },
                    update: {
                      loading: "Aggiornamento di un nuovo ToR...",
                      success: "ToR aggiornati con successo",
                      failure: "Errore durante l'aggiornamento dei ToR."
                    }
                  },
                  componentFeedback: {
                    create: {
                      loading: "Creare un nuovo componente...",
                      success: "Componente creato con successo",
                      failure: "Errore durante la creazione del componente."
                    },
                    edit: {
                      loading: "Modifica di un nuovo componente...",
                      success: "Componente modificato con successo",
                      failure: "Errore durante la modifica del componente."
                    },
                    delete: {
                      loading: "Eliminazione di un nuovo componente...",
                      success: "Eliminato con successo il componente",
                      failure: "Errore durante l'eliminazione del componente."
                    }
                  },
                  createTor: "Creare i ToR",
                  mobilityStart: "Data di inizio della mobilità:",
                  mStart: "Data di inizio della mobilità",
                  mEnd: "Data di fine della mobilità",
                  mobilityEnd: "Data di fine della mobilità:",
                  invalidInput: "Si prega di fornire una richiesta di valore valida.",
                  transcript: "TRASCRIZIONE DEI DATI DELL'ISTITUTO RICEVENTE",
                  recognition: "RICONOSCIMENTO PRESSO L'ISTITUTO DI PROVENIENZA",
                  addComponentTitle: { 
                    part1: "Aggiungere un nuovo ",
                    part2: "Componente"
                  },
                  editComponentTitle: {
                    part1: "Modifica ",
                    part2: "Componente"
                  },
                  deleteComponentTitle: {
                    confirmMessage: "Sei sicuro?",
                    part1: "Cancellare ",
                    part2: "Componente"
                  },
                  updateTor: {
                    part1: "Aggiornamento ",
                    part2: "ToR"
                  },
                  addComponent: "aggiungere un nuovo componente",
                  editComponent: "Modifica del componente",
                  deleteComponent: "Elimina componente",
                  submitButton: "Invia",
                  updateButton: "Aggiornamento"
                },
                destinationHeader: {
                  nominated: "NOMINATO",
                  rejected: "RIFIUTATO",
                  nominate: "Nominare"
                },
                destinationInfo: {
                  studyLevel: "LIVELLO DI STUDIO",
                  studyField: "CAMPO DI STUDIO",
                  reqDocuments: "DOCUMENTI RICHIESTI",
                  otherReq: "ALTRI REQUISITI"
                },
            }
          },
        pol: {
            translation: {
              loadingMessage: "Ładowanie...",
              errorMessage: "Wystąpił błąd",
              sidebar: {
                institutions: "Instytucje",
                requests: "Żądania",
                myUniversity: "Mój uniwersytet",
                accountsAndAccess: "Konta i dostęp",
                organizationalUnits: "Jednostki organizacyjne",
                ukraineAssistance: "Wsparcie dla Ukrainy",
                ola: "OLA",
                outgoingStudents: "Wyjeżdżający studenci",
                incomingStudents: "Przyjeżdżający studenci",
                upload: "Przesyłanie",
                shortTerm: "Krótkoterminowe",
                applications: "Zastosowania",
                requirements: "Kryteria",
                iia: "IIA",
                iiaList: "Lista IIA",
                newDigitalIIA: "Nowe cyfrowe IIA",
                importIIA: "Import IIA",
                exportIIAs: "Eksport IIA",
                defaultIIAData: "Domyślne dane IIA",
                erasmusApp: "Aplikacja Erasmus+",
                usefulInfo: "Przydatne informacje",
                deals: "Oferty",
                events: "Wydarzenia",
                coursesEvaluations: "Oceny kursów",
                ewpSettings: "Ustawienia EWP",
              },
              home: {
                  header: "Witamy w EWP Dashboard!",
                  title: "Strona główna",
                  tab: "Strona główna",
                  description: {
                    part1: "Witamy w EWP Dashboard, narzędziu do zarządzania mobilnością studentów w ramach programu Erasmus+. Jako IRO odgrywasz kluczową rolę w pomaganiu swoim studentom w jak najlepszym wykorzystaniu ich doświadczeń za granicą, a EWP Dashboard jest tutaj, aby wspierać Cię w tym wysiłku.", 
                    part2: "Z radością informujemy, że wprowadziliśmy kilka znaczących ulepszeń w interfejsie użytkownika i obsłudze aplikacji. Ciężko pracowaliśmy, aby EWP Dashboard był bardziej intuicyjny i przyjazny dla użytkownika, i wierzymy, że te zmiany ułatwią zarządzanie mobilnością studentów i maksymalne wykorzystanie możliwości oferowanych przez program Erasmus+."
                  }
              },
              articleSlider: {
                loadingMessage: "Ładowanie...",
                errorMessage: "Wystąpił błąd",
                created: "Utworzony: "
              },
              articleSingleView: {
                title: "Wpis na blogu",
                subTitle: "Widok pojedynczy",
                created: "Utworzony: ",
                lastUpdated: "Ostatnia aktualizacja: ",
                tags: "Tagi: "
              },
              ewpSettings: {
                header: "WYMIANA INFORMACJI ZA POŚREDNICTWEM PULPITU NAWIGACYJNEGO EWP",
                title: "USTAWIENIA EWP",
                subTitle: {
                  part1: "",
                  part2: "Zaznacz / usuń ", 
                  part3: "zaznaczenie zgodnie z tym, co dotyczy Twojej uczelni."
                },
                ewpSettingType: {
                  updatingSettings: "Aktualizacja ustawień EWP...",
                  updatedSuccessfully: `Ustawienia EWP zostały pomyślnie zaktualizowane`,
                  unableToUpdate: `Nie można zaktualizować ustawień EWP`,
                  exchangeOfIIAs: {
                    title: "Wymiana IIA za pośrednictwem EWP Dashboard",
                    checkbox: "Zgadzam się, że EWP Dashboard reprezentuje moją instytucję szkolnictwa wyższego w sieci EWP w celu wymiany umów międzyinstytucjonalnych z partnerami."
                  },
                  exchangeOfOUnits: {
                    title: "Wymiana OUnits za pośrednictwem EWP Dashboard",
                    checkbox: "Wyrażam zgodę na to, aby EWP Dashboard reprezentował moją instytucję szkolnictwa wyższego w sieci EWP w celu udostępniania informacji o jednostkach organizacyjnych partnerom."
                  },
                  exchangeOfStaticInfo: {
                    title: "Wymiana informacji statycznych dla IIA za pośrednictwem EWP Dashboard",
                    checkbox: "Wyrażam zgodę, aby EWP Dashboard reprezentował moją instytucję szkolnictwa wyższego w sieci EWP w celu wymiany informacji statycznych dotyczących umów międzyinstytucjonalnych z partnerami."
                  },
                  exchangeOfOLAs: {
                    title: "Wymiana umów OLA za pośrednictwem EWP Dashboard",
                    checkbox: "Zgadzam się, że EWP Dashboard reprezentuje moją instytucję szkolnictwa wyższego w sieci EWP w celu umożliwienia elektronicznej wymiany informacji dotyczących Porozumienia o programie zajęć online."
                  }
                },
                cancelButton: "Anuluj",
                saveSettingsButton: "Zapisz ustawienia EWP"
              },
              accountsAndAccess: {
                title: "Konta i dostęp",
                accountsTab: "Konta",
                rolesTab: "Role",
                saveChangesButton: "Zapisz zmiany",
                accountForm: {
                  accountName: {
                    label: "Nazwa konta",
                    error: "Prosimy o podanie nazwy konta."
                  },
                  accountEmail: {
                    label: "Konto e-mail",
                    error: "Podaj prawidłowy adres e-mail konta."
                  },
                  accountPhone: {
                    label: "Numer telefonu",
                    error: "Zarejestrowany numer telefonu tego konta nie jest prawidłowym numerem telefonu. Podaj prawidłowy numer telefonu."
                  },
                  accountRole: {
                    label: "Rola",
                    placeholder: "Wybierz rolę...",
                    error: "Podaj rolę dla konta."
                  },
                },
                roleForm: {
                  roleName: {
                    label: "Nazwa roli",
                    error: "Podaj nazwę roli."
                  },
                  rolePermissions: {
                    label: "Uprawnienia",
                    placeholder: "Wybierz uprawnienia...",
                    error: {
                      a: "Wybierz co najmniej jedno uprawnienie dla tej roli.",
                      b: "Musisz wybrać co najmniej jedno pozwolenie na rolę."
                    }
                  }
                },
                accountFeedback: {
                  create: {
                    loading: "Tworzenie konta...",
                    success: "Konto zostało pomyślnie utworzone ",
                    failure: "Nie można utworzyć konta "
                  },
                  update: {
                    loading: "Aktualizacja konta...",
                    success: "Pomyślnie zaktualizowano konto ",
                    failure: "Nie można zaktualizować konta "
                  },
                  delete: {
                    loading: "Usuwanie konta...",
                    success: "Pomyślnie usunięto konto ",
                    failure: "Nie można usunąć konta "
                  }
                },
                addNewAccount: {
                  title: "Dodaj nowe konto",
                  subTitle: "Dodaj niezbędne informacje, aby dodać nowe konto dla jednego z pracowników",
                  buttonText: "Dodaj nowe konto"
                },
                editAccount: {
                  title: "Edytowanie istniejącego konta",
                  subTitle: "Edytuj informacje o istniejącym koncie i ",
                  buttonText: "Edytuj konto"
                },
                deleteAccount: {
                  title: {
                    part1: "Masz zamiar ",
                    part2: "usunąć",
                    part3: " konto "
                  },
                  subTitle: "Po usunięciu konta pracownik nie będzie mógł się zalogować.",
                  buttonText: "Usuń konto"
                },
                form: {
                  accountName: {
                    label: "Nazwa konta",
                    error: "Prosimy o podanie nazwy konta."
                  },
                  accountEmail: {
                    label: "Konto e-mail",
                    error: "Podaj prawidłowy adres e-mail konta."
                  },
                  accountPhone: {
                    label: "Numer telefonu",
                    error: "Zarejestrowany numer telefonu tego konta nie jest prawidłowym numerem telefonu. Podaj prawidłowy numer telefonu."
                  },
                  accountRole: {
                    label: "Rola",
                    placeholder: "Wybierz rolę...",
                    error: "Podaj rolę dla konta."
                  }
                },
                roleFeedback: {
                  create: {
                    loading: "Tworzenie roli...",
                    success: "Pomyślnie utworzono rolę ",
                    failure: "Nie można utworzyć roli "
                  },
                  update: {
                    loading: "Aktualizacja roli...",
                    success: "Pomyślnie zaktualizowano rolę ",
                    failure: "Nie można zaktualizować roli "
                  },
                  delete: {
                    loading: "Usuwanie roli...",
                    success: "Pomyślnie usunięto rolę ",
                    failure: "Nie można usunąć roli ",
                    warning: {
                      part1: "Rola ",
                      part2: " jest używany przez ",
                      part3: " konto",
                      part4: {
                        a: "s",
                        b: ""
                      },
                      part5: ". Dlatego nie można go usunąć."
                    }
                  }
                },
                addNewRole: {
                  title: "Dodaj nową rolę",
                  subTitle: "Dodaj niezbędne informacje, aby dodać nową rolę, którą możesz później przypisać swoim pracownikom",
                  buttonText: "Dodaj nową rolę"
                },
                editRole: {
                  title: "Edytowanie istniejącej roli",
                  subTitle: "Edytuj informacje o istniejącej Roli i ",
                  buttonText: "Edytuj rolę"
                },
                deleteRole: {
                  title: {
                    part1: "Masz zamiar ",
                    part2: "usunąć",
                    part3: " rola "
                  },
                  subTitle: "Po usunięciu roli nie będzie można jej ponownie zobaczyć.",
                  buttonText: "Usuń rolę"
                }
              },
              organizationalUnits: {
                title: "Jednostki organizacyjne",
                saveChangesButton: "Zapisz zmiany",
                unitForm: {
                  unitLanguage: {
                    label: "Nazwa jednostki Język",
                    placeholder: "Wybierz język...",
                  },
                  unitName: {
                    label: "Nazwa jednostki",
                    placeholder: "Nazwa jednostki",
                    error: "Podaj prawidłową nazwę jednostki organizacyjnej."
                  },
                  unitAbbreviation: {
                    label: "Skrót jednostki",
                    placeholder: "Skrót Ounit"
                  },
                  unitContactName: {
                    label: "Imię i nazwisko osoby kontaktowej Wyjazdowe porozumienia o programie zajęć",
                  },
                  unitContactEmail: {
                    label: "Kontakt E-mail Wychodzące porozumienia o programie zajęć",
                    error: "Podaj prawidłowy kontaktowy adres e-mail."
                  },
                  incomingDifferent: {
                    label: "Osoba kontaktowa ds. przychodzących porozumień o programie zajęć jest inna"
                  },
                  incomingContactName:{
                    label: "Imię i nazwisko osoby kontaktowej Przychodzące porozumienia o programie zajęć",
                  },
                  incomingContactEmail:{
                    label: "Kontakt Email Przychodzące porozumienia o programie zajęć",
                    error: "Podaj prawidłowy kontaktowy adres e-mail."
                  }
                },
                unitFeedback: {
                  create: {
                    loading: "Tworzenie jednostki organizacyjnej...",
                    success: "Pomyślnie utworzono jednostkę organizacyjną o nazwie ",
                    failure: "Nie można utworzyć jednostki organizacyjnej"
                  },
                  update: {
                    loading: "Aktualizacja jednostki organizacyjnej...",
                    success: "Pomyślnie zaktualizowano jednostkę organizacyjną ",
                    failure: "Nie można zaktualizować jednostki organizacyjnej"
                  },
                  delete: {
                    loading: "Usuwanie jednostki organizacyjnej...",
                    success: "Pomyślnie usunięto jednostkę organizacyjną",
                    failure: "Nie można usunąć jednostki organizacyjnej"
                  }
                },
                addNewUnit: {
                  title: "Dodaj nową jednostkę organizacyjną",
                  subTitle: "Dodaj niezbędne informacje, aby dodać nową jednostkę organizacyjną dla swojej instytucji szkolnictwa wyższego",
                  buttonText: "Dodaj nową jednostkę organizacyjną"
                },
                editUnit: {
                  title: "Edycja istniejącej jednostki organizacyjnej",
                  subTitle: "Dodaj niezbędne informacje, aby edytować jednostkę organizacyjną dla swojej uczelni",
                  buttonText: "Edytuj jednostkę organizacyjną"
                },
                deleteUnit: {
                  title: {
                    part1: "Masz zamiar ",
                    part2: "usunąć",
                    part3: " Jednostka organizacyjna"
                  },
                  subTitle: "Po usunięciu jednostki organizacyjnej nie będzie można jej ponownie wyświetlić.",
                  buttonText: "Usuń jednostkę organizacyjną",
                  buttonLabel: "Usuń",
                },
              },
              ukraineAssistance: {
                title: "Wsparcie dla Ukrainy",
                infoFeedback: {
                  loading: "Aktualizacja informacji o pomocy dla Ukrainy...",
                  success: "Pomyślnie zaktualizowano informacje o pomocy dla Ukrainy",
                  failure: "Nie można zaktualizować informacji o Ukraine Assistance"
                },
                validateError: "Wybierz co najmniej jedną opcję „Tak” lub podaj prawidłowy link.",
                invalidUrl: "Nieprawidłowy adres URL",
                header: "Wojna w Ukrainie - Studenckie Centrum Wsparcia",
                description: "W odpowiedzi na dramatyczne wydarzenia rozgrywające się na Ukrainie, stworzyliśmy portal, który dostarcza informacji na temat pomocy oferowanej przez instytucje szkolnictwa wyższego studentom uciekającym przed wojną. Jeśli chciałbyś pomóc nam w rozpowszechnianiu informacji o tym, jak Twoja uczelnia wkracza do akcji i czyni różnicę, prosimy o wskazanie:",
                form: {
                  shelter: "Czy instytucja zapewnia schronienie w nagłych wypadkach?",
                  health: "Czy w Twojej instytucji dostępne jest wsparcie zdrowotne i psychologiczne?",
                  fastTrack: "Czy Twoja uczelnia oferuje szybką ścieżkę aplikowania na studia?",
                  scholarship: "Czy Twoja instytucja oferuje specjalne stypendia na studia?",
                  options: {
                    yes: "Tak",
                    no: "Nie",
                    unkown: "Nieznany"
                  }
                },
                moreInfo: {
                  header: "Prosimy o podanie linku do dedykowanej strony internetowej instytucji zawierającej więcej informacji na ten temat lub do odpowiedniej strony kontaktowej instytucji.",
                  error: "Podaj prawidłowy adres URL.",
                  buttonText: "Informacje dotyczące pomocy dla Ukrainy"
                }
              },
              incomingStudents: {
                title: "Umowa o naukę online",
                subTitle: "Przyjeżdżający studenci",
                tab: "Przychodzące umowy dotyczące nauki online",
                tabSingleView: "Wyświetl pojedynczą umowę dotyczącą nauki online",
              },
              outgoingStudents: {
                title: "Umowa o naukę online",
                subTitle: "Wyjeżdżający studenci",
                tab: "Wychodzące umowy o naukę online",
                tabSingleView: "Wyświetl pojedynczą Umowę o naukę online wychodzącą",
              },
              applications: {
                title: "Zgłoszenia do programu Erasmus+",
                tab: {
                  out: "Nominacje wychodzące",
                  in: "Nadchodzące nominacje"
                }
              },
              learningAgreements: {
                loadingMessage: "Ładowanie...",
                signFeedback: {
                  loading: "Podpisanie OLA...",
                  success: "Pomyślnie podpisane porozumienie o programie zajęć",
                  failure: "Błąd podczas podpisywania."
                },
                declineOlaFeedback: {
                  loading: "Spadek OLA...",
                  success: "Pomyślnie odrzucone porozumienie o programie zajęć",
                  failure: "Błąd przy odrzucaniu."
                }, 
                editTableFailed: "Edycja tabeli kursów OLA nie powiodła się.",
                LearningAgreementInfo: {
                  title: "INFORMACJE O UMOWIE O NAUKĘ"
                },
                generalInfo: {
                  title: "Informacje ogólne",
                  studentPersonalData: {
                    title: "DANE OSOBOWE STUDENTÓW"
                  },
                  sendingInstInfo: {
                    title: "WYSYŁANIE INFORMACJI O INSTYTUCJI"
                  },
                  receivingInstInfo: {
                    title: "INFORMACJE O INSTYTUCJI PRZYJMUJĄCEJ"
                  }
                },
                courses: {
                  title: "Kursy",
                  tableA: "TABELA A",
                  tableB: "TABELA B",
                  tableC: "TABELA C",
                },
                changes: {
                  title: "Zmiany",
                  tableA: "ZMIANY W TABELI A",
                  tableB: "ZMIANY W TABELI B",
                  tableC: "ZMIANY W TABELI C",
                },
                modalReject: {
                  declineButton: "Spadek",
                  body: {
                    part1: "Po odrzuceniu OLA student będzie mógł go edytować i wprowadzić proponowane zmiany. Prosimy o wpisanie komentarzy, które mają zostać przesłane do studenta w polu poniżej: ",
                    part2: "Powód odmowy",
                    part3: " Uwaga",
                  },
                  title: {
                    part1: "Masz zamiar ",
                    part2: "spadek",
                    part3: " OLA ucznia"
                  }
                },
                modalSign: {
                  signButton: "Podpisz OLA",
                  body: "Podpisując cyfrowo niniejszy dokument, student, uczelnia wysyłająca i uczelnia przyjmująca potwierdzają, że akceptują porozumienie o programie zajęć i że będą przestrzegać wszystkich ustaleń uzgodnionych przez wszystkie strony. Instytucje wysyłające i przyjmujące zobowiązują się do stosowania wszystkich zasad Karty Erasmusa dla szkolnictwa wyższego dotyczących mobilności na studia (lub zasad uzgodnionych w porozumieniu międzyinstytucjonalnym dla instytucji znajdujących się w krajach partnerskich). Uczelnia Beneficjenta i student powinni również zobowiązać się do przestrzegania postanowień umowy o dofinansowanie w ramach programu Erasmus+. Instytucja przyjmująca potwierdza, że wymienione komponenty edukacyjne są zgodne z jej katalogiem kursów i powinny być dostępne dla studenta. Uczelnia wysyłająca zobowiązuje się uznać wszystkie punkty lub równoważne jednostki uzyskane w uczelni przyjmującej za pomyślnie ukończone komponenty edukacyjne i zaliczyć je na poczet stopnia naukowego studenta. Student i Instytucja Przyjmująca będą informować Instytucję Wysyłającą o wszelkich problemach lub zmianach dotyczących programu studiów, osób odpowiedzialnych i/lub okresu studiów.",
                  title: {
                    part1: "Masz zamiar ",
                    part2: "znak",
                    part3: " OLA ucznia"
                  }
                },
                modalPrelimReject: {
                  confirmButton: "Potwierdzenie",
                  body: {
                    part1: "Zgodnie z oficjalnymi wytycznymi Komisji Europejskiej, jeśli automatyczne uznanie nie będzie miało miejsca, prosimy o jasne uzasadnienie i wskazanie, jaki inny rodzaj formalnego uznania zostanie zastosowany. ",
                    part2: "Wpisz powód odrzucenia"
                  }
                },
                modalFinalReject: {
                  body: {
                    part1: "Zgodnie z oficjalnymi wytycznymi Komisji Europejskiej, jeśli automatyczne uznanie nie będzie miało miejsca, prosimy o jasne uzasadnienie i wskazanie, jaki inny rodzaj formalnego uznania zostanie zastosowany. ",
                    part2: "Wpisz powód odrzucenia",
                  }
                },
                upload: {
                  titleMain: "Umowa o naukę online",
                  subTitle: "Prześlij CSV",
                  tab: "Prześlij CSV dla umów o naukę online",
                  validateData: {
                    invalidCode: {
                      part1: "Nieprawidłowy kod Erasmusa ",
                      part2: " w linii "
                    },
                    emailError: {
                      part1: "Błąd wiadomości e-mail w linii ",
                      part2: ": Sprawdź format wiadomości e-mail z danymi."
                    },
                    dataError: {
                      part1: "Błąd daty w wierszu ",
                      part2: ": Upewnij się, że daty są w formacie dd/mm/rrrr."
                    },
                    fileError: "Błąd pliku: Jednocześnie można przesłać tylko 25 plików LA."
                  },
                  uploadOla: {
                    loading: "Przesyłanie umów OLA...",
                    success: "Pomyślnie przesłane porozumienia o programie zajęć",
                    failure: "Podczas przesyłania OLA wystąpiły pewne błędy."
                  },
                  header: "Przesyłanie porozumień o programie zajęć",
                  cancel: "Anuluj",
                  upload: "Przesyłanie",
                  modalSign: {
                    part1: "Upewnij się, że dane są zgodne z poniższymi zasadami ",
                    part2: "szablon"
                  },
                  title: {
                    part1: "Masz zamiar ",
                    part2: "przesyłanie",
                    part3: " niektóre OLA"
                  },
                  dontClosePage: "Może to trochę potrwać, nie zamykaj tej strony!",
                  commonErrors: {
                    part1: "Jeśli widzisz jakieś błędy, sprawdź dostępną stronę z typowymi błędami ",
                    part2: "tutaj"
                  },
                  returnToUploadPage: "Powrót do strony przesyłania",
                  header2: "Prześlij plik CSV, aby wstępnie wypełnić umowy dotyczące nauki online",
                  bodyUpload: {
                    part1: "Funkcja wstępnego wypełniania Online Learning Agreement obsługuje teraz przesyłanie CSV. Po wprowadzeniu danych można przejrzeć listę studentów, aby upewnić się, że wszystkie informacje są poprawne i przesłać dane. Ta czynność spowoduje utworzenie wstępnie wypełnionych porozumień o programie zajęć online i powiadomienie dla uczniów z zaproszeniem do sfinalizowania dokumentu.",
                    part2: "Format pliku CSV można zweryfikować za pomocą szablonu arkusza kalkulacyjnego udostępnionego w EWP CC ",
                    part3: "tutaj"
                  },
                  checkBox: "Potwierdzam, również w imieniu mojej uczelni, że posiadam wszystkie upoważnienia, w tym zgodę odpowiednich osób fizycznych, niezbędne do przesłania danych osobowych i informacji, które przekazuję, zgodnie z Regulaminem i Polityką prywatności Dashboard.",
                  consent: "Prosimy o wyrażenie zgody na przetwarzanie danych"
                }
              },
              nominationList: {
                totalApplications: "Aplikacje ogółem",
                downloadFiles: "Pobierz wszystkie pliki",
                outSignleView: {
                  loadingMessage: "Ładowanie...",
                  errorMessage: "Wystąpił błąd",
                  applicationFeedback: {
                    nominate: {
                      loading: "Wniosek o nominację...",
                      success: "Pomyślnie nominowana aplikacja",
                      failure: "Nominacja wniosku nie powiodła się"
                    },
                    reject: {
                      loading: "Odrzucenie wniosku...",
                      success: "Pomyślnie odrzucony wniosek",
                      failure: "Odrzucenie wniosku nie powiodło się"
                    },
                    wait: {
                      loading: "Umieszczenie aplikacji na liście oczekujących...",
                      success: "Pomyślnie zaktualizowana aplikacja",
                      failure: "Aktualizacja aplikacji nie powiodła się"
                    },
                    delete: {
                      loading: "Usuwanie aplikacji...",
                      success: "Aplikacja została pomyślnie usunięta",
                      failure: "Usunięcie aplikacji nie powiodło się"
                    },
                  },
                  applicationInfo: {
                    title: "INFORMACJE O APLIKACJI",
                    status: "STATUS",
                    academicYear: "ROK AKADEMICKI",
                    academicTerm: "OKRES AKADEMICKI",
                    submissionTime: "CZAS ZGŁOSZENIA",
                    downloadFiles: "Pobierz wszystkie pliki",
                  },
                  generalInfo: {
                    title: "Informacje ogólne",
                    studentInfo: {
                      title: "INFORMACJE DLA STUDENTÓW",
                      name: "NAZWA",
                      email: "EMAIL",
                      studyLevel: "POZIOM STUDIÓW",
                      studyField: "POLE BADAŃ"
                    },
                    destinationheis: {
                      title: "CEL HEIs",
                      institutionFaculty: "INSTYTUCJA / WYDZIAŁ",
                      nominatedStudents: "NOMINOWANI STUDENCI"
                    }
                  },
                  documents: {
                    title: "Dokumenty",
                    viewPdf: "View Pdf",
                    files: { 
                      portfolio: "PORTFOLIO",
                      externalCertification: "CERTYFIKACJA ZEWNĘTRZNA",
                      referenceContact: "KONTAKT REFERENCYJNY",
                      motivationLetter: "LIST MOTYWACYJNY",
                      languageCert: "CERTYFIKATY JĘZYKOWE",
                      other: "INNE DOKUMENTY"
                    }
                  },
                  waitingList: "LISTA OCZEKUJĄCYCH",
                  reject: "ODRZUĆ",
                  delete: "USUŃ",
                  modalReject: {
                    body: "Po odrzuceniu wniosku student nie będzie mógł ubiegać się o udział w żadnym innym programie mobilności w bieżącym roku akademickim.",
                    reject: "Odrzucić",
                    title: {
                      part1: "Masz zamiar ",
                      part2: "odrzucenie",
                      part3: " wniosek studenta"
                    }
                  },
                  modalWaiting: {
                    body: "Po umieszczeniu wniosku studenta na liście oczekujących, nadal będziesz mógł nominować studenta później, jeśli zechcesz.",
                    waitingList: "Lista oczekujących",
                    title: {
                      part1: "Zamierzasz umieścić aplikację studenta w aplikacji ",
                      part2: "Lista oczekujących"
                    }
                  },
                  modalDelete: {
                    body: "Po usunięciu aplikacji ucznia nie będzie można jej ponownie zobaczyć.",
                    delete: "Usuń",
                    title: {
                      part1: "Masz zamiar ",
                      part2: "usunąć",
                      part3: " wniosek studenta"
                    }
                  },
                  modalNominate: {
                    body: "Po nominowaniu studenta na wyjazd do uczelni przyjmującej, uczelnia przyjmująca będzie mogła zaakceptować lub odrzucić nominację. W przypadku odrzucenia kandydatury przez uczelnię przyjmującą, będzie można nominować studenta na wyjazd do innej uczelni, jeśli taka będzie dostępna.",
                    nominate: "Nominuj",
                    title: {
                      part1: "Masz zamiar ",
                      part2: "nominować",
                      part3: " wniosek studenta"
                    }
                  }
                },
                inSingleView: {
                  loadingMessage: "Ładowanie...",
                  errorMessage: "Wystąpił błąd",
                  applicationFeedback: {
                    nominate: {
                      loading: "Wniosek o nominację...",
                      success: "Pomyślnie nominowana aplikacja",
                      failure: "Nominacja wniosku nie powiodła się"
                    },
                    reject: {
                      loading: "Odrzucenie wniosku...",
                      success: "Pomyślnie odrzucony wniosek",
                      failure: "Odrzucenie wniosku nie powiodło się"
                    }
                  },
                  applicationInfo: {
                    title: "INFORMACJE O APLIKACJI",
                    status: "STATUS",
                    academicYear: "ROK AKADEMICKI",
                    academicTerm: "OKRES AKADEMICKI",
                    submissionTime: "CZAS ZGŁOSZENIA",
                    downloadFiles: "Pobierz wszystkie pliki",
                  },
                  generalInfo: {
                    title: "Informacje ogólne",
                    studentInfo: {
                      title: "INFORMACJE DLA STUDENTÓW",
                      name: "NAZWA",
                      email: "EMAIL",
                      studyLevel: "POZIOM STUDIÓW",
                      studyField: "POLE BADAŃ"
                    },
                    homeHei: {
                      title: "HOME HEI",
                      name: "NAZWA",
                      orgUnit: "JEDNOSTKA ORGANIZACYJNA"
                    },
                    destinationheis: {
                      title: "CEL HEIs",
                      institutionFaculty: "INSTYTUCJA / WYDZIAŁ",
                      nominatedStudents: "NOMINOWANI STUDENCI"
                    }
                  },
                  documents: {
                    title: "Dokumenty",
                    viewPdf: "View Pdf",
                    files: { 
                      portfolio: "PORTFOLIO",
                      externalCertification: "CERTYFIKACJA ZEWNĘTRZNA",
                      referenceContact: "KONTAKT REFERENCYJNY",
                      motivationLetter: "LIST MOTYWACYJNY",
                      languageCert: "CERTYFIKATY JĘZYKOWE",
                      other: "INNE DOKUMENTY"
                    }
                  },
                  reject: "Odrzucić",
                  nominate: "Nominuj",
                  modalReject: {
                    body: "Po odrzuceniu wniosku student nie będzie mógł ubiegać się o udział w żadnym innym programie mobilności w danej uczelni w bieżącym roku akademickim. Wysyłający IRO może jednak nominować studenta do udziału w mobilności w innej uczelni, jeśli taka będzie dostępna.",
                    title: {
                      part1: "Masz zamiar ",
                      part2: "odrzucenie",
                      part3: " wniosek studenta"
                    }
                  },
                  modalNominate: {
                    body: "Nominując studenta, zapewniasz, że będzie on uprawniony do rozpoczęcia mobilności na uczelni, którą reprezentujesz.",
                    title: {
                      part1: "Masz zamiar ",
                      part2: "nominować",
                      part3: " wniosek studenta"
                    }
                  }
                }
              },
              shortTerm: {
                title: "Mobilność krótkoterminowa",
                tabOutgoing: "Wyjazdy krótkoterminowe",
                tabIncoming: "Nadchodzące wyjazdy krótkoterminowe",
              },
              shortTermMobilities: {
                loadingMessage: "Ładowanie...",
                errorMessage: "Wystąpił błąd",
                title: "Mobilność krótkoterminowa",
                tabOutgoing: "Zobacz pojedyncze krótkoterminowe wyjazdy",
                tabIncoming: "Zobacz pojedynczą nadchodzącą mobilność krótkoterminową",
                studentType: {
                  out: "Osoba odpowiedzialna w instytucji wysyłającej",
                  in: "Osoba odpowiedzialna w instytucji przyjmującej"
                },
                editTableFail: "Edycja tabeli kursów OLA nie powiodła się.",
                signFeedback: {
                  loading: "Podpisanie OLA...",
                  success: "Pomyślnie podpisane porozumienie o programie zajęć",
                  failure: "Błąd podczas podpisywania."
                },
                declineOlaFeedback: {
                  loading: "Spadek OLA...",
                  success: "Pomyślnie odrzucone porozumienie o programie zajęć",
                  failure: "Błąd przy odrzucaniu."
                }, 
                LearningAgreementInfo: {
                  title: "INFORMACJE O UMOWIE O NAUKĘ"
                },
                generalInfo: {
                  title: "Informacje ogólne",
                  studentPersonalData: {
                    title: "DANE OSOBOWE STUDENTÓW"
                  },
                  sendingInstInfo: {
                    title: "WYSYŁANIE INFORMACJI O INSTYTUCJI"
                  },
                  receivingInstInfo: {
                    title: "INFORMACJE O INSTYTUCJI PRZYJMUJĄCEJ"
                  }
                },
                courses: {
                  title: "Kursy",
                  table: "TABELA MOBILNOŚCI KRÓTKOTERMINOWEJ"
                },
                modalReject: {
                  declineButton: "Spadek",
                  body: {
                    part1: "Po odrzuceniu OLA student będzie mógł go edytować i wprowadzić proponowane zmiany. Prosimy o wpisanie komentarzy, które mają zostać przesłane do studenta w polu poniżej: ",
                    part2: "Powód odmowy",
                    part3: " Uwaga",
                  },
                  title: {
                    part1: "Masz zamiar ",
                    part2: "spadek",
                    part3: " OLA ucznia"
                  }
                },
                modalSign: {
                  signButton: "Podpisz OLA",
                  body: "Podpisując cyfrowo niniejszy dokument, student, uczelnia wysyłająca i uczelnia przyjmująca potwierdzają, że akceptują porozumienie o programie zajęć i że będą przestrzegać wszystkich ustaleń uzgodnionych przez wszystkie strony. Instytucje wysyłające i przyjmujące zobowiązują się do stosowania wszystkich zasad Karty Erasmusa dla szkolnictwa wyższego dotyczących mobilności na studia (lub zasad uzgodnionych w porozumieniu międzyinstytucjonalnym dla instytucji znajdujących się w krajach partnerskich). Uczelnia Beneficjenta i student powinni również zobowiązać się do przestrzegania postanowień umowy o dofinansowanie w ramach programu Erasmus+. Instytucja przyjmująca potwierdza, że wymienione komponenty edukacyjne są zgodne z jej katalogiem kursów i powinny być dostępne dla studenta. Uczelnia wysyłająca zobowiązuje się uznać wszystkie punkty lub równoważne jednostki uzyskane w uczelni przyjmującej za pomyślnie ukończone komponenty edukacyjne i zaliczyć je na poczet stopnia naukowego studenta. Student i Instytucja Przyjmująca będą informować Instytucję Wysyłającą o wszelkich problemach lub zmianach dotyczących programu studiów, osób odpowiedzialnych i/lub okresu studiów.",
                  title: {
                    part1: "Masz zamiar ",
                    part2: "znak",
                    part3: " OLA ucznia"
                  }
                },
                modalPrelimReject: {
                  confirmButton: "Potwierdzenie",
                  body: {
                    part1: "Zgodnie z oficjalnymi wytycznymi Komisji Europejskiej, jeśli automatyczne uznanie nie będzie miało miejsca, prosimy o jasne uzasadnienie i wskazanie, jaki inny rodzaj formalnego uznania zostanie zastosowany. ",
                    part2: "Wpisz powód odrzucenia"
                  }
                },
                modalFinalReject: {
                  body: {
                    part1: "Zgodnie z oficjalnymi wytycznymi Komisji Europejskiej, jeśli automatyczne uznanie nie będzie miało miejsca, prosimy o jasne uzasadnienie i wskazanie, jaki inny rodzaj formalnego uznania zostanie zastosowany. ",
                    part2: "Wpisz powód odrzucenia",
                  }
                },
              },
              requirements: {
                applicationSettings: {
                  feedback: {
                    success: "Ustawienia aplikacji zostały pomyślnie zaktualizowane.",
                    error1: "Wystąpił błąd podczas aktualizacji wymagań aplikacji.",
                    error2: "Wystąpił błąd podczas aktualizacji ustawień aplikacji: "
                  },
                  title: "Ustawienia aplikacji",
                  description: "Wybierz maksymalną liczbę uczelni przyjmujących dla studentów wyjeżdżających i ustal terminy składania wniosków",
                  numOfHeis: {
                    title: "Liczba uczelni ",
                    placeholder: "Wybierz liczbę uczelni",
                    error: "Wybierz kraj dla swojej instytucji",
                    message: "Maksymalna liczba priorytetów uczelni przyjmującej na wniosek studenta"
                  },
                  semesterApplications: {
                    titleAw: "Okres składania wniosków na semestr jesienno-zimowy",
                    titleSs: "Okres składania wniosków na semestr wiosenny/letni",
                    startDate: "Data rozpoczęcia ",
                    endDate: "Data zakończenia ",
                    error: "Podaj prawidłową wartość monitu."
                  },
                  cancel: "Anuluj",
                  saveSettings: "Zapisz ustawienia aplikacji"
                },
                applicationRequirements: {
                  feedback: {
                    success: "Kryteria aplikacji zostały pomyślnie zaktualizowane.",
                    error1: "Wystąpił błąd podczas aktualizacji wymagań aplikacji.",
                    error2: "Wystąpił błąd podczas aktualizacji wymagań aplikacji: "
                  },
                  title: "Kryteria (wychodzące)",
                  header: "Wymagane dokumenty",
                  descriptionMain: "Aktywuj przełączniki obok plików, które są wymagane dla studentów wyjeżdżających z Twojej uczelni i podaj odpowiednie adresy URL i opisy",
                  description: "Opis",
                  motivationLetter: "List motywacyjny",
                  transcriptOfRecords: "Transkrypcja zapisów",
                  externalCertification: "Certyfikacja zewnętrzna",
                  recommendationLetter: "List polecający",
                  languageLevel: {
                    title: "Poziom językowy",
                    languageRequired: {
                      title: "Wymagany język",
                      placeholder: "Wybierz język"
                    },
                    languageL: {
                      title: "Poziom językowy",
                      placeholder: "Wybierz poziom języka"
                    },
                    languageMoreInfo: "Język Więcej informacji",
                    delete: "Usuń język",
                    languageCertRequired: "Wymagany certyfikat językowy",
                    addLanguage: "Dodaj język"
                  },
                  otherDocument: "Inny dokument",
                  referenceContact: "Kontakt referencyjny",
                  portfolio: "Portfolio",
                  other: "Inne Kryteria",
                  addOther: "Dodaj inne Kryteria",
                  addDocument: "Dodaj inny dokument",
                  deleteRequirement: "Usuń wymaganie",
                  cancel: "Anuluj",
                  saveRequirements: "Zapisz Kryteria"
                }
              },
              iiaList: {
                title: "Porozumienia międzyinstytucjonalne",
                subTitle: "Lista umów",
                iiaFeedback: {
                  delete: {
                    loading: "Usuwanie IIA...",
                    success: "Pomyślnie usunięto IIA",
                    failure: "Usunięcie IIA nie powiodło się"
                  },
                  terminate: {
                    loading: "Zakończenie IIA...",
                    success: "Pomyślnie zakończone IIA",
                    failure: "Zakończenie IIA nie powiodło się"
                  }
                },
                modifyIIA: "Modyfikacja IIA",
                editIIA: "Edytuj IIA",
                deleteIIA: "Usuń IIA",
                modalDelete: {
                  body: "Po usunięciu IIA nie będzie można go ponownie zobaczyć.",
                  title: {
                    part1: "Masz zamiar ",
                    part2: "usunąć",
                    part3: " porozumienie międzyinstytucjonalne"
                  },
                  deleteButton: "Usuń"
                },
                modalTerminate: {
                  body: {
                    part1: "Działanie, które zamierzasz podjąć, skutkuje ",
                    part2: "zakończenie",
                    part3: " w efekcie IIA i jest nieodwracalny. Czy nadal chcesz kontynuować?"
                  },
                  title: {
                    part1: "Masz zamiar ",
                    part2: "zakończyć",
                    part3: " porozumienie międzyinstytucjonalne"
                  },
                  terminateButton: "Zakończenie"
                }
              },
              iiaSingleView: {
                loadingMessage: "Ładowanie...",
                errorMessage: "Wystąpił błąd",
                iiaFeedback: {
                  approve: {
                    loading: "Zapisywanie zmian...",
                    success: "Pomyślne zatwierdzenie IIA",
                    failure: "Wystąpił błąd podczas zatwierdzania IIA: ",
                    error: "Błąd podczas zapisywania zmian."
                  },
                  revert: {
                    loading: "Zapisywanie zmian...",
                    success: "Pomyślne przywrócenie IIA",
                    failure: "Wystąpił błąd podczas przywracania IIA: ",
                    error: "Błąd podczas zapisywania zmian."
                  },
                  terminate: {
                    loading: "Zakończenie IIA...",
                    success: "Pomyślnie zakończone IIA",
                    failure: "Zakończenie IIA nie powiodło się"
                  },
                  notifyPartner: {
                    loading: "Powiadomienie partnera...",
                    success: "Skutecznie powiadomiony partner",
                    failure: "Powiadomienie partnera nie powiodło się"
                  },
                },
                iiaInformation: {
                  title: "INFORMACJE IIA",
                  yourId: "TWÓJ ID",
                  partnerId: "ID PARTNERA",
                  partner: "PARTNER",
                  lastEdited: "OSTATNIO EDYTOWANE",
                  status: "STATUS",
                  terminatedByYou: "Rozwiązanie umowy przez użytkownika",
                  terminatedByPartner: "Wypowiedziane przez partnera",
                  terminationApprovedByYou: "Wypowiedzenie zatwierdzone przez użytkownika",
                  terminationApprovedByPartner: "Wypowiedzenie zatwierdzone przez partnera",
                  submittedByYou: "Przesłane przez Ciebie",
                  submittedByPartner: "Przesłane przez Partnera",
                  approvedByYou: "Zatwierdzone przez użytkownika",
                  approvedByPartner: "Zatwierdzony przez Partnera",
                  terminated: "Zakończone",
                  draft: "Wersja robocza",
                  terminatedByAll: "Wypowiedzenie zatwierdzone przez wszystkich",
                  approvedByAll: "Zatwierdzone przez wszystkich",
                  invalid: {
                    invalid1: "Niniejsze porozumienie międzyinstytucjonalne zawiera nieprawidłowe kody ISCED F lub brakuje w nim wymagań językowych. Zatwierdzenia są nadal ważne. Jeśli chcesz zakończyć to porozumienie międzyinstytucjonalne, musisz je zmodyfikować i wzajemnie zatwierdzić.",
                    invalid2: "Niniejsze porozumienie międzyinstytucjonalne zawiera nieprawidłowe kody ISCED F lub brakuje w nim wymagań językowych. Prosimy o edycję/modyfikację IIA."
                  },
                  thirdParty: "Należy pamiętać, że dane uczelni partnerskiej są zarządzane przez zewnętrznego dostawcę. Oznacza to, że ten konkretny IIA jest reprezentowany przez inny identyfikator w ich systemie.",
                  exportPdf: "Eksport Pdf"
                },
                generalInfo: {
                  title: "Informacje ogólne",
                  institutionData: "DANE INSTYTUCJI",
                  name: "Nazwa:",
                  country: "Kraj:",
                  schacCode: "Kod SCHAC:",
                  erasmusCode: "Kod Erasmusa:",
                  orgUnit: "Org. Jednostka:",
                  approvingPerson: "Osoba zatwierdzająca:",
                  contactPersons: "Osoby kontaktowe:",
                  signDate: "Data podpisania:",
                  partnerInstitutionData: "DANE INSTYTUCJI PARTNERSKIEJ",
                },
                yourData: {
                  title: "Twoje dane",
                  calendar: {
                    title: "KALENDARZ",
                    studentNominations: "Nominacje studentów muszą dotrzeć do instytucji przyjmującej do dnia: ",
                    autumnTerm: "Okres jesienny: ",
                    springTerm: "Okres wiosenny: ",
                    applicationsForNominated: "Zgłoszenia nominowanych studentów muszą dotrzeć do instytucji przyjmującej do dnia: ",
                    applicationProcedure: "Procedura składania wniosków dla nominowanych studentów: ",
                    email: "Kontakt Email: ",
                    phone: "Telefon kontaktowy: ",
                    websiteForInfo: "Strona internetowa z informacjami: "
                  },
                  additionalRequirements: {
                    title: "DODATKOWE KRYTERIA",
                    requirements: "WYMAGANIE",
                    details: "SZCZEGÓŁY",
                    ectsNumber: "LICZBA PUNKTÓW",
                    subjectFieldISCED: "POLE TEMATU (ISCED)",
                    studyLevelEQF: "POZIOM STUDIÓW (EQF)",
                    website: "STRONA",
                    message: "Instytucja prześle swoją decyzję w ciągu 4 tygodni i nie później niż w ciągu 5 tygodni."
                  },
                  inclusionAndAccessibility: {
                    title: "WŁĄCZENIE I DOSTĘPNOŚĆ",
                    descriptionMain: {
                      part1: "Instytucja zapewni wsparcie przyjeżdżającym mobilnym uczestnikom ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                      part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    infrastructure: "Infrastructure",
                    name: "NAME",
                    description: "DESCRIPTION",
                    contact: "CONTACT",
                    services: "Services"
                  },
                  housing: {
                    title: "HOUSING",
                    description: {
                      part1: "The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.",
                      part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    contact: "Contact"
                  },
                  visa: {
                    title: "VISA",
                    description: {
                      part1: "The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                      part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    contact: "Contact"
                  },
                  insurance: {
                    title: "INSURANCE",
                    description: {
                      part1: "The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.",
                      part2: " The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.",
                      part3: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    contact: "Kontakt"
                  },
                  additionalInfo: {
                    title: "DODATKOWE INFORMACJE",
                    description: {
                      part1: "Instytucja zapewni wsparcie przyjeżdżającym mobilnym uczestnikom ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                      part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    contact: "KONTAKT"
                  },
                  message: {
                    part1: "Transcript of Records zostanie wydany przez instytucję nie później niż 6 tygodni po zakończeniu okresu oceny.",
                    part2: "[Zgodnie z wytycznymi Karty Erasmusa dla szkolnictwa wyższego nie powinien on zwykle przekraczać pięciu tygodni]",
                    part3: "*Po utworzeniu profilu instytucji szkolnictwa wyższego i dodaniu odpowiednich informacji ogólnych, instytucja szkolnictwa wyższego może przystąpić do generowania umów międzyinstytucjonalnych z wybranymi partnerami."
                  }
                },
                partnerData: {
                  title: "Dane partnera",
                  calendar: {
                    title: "KALENDARZ",
                    studentNominations: "Nominacje studentów muszą dotrzeć do instytucji przyjmującej do dnia: ",
                    autumnTerm: "Okres jesienny: ",
                    springTerm: "Okres wiosenny: ",
                    applicationsForNominated: "Zgłoszenia nominowanych studentów muszą dotrzeć do instytucji przyjmującej do dnia: ",
                    applicationProcedure: "Procedura składania wniosków dla nominowanych studentów: ",
                    email: "Kontakt Email: ",
                    phone: "Telefon kontaktowy: ",
                    websiteForInfo: "Strona internetowa z informacjami: "
                  },
                  additionalRequirements: {
                    title: "DODATKOWE KRYTERIA",
                    requirements: "WYMAGANIE",
                    details: "SZCZEGÓŁY",
                    ectsNumber: "NUMER ECTS",
                    subjectFieldISCED: "POLE TEMATU (ISCED)",
                    studyLevelEQF: "POZIOM STUDIÓW (EQF)",
                    website: "STRONA",
                    message: "Instytucja prześle swoją decyzję w ciągu 4 tygodni i nie później niż w ciągu 5 tygodni."
                  },
                  inclusionAndAccessibility: {
                    title: "WŁĄCZENIE I DOSTĘPNOŚĆ",
                    descriptionMain: {
                      part1: "Instytucja zapewni wsparcie przyjeżdżającym mobilnym uczestnikom ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                      part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    infrastructure: "Infrastruktura",
                    name: "NAZWA",
                    description: "OPIS",
                    contact: "KONTAKT",
                    services: "Usługi"
                  },
                  housing: {
                    title: "MIESZKANIE",
                    description: {
                      part1: "Instytucja pomoże przyjeżdżającym uczestnikom mobilnym w znalezieniu zakwaterowania zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                      part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    contact: "Kontakt"
                  },
                  visa: {
                    title: "VISA",
                    description: {
                      part1: "W razie potrzeby instytucja zapewni pomoc w uzyskaniu wiz dla mobilnych uczestników przyjeżdżających i wyjeżdżających, zgodnie z wymogami Karty Erasmusa dla szkolnictwa wyższego.",
                      part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    contact: "Kontakt"
                  },
                  insurance: {
                    title: "UBEZPIECZENIE",
                    description: {
                      part1: "Instytucja zapewni pomoc w uzyskaniu ubezpieczenia dla mobilnych uczestników przyjeżdżających i wyjeżdżających, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                      part2: " Instytucja przyjmująca poinformuje uczestników mobilnych o przypadkach, w których ochrona ubezpieczeniowa nie jest zapewniana automatycznie.",
                      part3: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    contact: "Kontakt"
                  },
                  additionalInfo: {
                    title: "DODATKOWE INFORMACJE",
                    description: {
                      part1: "Instytucja zapewni wsparcie przyjeżdżającym mobilnym uczestnikom ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                      part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                    },
                    contact: "KONTAKT"
                  },
                  message: {
                    part1: "Transcript of Records zostanie wydany przez instytucję nie później niż 6 tygodni po zakończeniu okresu oceny.",
                    part2: "[Zgodnie z wytycznymi Karty Erasmusa dla szkolnictwa wyższego nie powinien on zwykle przekraczać pięciu tygodni.]",
                    part3: "*Po utworzeniu profilu instytucji szkolnictwa wyższego i dodaniu odpowiednich informacji ogólnych, instytucja szkolnictwa wyższego może przystąpić do generowania umów międzyinstytucjonalnych z wybranymi partnerami."
                  }
                },
                conditions: {
                  title: "Warunki",
                  header: {
                    part1: "Nauczyciel personelu:",
                    part2: " / Pola badań: ",
                    part3: "Szkolenie personelu:",
                    part4: "Badanie studentów:",
                    part5: "Praktyki studenckie:"
                  },
                  heis: {
                    sender: "NADAWCA",
                    receiver: "ODBIORNIK"
                  },
                  conditionDetails: {
                    title: "Szczegóły dotyczące stanu ",
                    mobilityType: "TYP MOBILNOŚCI",
                    duration: "CZAS TRWANIA",
                    academicYears: "LATA AKADEMICKIE",
                    spots: "SPOTS",
                    eqfLevel: "Poziom EQF",
                    staffTeachers: "Pracownicy Nauczyciele",
                    staffTrainings: "Szkolenia personelu",
                    otherInfo: "INNE INFORMACJE",
                  },
                  languageRequirements: {
                    title: "Kryteria językowe ",
                    language: "JĘZYK",
                    studyField: "POLE BADAŃ",
                    clarification: "UŚCIŚLENIE"
                  },
                  studyFields: {
                    title: "Pola badań ",
                    studyField: "POLE BADAŃ",
                    clarification: "UŚCIŚLENIE"
                  },
                  contacts: {
                    title: "Kontakty ",
                    sendingContacts: "WYSYŁANIE KONTAKTÓW",
                    receivingContacts: "ODBIERANIE KONTAKTÓW"
                  }
                },
                notifyPartner: {
                  text: "Powiadomić partnera",
                  part1: "Wkrótce będzie można kliknąć przycisk i ponownie powiadomić partnera.",
                  part2: "Powiadomienie partnera nie jest możliwe, ponieważ brakuje wymagań językowych lub w warunkach współpracy IIA występują kody ISCED zawierające mniej niż 4 cyfry.",
                  part3: "Powiadom partnera o aktualnym stanie IIA."
                },
                terminate: {
                  invalidISCEDormissingLang: "To porozumienie międzyinstytucjonalne nie może zostać rozwiązane, ponieważ zawiera nieprawidłowe kody ISCED F lub brakuje w nim wymagań językowych. Prosimy o zmodyfikowanie i wzajemne zatwierdzenie porozumienia międzyinstytucjonalnego w celu jego zakończenia.",
                  invalidISCEDormissingLang2: "To porozumienie międzyinstytucjonalne nie może zostać zatwierdzone, ponieważ zawiera nieprawidłowe kody ISCED F lub brakuje w nim wymagań językowych. Prosimy o edycję/modyfikację IIA.",
                  approvedByAll: "Porozumienie międzyinstytucjonalne można zakończyć tylko wtedy, gdy zostało zatwierdzone przez wszystkich.",
                  iiaTerminationApproved: "Rozwiązanie IIA zostało już zatwierdzone przez wszystkich.",
                  terminateButton: "zakończyć iia"
                },
                revert: {
                  noApproval: "Nie można przywrócić IIA, ponieważ nie ma jeszcze zatwierdzenia",
                  approvedByAll: "Nie można przywrócić IIA, ponieważ zostało ono już zatwierdzone przez wszystkich",
                  revertButton: "revert iia"
                },
                edit: {
                  cannotEditWhileTerminating: "Nie można edytować IIA podczas procesu zakończenia.",
                  editButton: "edytuj iia",
                  modifyButton: "modyfikować iia"
                },
                approve: {
                  approveButton: "Zatwierdzić",
                  confirm: {
                    part1: "Masz zamiar ",
                    part2: "zatwierdzać",
                    part3Terminate: " rozwiązanie porozumienia międzyinstytucjonalnego.",
                    part3Approve: " porozumienie międzyinstytucjonalne"
                  }
                },
                modalTerminate: {
                  body: "Po rozwiązaniu umowy IIA partner zostanie powiadomiony o rozwiązaniu umowy. Ostatnie zatwierdzenie pozostaje ważne i możesz przywrócić je do poprzedniego stanu w dowolnym momencie, dopóki oboje nie zatwierdzicie zakończenia IIA.",
                  title: {
                    part1: "Masz zamiar ",
                    part2: "zakończyć",
                    part3: " porozumienie międzyinstytucjonalne"
                  },
                  terminateButton: "Zakończenie"
                },
                modalRevert: {
                  title: "Zamierzasz przywrócić umowę międzyinstytucjonalną do stanu zatwierdzonego wcześniej przez wszystkie stany.",
                  revertButton: "Revert"
                },
                modalModify: {
                  body: "Po zmodyfikowaniu porozumienia międzyinstytucjonalnego partner zostanie powiadomiony o wprowadzonych zmianach. Ostatnie zatwierdzenie pozostaje ważne i można je przywrócić do poprzedniego stanu w dowolnym momencie, dopóki oboje nie zatwierdzicie ponownie porozumienia międzyinstytucjonalnego.",
                  title: "Zamierzasz zmodyfikować i wprowadzić zmiany do umowy międzyinstytucjonalnej.",
                  modifyButton: "Modyfikacja"
                },
                iiaApprovedByAll: "IIA jest już zatwierdzone przez wszystkich lub partner nie udostępnił swojego lokalnego identyfikatora IIA.",
                approveTermination: "zatwierdzenie rozwiązania umowy",
                approveIIA: "zatwierdzić iia"
              },
              iiasEdit: {
                title: "Porozumienia międzyinstytucjonalne",
                subTitle: "Edytuj IIA",
                createNewSubTitle: "Utwórz nowe IIA",
                createTab: "Utwórz IIA",
                tab: "Edytuj IIA",
                steps: {
                  yourDetails: "TWOJE SZCZEGÓŁY",
                  partnerDetails: "DANE PARTNERA",
                  conditions: "WARUNKI",
                  overviewAndSubmit: "PRZEGLĄD I PRZESYŁANIE"
                },
                alerts: {
                  part1: "Nieprawidłowe kody ISCED F są wykrywane w warunkach współpracy. Popraw kody ISCED F, które zawierają mniej niż 4 cyfry, wybierając odpowiedni kod z rozwijanej listy kodów ISCED F.",
                  part2: "Aby kontynuować, należy wypełnić co najmniej jeden język wykładowy w warunkach współpracy Student Studies",
                  part3: "Aby kontynuować, musisz wypełnić co najmniej jeden język nauczania w warunkach współpracy z nauczycielami personelu",
                  part4: "Wybrana instytucja szkolnictwa wyższego nie jest jeszcze gotowa do wymiany umów międzyinstytucjonalnych za pośrednictwem sieci Erasmus Without Paper.",
                  missingName: "Aby kontynuować, należy podać nazwę kontaktu",
                  invalidEmail: "Aby kontynuować, należy podać prawidłowy adres e-mail",
                },
                iiaFeedback: {
                  saving: {
                    loading: "Zapisywanie zmian...",
                    success: {
                      savedDraft: "Pomyślnie zapisany jako wersja robocza IIA",
                      submit: "Pomyślne złożenie IIA",
                      updateDraft: "Pomyślnie zaktualizowany projekt IIA",
                      update: "Pomyślna aktualizacja IIA"
                    },
                    failure: "Błąd podczas zapisywania zmian."
                  },
                },
                errors: {
                  part1: "Aby kontynuować, należy wybrać jednego partnera SCHAC",
                  part2: "Aby kontynuować, musisz dodać co najmniej jeden warunek współpracy"
                },
                next: "Następny",
                back: "Powrót",
                saveAsDraft: "ZAPISZ JAKO PROJEKT",
                submit: "Prześlij",
                firstStep: {
                  header: "TWOJE SZCZEGÓŁY",
                  institutionData: {
                    title: "Dane instytucji",
                    name: "Nazwa: ",
                    country: "Kraj: ",
                    schacCode: "Kod SCHAC: ",
                    erasmusCode: "Kod Erasmusa: ",
                    orgUnit: "Jednostka organizacyjna",
                    placeholder: "Wybierz"
                  },
                  contactPerson: {
                    title: "Osoby kontaktowe",
                    name: {
                      title: "Nazwa ",
                      placeholder: "Wprowadź nazwę.."
                    },
                    email: {
                      title: "Email",
                      placeholder: "Wprowadź adres e-mail..",
                      error: "Podaj prawidłowy adres e-mail."
                    },
                    phone: {
                      title: "Telefon",
                      placeholder: "Wprowadź telefon..",
                    },
                    deleteButton: "Usuń kontakt",
                    addContactPerson: "dodać osobę kontaktową"
                  }
                },
                secondStep: {
                  header: "DANE PARTNERA",
                  institutionData: {
                    title: "Dane instytucji partnerskiej",
                    institution: {
                      title: "Instytucja ",
                      loading: "Ładowanie...",
                      choose: "Wybierz instytucję...",
                      error: "Wybierz swoją instytucję."
                    },
                    schac: {
                      title: "Kod SCHAC",
                      loading: "Ładowanie...",
                      choose: "Wybierz kod SCHAC...",
                      error: "Wybierz swój kod SCHAC."
                    },
                    country: "Kraj: ",
                    schacCode: "Kod SCHAC: ",
                    erasmusCode: "Kod Erasmusa: ",
                    orgUnit: "Jednostka organizacyjna",
                    placeholder: "Wybierz"
                  },
                  erasmusCode: {
                    title: "Kod Erasmusa ",
                    loading: "Ładowanie...",
                    choose: "Wybierz kod Erasmus...",
                    error: "Wybierz swój kod Erasmusa."
                  },
                  orgUnit: {
                    title: "Jednostka organizacyjna",
                    loading: "Ładowanie...",
                    choose: "Wybierz OUnit...",
                    error: "Wybierz jednostkę OUnit."
                  },
                  contactPersons: {
                    title: "Osoby kontaktowe",
                    name: {
                      title: "Nazwa ",
                      placeholder: "Wprowadź nazwę.."
                    },
                    email: {
                      title: "Email",
                      placeholder: "Wprowadź adres e-mail..",
                      error: "Podaj prawidłowy adres e-mail."
                    },
                    phone: {
                      title: "Telefon",
                      placeholder: "Wprowadź telefon..",
                    },
                    deleteButton: "Usuń kontakt",
                    addContactPerson: "dodać osobę kontaktową"
                  }
                },
                thirdStep: {
                  mobilityTypes: {
                    studentStudies: "Studia dla studentów",
                    studentTraineeships: "Praktyki studenckie",
                    staffTeachers: "Pracownicy Nauczyciele",
                    staffTrainings: "Szkolenia personelu"
                  },
                  blendedMobility: {
                    yes: "Tak",
                    no: "Nie"
                  },
                  eqfLevels: {
                    lvl5: "Poziom 5 EQF - stopień naukowy",
                    lvl6: "Poziom 6 EQF - stopień licencjata",
                    lvl7: "Poziom 7 EQF - tytuł magistra",
                    lvl8: "Poziom 8 EQF - stopień doktora",
                  },
                  typeOfCoopCondition: {
                    fillAllFields: "Wypełnij wszystkie wymagane pola",
                    validEmailSending: "Podaj prawidłowy adres e-mail podczas wysyłania kontaktów",
                    validEmailReceiving: "Prosimy o podanie prawidłowego adresu e-mail podczas odbierania kontaktów",
                    invalidISCED: "Nieprawidłowe kody ISCED F są wykrywane w warunkach współpracy. Popraw kody ISCED F, które zawierają mniej niż 4 cyfry, wybierając odpowiedni kod z rozwijanej listy kodów ISCED F.",
                  },
                  cooperationConditions: {
                    header: "WARUNKI WSPÓŁPRACY",
                    staffTeacher: "Nauczyciel personelu",
                    studyFields: "Pola badań",
                    staffTraining: "Szkolenie personelu",
                    studentStudy: "Badanie studentów",
                    studentTraineeship: "Praktyki studenckie"
                  },
                  daysPerYear: " dni w roku",
                  monthsPerYear: " miesięcy w roku",
                  heis: {
                    sender: "NADAWCA",
                    receiver: "ODBIORNIK"
                  },
                  conditionDetails: {
                    header: "Szczegóły dotyczące stanu",
                    mobilityType: "TYP MOBILNOŚCI",
                    duration: "CZAS TRWANIA",
                    academicYears: "LATA AKADEMICKIE",
                    eqfLevel: "Poziom EQF",
                    spots: "SPOTS"
                  },
                  otherInfo: "INNE INFORMACJE",
                  languageRequirements: {
                    header: "Kryteria językowe",
                    language: "JĘZYK",
                    studyField: "POLE BADANIA",
                    clarification: "UŚCIŚLENIE"
                  },
                  studyFields: {
                    header: "Pola badań",
                    studyField: "POLE BADANIA",
                    clarification: "UŚCIŚLENIE"
                  },
                  contacts: {
                    header: "Kontakty",
                    sendingContacts: "WYSYŁANIE KONTAKTÓW",
                    receivingContacts: "ODBIERANIE KONTAKTÓW"
                  },
                  editConditionButton: "stan edycji",
                  deleteCoopConditionButton: "Usuń warunek współpracy",
                  addNewConditionButton: "dodać nowy warunek",
                  modal: {
                    title: "Liczba mobilności na rok akademicki",
                    subTitle: "Partnerzy zobowiązują się do zmiany poniższej tabeli w przypadku zmian w danych dotyczących mobilności nie później niż do końca września poprzedniego roku akademickiego. ",
                    schacCode: {
                      sender: {
                        title: "Kod SCHAC nadawcy",
                        placeholder: "Wybierz SCHAC..."
                      },
                      receiver: {
                        title: "Odbiornik Kod SCHAC",
                        placeholder: "Wybierz SCHAC..."
                      }
                    },
                    orgUnit: {
                      sender: {
                        title: "Jednostka organizacyjna nadawcy",
                        placeholder: "Wybierz jednostkę organizacyjną..."
                      },
                      receiver: {
                        title: "Jednostka organizacyjna odbiorcy",
                        placeholder: "Wybierz jednostkę organizacyjną..."
                      }
                    },
                    mobilitiesPerYear: {
                      title: "Mobilność w ciągu roku",
                      description: "Maksymalna liczba osób wysyłanych w każdym roku akademickim"
                    },
                    academicYear: {
                      start: {
                        title: "Rozpoczęcie roku akademickiego",
                        placeholder: "Wybierz Rozpocznij rok akademicki...",
                        error: "Wybierz swój kod SCHAC."
                      },
                      end: {
                        title: "Koniec roku akademickiego",
                        placeholder: "Wybierz Koniec roku akademickiego...",
                        error: "Wybierz swój kod SCHAC."
                      }
                    },
                    subjectArea: {
                      header: "Obszar tematyczny",
                      fCode: {
                        title: "Kod ISCED F",
                        placeholder: "Wybierz kod ISCED"
                      },
                      clarification: {
                        title: "Wyjaśnienie ISCED",
                        placeholder: "Wprowadź tekst.."
                      },
                      deleteButton: "Usuń obszar tematyczny",
                      addSubjectArea: "Dodaj obszar tematyczny"
                    },
                    mobilityType: {
                      header: "Typ mobilności",
                      description: "Partnerzy zobowiązują się do zmiany poniższej tabeli w przypadku zmian w danych dotyczących mobilności nie później niż do końca września poprzedniego roku akademickiego.",
                      duration: {
                        title: "Czas trwania",
                        totalMonths: " (łączna liczba miesięcy w roku)",
                        totalDays: " (całkowita liczba dni w roku)",
                        fill: {
                          allStudents: "Wypełnij sumę miesięcy dla wszystkich studentów (np. 10 studentów korzystających z 6-miesięcznej mobilności daje okres 60 miesięcy)",
                          staff: "Wypełnij sumę miesięcy dla personelu (np. 4 pracowników na 20-dniowej mobilności daje czas trwania 80 dni)"
                        }
                      },
                      studyCycle: {
                        header: "Cykl badania",
                        placeholder: "Wybierz jedną lub więcej opcji..."
                      },
                      blendedMobility: {
                        header: "Opcja mobilności mieszanej",
                        confirmation: "*Wybierając „Tak” w opcji mobilności mieszanej, partnerzy potwierdzają, że są gotowi do wymiany studentów, którzy chcą przeprowadzić swoją mobilność w formacie mieszanym, czyli połączenie krótkoterminowej mobilności fizycznej z komponentem wirtualnym. "
                      }
                    },
                    languageSkills: {
                      header: "Zalecane umiejętności językowe",
                      description: "Instytucja wysyłająca, po uzgodnieniu z instytucją przyjmującą, jest odpowiedzialna za zapewnienie wsparcia nominowanym kandydatom, tak aby mogli oni posiadać zalecane umiejętności językowe na początku okresu studiów lub nauczania (Mobilność Studentów - minimalny zalecany poziom: B1):",
                      languageOfInstruction: {
                        title: "Język instrukcji",
                        placeholder: "Wybierz język"
                      },
                      level: {
                        title: "Język wykładowy Poziom",
                        placeholder: "Wybierz poziom języka"
                      },
                      fCode: {
                        title: "Kod ISCED F",
                        placeholder: "Wybierz kod ISCED"
                      },
                      clarification: {
                        title: "Wyjaśnienie ISCED",
                        placeholder: "Wprowadź tekst.."
                      },
                      deleteButton: "Usuń język",
                      addLanguage: "dodaj JĘZYK"
                    },
                    contact: {
                      sending: {
                        header: "Wysyłające osoby kontaktowe",
                        description: "Prosimy o podanie danych wysyłających osób kontaktowych",
                        addContact: "dodać osobę kontaktową wysyłającą"
                      },
                      receiving: {
                        header: "Odbierające osoby kontaktowe",
                        description: "Prosimy o podanie danych przyjmujących osób kontaktowych",
                        addContact: "dodać osobę kontaktową odbierającą"
                      },
                      name: {
                        title: "Nazwa",
                        placeholder: "Wprowadź nazwę.."
                      },
                      email: {
                        title: "Email",
                        placeholder: "Wprowadź adres e-mail..",
                        error: "Podaj prawidłowy adres e-mail."
                      },
                      phone: {
                        title: "Telefon",
                        placeholder: "Wprowadź telefon.."
                      },
                      deleteContact: "Usuń kontakt"
                    },
                    otherInfo: {
                      header: "Inne informacje",
                      description: "Prosimy o podanie wszelkich innych informacji dotyczących warunków Umowy (maksymalnie 5000 znaków)",
                      placeholder: "Wprowadź inne informacje.."
                    },
                    addCondition: "dodać ten warunek",
                    addNewCondition: "Dodaj nowy warunek współpracy",
                    fillNew: "Wypełnij następujące pola, aby dodać nowy warunek współpracy ",
                    saveChanges: "zapisywanie zmian warunków",
                    editCondition: "Edytuj warunek współpracy",
                    fillEdit: "Wypełnij następujące pola, aby edytować warunek współpracy"
                  }
                },
                forthStep: {
                  overview: {
                    header: "PRZEGLĄD I PRZESYŁANIE",
                    staticInfo: {
                      header: "Informacje statyczne mające Wnioski do wszystkich umów międzyinstytucjonalnych",
                      body: "Instytucje wyrażają zgodę na współpracę w zakresie wymiany studentów i/lub pracowników w kontekście programu Erasmus. Zobowiązują się do przestrzegania wymogów jakościowych Karty Erasmusa dla szkolnictwa wyższego we wszystkich aspektach związanych z organizacją i zarządzaniem mobilnością, w szczególności do automatycznego uznawania punktów przyznanych studentom przez instytucję partnerską zgodnie z porozumieniem o programie zajęć i potwierdzonych w wykazie zaliczeń lub zgodnie z efektami uczenia się modułów ukończonych za granicą, zgodnie z opisem w katalogu kursów, zgodnie z europejskim systemem transferu i akumulacji punktów. Instytucje zgadzają się na wymianę danych związanych z mobilnością zgodnie ze standardami technicznymi inicjatywy europejskiej legitymacji studenckiej.",
                    },
                    gradingSystem: {
                      header: "Systemy oceniania instytucji",
                      body: "Zaleca się, aby instytucje przyjmujące podawały link do statystycznego rozkładu ocen lub udostępniały informacje za pośrednictwem systemu EGRACONS zgodnie z opisami zawartymi w przewodniku dla użytkowników systemu ECTS. Informacje te ułatwią interpretację każdej oceny przyznanej studentom i ułatwią transfer punktów przez instytucję wysyłającą."
                    }
                  },
                  generalInfo: {
                    header: "Informacje ogólne",
                    institutionData: {
                      header: "DANE INSTYTUCJI",
                      headerPartner: "DANE INSTYTUCJI PARTNERSKIEJ",
                      name: "Nazwa",
                      country: "Kraj",
                      schacCode: "Kod SCHAC",
                      erasmusCode: "Kod Erasmusa",
                      orgUnit: "Org. Jednostka",
                      approvingPerson: "Osoba zatwierdzająca",
                      contactPerson: "Osoby kontaktowe"
                    }
                  },
                  factSheet: {
                    yourFactSheet: {
                      header: "Dane arkusza informacyjnego",
                      calendar: {
                        title: "KALENDARZ",
                        studentNominations: "Nominacje studentów muszą dotrzeć do instytucji przyjmującej do dnia: ",
                        autumnTerm: "Okres jesienny: ",
                        springTerm: "Okres wiosenny: ",
                        applicationsForNominated: "Zgłoszenia nominowanych studentów muszą dotrzeć do instytucji przyjmującej do dnia: ",
                        applicationProcedure: "Procedura składania wniosków dla nominowanych studentów: ",
                        email: "Kontakt Email: ",
                        phone: "Telefon kontaktowy: ",
                        websiteForInfo: "Strona internetowa z informacjami: "
                      },
                      additionalRequirements: {
                        title: "DODATKOWE Kryteria",
                        requirements: "WYMAGANIE",
                        details: "SZCZEGÓŁY",
                        ectsNumber: "LICZBA PUNKTÓW ECTS",
                        subjectFieldISCED: "POLE PRZEDMIOTU (ISCED)",
                        studyLevelEQF: "POZIOM STUDIÓW (EQF)",
                        website: "STRONA",
                        message: "Instytucja prześle swoją decyzję w ciągu 4 tygodni i nie później niż w ciągu 5 tygodni."
                      },
                      inclusionAndAccessibility: {
                        title: "WŁĄCZENIE I DOSTĘPNOŚĆ",
                        descriptionMain: {
                          part1: "Instytucja zapewni wsparcie przyjeżdżającym mobilnym uczestnikom ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                          part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        infrastructure: "Infrastruktura",
                        name: "NAZWA",
                        description: "OPIS",
                        contact: "KONTAKT",
                        services: "Usługi"
                      },
                      housing: {
                        title: "MIESZKANIE",
                        description: {
                          part1: "Instytucja pomoże przyjeżdżającym uczestnikom mobilnym w znalezieniu zakwaterowania zgodnie z wymogami Karty Erasmusa dla szkolnictwa wyższego.",
                          part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        contact: "Kontakt"
                      },
                      visa: {
                        title: "VISA",
                        description: {
                          part1: "W razie potrzeby instytucja zapewni pomoc w uzyskaniu wiz dla mobilnych uczestników przyjeżdżających i wyjeżdżających, zgodnie z wymogami Karty Erasmusa dla szkolnictwa wyższego.",
                          part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        contact: "Kontakt"
                      },
                      insurance: {
                        title: "UBEZPIECZENIE",
                        description: {
                          part1: "Instytucja zapewni pomoc w uzyskaniu ubezpieczenia dla mobilnych uczestników przyjeżdżających i wyjeżdżających, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                          part2: " Instytucja przyjmująca poinformuje uczestników mobilnych o przypadkach, w których ochrona ubezpieczeniowa nie jest zapewniana automatycznie.",
                          part3: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        contact: "Kontakt"
                      },
                      additionalInfo: {
                        title: "DODATKOWE INFORMACJE",
                        description: {
                          part1: "Instytucja zapewni wsparcie przyjeżdżającym mobilnym uczestnikom ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                          part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        contact: "KONTAKT"
                      }
                    },
                    partnerFactSheet: {
                      header: "Dane z arkusza informacyjnego partnera",
                      calendar: {
                        title: "KALENDARZ",
                        studentNominations: "Nominacje studentów muszą dotrzeć do instytucji przyjmującej do dnia: ",
                        autumnTerm: "Okres jesienny: ",
                        springTerm: "Okres wiosenny: ",
                        applicationsForNominated: "Zgłoszenia nominowanych studentów muszą dotrzeć do instytucji przyjmującej do dnia: ",
                        applicationProcedure: "Procedura składania wniosków dla nominowanych studentów: ",
                        email: "Kontakt Email: ",
                        phone: "Telefon kontaktowy: ",
                        websiteForInfo: "Strona internetowa z informacjami: "
                      },
                      additionalRequirements: {
                        title: "DODATKOWE KRYTERIA",
                        requirements: "WYMAGANIE",
                        details: "SZCZEGÓŁY",
                        ectsNumber: "LICZBA PUNKTÓW ECTS",
                        subjectFieldISCED: "POLE PRZEDMIOTU (ISCED)",
                        studyLevelEQF: "POZIOM STUDIÓW (EQF)",
                        website: "STRONA",
                        message: "Instytucja prześle swoją decyzję w ciągu 4 tygodni i nie później niż w ciągu 5 tygodni."
                      },
                      inclusionAndAccessibility: {
                        title: "WŁĄCZENIE I DOSTĘPNOŚĆ",
                        descriptionMain: {
                          part1: "Instytucja zapewni wsparcie przyjeżdżającym mobilnym uczestnikom ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                          part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        infrastructure: "Infrastruktura",
                        name: "NAZWA",
                        description: "OPIS",
                        contact: "KONTAKT",
                        services: "Usługi"
                      },
                      housing: {
                        title: "MIESZKANIE",
                        description: {
                          part1: "Instytucja pomoże przyjeżdżającym uczestnikom mobilnym w znalezieniu zakwaterowania zgodnie z wymogami Karty Erasmusa dla szkolnictwa wyższego.",
                          part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        contact: "Kontakt"
                      },
                      visa: {
                        title: "VISA",
                        description: {
                          part1: "W razie potrzeby instytucja zapewni pomoc w uzyskaniu wiz dla mobilnych uczestników przyjeżdżających i wyjeżdżających, zgodnie z wymogami Karty Erasmusa dla szkolnictwa wyższego.",
                          part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        contact: "Kontakt"
                      },
                      insurance: {
                        title: "UBEZPIECZENIE",
                        description: {
                          part1: "Instytucja zapewni pomoc w uzyskaniu ubezpieczenia dla mobilnych uczestników przyjeżdżających i wyjeżdżających, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                          part2: " Instytucja przyjmująca poinformuje uczestników mobilnych o przypadkach, w których ochrona ubezpieczeniowa nie jest zapewniana automatycznie.",
                          part3: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        contact: "Kontakt"
                      },
                      additionalInfo: {
                        title: "DODATKOWE INFORMACJE",
                        description: {
                          part1: "Instytucja zapewni wsparcie przyjeżdżającym mobilnym uczestnikom ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego.",
                          part2: " Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:"
                        },
                        contact: "KONTAKT"
                      }
                    }
                  },
                  cooperationConditions: {
                    header: "Warunki współpracy",
                    blendedMobility: {
                      yes: "Yes",
                      no: "No"
                    },
                    eqfLevels: {
                      lvl5: "EQF Level 5 - Associate Degree",
                      lvl6: "EQF Level 6 - Bachelor's Degree",
                      lvl7: "EQF Level 7 - Master's Degree",
                      lvl8: "EQF Level 8 - Doctorate Degree",
                    },
                    typeOfCoopCondition: {
                      fillAllFields: "Please fill all the required fields",
                      validEmailSending: "Please fill a valid email address at sending contacts",
                      validEmailReceiving: "Please fill a valid email address at receiving contacts",
                      invalidISCED: "Invalid ISCED F-Codes are detected in the cooperation condition. Please correct the ISCED F-Codes that contain less than 4 digits by selecting the appropriate one from the ISCED F-Code dropdown list.",
                    },
                    cooperationConditions: {
                      header: "WARUNKI WSPÓŁPRACY",
                      staffTeacher: "Nauczyciel personelu",
                      staffTeachers: "Pracownicy Nauczyciele",
                      studyFields: "Pola badań",
                      staffTraining: "Szkolenie personelu",
                      staffTrainings: "Szkolenia personelu",
                      studentStudy: "Badanie studentów",
                      studentTraineeship: "Praktyki studenckie"
                    },
                    daysPerYear: " dni w roku",
                    monthsPerYear: " miesięcy w roku",
                    heis: {
                      sender: "NADAWCA",
                      receiver: "ODBIORNIK"
                    },
                    conditionDetails: {
                      header: "Szczegóły dotyczące stanu",
                      mobilityType: "TYP MOBILNOŚCI",
                      duration: "CZAS TRWANIA",
                      academicYears: "LATA AKADEMICKIE",
                      eqfLevel: "Poziom EQF",
                      spots: "SPOTS"
                    },
                    otherInfo: "INNE INFORMACJE",
                    languageRequirements: {
                      header: "Kryteria językowe",
                      language: "JĘZYK",
                      studyField: "POLE BADANIA",
                      clarification: "UŚCIŚLENIE"
                    },
                    studyFields: {
                      header: "Pola badań",
                      studyField: "POLE BADANIA",
                      clarification: "UŚCIŚLENIE"
                    },
                    contacts: {
                      header: "Kontakty",
                      sendingContacts: "WYSYŁANIE KONTAKTÓW",
                      receivingContacts: "ODBIERANIE KONTAKTÓW"
                    }
                  },
                  confirmation: "Potwierdzam, również w imieniu mojej uczelni, że posiadam wszystkie upoważnienia, w tym zgodę odpowiednich osób fizycznych, niezbędne do przesłania danych osobowych i informacji, które przekazuję, zgodnie z Regulaminem i Polityką prywatności Dashboard.",
                  confirmationCheck: "Prosimy o wyrażenie zgody na zapoznanie się z Polityką prywatności i Regulaminem."
                }
              },
              iiaImport: {
                subTitle: "Import IIA",
                alerts: {
                  incorrectFileType: "Przepraszamy, ale wybrany plik nie ma prawidłowego rozszerzenia. Wybierz plik .csv.",
                  invalidTemplate: "Przepraszamy, ale użyty szablon jest nieprawidłowy. Oficjalny szablon można znaleźć na stronie Import IIA.",
                  errorMessage: {
                    part1: "Obecnie importowanie umów międzyinstytucjonalnych akceptuje tylko do ",
                    part2: " i właśnie wybrany plik ",
                    part3: "mają łącznie ",
                    part4: " umowy, ",
                    part5: "więc ",
                    part6: "ostatni ",
                    part7: " ważne umowy zostaną odrzucone.",
                    message1: "z ",
                    message2: "te nieprawidłowe wiersze i ",
                    emptyRows: " puste wiersze, ",
                    wrongPartnerEC: " umowy z niewłaściwym PartnerEC (partnerzy nie mogą mieć tego samego kodu Erasmusa co Ty lub nieważnego kodu Erasmusa), "
                  },
                  errorMessage2: {
                    message:  "Ten błąd może być spowodowany przez jedną z następujących przyczyn:\n" +
                              " - Wszystkie wiersze w przesłanym pliku są puste lub nieprawidłowe;\n" +
                              " - Nie ma partnera EC;" +
                              " - Użyłeś własnego EC jako EC partnera.\n" +
                              "Sprawdź swój plik. Bez usunięcia tych błędów nie jest możliwe sprawdzenie pozostałych pól."
                  },
                },
                header: "Prześlij plik CSV, aby zaimportować umowy międzyinstytucjonalne",
                loadingMessage: "Może to trochę potrwać, ale nie zamykaj tej strony!",
                returnToIIAListing: "Powrót do listy IIA",
                returnToUploadPage: "Powrót do strony przesyłania",
                description: "Funkcja importu umów międzyinstytucjonalnych obsługuje teraz przesyłanie CSV. Po wprowadzeniu danych można przeglądać umowy pojedynczo, aby upewnić się, że wszystkie informacje są poprawne i przesłać dane. Ta czynność spowoduje utworzenie wstępnie wypełnionej umowy międzyinstytucjonalnej i powiadomienie partnera z zaproszeniem do edycji lub podpisania dokumentu. Możliwe jest również przesłanie wszystkich umów w tym samym czasie, klikając przycisk „Importuj wszystko”.",
                verifyFormat: "Format pliku CSV można zweryfikować za pomocą szablonu arkusza kalkulacyjnego udostępnionego w EWP CC  ",
                here: "tutaj",
                confirmation: "Potwierdzam, również w imieniu mojej uczelni, że posiadam wszystkie upoważnienia, w tym zgodę odpowiednich osób fizycznych, niezbędne do przesłania danych osobowych i informacji, które przekazuję, zgodnie z Regulaminem i Polityką prywatności Dashboard.                        ",
                consentError: "Prosimy o wyrażenie zgody na przetwarzanie danych",
                uploadButton: "Przesyłanie",
                importConfirmation: "Czy na pewno chcesz zaimportować wszystkie umowy?"
              },
              iiaExport: {
                subTitle: "Eksport IIAs",
                header: "Pobierz plik CSV, aby wyeksportować umowy międzyinstytucjonalne",
                description: "Funkcja eksportu umowy międzyinstytucjonalnej obsługuje teraz pobieranie CSV. Plik eksportu CSV dla danej instytucji jest tworzony asynchronicznie i okresowo. Najnowszy plik eksportu CSV jest dostępny do pobrania poniżej.",
                message: {
                  part1: "Plik eksportu CSV został pomyślnie utworzony w",
                  part2: " i jest dostępny do pobrania"
                },
                downloadButton: "Pobierz"
              },
              editFactsheet: {
                subTitle: "Edytuj arkusz informacyjny",
                loadingMessage: "Ładowanie...",
                errorMessage: "Wystąpił błąd",
                factsheetFeedBack: {
                  update: {
                    success: "Dane arkusza informacyjnego zostały pomyślnie zaktualizowane.",
                    failure: "Wystąpił błąd podczas aktualizacji danych arkusza informacyjnego.",
                    alert: "Wystąpił błąd podczas aktualizacji danych arkusza informacyjnego: "
                  }
                },
                calendar: {
                  header: "KALENDARZ",
                  studentNominations: "Nominacje studentów muszą dotrzeć do instytucji przyjmującej do dnia:",
                  autumnTerm: "Termin jesienny ",
                  springTerm: "Termin wiosenny ",
                  invalidDateFormat: "Nieprawidłowy format daty",
                  applicationsForNominated: "Zgłoszenia nominowanych studentów muszą dotrzeć do instytucji przyjmującej do dnia:",
                  applicationProcedure: "Procedura składania wniosków dla nominowanych studentów:",
                  email: {
                    title: "Kontakt Email ",
                    error: "Nieważne"
                  },
                  phone: {
                    title: "Telefon kontaktowy ",
                    error: " Wprowadź numer telefonu zaczynający się od kodu kraju (np. +000)."
                  },
                  websiteForInfo: {
                    title: "Strona internetowa z informacjami ",
                    error: "Wprowadź adres URL zaczynający się od http lub https."
                  },
                  defaultWeekValues: "Domyślne wartości tygodni:",
                  weeksForDecision: {
                    title: "Tygodnie na podjęcie decyzji o dodatkowych Kryteria ",
                    error: "Nieważne"
                  },
                  weeksForTranscript: {
                    title: "Tygodnie na wydanie transkrypcji zapisów  ",
                    error: "Nieważne"
                  }
                },
                additionalRequirements: {
                  title: "DODATKOWE KRYTERIA",
                  requirement: {
                    title: "Wymóg ",
                    placeholder: "Wybierz wymaganie"
                  },
                  details: {
                    title: "Szczegóły",
                  },
                  ectsNumber: {
                    title: "Liczba uzyskanych punktów ECTS",
                  },
                  studyFieldISCED: {
                    title: "Dziedzina studiów (ISCED)",
                    placeholder: "Wybierz ISCED"
                  },
                  studyLevelEQF: {
                    title: "Poziom studiów (EQF)",
                    placeholder: "Wybierz EQF"
                  },
                  website: {
                    title: "Strona internetowa z informacjami",
                    error: "Wprowadź adres URL zaczynający się od http lub https."
                  },
                  deleteButton: "Usuń dodatkowy wymóg",
                  addRequirement: "Dodatkowe Kryteria"
                },
                inclusionAndAccessibility: {
                  title: "WŁĄCZENIE I DOSTĘPNOŚĆ",
                  descriptionMain: "Instytucja zapewni wsparcie przyjeżdżającym uczestnikom mobilnym ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla szkolnictwa wyższego. Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:",
                  infrastructure: "Infrastruktura",
                  name: "Nazwa ",
                  description: "Opis",
                  contactEmail: {
                    title: "Kontakt Email ",
                    error: "Nieważne"
                  },
                  contactPhone: {
                    title: "Telefon kontaktowy ",
                    error: "Wprowadź numer telefonu zaczynający się od kodu kraju (np. +000)."
                  },
                  website: {
                    title: "Strona internetowa z informacjami ",
                    error: "Wprowadź adres URL zaczynający się od http lub https."
                  },
                  addInfrastucture: "Dodaj infrastrukturę",
                  services: "Usługi",
                  addService: "Dodaj usługę"
                },
                deleteInfrastructure: "Usuń infrastrukturę",
                deleteService: "Usuń usługę",
                housing: {
                  title: "MIESZKANIE",
                  description: "Instytucja pomoże przyjeżdżającym uczestnikom mobilnym w znalezieniu zakwaterowania zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego. Informacji i pomocy udzielają następujące punkty kontaktowe i źródła informacji:",
                  contact: "Kontakt",
                  contactEmail: {
                    title: "Kontakt Email ",
                    error: "Nieważne"
                  },
                  contactPhone: {
                    title: "Telefon kontaktowy ",
                    error: "Wprowadź numer telefonu zaczynający się od kodu kraju (np. +000)."
                  },
                  website: {
                    title: "Strona internetowa z informacjami ",
                    error: "Wprowadź adres URL zaczynający się od http lub https."
                  }
                },
                visa: {
                  title: "VISA",
                  description: "W razie potrzeby instytucja zapewni pomoc w uzyskaniu wiz dla mobilnych uczestników przyjeżdżających i wyjeżdżających, zgodnie z wymogami Karty Erasmusa dla szkolnictwa wyższego. Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:",
                  contact: "Kontakt",
                  contactEmail: {
                    title: "Kontakt Email ",
                    error: "Nieważne"
                  },
                  contactPhone: {
                    title: "Telefon kontaktowy ",
                    error: "Wprowadź numer telefonu zaczynający się od kodu kraju (np. +000)."
                  },
                  website: {
                    title: "Strona internetowa z informacjami ",
                    error: "Wprowadź adres URL zaczynający się od http lub https."
                  }
                },
                insurance: {
                  title: "UBEZPIECZENIE",
                  description: "Instytucja zapewni pomoc w uzyskaniu ubezpieczenia dla przyjeżdżających i wyjeżdżających uczestników mobilnych, zgodnie z wymogami Karty Erasmusa dla Szkolnictwa Wyższego. Instytucja przyjmująca poinformuje uczestników mobilnych o przypadkach, w których ubezpieczenie nie jest zapewniane automatycznie. Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:",
                  contact: "Kontakt",
                  contactEmail: {
                    title: "Kontakt Email ",
                    error: "Nieważne"
                  },
                  contactPhone: {
                    title: "Telefon kontaktowy ",
                    error: "Wprowadź numer telefonu zaczynający się od kodu kraju (np. +000)."
                  },
                  website: {
                    title: "Strona internetowa z informacjami ",
                    error: "Wprowadź adres URL zaczynający się od http lub https."
                  }
                },
                additionalInfo: {
                  title: "DODATKOWE INFORMACJE",
                  description: "Instytucja zapewni wsparcie przyjeżdżającym uczestnikom mobilnym ze specjalnymi potrzebami, zgodnie z wymogami Karty Erasmusa dla szkolnictwa wyższego. Informacje i pomoc mogą być udzielane przez następujące punkty kontaktowe i źródła informacji:",
                  recognitionProcess: "Proces uznawania",
                  contact: "KONTAKT",
                  contactEmail: {
                    title: "Kontakt Email ",
                    error: "Not valid"
                  },
                  contactPhone: {
                    title: "Telefon kontaktowy ",
                    error: "Wprowadź numer telefonu zaczynający się od kodu kraju (np. +000)."
                  },
                  website: {
                    title: "Strona internetowa z informacjami ",
                    error: "Wprowadź adres URL zaczynający się od http lub https."
                  },
                  deleteRecognitionProcess: "Usuń proces rozpoznawania",
                  addRecognitionProcess: "Dodaj informacje o procesie rozpoznawania",
                  otherInfo: "Inne przydatne informacje",
                  deleteInfo: "Usuń inne informacje",
                  addInfo: "Dodaj inne przydatne informacje"
                },
                saveFactsheet: "Zapisz dane z arkusza informacyjnego",
                containsErrors: "Formularz zawiera błędy",
                confirmation: "Potwierdzam, również w imieniu mojej uczelni, że posiadam wszystkie upoważnienia, w tym zgodę odpowiednich osób fizycznych, niezbędne do przesłania danych osobowych i informacji, które przekazuję, zgodnie z Regulaminem i Polityką prywatności Dashboard.",
                confirmationError: "Prosimy o wyrażenie zgody na przetwarzanie danych",
                saveConfirmation: "Zamierzasz edytować dane arkusza informacyjnego"
              },
              filter: {
                title: "Filtry i sortowanie",
                clearButton: "Wyczyść wszystko",
                applyButton: "Zastosuj filtry",
                academicTerms: {
                  firstSemester: "Pierwszy semestr (zima/jesień)",
                  secondSemester: "Drugi semestr (lato/wiosna)",
                  firstTrimester: "Pierwszy trymestr",
                  secondTrimester: "Drugi trymestr",
                  fullYear: "Pełny rok akademicki",
                },
                sortingList: {
                  status: "Sortowanie według statusu",
                  lastChangedAscending: "Sortowanie według ostatniej zmiany rosnąco",
                  lastChangedDescending: "Sortowanie według ostatniej zmiany malejąco",
                  name: "Sortowanie według nazwy"
                },
                pendingRequests: {
                  pending: "W toku",
                  accepted: "Zaakceptowany",
                  declined: "Odrzucono"
                },
                sortBy: {
                  title: "Sortuj według",
                  placeholder: "Wybierz sortowanie według..."
                },
                status: {
                  title: "Status",
                  placeholder: "Wybierz status..."
                },
                iia: {
                  title: "IIA ID",
                  placeholder: "IIA ID",
                  label: "Wyszukiwanie według ID użytkownika lub ID partnera:"
                },
                academicYear: {
                  title: "Rok akademicki",
                  placeholder: "Wybierz rok akademicki..."
                },
                academicTerm: {
                  title: "Okres akademicki",
                  placeholder: "Wybierz rok akademicki..."
                },
                country: {
                  title: "Kraj",
                  placeholder: "Wybierz jeden lub więcej krajów..."
                },
                institution: {
                  title: "Instytucja",
                  erasmusCode: "Kod Erasmusa:",
                  esasmusCodePlaceholder: "Wybierz kod erasmus...",
                  institutionName: "Nazwa instytucji:",
                  institutionNamePlaceholder: "Wybierz nazwę instytucji...",
                },
                approvingEmail: {
                  title: "E-mail osoby zatwierdzającej",
                  placeholder: "E-mail osoby zatwierdzającej"
                },
                createdBy: {
                  title: "Utworzony przez",
                  placeholder: "Wybór utworzony przez...",
                  student: "Stworzony przez studenta",
                  institution: "Stworzony przez instytucję"
                },
                studentName: {
                  title: "Imię i nazwisko studenta",
                  firstNameLabel: "Imię:",
                  firstNamePlaceholder: "Imię",
                  lastNameLabel: "Nazwisko:",
                  lastNamePlaceholder: "Nazwisko"
                },
                organizationalUnit: {
                  title: "Jednostka organizacyjna",
                  placeholder: "Wybór jednostki organizacyjnej..."
                },
                name: {
                  title: "Nazwa",
                },
                email: {
                  title: "Email",
                  error: "Podaj prawidłową wartość monitu."
                }
              },
              modal: {
                cancelButton: "Anuluj"
              },
              selectPhoneInput: {
                label: "Kod telefonu",
                placeholder: "Wybierz kod telefonu...",
                error: {
                  a: "Prosimy o podanie kodu telefonu",
                  b: "Prosimy o podanie ważnego telefonu."
                }
              },
              table: {
                filterLabel: "Filtry",
                totalAgreements: "Umowy ogółem",
                activeFilters: "Filtry aktywne:",
                noResults: "Przepraszamy, nie mogliśmy znaleźć żadnych wyników"
              },
              coursesTable: {
                olaReasons: {
                  part1: "Wcześniej wybrany komponent edukacyjny nie jest dostępny w instytucji przyjmującej",
                  part2: "Komponent jest w innym języku niż wcześniej określony w katalogu kursów",
                  part3: "Konflikt harmonogramów",
                  part4: "Inne (należy określić)",
                  part5: "Zastępowanie usuniętego komponentu",
                  part6: "Wydłużenie okresu mobilności",
                  part7: "Inne (należy określić)",
                  other: "Inne "
                },
                noCourses: "Brak kursów do pokazania",
                totalECTs: "Łączna liczba punktów ECTS: ",
                labels: {
                  code: "KOD",
                  subject: "PRZEDMIOT",
                  ects: "ECTS",
                  semester: "SEMESTR",
                  reason: "POWÓD",
                  autoRecognition: "AUTOMATYCZNE ROZPOZNAWANIE",
                  description: "OPIS"
                }
              },
              olaPdf: {
                download: {
                  part1: "Pobierz plik PDF, klikając ",
                  part2: "tutaj"
                }
              },
              institutionInfoTable: {
                name: "Nazwa:",
                country: "Kraj:",
                erasmusCode: "Kod Erasmusa:",
                address: "Adres:",
                faculty: "Wydział:",
                contactPerson: "Osoba kontaktowa: ",
                resPerson: "Res. Osoba:"
              },
              learningAgreementInfoTable: {
                status: "STATUS",
                academicYear: "ROK AKADEMICKI",
                plannedPeriod: "PLANOWANY OKRES",
                created: "UTWORZONY",
                pdfExportText: "Eksport do PDF"
              },
              studentInfo: {
                firstName: "Imię:",
                lastName: "Nazwisko:",
                email: "Email:",
                birthDate: "Data urodzenia:",
                gender: "Płeć:",
                nationality: "Narodowość:",
                foeISCED: "Dziedzina edukacji (ISCED):",
                foeClarification: "Dziedzina edukacji (Wyjaśnienie):"
              },
              tor: {
                totalECTs: "Łącznie ECTS: ",
                form: {
                  componentCode: {
                    title: {
                      part1: "Kod komponentu",
                      part2: "*"
                    },
                    error: "Prosimy o podanie kodu.",
                  },
                  componentTitle: {
                    title: {
                      part1: "Tytuł komponentu",
                      part2: "*"
                    },
                    error: "Prosimy o podanie tytułu."
                  },
                  grade: {
                    title: {
                      part1: "Klasa",
                      part2: "*"
                    },
                    error: "Podaj ocenę."
                  },
                  ectNum: {
                    title: {
                      part1: "Liczba punktów ECTS",
                      part2: "*"
                    },
                    error: "Prosimy o podanie kredytów ects."
                  },
                  success: {
                    part1: "Pomyślnie ukończone",
                    part2: "*"
                  }
                },
                torFeedback: {
                  create: {
                    loading: "Tworzenie nowego ToR...",
                    success: "Pomyślnie utworzony ToR",
                    failure: "Błąd podczas tworzenia ToR."
                  },
                  update: {
                    loading: "Aktualizacja nowego ToR...",
                    success: "Pomyślnie zaktualizowany ToR",
                    failure: "Błąd podczas aktualizacji ToR."
                  }
                },
                componentFeedback: {
                  create: {
                    loading: "Tworzenie nowego komponentu...",
                    success: "Pomyślnie utworzony komponent",
                    failure: "Błąd podczas tworzenia komponentu."
                  },
                  edit: {
                    loading: "Edytowanie nowego komponentu...",
                    success: "Pomyślnie edytowany komponent",
                    failure: "Błąd podczas edycji komponentu."
                  },
                  delete: {
                    loading: "Usuwanie nowego komponentu...",
                    success: "Komponent został pomyślnie usunięty",
                    failure: "Błąd podczas usuwania komponentu."
                  }
                },
                createTor: "Tworzenie ToR",
                mobilityStart: "Data rozpoczęcia mobilności:",
                mStart: "Data rozpoczęcia mobilności",
                mEnd: "Data zakończenia mobilności",
                mobilityEnd: "Data zakończenia mobilności:",
                invalidInput: "Podaj prawidłową wartość monitu.",
                transcript: "TRANSKRYPCJA ZAPISÓW W INSTYTUCJI PRZYJMUJĄCEJ",
                recognition: "UZNANIE W INSTYTUCJI WYSYŁAJĄCEJ",
                addComponentTitle: { 
                  part1: "Dodaj nowy ",
                  part2: "Komponent"
                },
                editComponentTitle: {
                  part1: "Edytuj ",
                  part2: "Komponent"
                },
                deleteComponentTitle: {
                  confirmMessage: "Jesteś pewien?",
                  part1: "Usuń ",
                  part2: "Komponent"
                },
                updateTor: {
                  part1: "Aktualizacja ",
                  part2: "ToR"
                },
                addComponent: "dodać nowy komponent",
                editComponent: "Edytuj komponent",
                deleteComponent: "Usuń komponent",
                submitButton: "Prześlij",
                updateButton: "Aktualizacja"
              },
              destinationHeader: {
                nominated: "NOMINOWANY",
                rejected: "ODRZUCONY",
                nominate: "Nominuj"
              },
              destinationInfo: {
                studyLevel: "POZIOM STUDIÓW",
                studyField: "POLE BADAŃ",
                reqDocuments: "WYMAGANE DOKUMENTY",
                otherReq: "INNE KRYTERIA"
              },
            }
        }
    }
  });

export default i18n;