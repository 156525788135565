import IconButton from '../../../../../components/IconButton';
import { Table, Row, Col, Container } from 'react-bootstrap';
import { StatusMapping } from '../../../../../data/statusMapping';
import { StatusActionPair } from '../../../../../data/statusActionPairs';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const LearningAgreementInfoTable = ({ typeOfStudent, data, size }) => {
    const { t } = useTranslation();
    
    const getNidFromTitle = (str) =>{
        return str.substring(str.indexOf("-")+1,str.length);
    } 

    return (
        <>
            {(size !== "md" && size !== "lg" && size !== "xl" && size !== "xxl") ?
                <div className={`${(StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.learningAgreement?.field_la_status] ?
                    "action-" + StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.learningAgreement?.field_la_status] : "")
                    }`}>
                    <Container>
                        <Row style={{ paddingLeft: 10 }}>
                            <Col>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>ID:</Col><Col xs={6}>{getNidFromTitle(data?.learningAgreement?.title)}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>STATUS:</Col><Col xs={6}>{StatusMapping[data?.learningAgreement?.field_la_status]}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>ACADEMIC YEAR:</Col><Col xs={6}>{data?.learningAgreement?.field_academic_year}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>PLANNED PERIOD:</Col><Col xs={6}>{new Date(data?.learningAgreement?.field_planned_period_from).toLocaleDateString('en-GB') + " - " + new Date(data?.learningAgreement?.field_planned_period_to).toLocaleDateString('en-GB')}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4}>CREATED:</Col><Col xs={6}>{new Date(data?.learningAgreement?.created).toLocaleDateString('en-GB') + " - " + new Date(data?.learningAgreement?.created).toLocaleTimeString('en-GB')}</Col></Row>
                                <Row style={{ paddingTop: 15, paddingBottom: 10 }}><Col className='border-hidden label-style text-end'>
                                    <Link to={`/ola/${typeOfStudent}/export/${data?.learningAgreement?.olaID}`} target="_blank">
                                        <IconButton
                                            variant="download"
                                            title="Download All files"
                                        />
                                    </Link>
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                :
                <div className={`${(StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.learningAgreement?.field_la_status] ?
                    "action-" + StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.learningAgreement?.field_la_status] : "")
                    }`}>
                    <Table responsive>
                        <thead className='thead-font'>
                            <tr>
                                <th style={{ paddingTop: 15, paddingBottom: 2, paddingLeft: 24 }} className='border-hidden label-style'>ID</th>
                                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style'>{t('learningAgreementInfoTable.status')}</th>
                                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style'>{t('learningAgreementInfoTable.academicYear')}</th>
                                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style'>{t('learningAgreementInfoTable.plannedPeriod')}</th>
                                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style'>{t('learningAgreementInfoTable.created')}</th>
                                <th className='download-wrap border-hidden'></th>
                            </tr>
                        </thead>
                        <tbody className='tbody-font'>
                            <tr>
                                <td style={{ paddingTop: 0, paddingBottom: 20, paddingLeft: 24 }} className='border-hidden body'>{getNidFromTitle(data?.learningAgreement?.title)}</td>
                                <td style={{ paddingTop: 0, paddingBottom: 20 }} className='border-hidden body'>{StatusMapping[data?.learningAgreement?.field_la_status]}</td>
                                <td style={{ paddingTop: 0, paddingBottom: 20 }} className='border-hidden body'>{data?.learningAgreement?.field_academic_year}</td>
                                <td style={{ paddingTop: 0, paddingBottom: 20 }} className='border-hidden body'>{new Date(data?.learningAgreement?.field_planned_period_from).toLocaleDateString('en-GB') + " - " + new Date(data?.learningAgreement?.field_planned_period_to).toLocaleDateString('en-GB')}</td>
                                <td style={{ paddingTop: 0, paddingBottom: 20 }} className='border-hidden body'>{new Date(data?.learningAgreement?.created).toLocaleDateString('en-GB') + " - " + new Date(data?.learningAgreement?.created).toLocaleTimeString('en-GB')}</td>
                                <td style={{ paddingTop: 0, marginTop: -5 }} className="download-wrap border-hidden">
                                    <Link to={`/ola/${typeOfStudent}/export/${data?.learningAgreement?.olaID}`} target="_blank">
                                        <IconButton
                                            variant="download"

                                            title={t('learningAgreementInfoTable.pdfExportText')}
                                            onClick={(e) => console.log("on click")}
                                        />
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

export default LearningAgreementInfoTable;