import {useState, useEffect, Fragment} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Accordion, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts';
import "./index.scss";
import useBreakpoint from '../../../hooks/useBreakpoint';
import { useQuery } from '@apollo/client';
import GET_COURSE_SINGLE_VIEW from '../../../graphql/queries/courses-evaluations/getCourseSingleView';
import CustomButton from '../../../components/CustomButton';
import { faDownload, faFileZipper } from '@fortawesome/free-solid-svg-icons';
import COLORS from '../../../data/colors';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function CoursesEvaluationsSingleView() {
    const [size] = useBreakpoint();
    const [activeTab, setActiveTab] = useState('general-info'); // Track active tab
    const [showExportTab, setShowExportTab] = useState(false);  // Track if export tab is visible


    const { id } = useParams();

  const { loading, error, data, refetch: refetchQuery } = useQuery(
    GET_COURSE_SINGLE_VIEW,
    {
      variables: {
        courseId: id
      }
    },
    );

    const EvaluationInfo = () => (
      <Table responsive>
      <thead className='thead-font'>
          <tr>
          <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className='border-hidden label-style'>ID</th>
          <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>COURSE CODE</th>
          <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>COURSE TITLE</th>
          <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC TERM</th>
          <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>INSTITUTION</th>
          </tr>
      </thead>
      <tbody className='tbody-font'>
          <tr>
          <td style={{paddingTop: 0, paddingBottom: 20, paddingLeft: 24}} className='border-hidden body'>{data?.course?.course?.id}</td>
          <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{data?.course?.course?.code}</td>
          <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{data?.course?.course?.title}</td>
          <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{data?.course?.course?.term}</td>
          <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{data?.course?.course?.institution}</td>
          </tr>
      </tbody>
      </Table>
    )

    const GeneralInfoTab = () => (
      <Row style={{marginLeft: 25, marginRight: 25}}>
      <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
      <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                      {/* <div className='h6-style'>What best describes your gender? </div> */}
                      <Row style={{marginTop: 12}}>
                          <Col xs={12} sm={12} md={12} lg={6} className='flex-center'>
                          <div><div className='question-title'>What best describes your gender?</div>
                          <div className='flex-center'><PieChart
                              series={[
                                  {arcLabel: (item) => `${parseFloat((100 * item.value / data?.course?.count).toFixed(2))}%`,
                                  arcLabelMinAngle: 35,
                                  arcLabelRadius: '60%',
                                  data: [
                                      { id: 0, value: data?.course?.evaluationSubmissions?.genderGroups?.female, label: 'Female' },
                                      { id: 1, value: data?.course?.evaluationSubmissions?.genderGroups?.male, label: 'Male' },
                                      { id: 2, value: data?.course?.evaluationSubmissions?.genderGroups?.non_binary, label: 'Non-binary' },
                                      { id: 3, value: data?.course?.evaluationSubmissions?.genderGroups?.other, label: 'Other' },
                                      { id: 4, value: data?.course?.evaluationSubmissions?.genderGroups?.prefer_not_to_say, label: 'Prefer not to say' },
                                      { id: 5, value: data?.course?.evaluationSubmissions?.genderGroups?.unsure_how_to_describe_myself, label: 'Unsure how to describe myself' },
                                  ],
                                  },
                              ]}
                              sx={{
                                  [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold',
                                  },
                                }}
                              width={400}
                              height={400}
                              margin={{ top: 50, bottom: 100, left: 100, right:100 }}
                              slotProps={{
                                  legend: {
                                  //   labelStyle: {
                                  //     tableLayout: 'fixed',
                                  //   },
                                    direction: 'row',
                                    position: {
                                      horizontal: 'middle',
                                      vertical: 'bottom',
                                    },padding: 0,
                                  },
                                }}
                          /></div><div className='total-count-label' style={{marginTop: 10}}>Total: {data?.course?.count} evaluations</div></div>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={6} className='flex-center'>
                          <div><div className='question-title'>What is your age?</div>
                          <div className='flex-center'><PieChart
                              series={[
                                  {arcLabel: (item) => `${parseFloat((100 * item.value / data?.course?.count).toFixed(2))}%`,
                                  arcLabelMinAngle: 35,
                                  arcLabelRadius: '60%',
                                  data: [
                                      { id: 0, value: data?.course?.evaluationSubmissions?.ageGroups?.group_16_18, label: '16-18' },
                                      { id: 1, value: data?.course?.evaluationSubmissions?.ageGroups?.group_19_25, label: '19-25' },
                                      { id: 2, value: data?.course?.evaluationSubmissions?.ageGroups?.group_26_30, label: '26-30' },
                                      { id: 3, value: data?.course?.evaluationSubmissions?.ageGroups?.group_31_60, label: '31-60' },
                                      { id: 4, value: data?.course?.evaluationSubmissions?.ageGroups?.group_61_plus, label: '61+' },
                                  ],
                                  },
                              ]}
                              sx={{
                                  [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold',
                                  },
                                }}
                              width={400}
                              height={400}
                              margin={{ top: 50, bottom: 100, left: 100, right:100 }}
                              slotProps={{
                                  legend: {
                                  //   labelStyle: {
                                  //     tableLayout: 'fixed',
                                  //   },
                                    direction: 'row',
                                    position: {
                                      horizontal: 'middle',
                                      vertical: 'bottom',
                                    },padding: 0,
                                  },
                                }}
                          /></div><div className='total-count-label' style={{marginTop: 10}}>Total: {data?.course?.count} evaluations</div></div>
                          </Col>
                      </Row>
                      </div>
      </Col>
      </Row>
    )

    const DocumentsTab = () => (
      <Row style={{marginLeft: 25, marginRight: 25}}>
      <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
      <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                      {/* <div className='h6-style'>What best describes your gender? </div> */}
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The overall quality of the course was sufficient</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseQuality?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseQuality?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseQuality?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseQuality?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseQuality?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                          />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The course was well organized</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseOrganisation?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseOrganisation?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseOrganisation?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseOrganisation?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseOrganisation?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The course subject matter was adapted to my prior knowledge</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.coursePrerequisites?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.coursePrerequisites?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.coursePrerequisites?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.coursePrerequisites?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.coursePrerequisites?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The course objectives were clear to me</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseClearObjectives?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseClearObjectives?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseClearObjectives?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseClearObjectives?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseClearObjectives?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The relevance of the topics of this course within the context of my field of study was clear to me</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The learning management system was clearly structured</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseOnlineTools?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseOnlineTools?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseOnlineTools?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseOnlineTools?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseOnlineTools?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      </div>
      </Col>
      </Row>
    )

    const PartnerTab = () => (
      <Row style={{marginLeft: 25, marginRight: 25}}>
      <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
          <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The lectures of the course were adding to my understanding of the topic(s)</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseRelevantLectures?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseRelevantLectures?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseRelevantLectures?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseRelevantLectures?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseRelevantLectures?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The activities in the course motivated me</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseMotivationalActivities?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseMotivationalActivities?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseMotivationalActivities?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseMotivationalActivities?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseMotivationalActivities?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The activities in the course prepared me for my future employment</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseFutureEmployment?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseFutureEmployment?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseFutureEmployment?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseFutureEmployment?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseFutureEmployment?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The activities in the course helped me reflect on current societal challenges</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseSocietalReflexion?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseSocietalReflexion?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseSocietalReflexion?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseSocietalReflexion?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseSocietalReflexion?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The relevance of the topics of this course within the context of my field of study was clear to me</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseTopicRelevance?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>I understand how I can apply the course materials in a practical setting</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseMaterials?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseMaterials?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseMaterials?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseMaterials?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseMaterials?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The number of social interactions with students in the course was sufficient</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseSocialInteraction?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseSocialInteraction?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseSocialInteraction?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseSocialInteraction?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseSocialInteraction?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The study load was evenly distributed throughout the course</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.courseStudyLoad?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseStudyLoad?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseStudyLoad?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseStudyLoad?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.courseStudyLoad?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
          </div>
      </Col>
      </Row>
    )

    const ConditionsTab = () => (
      <Row style={{marginLeft: 25, marginRight: 25}}>
      <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
          <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The teacher(s) of the course was/were approachable</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.teacherApproachability?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherApproachability?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherApproachability?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherApproachability?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherApproachability?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>There were enough contact moments with the teacher(s) (office hours, lectures)</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.teacherContactMoments?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherContactMoments?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherContactMoments?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherContactMoments?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherContactMoments?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The teacher(s) provided opportunities to discuss difficulties / questions</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.teacherDiscussionOpportunities?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherDiscussionOpportunities?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherDiscussionOpportunities?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherDiscussionOpportunities?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherDiscussionOpportunities?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The teacher(s) was able to deal with specific needs I had as an exchange student</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.teacherSpecificNeeds?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherSpecificNeeds?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherSpecificNeeds?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherSpecificNeeds?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherSpecificNeeds?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The teacher(s) taught in a motivating manner</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.teacherMotivatingManner?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherMotivatingManner?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherMotivatingManner?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherMotivatingManner?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherMotivatingManner?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The teacher(s) showed interest in international perspectives</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.teacherInternationalPerspective?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherInternationalPerspective?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherInternationalPerspective?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherInternationalPerspective?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.teacherInternationalPerspective?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
          </div>
      </Col>
      </Row>
    )

    const CulturalTab = () => (
      <Row style={{marginLeft: 25, marginRight: 25}}>
      <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
          <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>Input from an international perspective was valued in the course</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.culturalInternationalPerspective?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalInternationalPerspective?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalInternationalPerspective?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalInternationalPerspective?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalInternationalPerspective?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The course provided me with opportunities to share my thoughts and feelings</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.culturalThoughtSharing?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalThoughtSharing?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalThoughtSharing?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalThoughtSharing?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalThoughtSharing?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The learning activities allowed for contact with other local/exchange students</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.culturalStudentContact?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalStudentContact?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalStudentContact?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalStudentContact?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalStudentContact?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>I experienced no cultural obstacles in the course</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.culturalPrejudice?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalPrejudice?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalPrejudice?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalPrejudice?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalPrejudice?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>I experienced no language obstacles/barriers in the course</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.culturalLanguageBarriers?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalLanguageBarriers?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalLanguageBarriers?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalLanguageBarriers?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.culturalLanguageBarriers?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
          </div>
      </Col>
      </Row>
    )

    const AssessmentTab = () => (
      <Row style={{marginLeft: 25, marginRight: 25}}>
      <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
          <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>Details of the assessment(s) were communicated to me before the start of the course</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.methodsDetailsCommunication?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsDetailsCommunication?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsDetailsCommunication?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsDetailsCommunication?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsDetailsCommunication?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The actual assessment(s) were like what was communicated beforehand</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.methodsCommunicationCorrespondence?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsCommunicationCorrespondence?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsCommunicationCorrespondence?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsCommunicationCorrespondence?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsCommunicationCorrespondence?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The assessment(s) represented the subjects discussed in the course</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.methodsSubjectConsistency?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsSubjectConsistency?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsSubjectConsistency?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsSubjectConsistency?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsSubjectConsistency?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The assessment(s) was/were applied fairly with attention to cultural differences of the students</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.methodsCulturalAttention?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsCulturalAttention?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsCulturalAttention?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsCulturalAttention?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsCulturalAttention?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>I am satisfied with what I’ve learned during the course</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.methodsLearningSatisfaction?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsLearningSatisfaction?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsLearningSatisfaction?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsLearningSatisfaction?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.methodsLearningSatisfaction?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
          </div>
      </Col>
      </Row>
    )

    const EvaluationTab = () => (
      <Row style={{marginLeft: 25, marginRight: 25}}>
      <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
          <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The course is sufficiently evaluated</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.evaluationSufficiency?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationSufficiency?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationSufficiency?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationSufficiency?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationSufficiency?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>There was enough room to share my thoughts about the course with the teacher(s)</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.evaluationSharingOpportunities?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationSharingOpportunities?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationSharingOpportunities?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationSharingOpportunities?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationSharingOpportunities?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true } }}
                          />
                          </Col>
                      </Row>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>The timing of the evaluations was fitting</div>
                          </Col>
                          <Col lg={6} className='flex-center' style={{height: 70}}>
                          <BarChart
                              yAxis={[{ scaleType: 'band', data: ['Results'] }]}
                              series={[{ data: [data?.course?.evaluations?.likertScaleFields?.evaluationTiming?.one], stack: 'A', label: 'Strongly disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationTiming?.two], stack: 'A', label: 'Disagree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationTiming?.three], stack: 'A', label: 'Neutral' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationTiming?.four], stack: 'A', label: 'Agree' }, 
                              { data: [data?.course?.evaluations?.likertScaleFields?.evaluationTiming?.five], stack: 'A', label: 'Strongly agree' }]}
                              width={600}
                              height={140}
                              layout="horizontal"
                              barLabel="value"
                              leftAxis={null}
                              bottomAxis={null}
                              slotProps={{ legend: { hidden: true, padding: 0 } }}
                          />
                          </Col>
                      </Row>
          </div>
      </Col>
      </Row>
    )

    const ConcludingTab = () => (
      <Row style={{marginLeft: 25, marginRight: 25}}>
      <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
          <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center'>
                          <div className='question-title'>What are your main motives to participate in this course?</div>
                          <div className='flex-center'><PieChart
                              series={[
                                  {arcLabel: (item) => item.value,
                                  arcLabelMinAngle: 35,
                                  arcLabelRadius: '60%',
                                  data: [
                                      { id: 0, value: data?.course?.evaluations?.enumFields?.generalParticipationMotives?.academic_opportunities, label: 'Academic opportunities' },
                                      { id: 1, value: data?.course?.evaluations?.enumFields?.generalParticipationMotives?.career_advancement, label: 'Career advancement' },
                                      { id: 2, value: data?.course?.evaluations?.enumFields?.generalParticipationMotives?.experiencing_different_cultures, label: 'Experiencing different cultures' },
                                      { id: 3, value: data?.course?.evaluations?.enumFields?.generalParticipationMotives?.other, label: 'Other' },
                                      { id: 4, value: data?.course?.evaluations?.enumFields?.generalParticipationMotives?.personal_motives, label: 'Personal motives' },
                                      { id: 5, value: data?.course?.evaluations?.enumFields?.generalParticipationMotives?.quality_of_education, label: 'Quality of education' }
                                  ],
                                  },
                              ]}
                              sx={{
                                  [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold',
                                  },
                                }}
                              width={400}
                              height={450}
                              margin={{ top: 50, bottom: 100, left: 100, right:100 }}
                              slotProps={{
                                  legend: {
                                  //   labelStyle: {
                                  //     tableLayout: 'fixed',
                                  //   },
                                    direction: 'row',
                                    position: {
                                      horizontal: 'middle',
                                      vertical: 'bottom',
                                    },padding: 0,
                                  },
                                }}
                          /></div>
                          </Col>
                          <Col lg={6} className='flex-center'>
                          <div className='question-title'>What are you most satisfied with regarding the course?</div>
                          <div className='flex-center'><PieChart
                              series={[
                                  {arcLabel: (item) => item.value,
                                  arcLabelMinAngle: 35,
                                  arcLabelRadius: '60%',
                                  data: [
                                      { id: 0, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.interaction_with_students, label: 'Interaction with students' },
                                      { id: 1, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.other, label: 'Other' },
                                      { id: 2, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.the_assessment, label: 'The assessment(s)' },
                                      { id: 3, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.the_content, label: 'The content' },
                                      { id: 4, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.the_learning_activities, label: 'The learning activities' },
                                      { id: 5, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.the_learning_materials, label: 'The learning materials' },
                                      { id: 6, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.the_lectures, label: 'The lectures' },
                                      { id: 7, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.the_setup_design, label: 'The set-up/design' },
                                      { id: 8, value: data?.course?.evaluations?.enumFields?.generalSatisfactionAspects?.the_support_of_teachers, label: 'The support of teachers' }
                                  ],
                                  },
                              ]}
                              sx={{
                                  [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold',
                                  },
                                }}
                              width={400}
                              height={450}
                              margin={{ top: 50, bottom: 100, left: 100, right:100 }}
                              slotProps={{
                                  legend: {
                                  //   labelStyle: {
                                  //     tableLayout: 'fixed',
                                  //   },
                                    direction: 'row',
                                    position: {
                                      horizontal: 'middle',
                                      vertical: 'bottom',
                                    },padding: 0,
                                  },
                                }}
                          /></div>
                          </Col>
                      </Row>
                      <Row style={{marginTop: 25}}>
                          <Col lg={6} className='flex-center'>
                          <div className='question-title'>Would you advise other exchange students to join this course?</div>
                          <div className='flex-center'><PieChart
                              series={[
                                  {arcLabel: (item) => item.value,
                                  arcLabelMinAngle: 35,
                                  arcLabelRadius: '60%',
                                  data: [
                                      { id: 0, value: data?.course?.evaluations?.enumFields?.generalWouldRecommend?.no, label: 'No' },
                                      { id: 1, value: data?.course?.evaluations?.enumFields?.generalWouldRecommend?.other, label: 'Other' },
                                      { id: 2, value: data?.course?.evaluations?.enumFields?.generalWouldRecommend?.yes, label: 'Yes' },
                                      { id: 3, value: data?.course?.evaluations?.enumFields?.generalWouldRecommend?.not_sure, label: 'Not Sure' },
                                  ],
                              },
                              ]}
                              sx={{
                                  [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold',
                                  },
                                }}
                              width={400}
                              height={400}
                              margin={{ top: 50, bottom: 100, left: 100, right:100 }}
                              slotProps={{
                                  legend: {
                                  //   labelStyle: {
                                  //     tableLayout: 'fixed',
                                  //   },
                                    direction: 'row',
                                    position: {
                                      horizontal: 'middle',
                                      vertical: 'bottom',
                                    },padding: 0,
                                  },
                                }}
                          /></div>
                          </Col>
                          <Col lg={6} className='flex-center'>
                          </Col>
                      </Row>
                      <hr style={{marginTop: 20, marginBottom: 20}}/>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>Please explain what you liked or disliked from the course</div>
                          </Col>
                          <Col lg={6} className='flex-center'>
                              <TableContainer sx={{ maxHeight: 200 }}>
                              <Table stickyHeader aria-label="sticky table">
                              <TableBody>
                                  {data?.course?.evaluations?.textFields?.generalLikesDislikes?.map((row) => {
                                      return (
                                      <TableRow hover>
                                          
                                              <TableCell >
                                              {row}
                                              </TableCell>
                                      </TableRow>
                                      );
                                  })}
                              </TableBody>
                              </Table>
                          </TableContainer>
                          </Col>
                      </Row>
                      <hr style={{marginTop: 20, marginBottom: 20}}/>
                      <Row style={{marginTop: 30}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>Please explain why you would or wouldn’t advise other exchange students to join this course</div>
                          </Col>
                          <Col lg={6} className='flex-center'>
                              <TableContainer sx={{ maxHeight: 200 }}>
                              <Table stickyHeader aria-label="sticky table">
                              {/* <TableHead>
                                  <TableRow>
                                  
                                      <TableCell>
                                      {}
                                      </TableCell>
                                  </TableRow>
                              </TableHead> */}
                              <TableBody>
                                  {data?.course?.evaluations?.textFields?.generalRecommendationExplanation?.map((row) => {
                                      return (
                                      <TableRow hover>
                                          
                                              <TableCell >
                                              {row}
                                              </TableCell>
                                      </TableRow>
                                      );
                                  })}
                              </TableBody>
                              </Table>
                          </TableContainer>
                          </Col>
                      </Row>
                      <hr style={{marginTop: 20, marginBottom: 20}}/>
                      <Row style={{marginTop: 12}}>
                          <Col lg={6} className='flex-center' style={{justifyContent: "flex-start"}}>
                          <div className='question-title'>Are there any other personal thoughts and experiences as an exchange student you would like to share with us?</div>
                          </Col>
                          <Col lg={6} className='flex-center'>
                              <TableContainer sx={{ maxHeight: 200 }}>
                              <Table stickyHeader aria-label="sticky table">
                              <TableBody>
                                  {data?.course?.evaluations?.textFields?.generalThoughts?.map((row) => {
                                      return (
                                      <TableRow hover>
                                          
                                              <TableCell >
                                              {row}
                                              </TableCell>
                                      </TableRow>
                                      );
                                  })}
                              </TableBody>
                              </Table>
                          </TableContainer>
                          </Col>
                      </Row>
          </div>
      </Col>
      </Row>
    )

    const ExportTab = () => (
      <>
          <h2>Evaluation info</h2>
          <EvaluationInfo />
          <h2>Background info</h2>
          <GeneralInfoTab />
          <h2>General impression</h2>
          <DocumentsTab />
          <h2>Learning methods</h2>
          <PartnerTab />
          <h2>Teacher</h2>
          <ConditionsTab />
          <h2>Cultural Differences</h2>
          <CulturalTab />
          <h2>Assessment</h2>
          <AssessmentTab />
          <h2>Evaluation</h2>
          <EvaluationTab />
          <h2>Concluding</h2>
          <ConcludingTab />
      </>
    );

    const generatePDF = () => {
      const courseId = data?.course?.course?.id || "";
      const exportTabElement = document.getElementById('export-tab');
      html2canvas(exportTabElement, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 0.7);
          const pdf = new jsPDF('p', 'mm', 'a4', true);
          const margin = 10;
          const imgWidth = 210 - 2 * margin;
          const pageHeight = 295 - 2 * margin;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = margin;

          pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
              position = heightLeft - imgHeight + margin;
              pdf.addPage();
              pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
          }

          pdf.save(`Course_Evaluations_${courseId}.pdf`);
      });
    };

    const handleExportClick = () => {
        setShowExportTab(true);
        setActiveTab('export');
        setTimeout(() => {
            generatePDF();
            setShowExportTab(false);
            setActiveTab('general-info');
        }, 1000);
    };

  return (
    <>
    <Row style={ size !== "md" && size !== "lg" && size !== "xl" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
    <Col style={{marginTop: 75}}>
        <Tabs
        id="application-info"
        >
        <Tab eventKey="home" title="EVALUATION INFO" style={{background: "#ffff"}}>
          <EvaluationInfo />
        </Tab>
        </Tabs>
    </Col>
</Row>

<Row style={size !== "md" && size !== "lg" && size !== "xl" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36}}>
            <Tabs
            id="second-tab"
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
            >
            <Tab eventKey="general-info" id="general-info" title="Background info" style={{background: "#EAECF3"}}>
              <GeneralInfoTab />
            </Tab>
            <Tab eventKey="documents" id="documents" title="General impression" style={{background: "#EAECF3"}}>
              <DocumentsTab />
            </Tab>
            <Tab eventKey="partner" id="partner" title="Learning methods" style={{background: "#EAECF3"}}>
              <PartnerTab />
            </Tab>
            <Tab eventKey="conditions" id="conditions" title="Teacher" style={{background: "#EAECF3"}}>
              <ConditionsTab />
            </Tab>
            <Tab eventKey="cultural" id="cultural" title="Cultural differences" style={{background: "#EAECF3"}}>
              <CulturalTab />
            </Tab>
            <Tab eventKey="assessment" id="assessment" title="Assessment" style={{background: "#EAECF3"}}>
              <AssessmentTab />
            </Tab>
            <Tab eventKey="evaluation" id="evaluation" title="Evaluation" style={{background: "#EAECF3"}}>
              <EvaluationTab />
            </Tab>
            <Tab eventKey="concluding" id="concluding" title="Concluding" style={{background: "#EAECF3"}}>
              <ConcludingTab />
            </Tab>
              {showExportTab && (
                <Tab eventKey="export" title="Export" style={{ background: "#fff" }}>
                  <div id="export-tab">
                    <ExportTab />
                  </div>
                </Tab>
              )}
            </Tabs>
        </Col>
    </Row>
    <Row style={size !== "md" && size !== "lg" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36, display: "flex", justifyContent: "flex-end"}}>
        <a href={`/courses/export/${id}`} target="_blank">
            <CustomButton buttonType={"secondary"} text={"EXPORT"} styles={{marginLeft: 20}} icon={faFileZipper} hasIcon={true} iconColor={COLORS.pink} 
            disabled={loading} 
            //handleClick={handleExportClick}
            /></a>
        </Col>
    </Row>

    </>
  );
}