import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import withTitle from "../../../components/withTitle";

import { useTranslation } from "react-i18next";
import GET_COURSE_PDF from "../../../graphql/queries/courses-evaluations/getCoursePdf";

const ExportPdfCourse = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const { data } = useQuery(GET_COURSE_PDF, {
    variables: {
        course_id: id,
    },
  });

  let pdf = data?.exportEvaluationsPDF?.base64PDF;

  if (pdf) {
    let byteCharacters = atob(pdf);
    let byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    const fileURL = URL.createObjectURL(file);

    return (
      <>
        <object
          style={{ width: "100%", height: "100vh" }}
          type="application/pdf"
          data={fileURL}
        >
          <p>
            {t('olaPdf.download.part1')} <a href={fileURL}>{t('olaPdf.download.part2')}</a>.
          </p>
        </object>
      </>
    );
  }
};

export default withTitle(ExportPdfCourse, "Export Course Evaluation to PDF");
