import { reducer } from '../reducers/useOlaFiltersReducer';
import { useReducer, useCallback } from 'react';
import {
    SET_ACADEMIC_YEAR_ACTION,
    SET_CREATED_BY_STUDENT_ACTION,
    SET_COUNTRY_ACTION,
    SET_ERASMUS_CODE_ACTION,
    SET_STUDENT_LAST_NAME_ACTION,
    SET_STUDENT_FIRST_NAME_ACTION,
    SET_STATUS_ACTION,
    SET_EXPAND_FILTERS_ACTION,
    SET_SELECTED_FILTERS_LIST_ACTION,
    SET_SORT_BY_ACTION,
    UPDATE_MULTIPLE_VALUES_ACTION,
    SET_ISCED_LA_ACTION
} from '../reducers/useOlaFiltersReducer';

// fallback state
export const defaultState = {
    academicYear: "",
    createdByStudent: false,
    country: [],
    erasmusCode: "",
    studentLastName: "",
    studentFirstName: "",
    status: "",
    selectedFiltersList:[],
    sortBy:"",
    expandFilters: false
}

/**
 * Used to handle filters for IIA queries
 * 
 * @param {Object} initialState the initial state of the filters, fallbacks to a default state with empty string and lists if no initial state is provided
 * @returns Filters state as well as handlers for changing the state
 */
export const useOlaFilters = (initialState = defaultState) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setAcademicYear = useCallback((data) => {
        dispatch({ type: SET_ACADEMIC_YEAR_ACTION, payload: { academicYear: data } });
    }, []);

    const setCreatedByStudent = useCallback((data) => {
        dispatch({ type: SET_CREATED_BY_STUDENT_ACTION, payload: { createdByStudent: data } });
    }, []);

    const setCountry = useCallback((data) => {
        dispatch({ type: SET_COUNTRY_ACTION, payload: { country: data } });
    }, []);

    const setErasmusCode = useCallback((data) => {
        dispatch({ type: SET_ERASMUS_CODE_ACTION, payload: { erasmusCode: data } });
    }, []);

    const setStudentFirstName = useCallback((data) => {
        dispatch({ type: SET_STUDENT_FIRST_NAME_ACTION, payload: { studentFirstName: data } });
    }, []);

    const setStudentLastName = useCallback((data) => {
        dispatch({ type: SET_STUDENT_LAST_NAME_ACTION, payload: { studentLastName: data } });
    }, []);

    const setStatus = useCallback((data) => {
        dispatch({ type: SET_STATUS_ACTION, payload: { status: data } });
    }, []);

    const setExpandFilters = useCallback((data) => {
        dispatch({ type: SET_EXPAND_FILTERS_ACTION, payload: { expandFilters: data } });
    }, []);

    const setSelectedFiltersList = useCallback((data) => {
        dispatch({ type: SET_SELECTED_FILTERS_LIST_ACTION, payload: { selectedFiltersList: data } });
    }, []);

    const setSortBy = useCallback((data) => {
        dispatch({ type: SET_SORT_BY_ACTION, payload: { sortBy: data } });
    }, []);

    const updateMultipleValues = useCallback((data) => {
        dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data })
    }, []);

    const setIscedCodeLA = useCallback((data) => {
        dispatch({ type: SET_ISCED_LA_ACTION, payload: { iscedCodeLA: data } });
    }, []);

    return {
        state,
        setAcademicYear,
        setCreatedByStudent,
        setCountry,
        setErasmusCode,
        setStudentLastName,
        setStudentFirstName,
        setStatus,
        setExpandFilters,
        setSelectedFiltersList,
        setSortBy,
        updateMultipleValues,
        setIscedCodeLA
    };
}