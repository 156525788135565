import { Form, Row, Col, Container, Spinner } from 'react-bootstrap';
import { useQuery, useMutation } from "@apollo/client";
import CustomButton from '../../components/CustomButton';
import "./index.scss";
import {
    faFloppyDisk
} from "@fortawesome/free-solid-svg-icons";
import toastConfiguration from "../../data/toastConfiguration";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import GET_DASHBOARD_EWP_SETTINGS from '../../graphql/queries/settings/getDashboardEwpSettings';
import CHANGE_EWP_API from '../../graphql/mutations/ewpSettings/changeEWPAPIs';
import { useTranslation } from 'react-i18next';


const EwpSettings = () => {
    const [apiStatic, setApiStatic] = useState(false);
    const [apiIia, setApiIia] = useState(false);
    const [apiOla, setApiOla] = useState(false);
    const [apiOunits, setApiOunits] = useState(false);
    const { t } = useTranslation();


    const {
        loading: loadingEWPSettings,
        error: errorEWPSettings,
        data: dataEWPSettings,
        refetch: refetchQuery,
    } = useQuery(GET_DASHBOARD_EWP_SETTINGS, {
        onCompleted: (data) => {
            setApiIia(data?.dashboardConnections[0]?.api_iia);
            setApiOla(data?.dashboardConnections[0]?.api_ola);
            setApiStatic(data?.dashboardConnections[0]?.api_static);
            setApiOunits(data?.dashboardConnections[0]?.api_ounits);
        }
    });


    const [updateEwpSettings] =
        useMutation(CHANGE_EWP_API);


    const handleEdit = async () => {
        const toastId = toast.loading(t('updatingSettings'), toastConfiguration);

        try {
            const result = await updateEwpSettings({
                variables: {
                    api_iia: apiIia,
                    api_ola: apiOla,
                    api_static: apiStatic,
                    api_ounits: apiOunits
                }
            });

            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: t('updatedSuccessfully'),
                    type: "success",
                    isLoading: false,
                });
                setTimeout(() => {
                    refetchQuery();
                }, "2000");
            }

        } catch (err) {
            toast.update(toastId, {
                ...toastConfiguration,
                render: t('unableToUpdate'),
                type: "error",
                isLoading: false,
            });
        }
    };

    return (
        <main className="h-auto text-center mt-4">
            {loadingEWPSettings ? <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                <Spinner
                    as="div"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="align-middle my-auto"
                />
                <span>{t('loadingMessage')}</span>
            </div> : errorEWPSettings ?
                <div className="text-center text-danger mx-auto">{t('errorMessage')}</div> :
                <div className='ewp-settings-box'>
                    <div className='ewp-settings-title'>{t('ewpSettings.header')}</div>
                    <span className='h5-style mb-4'>{t('ewpSettings.subTitle.part1')}<span style={{ color: "#3D619C" }}>{t('ewpSettings.subTitle.part2')}</span>{t('ewpSettings.subTitle.part3')}</span>
                    <div className='ewp-setting-type-title'>{t('ewpSettings.ewpSettingType.exchangeOfIIAs.title')}</div>
                    <Form.Check type="checkbox" id="agree-terms" >
                        <Form.Check.Input
                            type={"checkbox"}
                            className="ewp-settings-checkbox-label"
                            checked={apiIia}
                            onClick={() => setApiIia(!apiIia)} />
                        <Form.Check.Label> <div className="ewp-settings-checkbox-label">{t('ewpSettings.ewpSettingType.exchangeOfIIAs.checkbox')}
                        </div>
                        </Form.Check.Label>
                    </Form.Check>
                    <div className='ewp-setting-type-title'>{t('ewpSettings.ewpSettingType.exchangeOfOUnits.title')}</div>
                    <Form.Check type="checkbox" id="agree-terms" >
                        <Form.Check.Input
                            type={"checkbox"}
                            className="ewp-settings-checkbox-label"
                            checked={apiOunits}
                            onClick={() => setApiOunits(!apiOunits)} />
                        <Form.Check.Label> <div className="ewp-settings-checkbox-label">{t('ewpSettings.ewpSettingType.exchangeOfOUnits.checkbox')}
                        </div>
                        </Form.Check.Label>
                    </Form.Check>
                    <div className='ewp-setting-type-title'>{t('ewpSettings.ewpSettingType.exchangeOfStaticInfo.title')}</div>
                    <Form.Check type="checkbox" id="agree-terms" >
                        <Form.Check.Input
                            type={"checkbox"}
                            className="ewp-settings-checkbox-label"
                            checked={apiStatic}
                            onClick={() => setApiStatic(!apiStatic)}
                        />
                        <Form.Check.Label> <div className="ewp-settings-checkbox-label">
                           {t('ewpSettings.ewpSettingType.exchangeOfStaticInfo.checkbox')}
                        </div>
                        </Form.Check.Label>
                    </Form.Check>
                    <div className='ewp-setting-type-title'>{t('ewpSettings.ewpSettingType.exchangeOfOLAs.title')}</div>
                    <Form.Check type="checkbox" id="agree-terms" >
                        <Form.Check.Input
                            type={"checkbox"}
                            className="ewp-settings-checkbox-label"
                            checked={apiOla}
                            onClick={() => setApiOla(!apiOla)}
                        />
                        <Form.Check.Label> <div className="ewp-settings-checkbox-label">
                            {t('ewpSettings.ewpSettingType.exchangeOfOLAs.checkbox')}
                        </div>
                        </Form.Check.Label>
                    </Form.Check>
                    <Container>
                        <Row>
                            <Col>
                                <Col style={{ marginTop: 76, marginBottom: 10, display: "flex", justifyContent: "flex-end" }}>
                                    <CustomButton
                                        buttonType={"secondary"}
                                        text={t('ewpSettings.cancelButton')}
                                        hasIcon={false}
                                        handleClick={async() =>{
                                             await refetchQuery();
                                             setApiIia(dataEWPSettings?.dashboardConnections[0]?.api_iia);
                                             setApiOla(dataEWPSettings?.dashboardConnections[0]?.api_ola);
                                             setApiStatic(dataEWPSettings?.dashboardConnections[0]?.api_static);
                                             setApiOunits(dataEWPSettings?.dashboardConnections[0]?.api_ounits);
                                        }}
                                    />
                                    <CustomButton type="submit" buttonType={"primary"} text={t('ewpSettings.saveSettingsButton')} handleClick={handleEdit} hasIcon icon={faFloppyDisk} styles={{ marginLeft: 20 }} small />
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    /></div>}
        </main>
    );
};

export default EwpSettings;
