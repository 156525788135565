import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faCheck,
  faXmark,
  faCircleMinus
} from "@fortawesome/free-solid-svg-icons";
import COLORS from '../../../../../data/colors';
import { Table, Row, Col, Container } from 'react-bootstrap';

import { useTranslation } from "react-i18next";

const CoursesTable = ({ type, data, size }) => {
  const { t } = useTranslation();

  const shouldRenderAutoRec = (type === "preliminary-b" || type === "preliminary-c" || type === "final-b2" || type === "final-c2");


  function generateReason(reason_int, reason_txt) {
    let reason = "";
    const olaReasons = {
      1: t('coursesTable.olaReasons.part1'),
      2: t('coursesTable.olaReasons.part2'),
      3: t('coursesTable.olaReasons.part3'),
      4: t('coursesTable.olaReasons.part4'),
      5: t('coursesTable.olaReasons.part5'),
      6: t('coursesTable.olaReasons.part6'),
      7: t('coursesTable.olaReasons.part7'),
    }


    if (reason_int > 0) {
      reason = olaReasons[reason_int];
    }
    if (reason_int === 4 || reason_int === 7) {
      reason = t('coursesTable.olaReasons.other') + `(${reason_txt})`;
    }
    return reason;
  }

  const calculateECTStotal = (ects) => {
    let totalECTS = 0;
    ects.map((e) => {
      totalECTS += Number(e);
    });
    return totalECTS;
  }

  if (data?.length === 0) {
    return (
      <h5 className="text-center p-4">
        {t('coursesTable.noCourses')}
      </h5>
    )
  }

  return (
    <>
      {(size !== "md" && size !== "lg" && size !== "xl" && size !== "xxl") ?
        <>
          <div className='text-end' style={size === "xs" ? { transform: "translateY(-260%)" } : { transform: "translateY(-130%)" }}>Total ECTS credits: {calculateECTStotal(data?.map(c => c?.ects))}</div>
          <Container>
            {data?.map((course, index) => (

              <Row style={{ paddingLeft: 10 }}>
                <Col>
                  <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3}>CODE:</Col><Col>{course?.code}</Col></Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3}>SUBJECT:</Col><Col>{course?.title}</Col></Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3}>ECTS:</Col><Col>{course?.ects}</Col></Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3}>DESCRIPTION:</Col><Col>{course?.description}</Col></Row>
                  {(course?.automaticRecognition === true || course?.automaticRecognition === false) ?
                    <Row style={{ paddingTop: 15, paddingBottom: 2 }}>
                      <Col className='border-hidden label-style' xs={4} sm={3}>AUTOMATIC RECOGNITION:</Col>
                      <Col>
                        <FontAwesomeIcon
                          icon={course?.automaticRecognition ? faCheck : faXmark}
                          size="2x"
                          color={course?.automaticRecognition ? COLORS.greenAccept : COLORS.redCross}
                        />
                      </Col>
                    </Row> : course?.automaticRecognition}
                </Col>
                <Row style={{ paddingTop: 15, paddingBottom: 2 }}>
                  {data?.length - 1 !== index && <hr style={{ border: "1px solid grey" }} />}

                </Row>
              </Row>
            ))}
          </Container>
        </>
        :
        <>
          <div className='text-end' style={{ transform: "translateY(-130%)" }}>{t('coursesTable.totalECTs')}{calculateECTStotal(data?.map(c => c?.ects))}</div>
          <Table responsive>
            <thead>
              <tr>
              
                <th style={{ paddingTop: 15, paddingBottom: 2, paddingLeft: type.includes("preliminary") ? 24 : 0 }} className='border-hidden label-style col-2'>{t('coursesTable.labels.code')}</th>
                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style col-2'>{t('coursesTable.labels.subject')}</th>
                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style col-2'>{t('coursesTable.labels.ects')}</th>
                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style col-2'>{t('coursesTable.labels.description')}</th>
                <th style={{ paddingTop: 15, paddingBottom: 2 }} className='border-hidden label-style col-2'>{t('coursesTable.labels.autoRecognition')}</th>

              </tr>
            </thead>
            <tbody className='tbody-font'>
              {data?.map((course, index) => (
                <>
                  <tr>
                 
                    <td style={{ paddingTop: 10, paddingBottom: 20, paddingLeft: type.includes("preliminary") ? 24 : 0 }} className='border-hidden'>{course?.code}</td>
                    <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden col-2'>{course?.title}</td>
                    <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden col-2'>{course?.ects}</td>
                    <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden col-2'>{course?.description}</td>
                    {(course?.automaticRecognition === true || course?.automaticRecognition === false) ? <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden justify-content-center d-flex col-8'>
                      <FontAwesomeIcon
                        icon={course?.automaticRecognition ? faCheck : faXmark}
                        size="2x"
                        color={course?.automaticRecognition ? COLORS.greenAccept : COLORS.redCross}
                      />
                    </td> :
                      <td style={{ paddingTop: 10, paddingBottom: 20 }} className='border-hidden justify-content-center d-flex col-8'>
                        {course?.automaticRecognition}
                      </td>
                    }
                  </tr>
                  <tr>
                    <td colSpan="6" className='border-hidden'>
                      {data?.length - 1 !== index && <hr style={{ marginLeft: 18, marginRight: 20, border: "1px solid grey" }} />}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </>
      }
    </>
  )
}

export default CoursesTable;