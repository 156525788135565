import { useState, useEffect, useRef } from "react";

import Form from "react-bootstrap/Form";
import {
  faPlus,
  faCheck,
  faFloppyDisk,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import Table from "../../../../components/Table";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal";
import IconButton from "../../../../components/IconButton";
import Select from "../../../../components/Select";
import SelectPhoneInput from "../../../../components/SelectPhoneInput";
import { parsePhoneNumber } from "react-phone-number-input";

import COLORS from "../../../../data/colors";
import toastConfiguration from "../../../../data/toastConfiguration";
import useBreakpoint from "../../../../hooks/useBreakpoint";

import ADD_ACCOUNT from "../../../../graphql/mutations/account/addAccount";
import REMOVE_ACCOUNT from "../../../../graphql/mutations/account/removeAccount";
import UPDATE_ACCOUNT from "../../../../graphql/mutations/account/updateAccount";

import { useTranslation } from "react-i18next";

const Accounts = ({ accounts, roles, refetchAccounts }) => {
  const [size] = useBreakpoint();

  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showParsingErrorMessage, setShowParsingErrorMessage] = useState(false);

  const [selectedAccountId, setSelectedAccountId] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [accountPhoneCode, setAccountPhoneCode] = useState("");
  const [accountPhone, setAccountPhone] = useState("");
  const [accountRoleId, setAccountRoleId] = useState("");

  const formRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    setValidated(false);
  }, [
    accountName,
    accountEmail,
    accountPhoneCode,
    accountPhone,
    accountRoleId,
  ]);

  const [addAccount, { loading: loadingAddAccount }] = useMutation(ADD_ACCOUNT);

  const [removeAccount, { loading: loadingRemoveAccount }] =
    useMutation(REMOVE_ACCOUNT);

  const [updateAccount, { loading: loadingUpdateAccount }] =
    useMutation(UPDATE_ACCOUNT);

  const formIsValidated = (e) => {
    setValidated(true);
    e.preventDefault();

    if (!formRef.current.checkValidity() || !accountRoleId) {
      e.stopPropagation();
      return false;
    }

    return true;
  };

  const handleAdd = async (e) => {
    if (!formIsValidated(e)) return;

    const toastId = toast.loading(t('accountsAndAccess.accountFeedback.create.loading'), toastConfiguration);
    try {
      const result = await addAccount({
        variables: {
          name: accountName,
          email: accountEmail,
          functionId: accountRoleId,
          phone: accountPhone,
          defaultContact: "",
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.accountFeedback.create.success') + `"${accountName}"`,
        type: "success",
        isLoading: false,
      });

      refetchAccounts();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.accountFeedback.create.failure') + `"${accountName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleDelete = async (e) => {
    const toastId = toast.loading(t('accountsAndAccess.accountFeedback.delete.loading'), toastConfiguration);
    try {
      const result = await removeAccount({
        variables: {
          id: selectedAccountId,
          email: accountEmail,
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.accountFeedback.delete.success') + `"${accountName}"`,
        type: "success",
        isLoading: false,
      });

      refetchAccounts();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.accountFeedback.delete.failure') + `"${accountName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleEdit = async (e) => {
    if (!formIsValidated(e)) return;

    const toastId = toast.loading(t('accountsAndAccess.accountFeedback.update.loading'), toastConfiguration);
    try {
      const result = await updateAccount({
        variables: {
          id: selectedAccountId,
          name: accountName,
          email: accountEmail,
          functionId: accountRoleId,
          phone: accountPhone,
          defaultContact: "",
        },
      });

      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.accountFeedback.update.success') + `"${result.data.updateAccount.name}"`,
        type: "success",
        isLoading: false,
      });

      refetchAccounts();
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('accountsAndAccess.accountFeedback.update.failure') + `"${accountName}"`,
        type: "error",
        isLoading: false,
      });
    }
  };

  return (
    <>
      <Table
        data={accounts.map((account) => ({
          id: account.id,
          name: account.name,
          email: account.email,
          phone: account.phone,
          role: account.role.name,
        }))}
        page={0}
        setPage={(p) => {}}
        type={``}
        expandFilters={false}
        setExpandFilters={() => {}}
        filters={{}}
        setFilters={(filter, value) => {}}
        sortBy={""}
        setSortBy={() => {}}
        columnsPerFieldMap={{
          name: "col-3",
          email: "col-3",
          phone: "col-3",
          role: "col-3",
        }}
        rowClassName="accounts-and-access-table-row"
        headerClassName="accounts-and-access-table-header"
      >
        {accounts.map((account) => (
          <div
            key={account.name}
            className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
          >
            <IconButton
              variant="edit"
              disabled={false}
              title={t('accountsAndAccess.editAccount.buttonText')}
              onClick={(e) => {
                setSelectedAccountId(account.id);
                setAccountName(account.name);
                setAccountEmail(account.email);

                if (parsePhoneNumber(account.phone) === undefined) {
                  setShowParsingErrorMessage(true);
                  setTimeout(() => {
                    setShowParsingErrorMessage(false);
                  }, 3000);
                  setAccountPhone("");
                  setAccountPhoneCode("");
                } else {
                  setAccountPhone(account.phone);
                  setAccountPhoneCode(parsePhoneNumber(account.phone).country);
                }

                setAccountRoleId(account.role.id);
                setShowModal(true);
              }}
            />
            <IconButton
              variant="delete"
              disabled={false}
              title={t('accountsAndAccess.deleteAccount.buttonText')}
              onClick={(e) => {
                setSelectedAccountId(account.id);
                setAccountName(account.name);
                setAccountEmail(account.email);
                setAccountPhone("");
                setAccountPhoneCode("");
                setAccountRoleId("");
                setShowModal(true);
              }}
            />
          </div>
        ))}
      </Table>
      <div className="d-flex justify-content-end mx-1 mx-xxl-5">
        <CustomButton
          buttonType={"primary"}
          disabled={false}
          text={t('accountsAndAccess.addNewAccount.buttonText')}
          icon={faPlus}
          hasIcon={true}
          iconColor={COLORS.white}
          handleClick={(e) => {
            setSelectedAccountId("");
            setAccountName("");
            setAccountEmail("");
            setAccountPhone("");
            setAccountPhoneCode("");
            setAccountRoleId("");
            setShowModal(true);
          }}
        />
      </div>
      <CustomModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setSelectedAccountId("");
          setAccountName("");
          setAccountEmail("");
          setAccountPhone("");
          setAccountPhoneCode("");
          setAccountRoleId("");
          setValidated(false);
        }}
        disabledCancelButton={
          loadingAddAccount || loadingRemoveAccount || loadingUpdateAccount
        }
        body={
          (!selectedAccountId || accountRoleId) && (
            <div>
              <Form validated={validated} noValidate ref={formRef}>
                <Form.Group className="mb-3" controlId="formAccountName">
                  <Form.Label>{t('accountsAndAccess.accountForm.accountName.label')}</Form.Label>
                  <Form.Control
                    placeholder={t('accountsAndAccess.accountForm.accountName.label')}
                    value={accountName}
                    required
                    onChange={(e) => setAccountName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                  {t('accountsAndAccess.accountForm.accountName.error')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAccountEmail">
                  <Form.Label>{t('accountsAndAccess.accountForm.accountEmail.label')}</Form.Label>
                  <Form.Control
                    placeholder={t('accountsAndAccess.accountForm.accountEmail.label')}
                    type="email"
                    value={accountEmail}
                    required
                    onChange={(e) => setAccountEmail(e.target.value)}
                    disabled={selectedAccountId && accountRoleId} // disable on edit
                  />
                  <Form.Control.Feedback type="invalid">
                  {t('accountsAndAccess.accountForm.accountEmail.error')}
                  </Form.Control.Feedback>
                </Form.Group>
                <div>
                  <SelectPhoneInput
                    key="select-phone-input"
                    size={size}
                    phoneCode={accountPhoneCode}
                    showTooltip={false}
                    phone={accountPhone}
                    phoneLabel={t('accountsAndAccess.accountForm.accountPhone.label')}
                    phonePropertyName={"phone"}
                    updateMultipleValues={(newStateObject) => {
                      setAccountPhone(newStateObject.phone);
                      if ("phoneCode" in newStateObject) {
                        setAccountPhoneCode(newStateObject.phoneCode);
                      }
                    }}
                  >
                    {showParsingErrorMessage && (
                      <div className="invalid-feedback d-block">
                        {t('accountsAndAccess.accountForm.accountPhone.error')}
                      </div>
                    )}
                  </SelectPhoneInput>
                </div>
                <Form.Group className="mb-3" controlId="formRoleForAccount">
                  <Form.Label>{t('accountsAndAccess.accountForm.accountRole.label')}</Form.Label>
                  <Select
                    options={roles.map((role) => ({
                      label: role.name,
                      value: role.id,
                    }))}
                    placeholder={t('accountsAndAccess.accountForm.accountRole.placeholder')}
                    onChange={(newSelectOption) => {
                      setAccountRoleId(newSelectOption.value);
                    }}
                    value={
                      accountRoleId
                        ? {
                            label: roles.find(
                              (role) => role.id === accountRoleId
                            ).name,
                            value: accountRoleId,
                          }
                        : null
                    }
                    validated={validated}
                    invalid={!accountRoleId}
                    invalidText={t('accountsAndAccess.accountForm.accountRole.error')}
                  />
                </Form.Group>
              </Form>
            </div>
          )
        }
        button={
          !selectedAccountId ? ( // new
            <CustomButton
              buttonType={"primary"}
              disabled={loadingAddAccount}
              text={t("accountsAndAccess.addNewAccount.buttonText")}
              icon={faCheck}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleAdd}
            />
          ) : accountRoleId ? ( // edit
            <CustomButton
              buttonType={"accept"}
              disabled={loadingUpdateAccount}
              text={t("accountsAndAccess.saveChangesButton")}
              icon={faFloppyDisk}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleEdit}
            />
          ) : (
            // delete
            <CustomButton
              buttonType={"delete"}
              disabled={loadingRemoveAccount}
              text={t("accountsAndAccess.deleteAccount.buttonText")}
              icon={faTrash}
              hasIcon={true}
              iconColor={COLORS.white}
              handleClick={handleDelete}
            />
          )
        }
        title={
          !selectedAccountId ? (
            t("accountsAndAccess.addNewAccount.title")
          ) : accountRoleId ? (
            t("accountsAndAccess.editAccount.title")
          ) : (
            <>
              {t('accountsAndAccess.deleteAccount.title.part1')}
              <span className="accounts-and-access-delete-text-on-modal">
                {t('accountsAndAccess.deleteAccount.title.part2')}
              </span>
              {t('accountsAndAccess.deleteAccount.title.part3')}
              {`"${
                accounts.find((account) => account.id === selectedAccountId)
                  .name
              }"`}
            </>
          )
        }
        subtitle={
          !selectedAccountId ? (
            t("accountsAndAccess.addNewAccount.subTitle")
          ) : accountRoleId ? (
            <>
              {t("accountsAndAccess.editAccount.subTitle")}
              <span className="fw-bold">{t('accountsAndAccess.saveChangesButton')}</span>
            </>
          ) : (
            t('accountsAndAccess.deleteAccount.subTitle')
          )
        }
        hasIcon
        fullscreen
      />
    </>
  );
};

export default Accounts;
