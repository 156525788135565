import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const StudentInfo = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Row style={{ paddingBottom: 10 }}>
            <Col md={12} lg={4}>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>{t('studentInfo.firstName')}</Col><Col>{data?.shortTermMobility?.field_student_firstname}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>{t('studentInfo.lastName')}</Col><Col>{data?.shortTermMobility?.field_student_lastname}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>{t('studentInfo.email')}</Col><Col>{data?.shortTermMobility?.field_student_email}</Col></Row>
            </Col>
            <Col md={12} lg={4}>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>{t('studentInfo.birthDate')}</Col><Col>{data?.shortTermMobility?.field_student_birthdate}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>{t('studentInfo.gender')}</Col><Col>{data?.shortTermMobility?.gender?.map(g => g.name).toString()}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={4}>{t('studentInfo.nationality')}</Col><Col>{data?.shortTermMobility?.nationality?.map(g => g.name).toString()}</Col></Row>
            </Col>
            <Col md={12} lg={4}>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={6}>{t('studentInfo.foeISCED')}</Col><Col>{data?.shortTermMobility?.isced?.map(g => g.name).toString()}</Col></Row>
                <Row style={{ paddingTop: 10, paddingBottom: 2, paddingLeft: 24 }}><Col className='label-style' xs={4} sm={3} md={4} lg={5} xl={6}>{t('studentInfo.foeClarification')}</Col><Col></Col></Row>
            </Col>
        </Row>
    )
}

export default StudentInfo;