import { gql } from "@apollo/client";

const GET_COURSE_PDF = gql`
query exportEvaluationsPDF($course_id: String!)
    {exportEvaluationsPDF(course_id: $course_id){
    base64PDF
}

}`
;

export default GET_COURSE_PDF;
