import { gql } from "@apollo/client";

const GET_ISCED_CODES_LA = gql`
query IscedCodesLA
    {iscedCodesLA {
      id
      name
    }  
}`
;

export default GET_ISCED_CODES_LA