import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import GET_SINGLE_ARTICLE from '../../graphql/queries/requests/getArticle';
import './index.scss';
import { useTranslation } from 'react-i18next';

const ArticleSingleView = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_SINGLE_ARTICLE, {
    variables: { id },
  });

  useEffect(() => {
    // Scroll to the top of the page when the component mounts or when the article ID changes
    window.scrollTo(0, 0);  // Ensure it always starts at the top

  }, [id]);

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Spinner
          as="div"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className="align-middle my-auto"
        />
        <span>{t('loadingMessage')}</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-danger mx-auto">{t('errorMessage')}</div>
    );
  }

  const { article } = data;

  return (
    <div className="single-article-container">
      <h1>{article.title}</h1>
      <div className="article" dangerouslySetInnerHTML={{ __html: article.body.value }} />
      <p>{t('articleSingleView.created')}{new Date(article.created).toLocaleDateString()}</p>
      <p>{t('articleSingleView.lastUpdated')}{new Date(article.changed).toLocaleDateString()}</p>
      {article.relationships.field_image.data.length > 0 && (
        <img
          src={article.relationships.field_image.data[0].url}
          alt={article.relationships.field_image.data[0].alt}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      )}
      {article.relationships.field_tags.data.length > 0 && (
        <div className="tags">
          <h4>{t('articleSingleView.tags')}</h4>
          <ul>
            {article.relationships.field_tags.data.map((tag) => (
              <li key={tag.id}>{tag.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ArticleSingleView;
