export const SET_ACADEMIC_YEAR_ACTION = "SET_ACADEMIC_YEAR";
export const SET_COUNTRY_ACTION = "SET_COUNTRY";
export const SET_ERASMUS_CODE_ACTION = "SET_ERASMUS_CODE";
export const SET_STUDENT_LAST_NAME_ACTION = "SET_STUDENT_LAST_NAME";
export const SET_STUDENT_FIRST_NAME_ACTION = "SET_STUDENT_FIRST_NAME";
export const SET_STATUS_ACTION = "SET_STATUS";
export const SET_EXPAND_FILTERS_ACTION = "SET_EXPAND_FILTERS";
export const SET_SELECTED_FILTERS_LIST_ACTION = "SET_SELECTED_FILTERS_LIST";
export const SET_SORT_BY_ACTION = "SET_SORT_BY";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";
export const SET_ISCED_STLA_ACTION = "SET_ISCED_STLA_ACTION";

/**
 * Reducer for handling filter associated actions and state changes
 */
export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload,
    }
}