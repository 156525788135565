import { useState, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from "react-bootstrap/Spinner";
import {
    faFileZipper,
    faXmark
} from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

import COLORS from '../../../data/colors';
import { ACCOUNT_TYPES } from '../../../data/constants';
import useBreakpoint from '../../../hooks/useBreakpoint';
import useUser from '../../../hooks/useUser';

import CustomButton from '../../../components/CustomButton';
import GET_SHORT_TERM_MOBILITY from '../../../graphql/queries/short-term/getSingleShortTerm';
import ACCEPT_MOBILITY from '../../../graphql/mutations/short-term/acceptShortTerm';
import DECLINE_MOBILITY from '../../../graphql/mutations/short-term/declineShortTerm';
import EDIT_PRELIMINARY_TABLE from '../../../graphql/mutations/ola/editPreliminaryTable';
import { useQuery, useMutation } from '@apollo/client/react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import CustomTab from '../../../components/CustomTab';
import CustomModal from '../../../components/CustomModal';
import CoursesTable from './components/CoursesTable';
import LearningAgreementInfoTable from './components/LearningAgreementInfoTable';
import InstitutionInfoTable from './components/InstitutionInfoTable';
import StudentInfo from './components/StudentInfo';
import { StatusActionPair } from '../../../data/statusActionPairs';
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from '../../../data/toastConfiguration';

import { useTranslation } from 'react-i18next';

const SingleViewShortTermMobility = ({ typeOfStudent }) => {
    const { t } = useTranslation('translation', {keyPrefix: 'shortTermMobilities'});

    const [size] = useBreakpoint();
    const { id } = useParams();
    const { user } = useUser();
    let location = useLocation();
    const navigate = useNavigate();

    const [showModalReject, setShowModalReject] = useState(false);
    const [showModalSign, setShowModalSign] = useState(false);
    const [rejectionMessage, setRejectionMessage] = useState("");
    const [rejectionAutoRecMessage, setRejectionAutoRecMessage] = useState(null);
    const [editPreliminaryTableData, setEditPreliminaryTableData] = useState([]);
    const [showModalPreliminaryRejectAutoRec, setShowModalPreliminaryRejectAutoRec] = useState(false);
    const [tableRowRejectAutoRec, setTableRowRejectAutoRec] = useState();

    const handleCloseModalSign = () => setShowModalSign(false);
    const handleShowModalSign = () => setShowModalSign(true);
    const handleCloseModalReject = () => setShowModalReject(false);
    const handleShowModalReject = () => setShowModalReject(true);
    const handleCloseModalPreliminaryRejectAutoRec = () => setShowModalPreliminaryRejectAutoRec(false);
    const handleShowModalPreliminaryRejectAutoRec = () => setShowModalPreliminaryRejectAutoRec(true);

    const { loading, data, error, refetch } = useQuery(GET_SHORT_TERM_MOBILITY, {
        variables: {
            ID: id,
            pathName: typeOfStudent
        }
    });

    const [
        acceptOlaMutation,
        {
            loading: loadingAcceptOla,
            client: acceptOlaClient,
            error: errorAcceptOlaMutation,
            reset: resetAcceptOlaMutation,
        },
    ] = useMutation(ACCEPT_MOBILITY);

    const [
        declineOlaMutation, {
            loading: loadingDeclineOla,
            client: declineOlaClient,
            error: errorDeclineOlaMutation,
            reset: resetDeclineOlaMutation,
        },
    ] = useMutation(DECLINE_MOBILITY);

    const [
        editPreliminaryTableMutation,
        {
            loading: loadingEditPreliminaryTable
        }
    ] = useMutation(EDIT_PRELIMINARY_TABLE);

    useEffect(() => {
        if(data){
            if(location?.pathname.includes("outgoing")){
                if (user?.erasmusCode!==data?.shortTermMobility?.sending_institution?.field_hei_erasmus_code){
                    navigate("/unauthorized");   
                }
            }
            else{
                if (user?.erasmusCode!==data?.shortTermMobility?.receiving_institution?.field_hei_erasmus_code){
                    navigate("/unauthorized");   

                }
            }
        }
        setEditPreliminaryTableData(concatPreliminaryCourseTables());
    }, [data]);

    const concatPreliminaryCourseTables = () => {
        let tableToReturn = []
      
        if (data?.shortTermMobility?.preliminary_table_c && data?.shortTermMobility?.preliminary_table_c?.length > 0) {
            tableToReturn = tableToReturn.concat(data?.shortTermMobility?.preliminary_table_c.map(e => ({
                tableID: e?.id,
                tableType: "c",
                autoRecoValue: e?.attributes?.field_pre_tbl_c_auto_recognised,
                reason: e?.attributes?.field_pre_tbl_c_auto_comment,
                isChanged: false
            })));
        }
        return tableToReturn;
    }


    const position = typeOfStudent == "outgoing" ? t('studentType.out') : t('studentType.in');

    const shouldDisableSignOlaButtons = (ACCOUNT_TYPES.hei !== user?.type || StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.shortTermMobility?.field_la_status] !== "act" || user?.permissions.find(
        (permission) => permission.value === "managingStudents"
    ) === undefined);

    const shouldDisableSignOlaButtonRejectAutoRec = (editPreliminaryTableData.filter(i => i?.isChanged === false)?.length !== editPreliminaryTableData?.length );
   
    const shouldEditCoursesTables = (typeOfStudent === "outgoing" && StatusActionPair["ola-typeOfStudent-" + (typeOfStudent === "outgoing" ? "1-" : "0-") + data?.shortTermMobility?.field_la_status] === "act");

    const triggerEditTables = async () => {
        try {

            var tablePromise = [];
            if (editPreliminaryTableData.length > 0) {
                editPreliminaryTableData.forEach(async (item) => {
                    if (item?.isChanged) {
                        const variables = {
                            tableID: item.tableID,
                            tableType: item.tableType,
                            autoRecoValue: item.autoRecoValue,
                            reason: item.reason
                        }

                        tablePromise.push(editPreliminaryTableMutation({ variables }));
                    }
                });
            }
            
            return await Promise.all(tablePromise);
        } catch (error) {
            if (error) {
                toast.error(t('editTableFail'), toastConfiguration);
            }
        }
    }

    const handleSignButtonClick = async () => {
        const toastId = toast.loading(t('signFeedback.loading'), toastConfiguration);
        try {

            let result = await acceptOlaMutation({
                "variables": {
                    "email": user?.email,
                    "institution": typeOfStudent === "outgoing" ? 1 : 2,
                    "name": user?.userName ? user?.userName : user?.email,
                    "shortTermID": data?.shortTermMobility?.shortTermID,
                    "position": position
                }
            });
            handleCloseModalSign()
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: t('signFeedback.success'),
                    type: "success",
                    isLoading: false,
                });
                refetch();
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: t('signFeedback.failure'),
                    type: "error",
                    isLoading: false,
                });
            }
        }
    };


    const handleDeclineButtonClick = async () => {
        const toastId = toast.loading(t('declineOlaFeedback.loading'), toastConfiguration);
        try {
            await triggerEditTables();

            let result = await declineOlaMutation({
                "variables": {
                    "email": user?.email,
                    "institution": typeOfStudent === "outgoing" ? 1 : 2,
                    "name": user?.userName ? user?.userName : user?.email,
                    "shortTermID": data?.shortTermMobility?.shortTermID,
                    "reason": rejectionMessage,
                    "position": position
                }
            });
            handleCloseModalReject()
            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: t('declineOlaFeedback.success'),
                    type: "success",
                    isLoading: false,
                });
                refetch();
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: t('declineOlaFeedback.failure'),
                    type: "error",
                    isLoading: false,
                });
            }
        }
    };

    const handleEditPreliminaryTableReason = () => {
        let tempEditPreliminaryTable = editPreliminaryTableData;
        tempEditPreliminaryTable = tempEditPreliminaryTable.map(row => {
            if (row?.tableID === tableRowRejectAutoRec) {
                row["autoRecoValue"] = !row["autoRecoValue"];
                row["isChanged"] = true;
                row["reason"] = rejectionAutoRecMessage;
            }
            return row;
        })
        setEditPreliminaryTableData(tempEditPreliminaryTable);
        setRejectionAutoRecMessage(null);
        handleCloseModalPreliminaryRejectAutoRec();
    }

    const handleEditPreliminaryTable = (tableRow) => {
        let tempEditPreliminaryTable = editPreliminaryTableData;
        tempEditPreliminaryTable = tempEditPreliminaryTable.map(row => {
            if (row?.tableID === tableRow) {
                if (row["autoRecoValue"]) {
                    handleShowModalPreliminaryRejectAutoRec();
                    setTableRowRejectAutoRec(tableRow);
                }
                else {
                    row["autoRecoValue"] = true;
                    row["reason"] = null;
                    row["isChanged"] = true;
                }
                setTableRowRejectAutoRec(tableRow);
            }
            return row;
        })
        setEditPreliminaryTableData(tempEditPreliminaryTable);
    }

    if (error)
        return (
            <div className="text-center text-danger mx-auto">{t('errorMessage')}</div>
        );


    if (loading) {
        return (
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                <Spinner
                    as="div"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="align-middle my-auto"
                />
                <span>{t('loadingMessage')}</span>
            </div>
        )
    }

    return (
        <>
            <Row style={size !== "md" && size !== "lg" && size !== "xl" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                <Col style={{ marginTop: 75 }}>
                    <Tabs
                        id="application-info"
                    >
                        <CustomTab eventKey="home" title={t('LearningAgreementInfo.title')} style={{ background: "#ffff" }}>
                            <LearningAgreementInfoTable typeOfStudent={typeOfStudent} data={data} size={size} />
                        </CustomTab>
                    </Tabs>
                </Col>
            </Row>
            <Row style={size !== "md" && size !== "lg" && size !== "xl" ? { marginLeft: 35, marginRight: 35 } : { marginLeft: 0, marginRight: 0 }}>
                <Col style={{ marginTop: 36 }}>
                    <Tabs id="second-tab">
                        <CustomTab eventKey="general-info" id="general-info" title={t('generalInfo.title')} style={{ background: "#EAECF3" }}>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 36, marginBottom: 10 }}>
                                    <Tabs
                                        defaultActiveKey="home"
                                        id="student-info"
                                    >
                                        <CustomTab eventKey="home" title={t('generalInfo.studentPersonalData.title')} style={{ background: "#ffff" }}>
                                            <StudentInfo data={data} />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col md={6} style={{ marginTop: 33, marginBottom: 10 }}>
                                    <Tabs
                                        defaultActiveKey="home"
                                        id="sending-hei"
                                    >
                                        <CustomTab eventKey="home" title={t('generalInfo.sendingInstInfo.title')} style={{ background: "#ffff" }}>
                                            <InstitutionInfoTable size={size} institution={data?.shortTermMobility?.sending_institution} contact_person={data?.shortTermMobility?.sending_contact_person} resp_person={data?.shortTermMobility?.sending_resp_person} />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                                <Col md={6} style={{ marginTop: 33, marginBottom: 36 }}>
                                    <Tabs defaultActiveKey="home" id="receiving-hei">
                                        <CustomTab eventKey="home" title={t('generalInfo.receivingInstInfo.title')} style={{ background: "#ffff" }}>
                                            <InstitutionInfoTable size={size} institution={data?.shortTermMobility?.receiving_institution} contact_person={data?.shortTermMobility?.receiving_contact_person} resp_person={data?.shortTermMobility?.receiving_resp_person} />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row >
                        </CustomTab>
                        <CustomTab eventKey="courses" id="courses" title={t('courses.title')} style={{ background: "#EAECF3" }}>
                            <Row style={{ marginLeft: 5, marginRight: 5 }}>
                                <Col style={{ marginTop: 36, marginBottom: 10 }}>
                                    <Tabs defaultActiveKey="table-c" id="courses">
                                        <CustomTab eventKey="table-c" title={t('courses.table')} style={{ background: "#ffff" }}>
                                            <CoursesTable type={"preliminary-c"} data={
                                                data?.shortTermMobility?.preliminary_table_c?.map(
                                                    (course) => ({
                                                        code: course?.attributes?.field_pre_tbl_c_code,
                                                        title: course?.attributes?.field_pre_tbl_c_title,
                                                        ects: course?.attributes?.field_pre_tbl_c_ects,
                                                        description: course?.attributes?.field_pre_tbl_c_virt_comp_descr,
                                                        automaticRecognition: shouldEditCoursesTables ? (!editPreliminaryTableData.find(i => i.tableID === course?.id)?.isChanged ?
                                                        <Form.Check type="checkbox" id="check-authorizations">
                                                            <Form.Check.Input type="checkbox" checked={editPreliminaryTableData.find(i => i.tableID === course?.id)?.autoRecoValue} onClick={() => handleEditPreliminaryTable(course?.id)} />
                                                        </Form.Check> : editPreliminaryTableData.find(i => i.tableID === course?.id)?.autoRecoValue) : course?.attributes?.field_pre_tbl_c_auto_recognised,                                                        
                                                        // semester: data?.learningAgreement?.semester_map?.find(s => s?.id === course?.semester?.data?.id)?.name
                                                    })
                                                )
                                            }
                                                size={size}
                                            />
                                        </CustomTab>
                                    </Tabs>
                                </Col>
                            </Row>
                        </CustomTab>
                    </Tabs>
                </Col>
                {!(size !== "md" && size !== "lg" && size !== "xl" && size !== "xxl") ?
                    <Row style={{ marginLeft: 35, marginRight: 35 }}>
                        <Col style={{ marginTop: 36, display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton disabled={shouldDisableSignOlaButtons} buttonType={"decline"} text={t('modalReject.declineButton')} icon={faXmark} hasIcon={true} iconColor={COLORS.white} handleClick={handleShowModalReject} />
                            <CustomButton disabled={shouldDisableSignOlaButtons || shouldDisableSignOlaButtonRejectAutoRec} buttonType={"accept"} text={t('modalSign.signButton')} styles={{ marginLeft: 20 }} icon={faFileZipper} hasIcon={true} iconColor={COLORS.white} handleClick={handleShowModalSign} />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col style={{ marginTop: 15, paddingLeft: 0, paddingRight: 0 }} xs={6} sm={6}>
                            <div className="col-12">
                                <CustomButton
                                    styles={{
                                        width: "100%"
                                    }}
                                    disabled={shouldDisableSignOlaButtons}
                                    buttonType={"decline"} text={"Decline"} icon={faXmark} hasIcon={true} iconColor={COLORS.white} handleClick={handleShowModalReject} />
                            </div>
                        </Col>
                        <Col style={{ marginTop: 15, paddingLeft: 5, display: "flex", justifyContent: "flex-end", paddingRight: 0 }} xs={6} sm={6}>
                            <div className="col-12">
                                <CustomButton buttonType={"accept"} text={"Sign OLA"} icon={faFileZipper} hasIcon={true} iconColor={COLORS.white} handleClick={handleShowModalSign}
                                    styles={{
                                        width: "100%"
                                    }}
                                    disabled={shouldDisableSignOlaButtons || shouldDisableSignOlaButtonRejectAutoRec}
                                />
                            </div>
                        </Col>
                    </Row>}
            </Row>
            <CustomModal show={showModalReject} handleClose={handleCloseModalReject}
                fullscreen={false}
                body={<div>{t('modalReject.body.part1')}<br /><div style={{ background: "#EAECF3", padding: "5%" }}><h6 className='h6-style'>{t('modalReject.body.part2')}</h6><textarea style={{ width: "100%" }} rows={3} value={rejectionMessage} onChange={(e) => setRejectionMessage(e.target.value)} /><h6 style={{ color: "#6C757D" }}>{t('modalReject.body.part3')}</h6></div></div>}
                button={<CustomButton disabled={!rejectionMessage || loadingDeclineOla} buttonType={"decline"} text={t('modalReject.declineButton')} icon={faXmark} hasIcon={true} iconColor={COLORS.white} handleClick={handleDeclineButtonClick} />}
                title={<div>{t('modalReject.title.part1')}<span style={{ color: "red" }}>{t('modalReject.title.part2')}</span>{t('modalReject.title.part3')}</div>} />
            <CustomModal show={showModalSign} handleClose={handleCloseModalSign}
                fullscreen={false}
                body={<div>{t('modalSign.body')}</div>}
                button={<CustomButton disabled={loadingAcceptOla} buttonType={"accept"} text={t('modalSign.signButton')} hasIcon={true} iconColor={COLORS.white} icon={faFileZipper} handleClick={handleSignButtonClick} />}
                title={<div style={{ paddingBottom: "20px" }}>{t('modalSign.title.part1')}<span style={{ color: "green" }}>{t('modalSign.title.part2')}</span>{t('modalSign.title.part3')}</div>} />
            <CustomModal show={showModalPreliminaryRejectAutoRec} handleClose={handleCloseModalPreliminaryRejectAutoRec}
                fullscreen={false}
                body={<div>{t('modalPrelimReject.body.part1')}<br /><div style={{ background: "#EAECF3", padding: "5%" }}><h6 className='h6-style'>{t('modalPrelimReject.body.part2')}</h6><textarea style={{ width: "100%" }} rows={3} value={rejectionAutoRecMessage} onChange={(e) => setRejectionAutoRecMessage(e.target.value)} /></div></div>}
                button={<CustomButton disabled={!rejectionAutoRecMessage} buttonType={"accept"} text={t('modalPrelimReject.confirmButton')} handleClick={handleEditPreliminaryTableReason} />}
                title={<div />} />
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>)
};

export default SingleViewShortTermMobility;