import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import GET_SHORT_TERM_PDF from '../../../../../graphql/queries/short-term/shortTermPdf';
import withTitle from '../../../../../components/withTitle';
import { useTranslation } from 'react-i18next';

const ExportShortTermPdf = () => {
    const { t } = useTranslation();

    const { uuid } = useParams();


    const { loading, data, error } = useQuery(GET_SHORT_TERM_PDF, {
        variables: {
            ID: uuid
        }
    });

    if (error) {
        return t('errorMessage');
    }

    if (!loading) {

        let pdf = data?.shortTermLAPdf?.file;

        if (pdf) {
            let byteCharacters = atob(pdf);
            let byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: "application/pdf;base64" });
            const fileURL = URL.createObjectURL(file);

            return (
                <>
                    <object
                        style={{ width: "100%", height: "100vh" }}
                        type="application/pdf"
                        data={fileURL}
                    >
                        <p>{t('olaPdf.download.part1')}<Link to={fileURL}>{t('olaPdf.download.part2')}</Link>.</p>
                    </object>
                </>
            );
        }
    }



};

export default withTitle(ExportShortTermPdf, "Export Short Term Mobility to PDF");