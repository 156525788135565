import { useQuery } from '@apollo/client/react';

import { Form, Row, Col } from "react-bootstrap";
import {
    faDownload,
    
} from "@fortawesome/free-solid-svg-icons";
import CustomButton from '../../../components/CustomButton';

import COLORS from '../../../data/colors';
import GET_EXPORT_CHECK from '../../../graphql/queries/IIAs/getExportCheck';
import GET_EXPORT from '../../../graphql/queries/IIAs/getExport';

import { useTranslation } from 'react-i18next';

const ExportIIAs = () => {    
    const { t } = useTranslation('translation', {keyPrefix: 'iiaExport'});

    const {
        data: dataExportCheck,
        loading: loadingExportCheck
    } = useQuery(GET_EXPORT_CHECK);


    const { data, loading } = useQuery(GET_EXPORT, {
        
    });


    function exportToCsv() {
        let byteCharacters = atob(data?.iiaBulkCsv);
        let byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        var blob = new Blob([byteArray], { type: 'text/csv;base64' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, "iias.csv");
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "iias.csv");
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    return (
        <div className="d-flex justify-content-between mx-5 pt-4 pt-xxl-5">
            <Form noValidate validated={true} id={"upload-ola-form"}>
                <h3 className='h3-upload'>
                    {t('header')}
                </h3>
                <p className='body-upload'>
                {t('description')}
                </p>
                <Row>
                    <Col style={{ marginTop: 36, display: "flex", justifyContent: "flex-end" }}>
                    <div style={{justifyContent: "center", display: "flex", flexDirection: "column", marginRight: 20}} className='body-upload'>{dataExportCheck?.iiaBulkCsvCheck?.exists && t('message.part1')}{dataExportCheck?.iiaBulkCsvCheck?.exists && dataExportCheck?.iiaBulkCsvCheck?.date} {dataExportCheck?.iiaBulkCsvCheck?.exists && t('message.part2')}
                    </div>
                        <CustomButton disabled={!(dataExportCheck?.iiaBulkCsvCheck?.exists) || loading} buttonType={"primary"} small={true} text={t('downloadButton')} hasIcon={true} iconColor={COLORS.white} icon={faDownload} handleClick={exportToCsv} />
                    </Col>
                </Row>
            </Form>
        </div>)
};

export default ExportIIAs;