import { useQuery } from "@apollo/client";
import CustomTable from "../../../components/Table";
import Filters from "../../../components/Filters";
import { useEffect, useState } from "react";
import GET_OUTGOING_NOMINATION_LISTING from "../../../graphql/queries/nomination/getOutgoingNominationListing";
import { useLocation, useNavigate } from "react-router-dom";
import GET_INCOMING_NOMINATION_LISTING from "../../../graphql/queries/nomination/getIncomingNominationListing";
import TableSkeleton from "../../../components/Table/components/TableSkeleton";
import { useNominationsFilters } from "./hooks/useNominationsFilters";
import GET_NOMINATIONS_COUNT from "../../../graphql/queries/nomination/getNominationsCount";
import IconButton from "../../../components/IconButton";
import "./index.scss";
import ExportZip from "../ExportZip";
import { useTranslation } from 'react-i18next';

const NominationsList = ({ userHeiID, userEC }) => {
  const { t } = useTranslation();

  const academicFilter = () => new Date().getMonth() < 6 ?  (new Date().getFullYear() - 1) + "/" + new Date().getFullYear() : new Date().getFullYear() + "/" + (new Date().getFullYear() + 1)
  const initialState = {
    academicYear: "",
    academicTerm: "",
    status: "",
    erasmusCode: "",
    studentName: "",
    studentEmail: "",
    sortBy: "changed_descending",
    studentEQF: "",
    studentISCED: "",
    selectedFiltersList: localStorage.getItem('selectedFiltersNominations') !== null ? JSON.parse(localStorage.getItem('selectedFiltersNominations')) : {
      erasmusCode: "",
      status: "",
      academicTerm: "",
      academicYear: academicFilter(),
      studentName: "",
      studentEQF: "",
      studentISCED: "",
      studentEmail: "",
    },
  };

  const filters = useNominationsFilters(initialState);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    state,
    setSelectedFiltersList,
    setExpandFilters,
    setSortBy,
    updateMultipleValues,
  } = filters;

  const {
    academicYear,
    academicTerm,
    status,
    erasmusCode,
    studentName,
    studentEmail,
    studentEQF,
    studentISCED,
    selectedFiltersList,
    sortBy,
    expandFilters,
  } = state;

  const [page, setPage] = useState(localStorage.getItem('pageNominations') !== null ? parseInt(JSON.parse(localStorage.getItem('pageNominations'))) : 0);

  const { loading, error, data, refetch } = useQuery(
    location.pathname.includes("outgoing")
      ? GET_OUTGOING_NOMINATION_LISTING
      : GET_INCOMING_NOMINATION_LISTING,
    {
      variables: {
        page: page,
        sortBy: sortBy,
        OptionalNominationsFilters: {
          academicYear: selectedFiltersList.academicYear,
          academicTerm: selectedFiltersList.academicTerm,
          nominationStatus: selectedFiltersList.status,
          ...(location.pathname.includes("outgoing")
            ? { hostErasmusCode: selectedFiltersList.erasmusCode }
            : { homeErasmusCode: selectedFiltersList.erasmusCode }),
          studentName: selectedFiltersList.studentName,
          studentEmail: selectedFiltersList.studentEmail,
          studentEQF: selectedFiltersList.studentEQF,
          studentISCED: selectedFiltersList.studentISCED,
        },
        fetchPolicy: "cache-and-network",
      },
    }
  );

  const {
    loading: nominationsCountLoading,
    error: nominationsCountError,
    data: nominationsCount,
    refetch: nominationsCountRefetch,
  } = useQuery(GET_NOMINATIONS_COUNT, {
    variables: {
      homeHei: location.pathname.includes("outgoing") ? userEC : "",
      hostHei: location.pathname.includes("incoming") ? userEC : "",
      academicYear: academicYear,
      status: status,
      studentName: studentName,
      studentEmail: studentEmail,
      erasmusCode: erasmusCode,
      studentEQF: studentEQF,
      studentISCED: studentISCED,
      academicTerm: academicTerm,
    },
    fetchPolicy: "cache-and-network",
  });


  const handleExpand = (value) => {
    setExpandFilters(value);
  };

  useEffect(() => {
    const data = localStorage.getItem('selectedFiltersNominations');
  
    if (data) {
      if (!Object.values(JSON.parse(data) || {})?.every(x => x === '')) {
        setSelectedFiltersList(JSON.parse(data));
        refetch();
        nominationsCountRefetch();
      }
      
    }
  }, []);

  useEffect(() => {
    page !== null && localStorage.setItem('pageNominations', JSON.stringify(page));
  }, [page]);

  useEffect(() => {
    localStorage.setItem('selectedFiltersNominations', JSON.stringify(selectedFiltersList));
  }, [selectedFiltersList]);

  useEffect(() => {
    updateMultipleValues(selectedFiltersList);
    refetch();
    nominationsCountRefetch();
  }, [selectedFiltersList, updateMultipleValues, refetch, sortBy]);

  const handleRemoveFilter = (filter, value) => {
    if (filter === "country") {
      let temp = Object.assign({}, selectedFiltersList);
      temp[filter] = temp[filter].filter((i) => i !== value);
      setSelectedFiltersList(temp);
    } else {
      let temp = {...selectedFiltersList};
      temp[filter] = "";
      setSelectedFiltersList(temp);
    }
  };

  if (loading || nominationsCountLoading) {
    return (
      <>
        <TableSkeleton
          columnsPerFieldMap={{
            name: "col-2",
            email: "col-2",
            study_level: "col-2",
            study_field: "col-2",
            academic_year: "col-2 col-xl-2",
            academic_term: "col-1",
            status: "col-1",
          }}
        />
      </>
    );
  }

  if (error || nominationsCountError)
    return (
      <div className="text-center text-danger mx-auto">{t('errorMessage')}</div>
    );

  return (
    <main className="h-auto text-center mt-4">
      <CustomTable
        data={
          data?.incomingNominations
            ? data?.incomingNominations?.nominationList.map(
                ({
                  id,
                  field_student_name,
                  field_student_email,
                  field_student_isced,
                  field_student_eqf,
                  field_academic_year,
                  field_academic_term,
                  field_nomination_status,
                }) => ({
                  id: id,
                  name: field_student_name,
                  email: field_student_email,
                  study_level: field_student_eqf,
                  study_field: field_student_isced,
                  academic_year: field_academic_year,
                  academic_term: field_academic_term,
                  status: field_nomination_status,
                })
              )
            : data?.outgoingNominations?.nominationList.map(
                ({
                  id,
                  field_student_name,
                  field_student_email,
                  field_student_isced,
                  field_student_eqf,
                  field_academic_year,
                  field_academic_term,
                  field_nomination_status,
                }) => ({
                  id: id,
                  name: field_student_name,
                  email: field_student_email,
                  study_level: field_student_eqf,
                  study_field: field_student_isced,
                  academic_year: field_academic_year,
                  academic_term: field_academic_term,
                  status: field_nomination_status,
                })
              )
        }
        page={page}
        setPage={(p) => setPage(p)}
        type={`nominations-${
          data?.incomingNominations ? "incoming-" : "outgoing-"
        }`}
        expandFilters={expandFilters}
        setExpandFilters={(e) => setExpandFilters(e)}
        filters={selectedFiltersList}
        setFilters={(filter, value) => {handleRemoveFilter(filter, value); setPage(0);}}
        sortBy={sortBy}
        hasFilters={true}
        pageLimit={30}
        totalCount={nominationsCount?.nominationsCount}
        hasPagination={true}
        setSortBy={() => setSortBy("")}
        columnsPerFieldMap={{
          name: "col-2",
          email: "col-2",
          study_level: "col-1",
          study_field: "col-3",
          academic_year: "col-1 col-xl-1",
          academic_term: "col-2",
          status: "col-1",
        }}
        onClickHandlers={
          data?.incomingNominations
            ? data?.incomingNominations?.nominationList.map(({ id }) => (e) => {
                navigate("/applications-for-erasmus+/incoming-students/" + id);
              })
            : data?.outgoingNominations?.nominationList.map(({ id }) => (e) => {
                navigate("/applications-for-erasmus+/outgoing-students/" + id);
              })
        }
        rowClassName="reusable-components-table-row-nominations"
        headerClassName="reusable-components-table-header-nominations"
        customTitle={t('nominationList.totalApplications')}
      >
        {data?.incomingNominations ? 
                data?.incomingNominations?.nominationList.map((element, index) => (
                  <div
                    key={element?.id || index}
                    className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
                  >
                    <ExportZip id={element?.id} studentName={element?.field_student_name} title={t('nominationList.downloadFiles')}/>
                  </div>
        )) : data?.outgoingNominations?.nominationList.map((element, index) => (
          <div
            key={element?.id || index}
            className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
          >
            <ExportZip id={element?.id} studentName={element?.field_student_name} title={t('nominationList.downloadFiles')}/>
          </div>
      ))}
      </CustomTable>
      <Filters
        expand={expandFilters}
        countries={[]}
        handleExpand={handleExpand}
        selectedFiltersList={selectedFiltersList}
        setSelectedFiltersList={(e) => {setSelectedFiltersList(e); setPage(0);}}
        sortBy={sortBy}
        setSortBy={(e) => setSortBy(e)}
        type={
          data?.incomingNominations
            ? "nominations-incoming"
            : "nominations-outgoing"
        }
      />
    </main>
  );
};

export default NominationsList;
