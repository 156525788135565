import { gql } from "@apollo/client";

const GET_LA_COUNT = gql`
  query laCount(
        $homeHeiID: String, 
        $hostHeiID: String, 
        $academicYear: String, 
        $status: String, 
        $studentFirstName: String,
        $studentLastName: String, 
        $erasmusCode: String, 
        $createdBy: String, 
        $country: [String],
        $faculty: String,
        $iscedId: String
     ){
        laCount(
            homeHeiID: $homeHeiID,
            hostHeiID: $hostHeiID,
            academicYear: $academicYear,
            status: $status,
            studentFirstName: $studentFirstName,
            studentLastName: $studentLastName,
            erasmusCode: $erasmusCode,
            createdBy: $createdBy,
            country: $country,
            faculty: $faculty,
            iscedId: $iscedId
        )
      
        
    
      
    }`
    ;

export default GET_LA_COUNT;
