import { gql } from "@apollo/client";

const GET_OUNIT = gql`
  query ounit($heiID: String!, $ounitID: String!) {
    ounit(heiID: $heiID, ounitID: $ounitID) {
      id
      abbreviation
      ounit_id
      ounit_code
      ounit_name {
        name
        language
      }
      parent_ounit_id
      street_address
      mailing_address
      website_url
      logo_url
      mobility_factsheet_url
      ounit_contact {
        contact_names
        emails
      }
    }
  }
`;

export default GET_OUNIT;
