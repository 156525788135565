import { Form, Row, Col } from 'react-bootstrap';
import { NetworkStatus } from '@apollo/client';
import { useQuery, useMutation } from "@apollo/client";
import CustomModal from '../../components/CustomModal';
import CustomButton from '../../components/CustomButton';
import "./index.scss";

import GET_OUNITS from "../../graphql/queries/IIAs/getOUnits";
import ADD_OUNIT from '../../graphql/mutations/organizationalUnits/addOunit';
import UPDATE_OUNIT from '../../graphql/mutations/organizationalUnits/updateOnit';
import DELETE_OUNIT from '../../graphql/mutations/organizationalUnits/deleteOunit';

import CustomTable from "../../components/Table";
import TableSkeleton from "../../components/Table/components/TableSkeleton";

import toastConfiguration from "../../data/toastConfiguration";
import { ToastContainer, toast } from "react-toastify";

import { useState, useRef } from "react";
import IconButton from "../../components/IconButton";
import {
  faPlus,
  faCheck,
  faFloppyDisk,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import COLORS from "../../data/colors";
import Select from "../../components/Select";
import { languagesvalues } from '../Nominations/Requirements/Taxonomy';

import { useTranslation } from 'react-i18next';


const OrganizationalUnits = ({ userHeiID }) => {
  const formRef = useRef(null);
  const [ounitListingData,setOunitListingData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ounitName, setOunitName] = useState("");
  const [ounitId, setOunitId] = useState("");
  const [ounitCode, setOunitCode] = useState("");
  const [ounitNameLanguage, setOunitNameLanguage] = useState("");
  const [ounitAbbreviation, setOunitAbbreviation] = useState("");
  const [validated, setValidated] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [ounitContactNames, setOunitContactNames] = useState("");
  const [ounitContactEmails, setOunitContactEmails] = useState("");
  const [incomingContactNames, setIncomingContactNames] = useState("");
  const [incomingContactEmails, setIncomingContactEmails] = useState("");
  const [isIncomingDifferent, setIsIncomingDifferent] = useState(false);

  const { t } = useTranslation();


  const {
    loading: ounitListingLoading,
    error: ounitListingError,
    refetch: refetchOunits,
  } = useQuery(GET_OUNITS, {
    notifyOnNetworkStatusChange: true,
    variables: { heiID: userHeiID },
        // When request is completed, we set the state
        onCompleted: (data) => {
          setOunitListingData(data)
        }
  });

  const [addOunit, { loading: loadingAddOunit }] = useMutation(ADD_OUNIT);

  const [removeOunit, { loading: loadingRemoveOunit }] =
    useMutation(DELETE_OUNIT);

  const [updateOunit, { loading: loadingUpdateOunit }] =
    useMutation(UPDATE_OUNIT);

  const formIsValidated = (e) => {
    setValidated(true);
    e.preventDefault();

    if (!formRef.current.checkValidity()) {
      e.stopPropagation();
      return false;
    }

    return true;
  };

  const handleAdd = async (e) => {
    if (!formIsValidated(e)) return;
    setShowModal(false);

    const toastId = toast.loading(t('organizationalUnits.unitFeedback.create.loading'), toastConfiguration);
    try {
      let result;
      const outgoingContact = {
        contact_names: ounitContactNames ? [{ value: ounitContactNames }] : [],
        emails: ounitContactEmails ? [ounitContactEmails] : [],
      };
  
      const incomingContact = isIncomingDifferent
        ? {
            contact_names: incomingContactNames ? [{ value: incomingContactNames }] : [],
            emails: incomingContactEmails ? [incomingContactEmails] : [],
          }
        : null;

      const contacts =
        outgoingContact.contact_names.length === 0 &&
        outgoingContact.emails.length === 0 &&
        (!incomingContact || (incomingContact.contact_names.length === 0 && incomingContact.emails.length === 0))
          ? []
          : [outgoingContact, incomingContact].filter(Boolean);
      result = await addOunit({
        variables: {
          oUnitData: {
            abbreviation: ounitAbbreviation || "",
            name:
              [{
                value: ounitName,
                language: ounitNameLanguage || ""
              }],
              contact: contacts
          }

        }
      }
      );
      setOunitName("");
      setOunitNameLanguage("");
      setOunitAbbreviation("");
      setOunitContactNames("");
      setOunitContactEmails("");
      setIncomingContactNames("");
      setIncomingContactEmails("");
      setIsIncomingDifferent(false);
      setValidated(false);
      if (!result?.errors) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: t('organizationalUnits.unitFeedback.create.success') + `"${ounitName}"`,
          type: "success",
          isLoading: false,
        });
        refetchOunits();
      }
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('organizationalUnits.unitFeedback.create.failure'),
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleDelete = async (e) => {
    setShowModal(false);
    const toastId = toast.loading(t('organizationalUnits.unitFeedback.delete.loading'), toastConfiguration);
    try {
      const result = await removeOunit({
        variables: {
          ounit_id: ounitId,
        },
      });
      setValidated(false);
      if (!result?.errors) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: t('organizationalUnits.unitFeedback.delete.success'),
          type: "success",
          isLoading: false,
        });
        refetchOunits();
        
      }
    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('organizationalUnits.unitFeedback.delete.failure'),
        type: "error",
        isLoading: false,
      });
    }
  };

  const handleEdit = async (e) => {
    if (!formIsValidated(e)) return;
    setShowModal(false);
    const toastId = toast.loading(t('organizationalUnits.unitFeedback.update.loading'), toastConfiguration);

    try {
      const outgoingContact = {
        contact_names: ounitContactNames ? [{ value: ounitContactNames }] : [],
        emails: ounitContactEmails ? (Array.isArray(ounitContactEmails) ? ounitContactEmails : [ounitContactEmails]) : [],
      };
  
      const incomingContact = isIncomingDifferent
        ? {
            contact_names: incomingContactNames ? [{ value: incomingContactNames }] : [],
            emails: incomingContactEmails ? (Array.isArray(incomingContactEmails) ? incomingContactEmails : [incomingContactEmails]) : [],
          }
        : null;
  
      // If both Outgoing and Incoming contacts are empty, set contacts to an empty array
      const contacts =
        outgoingContact.contact_names.length === 0 &&
        outgoingContact.emails.length === 0 &&
        (!incomingContact || (incomingContact.contact_names.length === 0 && incomingContact.emails.length === 0))
          ? []
          : [outgoingContact, incomingContact].filter(Boolean);
      const result = await updateOunit({
        variables: {
          oUnitData: {
            abbreviation: ounitAbbreviation || "",
            name:
              [{
                value: ounitName,
                language: ounitNameLanguage || ""
              }],
            ounit_id: ounitId,
            ounit_code: ounitCode,
            contact: contacts
          }

        }
      });
      setOunitName("");
      setOunitNameLanguage("");
      setOunitAbbreviation("");
      setOunitContactNames("");
      setOunitContactEmails("");
      setIncomingContactNames("");
      setIncomingContactEmails("");
      setIsIncomingDifferent(false);
      setValidated(false);
      if (!result?.errors) {
        toast.update(toastId, {
          ...toastConfiguration,
          render: t('organizationalUnits.unitFeedback.update.success') + `"${ounitName}"`,
          type: "success",
          isLoading: false,
        });
        refetchOunits(); 
      }

    } catch (err) {
      toast.update(toastId, {
        ...toastConfiguration,
        render: t('organizationalUnits.unitFeedback.update.failure'),
        type: "error",
        isLoading: false,
      });
    }
  };


  return (
    <main className="h-auto text-center mt-4">
      {ounitListingLoading ? <TableSkeleton
        columnsPerFieldMap={{
          id_: "col-4",
          partner: "col-4",
          last_edited: "col-4",

        }}
      /> : ounitListingError ?
        <div className="text-center text-danger mx-auto">{t('errorMessage')}</div> :
        <>
          <CustomTable
            type={"ounit-"}
            columnsPerFieldMap={{
              ounit_name_language: "col-3",
              ounit_name: "col-3",
              abbreviation: "col-3",
              contact: "col-3"
              
            }}
            hasPagination={false}
            hasFilters={false}
            rowClassName="ounits-listing-table-row"
            headerClassName="ounits-listing-table-header"

            data={ounitListingData?.ounits?.map(
              ({
                ounit_name,
                abbreviation,
                ounit_contact
              }) => ({
                ounit_name_language: ounit_name?.language ? ounit_name.language : "",
                ounit_name: ounit_name?.name,
                abbreviation: abbreviation,
                contact: (
                  <div>
                    {/* Outgoing Contact */}
                    {(ounit_contact?.[0]?.contact_names || (ounit_contact?.[0]?.emails && ounit_contact[0].emails.length > 0)) && (
                      <>
                        <strong>Outgoing LAs Contact:</strong>
                        {ounit_contact?.[0]?.contact_names && <div>{ounit_contact[0].contact_names}</div>}
                        {ounit_contact?.[0]?.emails && ounit_contact[0].emails.length > 0 && <div>{ounit_contact[0].emails}</div>}
                      </>
                    )}

                    {/* Conditionally render <br /> only if both Outgoing and Incoming contacts exist */}
                    {(ounit_contact?.[0]?.contact_names || (ounit_contact?.[0]?.emails && ounit_contact[0].emails.length > 0)) &&
                      (ounit_contact?.[1]?.contact_names || (ounit_contact?.[1]?.emails && ounit_contact[1].emails.length > 0)) && (
                      <br />
                    )}

                    {/* Incoming Contact */}
                    {ounit_contact?.[1] && (
                      <>
                        {(ounit_contact?.[1]?.contact_names || (ounit_contact?.[1]?.emails && ounit_contact[1].emails.length > 0)) && (
                          <strong>Incoming LAs Contact:</strong>
                        )}
                        {ounit_contact?.[1]?.contact_names && <div>{ounit_contact[1].contact_names}</div>}
                        {ounit_contact?.[1]?.emails && ounit_contact[1].emails.length > 0 && <div>{ounit_contact[1].emails}</div>}
                      </>
                    )}
                  </div>
                )
              })

            )
            }
          >
            {ounitListingData?.ounits?.map((ounit) => (
              <div
                key={ounit?.ounit_id}
                className={`py-3 px-2 justify-content-end justify-content-lg-around align-items-center bg-none bg-md-light button-triplet-requests flex-grow-1 d-flex gap-3 gap-lg-1`}
              >
                {/* <Toggle/> */}
                <IconButton
                  variant="edit"
                  title={t('organizationalUnits.editUnit.buttonText')}
                  onClick={() => {
                    setSelectedAction("edit");
                    setOunitName(ounit?.ounit_name?.name);
                    setOunitNameLanguage(ounit?.ounit_name?.language);
                    setOunitAbbreviation(ounit?.abbreviation);
                    setOunitContactNames(ounit?.ounit_contact?.[0]?.contact_names || "");
                    setOunitContactEmails(ounit?.ounit_contact?.[0]?.emails || "");
                    setIncomingContactNames(ounit?.ounit_contact?.[1]?.contact_names || "");
                    setIncomingContactEmails(ounit?.ounit_contact?.[1]?.emails || "");
                    setIsIncomingDifferent(!!ounit?.ounit_contact?.[1]);
                    setOunitId(ounit?.ounit_id);
                    setOunitCode(ounit?.ounit_code);
                    setShowModal(true);

                  }}
                />
                <IconButton
                  variant="delete"
                  onClick={() => {
                    setSelectedAction("delete");
                    setOunitId(ounit?.ounit_id);
                    setShowModal(true);

                  }}
                  title={t('organizationalUnits.deleteUnit.buttonText')}
                />
              </div>
            ))}
          </CustomTable>
          <CustomModal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
              setOunitName("");
              setOunitNameLanguage("");
              setOunitAbbreviation("");
              setOunitContactNames("");
              setOunitContactEmails("");
              setIncomingContactNames("");
              setIncomingContactEmails("");
              setIsIncomingDifferent(false);
              setValidated(false);
            }}
            disabledCancelButton={
              loadingAddOunit || loadingUpdateOunit || loadingRemoveOunit
            }
            body={
              (selectedAction === "edit" || selectedAction === "add") && (
                <div>
                  <Form validated={validated} noValidate ref={formRef}>
                    <Form.Group className="mb-3" controlId="formOunitNameLanguage">
                      <Form.Label>{t('organizationalUnits.unitForm.unitLanguage.label')}</Form.Label>
                      <Select
                        options={[
                          { label: '--null--', value: null },
                          ...languagesvalues.map((role) => ({
                            label: role.title,
                            value: role.code,
                          })),
                        ]}
                        placeholder={t('organizationalUnits.unitForm.unitLanguage.placeholder')}
                        onChange={(newSelectOption) => {
                          setOunitNameLanguage(newSelectOption.value);
                        }}
                        value={ ounitNameLanguage ?
                          {
                            label: ounitNameLanguage,
                            value: ounitNameLanguage,
                          }
                          : null
                        }
                        validated={validated}
                      />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOunitName">
                      <Form.Label>{t('organizationalUnits.unitForm.unitName.label')}</Form.Label>
                      <Form.Control
                        placeholder={t('organizationalUnits.unitForm.unitName.placeholder')}
                        value={ounitName}
                        required
                        onChange={(e) => setOunitName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('organizationalUnits.unitForm.unitName.error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOunitEmail">
                      <Form.Label>{t('organizationalUnits.unitForm.unitAbbreviation.label')}</Form.Label>
                      <Form.Control
                        placeholder={t('organizationalUnits.unitForm.unitAbbreviation.placeholder')}
                        value={ounitAbbreviation}
                        onChange={(e) => setOunitAbbreviation(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOunitContactNames">
                      <Form.Label>{t('organizationalUnits.unitForm.unitContactName.label')}</Form.Label>
                      <Form.Control
                        placeholder={t('organizationalUnits.unitForm.unitContactName.label')}
                        value={ounitContactNames}
                        onChange={(e) => setOunitContactNames(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOunitContactEmails">
                      <Form.Label>{t('organizationalUnits.unitForm.unitContactEmail.label')}</Form.Label>
                      <Form.Control
                        placeholder={t('organizationalUnits.unitForm.unitContactEmail.label')}
                        type="email"
                        value={ounitContactEmails}
                        onChange={(e) => setOunitContactEmails(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('organizationalUnits.unitForm.unitContactEmail.error')}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formIncomingDifferent">
                      <Form.Check
                        type="checkbox"
                        label={t('organizationalUnits.unitForm.incomingDifferent.label')}
                        checked={isIncomingDifferent}
                        onChange={(e) => setIsIncomingDifferent(e.target.checked)}
                      />
                    </Form.Group>

                    {isIncomingDifferent && (
                      <>
                        <Form.Group className="mb-3" controlId="formIncomingContactNames">
                          <Form.Label>{t('organizationalUnits.unitForm.incomingContactName.label')}</Form.Label>
                          <Form.Control
                            placeholder={t('organizationalUnits.unitForm.incomingContactName.label')}
                            value={incomingContactNames}
                            onChange={(e) => setIncomingContactNames(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formIncomingContactEmails">
                          <Form.Label>{t('organizationalUnits.unitForm.incomingContactEmail.label')}</Form.Label>
                          <Form.Control
                            placeholder={t('organizationalUnits.unitForm.incomingContactEmail.label')}
                            type="email"
                            value={incomingContactEmails}
                            onChange={(e) => setIncomingContactEmails(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('organizationalUnits.unitForm.incomingContactEmail.error')}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}


                  </Form>
                </div>
              )
            }
            button={
              selectedAction === "add" ? ( // new
                <CustomButton
                  buttonType={"primary"}
                  disabled={loadingAddOunit}
                  text={t('organizationalUnits.addNewUnit.buttonText')}
                  icon={faCheck}
                  hasIcon={true}
                  iconColor={COLORS.white}
                  handleClick={handleAdd}
                />
              ) : selectedAction === "edit" ? ( // edit
                <CustomButton
                  buttonType={"accept"}
                  disabled={loadingUpdateOunit}
                  text={t('organizationalUnits.saveChangesButton')}
                  icon={faFloppyDisk}
                  hasIcon={true}
                  iconColor={COLORS.white}
                  handleClick={handleEdit}
                />
              ) : (
                // delete
                <CustomButton
                  buttonType={"decline"}
                  disabled={loadingRemoveOunit}
                  text={t('organizationalUnits.deleteUnit.buttonLabel')}
                  icon={faTrash}
                  hasIcon={true}
                  iconColor={COLORS.white}
                  handleClick={handleDelete}
                />
              )
            }
            title={
              selectedAction === "add" ? (
                t('organizationalUnits.addNewUnit.title')
              ) : selectedAction === "edit" ? (
                t('organizationalUnits.editUnit.title')
              ) : (
                <>
                  {t('organizationalUnits.deleteUnit.title.part1')}
                  <span className="accounts-and-access-delete-text-on-modal">
                    {t('organizationalUnits.deleteUnit.title.part2')}
                  </span>{" "}
                  {t('organizationalUnits.deleteUnit.title.part3')}
                </>
              )
            }
            subtitle={
              selectedAction === "add" ? (
                t('organizationalUnits.addNewUnit.subTitle')
              ) : selectedAction === "edit" ? (
                t('organizationalUnits.editUnit.subTitle')
              ) : (
                t('organizationalUnits.deleteUnit.subTitle')
              )
            }
            hasIcon
            fullscreen
          />
          <Row>
            <Col>
              <Col style={{ marginTop: 76, marginBottom: 10, display: "flex", justifyContent: "flex-end" }}>
                <CustomButton type="submit" buttonType={"primary"} text={t('organizationalUnits.addNewUnit.buttonText')} icon={faPlus} handleClick={() => {
                  setSelectedAction("add");
                  setOunitName("");
                  setOunitNameLanguage("");
                  setOunitAbbreviation("");
                  setOunitContactNames("");
                  setOunitContactEmails("");
                  setValidated(false);
                  setShowModal(true);

                }} hasIcon styles={{ marginLeft: 20 }} small />
              </Col>
            </Col>
          </Row>
        </>}
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
    </main>
  );
};

export default OrganizationalUnits;
