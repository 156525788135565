import { Form, Row, Col, Container, Spinner } from 'react-bootstrap';
import { useQuery, useMutation } from "@apollo/client";
import CustomButton from '../../components/CustomButton';
import "./index.scss";
import {
    faFloppyDisk
} from "@fortawesome/free-solid-svg-icons";
import toastConfiguration from "../../data/toastConfiguration";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import GET_UKRAINE_ASSISTANCE_INFO from '../../graphql/queries/ukraineAssistance/getUkraineAssistanceInfo';
import CHANGE_UKRAINE_ASSISTANCE_INFO from '../../graphql/mutations/ukraineAssistance/changeUkraineAssistanceInfo';
import validator from 'validator';

import { useTranslation } from 'react-i18next';

const UkraineAssistanceSettings = () => {
    const [apiShelter, setApiShelter] = useState("");
    const [apiHealth, setApiHealth] = useState("");
    const [apiFastTrack, setApiFastTrack] = useState("");
    const [apiScholarship, setApiScholarship] = useState("");
    const [apiMoreInfoUrl, setApiMoreInfoUrl] = useState("");
    const [isUrlValid, setIsUrlValid] = useState(true);

    const { t } = useTranslation();

    const {
        loading: loadingUkraineAssistance,
        error: errorUkraineAssistance,
        data: dataUkraineAssistance,
        refetch: refetchQuery,
    } = useQuery(GET_UKRAINE_ASSISTANCE_INFO, {
        onCompleted: (data) => {
            setApiShelter(data?.getUkraineAssistanceInfo[0]?.shelter || "Unknown");
            setApiHealth(data?.getUkraineAssistanceInfo[0]?.health || "Unknown");
            setApiFastTrack(data?.getUkraineAssistanceInfo[0]?.fast_track || "Unknown");
            setApiScholarship(data?.getUkraineAssistanceInfo[0]?.scholarship || "Unknown");
            setApiMoreInfoUrl(data?.getUkraineAssistanceInfo[0]?.more_info_url || "");
        },
    });

    const [updateUkraineAssistance] = useMutation(CHANGE_UKRAINE_ASSISTANCE_INFO);

    const handleSave = async () => {

        if (!validateSettings()) {
            return;
        }

        const toastId = toast.loading(t('ukraineAssistance.infoFeedback.loading'), toastConfiguration);

        try {
            const result = await updateUkraineAssistance({
                variables: {
                    shelter: apiShelter,
                    health: apiHealth,
                    fastTrack: apiFastTrack,
                    scholarship: apiScholarship,
                    moreInfoUrl: apiMoreInfoUrl,
                },
            });

            if (!result?.errors) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: t('ukraineAssistance.infoFeedback.success'),
                    type: "success",
                    isLoading: false,
                });
                setTimeout(() => {
                    refetchQuery();
                }, 2000);
            }
        } catch (err) {
            toast.update(toastId, {
                ...toastConfiguration,
                render: t('ukraineAssistance.infoFeedback.failure'),
                type: "error",
                isLoading: false,
            });
        }
    };

    const handleCancel = () => {
      refetchQuery();
      setApiShelter(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.shelter || "Unknown");
      setApiHealth(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.health || "Unknown");
      setApiFastTrack(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.fast_track || "Unknown");
      setApiScholarship(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.scholarship || "Unknown");
      setApiMoreInfoUrl(dataUkraineAssistance?.getUkraineAssistanceInfo[0]?.more_info_url || "");
      setIsUrlValid(true);
    };

    const validateSettings = () => {
      // Validate at least one "YES" option is selected or a valid URL is provided
      if (!(apiShelter === "Yes" || apiHealth === "Yes" || apiFastTrack === "Yes" || apiScholarship === "Yes" || apiMoreInfoUrl)) {
        toast.error(t('ukraineAssistance.validateError'));
        return false;
      }
  
      // Validate URL format
      if (apiMoreInfoUrl) {
          const isValid = validator.isURL(apiMoreInfoUrl);
  
          if (!isValid) {
            toast.error(t('ukraineAssistance.invalidUrl'));
            return false;
          }
      }
  
      return true;
    };

    const handleUrlChange = (e) => {
    const url = e.target.value;
    setApiMoreInfoUrl(url);

    // Check if the entered URL is valid
    const isValid = validator.isURL(url)
    setIsUrlValid(isValid);
    };

    return (
        <main className="h-auto text-center mt-4">
            {loadingUkraineAssistance ? (
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <Spinner
                        as="div"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="align-middle my-auto"
                    />
                    <span>{t('loadingMessage')}</span>
                </div>
            ) : errorUkraineAssistance ? (
                <div className="text-center text-danger mx-auto">{t('errorMessage')}</div>
            ) : (
                <div className="ukraine-assistance-box">
                    <div className="ukraine-assistance-title">{t('ukraineAssistance.header')}</div>
                    <span className="h5-style mb-4">{t('ukraineAssistance.description')}</span>
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      handleSave();
                    }}>
                    {/* ---SHELTER--- */}
                    <div className="ukraine-assistance-type-title">{t('ukraineAssistance.form.shelter')}</div>
                    <Form.Group as={Row} className="mx-auto mb-5">
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.yes')}
                                    </div>
                                }
                                name="shelterRadio"
                                id="apiShelterYes"
                                checked={apiShelter === "Yes"}
                                onChange={() => setApiShelter("Yes")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.no')}
                                    </div>
                                }
                                name="shelterRadio"
                                id="apiShelterNo"
                                checked={apiShelter === "No"}
                                onChange={() => setApiShelter("No")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.unkown')}
                                    </div>
                                }
                                name="shelterRadio"
                                id="apiShelterUnknown"
                                checked={apiShelter === "Unknown"}
                                onChange={() => setApiShelter("Unknown")}
                            />
                        </Row>
                    </Form.Group>

                    {/* ---HEALTH--- */}
                    <div className="ukraine-assistance-type-title">{t('ukraineAssistance.form.health')}</div>
                    <Form.Group as={Row} className="mx-auto mb-5">
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.yes')}
                                    </div>
                                }
                                name="healthRadio"
                                id="apiHealthYes"
                                checked={apiHealth === "Yes"}
                                onChange={() => setApiHealth("Yes")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.no')}
                                    </div>
                                }
                                name="healthRadio"
                                id="apiUnknownNo"
                                checked={apiHealth === "No"}
                                onChange={() => setApiHealth("No")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.unkown')}
                                    </div>
                                }
                                name="healthRadio"
                                id="apiHealthUnknown"
                                checked={apiHealth === "Unknown"}
                                onChange={() => setApiHealth("Unknown")}
                            />
                        </Row>
                    </Form.Group>

                    {/* ---FAST TRACK--- */}
                    <div className="ukraine-assistance-type-title">{t('ukraineAssistance.form.fastTrack')}</div>
                    <Form.Group as={Row} className="mx-auto mb-5">
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.yes')}
                                    </div>
                                }
                                name="fastTrackRadio"
                                id="apiFastTrackYes"
                                checked={apiFastTrack === "Yes"}
                                onChange={() => setApiFastTrack("Yes")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.no')}
                                    </div>
                                }
                                name="fastTrackRadio"
                                id="apiFastTrackNo"
                                checked={apiFastTrack === "No"}
                                onChange={() => setApiFastTrack("No")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.unkown')}
                                    </div>
                                }
                                name="fastTrackRadio"
                                id="apiFastTrackYesUnknown"
                                checked={apiFastTrack === "Unknown"}
                                onChange={() => setApiFastTrack("Unknown")}
                            />
                        </Row>
                    </Form.Group>

                    {/* ---SCHOLARSHIP--- */}
                    <div className="ukraine-assistance-type-title">{t('ukraineAssistance.form.scholarship')}</div>
                    <Form.Group as={Row} className="mx-auto mb-5">
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.yes')}
                                    </div>
                                }
                                name="scholarshipRadio"
                                id="apiScholarshipYes"
                                checked={apiScholarship === "Yes"}
                                onChange={() => setApiScholarship("Yes")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.no')}
                                    </div>
                                }
                                name="scholarshipRadio"
                                id="apiScholarshipNo"
                                checked={apiScholarship === "No"}
                                onChange={() => setApiScholarship("No")}
                            />
                        </Row>
                        <Row sm={4} className="d-flex align-items-center">
                            <Form.Check
                                type="radio"
                                label={
                                    <div className="ukraine-assistance-radio-button">
                                        {t('ukraineAssistance.form.options.unkown')}
                                    </div>
                                }
                                name="scholarshipRadio"
                                id="apiScholarshipUnknown"
                                checked={apiScholarship === "Unknown"}
                                onChange={() => setApiScholarship("Unknown")}
                            />
                        </Row>
                    </Form.Group>

                    {/* ---MORE INFO URL--- */}
                    <div className="ukraine-assistance-type-title">{t('ukraineAssistance.moreInfo.header')}</div>
                    <Form.Group controlId="formBasicName">
                      <Form.Control
                        type="text"
                        placeholder="https://www.example.com"
                        onChange={handleUrlChange}
                        value={apiMoreInfoUrl}
                        className={isUrlValid ? '' : 'is-invalid'}
                      />
                      <Form.Control.Feedback type="invalid">
                      {t('ukraineAssistance.moreInfo.error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Container>
                        <Row>
                            <Col>
                                <Col
                                    style={{
                                        marginTop: 76,
                                        marginBottom: 10,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <CustomButton
                                        buttonType={"secondary"}
                                        text={"Cancel"}
                                        hasIcon={false}
                                        handleClick={handleCancel}
                                    />
                                    <CustomButton type="submit" buttonType={"primary"} text={t('ukraineAssistance.moreInfo.buttonText')} hasIcon icon={faFloppyDisk} styles={{ marginLeft: 20 }} small />
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    </Form>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            )}
        </main>
    );
};

export default UkraineAssistanceSettings;
